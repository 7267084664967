export const formatNumber = (value?: number, symbol = '', decimalLength = 2): string => {
  const chunkDelimiter = '.';
  const decimalDelimiter = ',';
  const chunkLength = 3;

  const result = `\\d(?=(\\d{${chunkLength}})+'${(decimalLength > 0 ? '\\D' : '$')})`;
  const pom = value ?? 0;
  const num = (Math.round(pom * 100) / 100).toFixed(Math.max(0, decimalLength));

  const formatValue = (decimalDelimiter ? num.replace('.', decimalDelimiter) : num).replace(new RegExp(result, 'g'), `$&${chunkDelimiter}`);
  return `${formatValue} ${symbol}`;
}