import { Button, Drawer, Space } from "antd";
import React from "react";
import { ActivityInterface, QueryParams, RootState, closeActivityDrawer, createActivity, i18n, submitActivityForm, updateActivity, useAppDispatch, useAppSelector } from "../../common";
import { ActivityForm, allActivityFormItems } from "../forms";

interface Props {
  queryParams?: QueryParams;
}

const ActivityDrawer = ({ queryParams }: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  const activityDrawer = useAppSelector((state: RootState) => state.componentState.drawer.activity);

  const handleClose = () => {
    dispatch(closeActivityDrawer());
  };

  const onActivityCreate = (data: ActivityInterface) => {
    dispatch(createActivity(data, queryParams));
    dispatch(closeActivityDrawer());
  };

  const onActivityUpdate = (data: ActivityInterface) => {
    dispatch(updateActivity(data.id!, data, queryParams));
    dispatch(closeActivityDrawer());
  };

  if (!activityDrawer.data) {
    return (
      <Drawer
        title={i18n.translate('components.drawer.activity.titleCreate')}
        open={activityDrawer.open}
        onClose={handleClose}
        destroyOnClose
        width={650}
        extra={
          <Space>
            <Button onClick={handleClose}>{i18n.translate('components.drawer.buttons.cancel')}</Button>
            <Button onClick={() => dispatch(submitActivityForm())} type="primary">
              {i18n.translate('components.drawer.buttons.create')}
            </Button>
          </Space>
        }
      >
        <ActivityForm
          disabled={activityDrawer.disabled}
          initialValues={activityDrawer.initialValues ? activityDrawer.initialValues : undefined}
          onActivityFormFinish={(values) => onActivityCreate(values)}
          formItems={allActivityFormItems.filter((f) => (f !== 'activityStatusButton'))}
        />
      </Drawer>
    )
  }
  return (
    <Drawer
      title={i18n.translate('components.drawer.activity.titleEdit')}
      open={activityDrawer.open}
      onClose={handleClose}
      destroyOnClose
      width={650}
      extra={
        <Space>
          <Button onClick={handleClose}>{i18n.translate('components.drawer.buttons.cancel')}</Button>
          <Button onClick={() => dispatch(submitActivityForm())} type="primary">
            {i18n.translate('components.drawer.buttons.edit')}
          </Button>
        </Space>
      }
    >
      <ActivityForm 
        activity={activityDrawer.data}
        onActivityFormFinish={(values) => onActivityUpdate(values)}
        formItems={allActivityFormItems.filter((f) => (f !== 'activityStatus'))}
        disabled={['activityType', 'client', 'salesOpportunity', 'salesOpportunityStatus']}
      ></ActivityForm>
    </Drawer>
  )

}


export default ActivityDrawer;