import { Breadcrumb, Card } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ClientInterface, createClient, i18n, useAppDispatch } from '../../../common';
import { ClientForm, allClientFormItems } from '../../../components';
import { AppRoutes } from '../_router/app.routes';

const ClientCreatePage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const onClientCreate = (data: ClientInterface) => {
    dispatch(createClient(data));
  };

  return (
    <>
      <Breadcrumb style={{ marginBottom: 24 }} items={[
        { title: <Link to={AppRoutes.DASHBOARD.fullPath} key={AppRoutes.DASHBOARD.fullPath}>{i18n.translate('breadcrumb.homepage')}</Link> },
        { title: <Link to={AppRoutes.CLIENTS.fullPath} key={AppRoutes.CLIENTS.fullPath}>{i18n.t('breadcrumb.clients')}</Link> },
        { title: i18n.translate('breadcrumb.clientCreate') }]}
      />
      <Card>
        <ClientForm
          onClientFormFinish={(values) => onClientCreate(values)}
          formItems={allClientFormItems.filter((f) => (f !== 'active'))}
          disabled={location.state ? location.state.map((f: {formItem: string, value: any}) => f.formItem) : undefined}
          initialValues={location.state ? location.state : undefined}
          title='Kreiraj novog klijenta'
          submitBtnLabel='Kreiraj klijenta'></ClientForm>
      </Card>
    </>
  );
}

export default ClientCreatePage;
