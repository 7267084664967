import { Breadcrumb, Card, Space, Tabs, TabsProps, Typography } from 'antd';
import React from 'react';
import { i18n } from '../../../common';
import { CompanyDetails, ExportClients, ImportClients, ReservationCategories } from '../../../components';

const { Title } = Typography;

const ApplicationSettingsPage = () => {
  
  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.translate('pages.applicationSettings.tabs.companyDetails'),
      children: <CompanyDetails />,
    },
    {
      key: '2',
      label: i18n.translate('pages.applicationSettings.tabs.reservationCategories'),
      children: <ReservationCategories />,
    },
    {
      key: '3',
      label: i18n.translate('pages.applicationSettings.tabs.exportClients'),
      children: <ExportClients />,
    },
    {
      key: '4',
      label: i18n.translate('pages.applicationSettings.tabs.importClients'),
      children: <ImportClients />,
    },
  ];

  return (
    <>
      <Breadcrumb items={[{ title: i18n.translate('breadcrumb.homepage') }, { title: i18n.translate('breadcrumb.applicationSettings') }]} style={{ marginBottom: 24 }} />
      <Card>
        <Space direction="vertical" size={16} style={{ display: 'flex' }}>
          <Title level={2} style={{ margin: 0 }}>{i18n.translate('pages.applicationSettings.title')}</Title>
          <Tabs defaultActiveKey="1" items={tabItems} destroyInactiveTabPane />
        </Space>
      </Card>
    </>

  );
}

export default ApplicationSettingsPage;
