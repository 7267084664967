import React, { useEffect, useState } from 'react';
import { Avatar, Button, Checkbox, Dropdown, MenuProps, Space, Tag, Typography, theme } from "antd";
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DownOutlined, CloseOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';

import { FilterProps } from '../../tables/table.component';
import { QueryParams, RootState, UserInterface, getUserFilter, i18n, useAppDispatch, useAppSelector } from '../../../common';
import Search from '../search/search.component';
import { UserIcon } from '../../icons';

const { Title } = Typography;
const { useToken } = theme;

interface FilterValue {
  id: number;
  checked: boolean;
}

const UserFilter = ({ dataKey, handleFilterChange, nullOption }: FilterProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [filterValues, setFilterValues] = useState<Array<FilterValue>>([]);

  const [selectedUsers, setSelectedUsers] = useState<Array<UserInterface>>([]);

  const [open, setOpen] = useState(false);

  const userFilter = useAppSelector((state: RootState) => state.user.filter);

  const componentQueryParams: QueryParams = { pagination: { current: 1, pageSize: 999999 } };

  useEffect(() => {
    dispatch(getUserFilter(componentQueryParams));
  }, []
  )

  useEffect(() => {
    if (userFilter && !filterValues.length) {
      const filters = userFilter.map((user: UserInterface) => ({ id: user.id!, checked: false }));
      if (nullOption) filters.unshift({ id: 0, checked: false });
      setFilterValues(filters);
    }
  }, [userFilter]
  )

  const onValueChange = (value: boolean, user: UserInterface) => {
    if (value) {
      const newSelectedUsers = [...selectedUsers]
      newSelectedUsers.push(user)
      setSelectedUsers([...newSelectedUsers])
    } else {
      const newSelectedUsers = [...selectedUsers]
      const index = newSelectedUsers.findIndex(x => x.id === user.id)
      newSelectedUsers.splice(index, 1)
      setSelectedUsers([...newSelectedUsers])
    }
    setFilterValues(filterValues.map((filterValue: FilterValue) => filterValue.id === user.id
      ? { ...filterValue, checked: value }
      : { ...filterValue }))
  }

  const onFilterConfirm = () => {
    if (filterValues.filter((fv: FilterValue) => fv.checked).length === 0) handleFilterChange();
    else handleFilterChange({name: dataKey, operator: 'EQ', value: filterValues.filter((f: FilterValue) => f.checked).map((f: FilterValue) => f.id === 0 ? 'null' : f.id.toString())});
    setOpen(false);
  }

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  const onCloseTag = (user: UserInterface, index: number) => {
    const newSelectedUsers = [...selectedUsers];
    newSelectedUsers.splice(index, 1);
    setSelectedUsers([...newSelectedUsers]);
    setFilterValues(filterValues.map((filterValue: FilterValue) => filterValue.id === user.id
      ? { ...filterValue, checked: false }
      : { ...filterValue }))
  };

  const onSearchChange = (keys: Array<string>, value: string) => {
    dispatch(getUserFilter({ pagination: { current: 1, pageSize: 999999 }, search: { keys, value } }));
  };

  // konfiguracija menu-a unutar dropdowna
  const menu = () => {
    const items: MenuProps['items'] = userFilter?.map((user: UserInterface, index: number) => {
      const item: ItemType = {
        label: (
          <Checkbox
            onChange={(e: CheckboxChangeEvent) => {
              onValueChange(e.target.checked, user);
            }}
            checked={filterValues.find((fv: FilterValue) => fv.id === user.id)?.checked}
          >
            <Space direction='horizontal' size="small">{user.profileImageUrl ? <Avatar src={user.profileImageUrl} size="small" style={{ marginRight: 4, marginBlock: 2 }}/> : <Avatar style={{ marginRight: 4, marginBlock: 2 }} size="small" icon={<UserIcon />} />}{user.fullName}</Space>
          </Checkbox>
        ),
        key: index + 1
      }
      return item;
    })

    if (nullOption) {
      const nullUser = { id: 0, firstName: '', lastName: '', email: '', phoneNumber: '', address: '', fullName: i18n.translate('components.filters.nullOptions.noAgent')};

      const nullItem: ItemType = {
        label: (
          <Checkbox
            onChange={(e: CheckboxChangeEvent) => {
              onValueChange(e.target.checked, nullUser);
            }}
            checked={filterValues.find((fv: FilterValue) => fv.id === nullUser.id)?.checked}
          >
            <Space direction='horizontal' size="small">{nullUser.fullName}</Space>
          </Checkbox>
        ),
        key: 0
      }
      items?.unshift(nullItem);
    }

    return { items };
  };

  // Dropdown i menu style - nažalost ant design nije ponudio kvalitetnije rješenje, a nisam htio iz nule raditi dropdown i menu
  const { token } = useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8
  };

  const menuStyle = {
    boxShadow: 'none',
    padding: 0,
    margin: 0,
    height: 250,
    overflow: 'auto'
  };

  return (
    // <Select key={dataKey} onChange={onFilterChange} options={[{ value: 1, label: 'Admin' },{ value: 2, label: 'Marketing' }]}/>
    <Dropdown
      menu={menu()}
      open={open}
      onOpenChange={handleOpenChange}
      trigger={['click']} dropdownRender={(menuRender) => (
        <div style={contentStyle}>
          <Title level={3} style={{ paddingLeft: 12, paddingRight: 12, paddingBottom: 8 }}>{i18n.t('components.filters.user')}</Title>
          <Space direction="vertical" size="small" style={{ display: 'flex', paddingLeft: 12, paddingBottom: 8 }}>
            {selectedUsers?.map((user: UserInterface, index: number) =>
              <Tag color="processing" closable closeIcon={<CloseOutlined />} onClose={() => onCloseTag(user, index)} style={{ borderRadius: 16 }} key={user.id} icon={user.profileImageUrl ? <Avatar size="small" style={{marginRight: 4, marginBlock: 2 }} src={user.profileImageUrl} /> : <Avatar style={{ marginRight: 4, marginBlock: 2 }} size="small" icon={<UserIcon />} />}>{user.fullName}</Tag>
            )}
          </Space>
          <Space direction="vertical" size="small" style={{ display: 'flex', paddingLeft: 12, paddingBottom: 8 }}>
            <Search dataKeys={['fullName']} placeholder='' handleSearchChange={onSearchChange} />
          </Space>
          {React.cloneElement(menuRender as React.ReactElement, { style: menuStyle })}
          <Space style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 16 }}>
            <Button type='link' onClick={onFilterConfirm}>
              Filtriraj
            </Button>
          </Space>
        </div>
      )}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          {i18n.t('components.filters.user')}{filterValues.find((f: FilterValue) => f.checked) && `(${filterValues.filter((f: FilterValue) => f.checked).length})`}
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  )
}

export default UserFilter;
