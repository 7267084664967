import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

import { NavigationService, RootState, setTenant, useAppDispatch, useAppSelector } from '../../common';
import { GoToSubdomainPage } from '../global/goToSubdomain/goToSubdomain.page';
import { MainRouting } from './main.routes';

declare const CHAT_START: any;

const MainRouter = () => {
  const userAuth = useAppSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (userAuth.isAuthenticated) {
      CHAT_START(process.env.REACT_APP_CHAT_URL, userAuth.accessToken);
    }
  }, []);

  const RoutesWrapper = ({ children }: {
    children: React.ReactNode;
  }) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { origin, host } = window.location;
    const { REACT_APP_PRIMARY_DOMAIN, REACT_APP_TENANT_ROOT } = process.env;

    const isPrimaryDomain = origin === REACT_APP_PRIMARY_DOMAIN;

    useEffect(() => {
      NavigationService.setNavigateFunction(navigate);

      if (!isPrimaryDomain) {
        const tenant = [REACT_APP_TENANT_ROOT, host.split('.')[0]].join('_');
        dispatch(setTenant(tenant));
      }
    }, [])

    return <Routes>
      {isPrimaryDomain ? <Route path="*" element={<GoToSubdomainPage />}></Route> : children}
    </Routes>
  };

  return (
    <Router>
      <RoutesWrapper>
        {NavigationService.generateRoutes(MainRouting(userAuth), userAuth)}
      </RoutesWrapper>
    </Router>
  )
}

export default MainRouter;
