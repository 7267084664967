import React, { useEffect, useState } from 'react';
import { Avatar, Button, Form, Input, Space, Upload, UploadProps, notification } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { ApplicationSettingsInterface, UserService, i18n } from '../../../common';
import { UserIcon } from '../../icons';

interface Props {
  trigger: number;
  applicationSettings?: ApplicationSettingsInterface;
  onApplicationSettingsFormFinish?: (values: any) => void;
}

function ApplicationSettingsForm({ trigger, applicationSettings, onApplicationSettingsFormFinish }: Props) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (onApplicationSettingsFormFinish && trigger > 0) {
      form.submit()
    }
  }, [trigger]);

  const [imageUrl, setImageUrl] = useState<string | undefined>(applicationSettings?.image ? applicationSettings?.image : undefined);

  useEffect(() => {
    form.setFieldValue('image', imageUrl)
  }, [imageUrl]);

  const handleImageChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'done') {
      UserService.getInfiniteUrl(info.file.response[0].uuid).subscribe({
        next: (response: any) => {
          setImageUrl(response.url);
        },
        error: (error: Error) => {
          notification.error({ message: error.message, duration: 2 });
        }
      })
    }
  };
  
  return (<>
    <Form
      name='applicationSettings'
      form={form}
      colon={false}
      layout='vertical'
      initialValues={applicationSettings}
      onFinish={onApplicationSettingsFormFinish}
    >
      <Form.Item
        name="image"
        label={i18n.translate(`components.forms.applicationSettings.companyLogo`)}
      >
        <Space direction='vertical' size={16} style={{ display: 'flex' }}>
          {imageUrl ? <Avatar size={64} src={imageUrl} /> : <Avatar size={64} icon={<UserIcon />} />}
          <Space>
            <Upload
              name='files'
              action={process.env.REACT_APP_DMS_URL}
              showUploadList={false}
              onChange={handleImageChange}
            >
              {imageUrl ? <Button>{i18n.t('components.forms.user.editImage')}</Button> : <Button>{i18n.t('components.forms.user.addImage')}</Button>}
            </Upload>
            {imageUrl ? <Button type="link" danger onClick={() => {setImageUrl('')}}>{i18n.t('components.forms.user.deleteImage')}</Button> : <></>}
          </Space>
        </Space>
      </Form.Item>
      <Form.Item
        name="companyName"
        label={i18n.translate(`components.forms.applicationSettings.companyName`)} 
        rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
      >
        <Input size="small" />
      </Form.Item>
      <Form.Item
        name='oib'
        label={i18n.translate(`components.forms.applicationSettings.oib`)} 
        rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
      >
        <Input size="small" />
      </Form.Item>
      <Form.Item
        name='address'
        label={i18n.translate(`components.forms.applicationSettings.address`)}
        rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
      >
        <Input size="small" />
      </Form.Item>
    </Form>
  </>
  );
}

export default ApplicationSettingsForm;
