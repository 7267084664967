import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Avatar, Space, Tag, Typography } from 'antd';
import { useAppDispatch, useAppSelector, RootState, QueryParams, i18n, getClients, ClientInterface } from '../../common';
import { AreaFilter, Search, UserFilter } from '../filters';
import { UserIcon } from '../icons';
import Table, { TableFilter, TableParams, TableSearch } from './table.component';

const { Text } = Typography;

type ClientsTableColumnType = 'name' | 'user' | 'area';
type ClientsTableFilterType = 'area' | 'user';

export const allClientsTableColumns: Array<ClientsTableColumnType> = ['name', 'user', 'area'];
export const allClientsTableFilters: Array<ClientsTableFilterType> = ['area', 'user'];

interface Props {
  onAddClientsSelectionChanged?: (values: any) => void;
  injectedQueryParams?: QueryParams;
  columns?: Array<ClientsTableColumnType>;
  filters?: Array<ClientsTableFilterType>;
  excludedClientIds?: Array<number | undefined>;
}

const AddClientsToCampaignTable = ({ onAddClientsSelectionChanged, injectedQueryParams, columns, filters, excludedClientIds }: Props) => {
  const dispatch = useAppDispatch();

  const componentQueryParams: QueryParams = {
    ...injectedQueryParams,
    includes: [
      ...(injectedQueryParams?.includes || []),
      'user',
      'areas'
    ],
    filters: [{ name: 'user.id', operator: 'NEQ', value: 'null' }],
    pagination: {current: 1, pageSize: 999999}
  };

  const [queryParams, setQueryParams] = useState<QueryParams>(componentQueryParams);

  useEffect(() => {
    dispatch(getClients(queryParams));
  }, [queryParams]
  )

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: ClientInterface[]) => onAddClientsSelectionChanged && onAddClientsSelectionChanged(selectedRows)
  };

  const clients = useAppSelector((state: RootState) => state.client.all);

  const filteredClients = clients.data?.filter(c => !excludedClientIds?.includes(c.id!));

  const tableColumns: Array<ClientsTableColumnType> = columns || allClientsTableColumns;

  const clientsTableColumns: ColumnsType<ClientInterface> = [
    {
      title: i18n.t('components.table.addClientsToCampaignTable.name'),
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      render: (dataIndex: string, client: ClientInterface) => client.name,
      sorter: true,
      width: 150
    },
    {
      title: i18n.t('components.table.addClientsToCampaignTable.user'),
      dataIndex: 'user.fullName',
      key: 'user',
      ellipsis: true,
      render: (dataIndex: string, client: ClientInterface) => client.user && client.user.profileImageUrl ? <Space><Avatar src={client.user.profileImageUrl} /><Text>{client.user.fullName}</Text></Space> : <Space><Avatar icon={<UserIcon />} /><Text>{client.user?.fullName}</Text></Space>,
      width: 300
    },
    {
      title: i18n.t('components.table.addClientsToCampaignTable.area'),
      dataIndex: 'area.name',
      key: 'area',
      ellipsis: true,
      render: (dataIndex: string, client: ClientInterface) => (client.areas?.length && client.areas?.length > 0) ? client.areas.map((area, index) => <Tag key={index}>{area.name}</Tag>) : '-',
      width: 300
    }
  ].filter((c) => tableColumns.includes(c.key! as ClientsTableColumnType));

  interface Filter {
    filterKey: ClientsTableFilterType;
    tableFilter: TableFilter;
  }

  const tableFilters: Array<ClientsTableFilterType> = filters || allClientsTableFilters;

  const clientsTableFilters: Array<Filter> = [
    { filterKey: 'area' as ClientsTableFilterType, tableFilter: {component: AreaFilter, key: 'areas.id' }},
    { filterKey: 'user' as ClientsTableFilterType, tableFilter: {component: UserFilter, key: 'user.id' }},
  ].filter((f) => tableFilters.includes(f.filterKey as ClientsTableFilterType))

  const searchFields: Array<TableSearch> = [
    { component: Search, keys: ['name', 'user.fullName'], placeholder: i18n.t('components.table.addClientsToCampaignTable.searchPlaceholder') }
  ]

  const handleTableChange = (tableParams: TableParams) => {
    setQueryParams({
      pagination: componentQueryParams.pagination,
      filters: [
        ...(tableParams.filters || []),
        ...(componentQueryParams?.filters || []),
      ],
      sorters: tableParams.sorters,
      includes: componentQueryParams.includes,
      search: tableParams.search
    })
  };

  if (!filteredClients) return null;
  return (
    <Table
      injectedRowSelection={rowSelection}
      columns={clientsTableColumns}
      dataSource={filteredClients}
      rowKey={"id"}
      handleTableChange={handleTableChange}
      total={clients.total && excludedClientIds?.length ? clients.total - excludedClientIds.length : clients.total}
      filters={clientsTableFilters.map((filter: Filter) => filter.tableFilter)}
      searchFields={searchFields}
    />
  )
}

export default AddClientsToCampaignTable;
