import { Breadcrumb, Card, Col, Row, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  QueryParams,
  RootState,
  getAnnouncements,
  getNews,
  i18n,
  useAppDispatch,
  useAppSelector,
} from '../../../common';
import { AnnouncementsComponent, NewsComponent } from '../../../components';

const { Title } = Typography;

const NotificationsPage = () => {
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  const news = useAppSelector((state: RootState) => state.news.all);
  const announcements = useAppSelector((state: RootState) => state.announcements.all);
  
  const newsComponentQueryParams: QueryParams = {
    includes: ['newsSeen,users,files'],
    filters: [{ name: 'users.id', operator: 'EQ', value: currentUser!.id!.toString() }],
    sorters: [{ columnKey: 'id', order: 'descend' }]
  };
  const [newsQueryParams, setNewsQueryParams] = useState<QueryParams>(newsComponentQueryParams);

  const announcementComponentQueryParams: QueryParams = {
    includes: ['announcementSeen,users,announcementType,announcementAction'],
    filters: [{ name: 'users.id', operator: 'EQ', value: currentUser!.id!.toString() }],
    sorters: [{ columnKey: 'id', order: 'descend' }]
  };
  const [announcementQueryParams, setAnnouncementQueryParams] = useState<QueryParams>(announcementComponentQueryParams);

  useEffect(() => {
    dispatch(getAnnouncements(announcementQueryParams));
  }, [announcementQueryParams]);

  useEffect(() => {
    dispatch(getNews(newsQueryParams));
  }, [newsQueryParams]);

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.t('header.notifications.announcements'),
      children: 
        <AnnouncementsComponent 
          announcements={announcements.data}
          onPaginationChange={(page: number, size: number) => setAnnouncementQueryParams({...announcementQueryParams, pagination: { current: page, pageSize: size }})}
          total={announcements.total ? announcements.total : 0}
        />
    },
    {
      key: '2',
      label: i18n.t('header.notifications.news'),
      children:
        <NewsComponent 
          news={news.data}
          onPaginationChange={(page: number, size: number) => setNewsQueryParams({...newsQueryParams, pagination: { current: page, pageSize: size }})}
          total={news.total ? news.total : 0}
          injectedQueryParams={newsQueryParams}
        />
    },
  ];

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: 24 }}
        items={[{ title: i18n.translate('breadcrumb.homepage') }, { title: i18n.translate('breadcrumb.news') }]}
      />
      <Card>
        <Row justify={'space-between'} align={'middle'}>
          <Col>
            <Title level={2} style={{ margin: 0 }}>
              {i18n.t('pages.notifications.title')}
            </Title>
          </Col>
        </Row>
        <Tabs defaultActiveKey="1" items={tabs} />
      </Card>
    </>
  );
};

export default NotificationsPage;
