import React from "react";
import { Link } from "react-router-dom";
import { AnnouncementActionEnum, AnnouncementTypeEnum } from "../enums";
import { i18n } from "../services";
import { AnnouncementInterface } from "../interfaces";
import { AppRoutes } from "../../pages/app";


export interface AnnouncementType {
  id: number;
  update?: (announcement: AnnouncementInterface) => JSX.Element;
  create?: (announcement: AnnouncementInterface) => JSX.Element;
  addUser?: (announcement: AnnouncementInterface) => JSX.Element;
  addUserUpdate?: (announcement: AnnouncementInterface) => JSX.Element;
}

export const AnnouncementTypes = (): Array<AnnouncementType> => [
  { id: AnnouncementTypeEnum.CAMPAIGN,
    update: (announcement) => <><Link to={`${AppRoutes.USERS.fullPath}/${announcement.creator?.id}}`}>{announcement.creator?.fullName}</Link> {i18n.t(`common.constants.announcementType.text.updated`)} <b>{i18n.t("components.other.announcement.campaign")}</b> <Link to={`${AppRoutes.CAMPAIGNS.fullPath}/${JSON.parse(announcement?.value)?.id}}`}>#{JSON.parse(announcement?.value)?.id} - {JSON.parse(announcement?.value)?.name}</Link></>
  },
  { id: AnnouncementTypeEnum.ASSIGNMENT,
    create: (announcement) => <><Link to={`${AppRoutes.USERS.fullPath}/${announcement.creator?.id}}`}>{announcement.creator?.fullName}</Link> {i18n.t(`common.constants.announcementType.text.created`)} <b>{i18n.t("components.other.announcement.assignment")}</b> <Link to={`${AppRoutes.ASSIGNMENTS.fullPath}/${JSON.parse(announcement?.value)?.id}}`}>#{JSON.parse(announcement?.value)?.id} - {JSON.parse(announcement?.value)?.name}</Link></>,
    update: (announcement) => <><Link to={`${AppRoutes.USERS.fullPath}/${announcement.creator?.id}}`}>{announcement.creator?.fullName}</Link> {i18n.t(`common.constants.announcementType.text.updated`)} <b>{i18n.t("components.other.announcement.assignment")}</b> <Link to={`${AppRoutes.ASSIGNMENTS.fullPath}/${JSON.parse(announcement?.value)?.id}}`}>#{JSON.parse(announcement?.value)?.id} - {JSON.parse(announcement?.value)?.name}</Link></>,
    addUser: (announcement) => <><Link to={`${AppRoutes.USERS.fullPath}/${announcement.creator?.id}}`}>{announcement.creator?.fullName}</Link> {i18n.t(`common.constants.announcementType.text.addUser`)} <b>{i18n.t("components.other.announcement.assignment")}</b> <Link to={`${AppRoutes.ASSIGNMENTS.fullPath}/${JSON.parse(announcement?.value)?.id}}`}>#{JSON.parse(announcement?.value)?.id} - {JSON.parse(announcement?.value)?.name}</Link></>,
  },
  { id: AnnouncementTypeEnum.SALES_OPPORTUNITY,
    create: (announcement) => <><Link to={`${AppRoutes.USERS.fullPath}/${announcement.creator?.id}}`}>{announcement.creator?.fullName}</Link> {i18n.t(`common.constants.announcementType.text.created`)} <b>{i18n.t("components.other.announcement.salesOpportunity")}</b> <Link to={`${AppRoutes.SALES_OPPORTUNITIES.fullPath}/${JSON.parse(announcement?.value)?.id}}`}>#{JSON.parse(announcement?.value)?.id} - {JSON.parse(announcement?.value)?.name}</Link> {JSON.parse(announcement?.value)?.clientId ? i18n.t("components.other.announcement.client") : ''} <Link to={`${AppRoutes.CLIENTS.fullPath}/${JSON.parse(announcement?.value)?.clientId}}`}>{JSON.parse(announcement?.value)?.clientName}</Link></>,
    update: (announcement) => <><Link to={`${AppRoutes.USERS.fullPath}/${announcement.creator?.id}}`}>{announcement.creator?.fullName}</Link> {i18n.t(`common.constants.announcementType.text.updated`)} <b>{i18n.t("components.other.announcement.salesOpportunity")}</b> <Link to={`${AppRoutes.SALES_OPPORTUNITIES.fullPath}/${JSON.parse(announcement?.value)?.id}}`}>#{JSON.parse(announcement?.value)?.id} - {JSON.parse(announcement?.value)?.name}</Link> {JSON.parse(announcement?.value)?.clientId ? i18n.t("components.other.announcement.client") : ''} <Link to={`${AppRoutes.CLIENTS.fullPath}/${JSON.parse(announcement?.value)?.clientId}}`}>{JSON.parse(announcement?.value)?.clientName}</Link></>,
    addUser: (announcement) => <><Link to={`${AppRoutes.USERS.fullPath}/${announcement.creator?.id}}`}>{announcement.creator?.fullName}</Link> {i18n.t(`common.constants.announcementType.text.addUser`)} <b>{i18n.t("components.other.announcement.salesOpportunity")}</b> <Link to={`${AppRoutes.SALES_OPPORTUNITIES.fullPath}/${JSON.parse(announcement?.value)?.id}}`}>#{JSON.parse(announcement?.value)?.id} - {JSON.parse(announcement?.value)?.name}</Link> {JSON.parse(announcement?.value)?.clientId ? i18n.t("components.other.announcement.client") : ''} <Link to={`${AppRoutes.CLIENTS.fullPath}/${JSON.parse(announcement?.value)?.clientId}}`}>{JSON.parse(announcement?.value)?.clientName}</Link></>,
    addUserUpdate: (announcement) => <><Link to={`${AppRoutes.USERS.fullPath}/${announcement.creator?.id}}`}>{announcement.creator?.fullName}</Link> {i18n.t(`common.constants.announcementType.text.addUserUpdate`)} <b>{i18n.t("components.other.announcement.salesOpportunityAlt")}</b> <Link to={`${AppRoutes.SALES_OPPORTUNITIES.fullPath}/${JSON.parse(announcement?.value)?.id}}`}>#{JSON.parse(announcement?.value)?.id} - {JSON.parse(announcement?.value)?.name}</Link> {JSON.parse(announcement?.value)?.clientId ? i18n.t("components.other.announcement.client") : ''} <Link to={`${AppRoutes.CLIENTS.fullPath}/${JSON.parse(announcement?.value)?.clientId}}`}>{JSON.parse(announcement?.value)?.clientName}</Link></>,
  },
  { id: AnnouncementTypeEnum.CLIENT,
    create: (announcement) => <><Link to={`${AppRoutes.USERS.fullPath}/${announcement.creator?.id}}`}>{announcement.creator?.fullName}</Link> {i18n.t(`common.constants.announcementType.text.created`)} <b>{i18n.t("components.other.announcement.client")}</b> <Link to={`${AppRoutes.CLIENTS.fullPath}/${JSON.parse(announcement?.value)?.id}}`}>#{JSON.parse(announcement?.value)?.id} - {JSON.parse(announcement?.value)?.name}</Link></>,
    update: (announcement) => <><Link to={`${AppRoutes.USERS.fullPath}/${announcement.creator?.id}}`}>{announcement.creator?.fullName}</Link> {i18n.t(`common.constants.announcementType.text.updated`)} <b>{i18n.t("components.other.announcement.client")}</b> <Link to={`${AppRoutes.CLIENTS.fullPath}/${JSON.parse(announcement?.value)?.id}}`}>#{JSON.parse(announcement?.value)?.id} - {JSON.parse(announcement?.value)?.name}</Link></>,
    addUser: (announcement) => <><Link to={`${AppRoutes.USERS.fullPath}/${announcement.creator?.id}}`}>{announcement.creator?.fullName}</Link> {i18n.t(`common.constants.announcementType.text.addUserAlt`)} <b>{i18n.t("components.other.announcement.clientAlt")}</b> <Link to={`${AppRoutes.CLIENTS.fullPath}/${JSON.parse(announcement?.value)?.id}}`}>#{JSON.parse(announcement?.value)?.id} - {JSON.parse(announcement?.value)?.name}</Link></>,
  },
  { id: AnnouncementTypeEnum.CAMPAIGN_SALES_OPPORTUNITY,
    create: (announcement) => <><Link to={`${AppRoutes.USERS.fullPath}/${announcement.creator?.id}}`}>{announcement.creator?.fullName}</Link> {i18n.t(`common.constants.announcementType.text.createdAlt`)} <b>{i18n.t("components.other.announcement.campaignSalesOpportunity")}</b> <Link to={`${AppRoutes.CAMPAIGNS.fullPath}/${JSON.parse(announcement?.value)?.id}}`}>#{JSON.parse(announcement?.value)?.id} - {JSON.parse(announcement?.value)?.name}</Link> {JSON.parse(announcement?.value)?.clientId ? i18n.t("components.other.announcement.client") : ''} <Link to={`${AppRoutes.CLIENTS.fullPath}/${JSON.parse(announcement?.value)?.clientId}}`}>{JSON.parse(announcement?.value)?.clientName}</Link></>,
  },
]

export const getAnnouncementElement = (announcement: AnnouncementInterface) => {
  const announcementTypeId = parseInt(announcement.announcementType?.id as string, 10);
  const announcementActionId = parseInt(announcement.announcementAction?.id as string, 10);

  switch (announcementActionId) {
    case AnnouncementActionEnum.CREATE:
      return AnnouncementTypes().find(at => at.id === announcementTypeId)?.create && AnnouncementTypes().find(at => at.id === announcementTypeId)?.create!(announcement)
    case AnnouncementActionEnum.UPDATE:
      return AnnouncementTypes().find(at => at.id === announcementTypeId)?.update && AnnouncementTypes().find(at => at.id === announcementTypeId)?.update!(announcement)
    case AnnouncementActionEnum.ADD_USER:
      return AnnouncementTypes().find(at => at.id === announcementTypeId)?.addUser && AnnouncementTypes().find(at => at.id === announcementTypeId)?.addUser!(announcement)
    case AnnouncementActionEnum.ADD_USER_UPDATE:
      return AnnouncementTypes().find(at => at.id === announcementTypeId)?.addUserUpdate && AnnouncementTypes().find(at => at.id === announcementTypeId)?.addUserUpdate!(announcement)
    default:
      return <></>
  }
}
