import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Avatar, Space } from 'antd';
import { Link } from 'react-router-dom';
import { useAppDispatch, getUsers, useAppSelector, RootState, UserInterface, QueryParams, DefaultPagination, Roles, Role, i18n, euroFormat } from '../../common';
import { RoleFilter, Search } from '../filters';
import { AppRoutes } from '../../pages/app';
import { UserIcon } from '../icons';
import Table, { TableFilter, TableParams, TableSearch } from './table.component';

const UsersTable = () => {
  const dispatch = useAppDispatch();

  const componentQueryParams: QueryParams = { 
    includes: ['dbRole'],
    sorters: [
      {columnKey: 'id', order: 'descend', field: 'id'}
    ]
  }

  const [queryParams, setQueryParams] = useState<QueryParams>(componentQueryParams);

  useEffect(() => {
    dispatch(getUsers(queryParams));
  }, [queryParams]
  )

  const users = useAppSelector((state: RootState) => state.user.all);
  
  const usersTableColumns: ColumnsType<UserInterface> = [
    {
      title: i18n.t('components.table.userTable.name'),
      dataIndex: 'lastName',
      key: 'lastName',
      render: (dataIndex: string, user: UserInterface) => 
        <Link to={`${AppRoutes.USERS.fullPath}/${user.id}`} key={`${AppRoutes.USERS.fullPath}/${user.id}`}>
          <Space>
            {user.profileImageUrl ? <Avatar src={user.profileImageUrl} /> : <Avatar icon={<UserIcon />} />}
            {`${user.lastName} ${user.firstName}`}
          </Space>
        </Link>,
      sorter: true,
      width: 600
    },
    {
      title: i18n.t('components.table.userTable.role'),
      dataIndex: 'dbRole.id',
      key: 'dbRole.id',
      render: (dataIndex: string, user: UserInterface) => user.dbRole && Roles().find((r: Role) => r.id === user.dbRole!.id)?.name,
      width: 300
    },
    {
      title: i18n.t('components.table.userTable.mail'),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      width: 600
    },
    {
      title: i18n.t('components.table.userTable.phone'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 300
    },
    {
      title: i18n.t('components.table.userTable.numberOfCampaigns'),
      dataIndex: 'transientValues.numberOfCampaigns',
      key: 'transientValues.numberOfCampaigns',
      render: (dataIndex: string, user: UserInterface) => user.transientValues?.numberOfCampaigns.toString(),
      sorter: false,
      width: 150
    },
    {
      title: i18n.t('components.table.userTable.numberOfSalesOpportunities'),
      dataIndex: 'transientValues.numberOfSalesOpportunities',
      key: 'transientValues.numberOfSalesOpportunities',
      render: (dataIndex: string, user: UserInterface) => user.transientValues?.numberOfSalesOpportunities.toString(),
      sorter: false,
      width: 150
    },
    {
      title: i18n.t('components.table.userTable.monthlyPerformance'),
      dataIndex: 'monthlySuccess',
      key: 'monthlySuccess',
      render: (dataIndex: string, user: UserInterface) => `${euroFormat(user.transientValues?.monthlySuccess)}`,
      sorter: false,
      width: 150
    }
  ]

  const filters: Array<TableFilter> = [
    { component: RoleFilter, key: 'dbRole.id' }
  ]

  const searchFields: Array<TableSearch> = [
    { component: Search, keys: ['firstName', 'lastName', 'fullName'], placeholder: i18n.t('components.table.userTable.searchPlaceholder') }
  ]

  const handleTableChange = (tableParams: TableParams) => {
    setQueryParams({
      pagination: tableParams.pagination,
      filters: tableParams.filters,
      sorters: tableParams.sorters || componentQueryParams.sorters,
      includes: componentQueryParams.includes,
      search: tableParams.search
    })
  };

  if (!users) return null;
  return (
    <Table
      columns={usersTableColumns}
      dataSource={users.data}
      rowKey={"id"}
      handleTableChange={handleTableChange}
      paginationDefault={DefaultPagination}
      total={users.total}
      filters={filters}
      searchFields={searchFields}
    />
  )
}

export default UsersTable;
