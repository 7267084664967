import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AnnouncementInterface, AnnouncementResponseInterface, AnnouncementSeenInterface, AnnouncementsResponseInterface } from '../..';
import { JsonApiService, QueryParams } from '../../api';
import type { AppDispatch } from '../store';

export interface AnnouncementStateInterface {
  all: {
    data: Array<AnnouncementInterface>;
    dataNotification: Array<AnnouncementInterface>;
    seen?: Array<AnnouncementSeenInterface>;
    total?: number;
  };
  one?: AnnouncementInterface;
  error?: Error;
}

const initialState: AnnouncementStateInterface = {
  all: { data: [], dataNotification: [] },
};

const announcementSlice = createSlice({
  name: 'announcements',
  initialState,
  reducers: {
    setAnnouncementAllData: (
      state: AnnouncementStateInterface,
      action: PayloadAction<Array<AnnouncementInterface>>
    ) => {
      state.all.data = action.payload;
    },
    setAnnouncementNotificationAllData: (
      state: AnnouncementStateInterface,
      action: PayloadAction<Array<AnnouncementInterface>>
    ) => {
      state.all.dataNotification = action.payload;
    },
    setAnnouncementSeenAllData: (state: AnnouncementStateInterface, action: PayloadAction<Array<AnnouncementSeenInterface>>) => {
      state.all.seen = action.payload;
    },
    setAnnouncementAllTotal: (state: AnnouncementStateInterface, action: PayloadAction<number>) => {
      state.all.total = action.payload;
    },
    setAnnouncementOne: (state: AnnouncementStateInterface, action: PayloadAction<AnnouncementInterface>) => {
      state.one = action.payload;
    },
    setAnnouncementError: (state: AnnouncementStateInterface, action: PayloadAction<Error>) => {
      state.error = action.payload;
    },
    createAnnouncementAllData: (state: AnnouncementStateInterface, action: PayloadAction<AnnouncementInterface>) => {
      state.all.data.unshift(action.payload);
      if (state.all.total || state.all.total === 0) state.all.total += 1;
    },
    updateAnnouncementAllData: (state: AnnouncementStateInterface, action: PayloadAction<AnnouncementInterface>) => {
      const index = state.all.data.findIndex((x) => x.id === action.payload.id);
      state.all.data[index] = action.payload;
    },
  },
});

export const {
  setAnnouncementAllData,
  setAnnouncementNotificationAllData,
  setAnnouncementSeenAllData,
  setAnnouncementAllTotal,
  setAnnouncementOne,
  setAnnouncementError,
  createAnnouncementAllData,
  updateAnnouncementAllData,
} = announcementSlice.actions;

export const getAnnouncements = (queryParams?: QueryParams) => (dispatch: AppDispatch) =>
  JsonApiService.get('announcement', undefined, queryParams).subscribe({
    next: (response: AnnouncementsResponseInterface) => {
      dispatch(setAnnouncementAllData(response.data));
      if (response.meta && (response.meta.totalResourceCount || response.meta.totalResourceCount === 0))
        dispatch(setAnnouncementAllTotal(response.meta.totalResourceCount));
    },
    error: (error: Error) => {
      dispatch(setAnnouncementError(error));
      dispatch(setAnnouncementAllTotal(0));
    },
  });

export const getAnnouncementsNotification = (queryParams?: QueryParams) => (dispatch: AppDispatch) =>
  JsonApiService.get('announcement', undefined, queryParams).subscribe({
    next: (response: AnnouncementsResponseInterface) => {
      dispatch(setAnnouncementNotificationAllData(response.data));
    },
    error: (error: Error) => {
      dispatch(setAnnouncementError(error));
    },
  });

export const getAnnouncement = (id: number, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('announcement', id, queryParams).subscribe({
    next: (response: AnnouncementResponseInterface) => {
      dispatch(setAnnouncementOne(response.data));
    },
    error: (error: Error) => {
      dispatch(setAnnouncementError(error));
    },
  });
};

export const createAnnouncement = (data: AnnouncementInterface) => (dispatch: AppDispatch) => {
  JsonApiService.post('announcement', data).subscribe({
    next: (response: any) => {
      dispatch(setAnnouncementOne(response.data));
      dispatch(createAnnouncementAllData(response.data));
    },
    error: (error: Error) => {
      dispatch(setAnnouncementError(error));
    },
  });
};

export const updateAnnouncement = (id: number, data: any) => (dispatch: AppDispatch) => {
  JsonApiService.patch('announcement', id, data).subscribe({
    next: (response: any) => {
      dispatch(setAnnouncementOne(response.data));
      dispatch(updateAnnouncementAllData(response.data));
    },
    error: (error: Error) => {
      dispatch(setAnnouncementError(error));
    },
  });
};

export const getAnnouncementSeenFromUser = (queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('announcement_seen', undefined, queryParams).subscribe({
    next: (response: any) => {
      dispatch(setAnnouncementSeenAllData(response.data));
    },
    error: (error: Error) => {
      dispatch(setAnnouncementError(error));
    },
  });
};

export const updateAnnouncementSeen = (id: number, data: any, announcementQueryParams?: QueryParams, announcementSeenQueryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.patch('announcement_seen', id, data).subscribe({
    next: () => {
      dispatch(getAnnouncementsNotification(announcementQueryParams));
      dispatch(getAnnouncementSeenFromUser(announcementSeenQueryParams));
    },
    error: (error: Error) => {
      dispatch(setAnnouncementError(error));
    },
  });
};

export default announcementSlice.reducer;
