import { DeleteOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Form, Grid, Input, Row, Select, Space, Switch, TreeSelect, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AreaInterface, ClientInterface, ClientType, ClientTypes, QueryParams, RootState, UserInterface, getAreaFilter, getUserFilter, i18n, useAppDispatch, useAppSelector, oibValidator } from '../../../common';
import { ClientTypeEnum } from '../../../common/enums';
import { colors } from '../../../theme';
import { GuardFunction } from '../../guard';
import { UserIcon } from '../../icons';

const { Title, Text } = Typography;
const { Option } = Select;
const { useBreakpoint } = Grid;

export type ClientFormItemType = 'clientType' | 'name' | 'oib' | 'areas' | 'address' | 'contacts' | 'description' | 'user' | 'active';
export type InitialValueTypeClient = { formItem: ClientFormItemType, value: number | string | Array<number> | { id: number } | { id: string } | boolean };

export const allClientFormItems: Array<ClientFormItemType> = ['clientType', 'name', 'oib', 'areas', 'address', 'contacts', 'description', 'user', 'active'];

interface Props {
  client?: ClientInterface;
  formItems?: Array<ClientFormItemType>;
  disabled?: Array<ClientFormItemType>;
  initialValues?: Array<InitialValueTypeClient>;
  onClientFormFinish?: (values: any) => void;
  onClientFormFinishFailed?: (errorInfo: any) => void;
  title?: string;
  submitBtnLabel?: string;
}

function ClientForm({ client, formItems, disabled, initialValues, onClientFormFinish, onClientFormFinishFailed, title, submitBtnLabel }: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { md } = useBreakpoint();

  // dohvacanje vrijednosti za padajuce izbornike
  const areaValues = useAppSelector((state: RootState) => state.area.filter);
  const userValues = useAppSelector((state: RootState) => state.user.filter);
  const me = useAppSelector((state: RootState) => state.auth.user);

  // Priprema parametara za dohvacanje vrijednosti za padajuce izbornike
  const userQueryParams: QueryParams = { pagination: { current: 1, pageSize: 999999 } };
  const areaQueryParams: QueryParams = { includes: ['childrenAreas'], pagination: { current: 1, pageSize: 999999 } };

  // Slanje zahtjeva za dohvacanje vrijednosti za padajuce izbornike
  useEffect(() => {
    if (GuardFunction({ domain: 'client', action: 'set_any_user' })) dispatch(getUserFilter(userQueryParams));
    dispatch(getAreaFilter(areaQueryParams));
  }, []
  )

  // Mapiranje izlaznih vrijednosti
  const onFormSubmit = (values: any) => {
    const data = {
      ...values,
      areas: values.areas?.map((area: number) => ({ id: area })),
      contactsDTO: values.contacts,
      contacts: undefined,
      user: values.user?.id ? { id: values.user?.id } : undefined,
    }
    if (onClientFormFinish) onClientFormFinish(data);
  }

  const items: Array<ClientFormItemType> = formItems || allClientFormItems;

  const clientTypeId = Form.useWatch(['clientType', 'id'], form);

  const areaTreeData = areaValues && areaValues.map((area: AreaInterface) => ({ title: area.name, value: area.id, children: area.childrenAreas?.map((a: AreaInterface) => ({ title: a.name, value: a.id })) }))
  const userData: Array<UserInterface> = GuardFunction({ domain: 'client', action: 'set_any_user' }) ? userValues! : [me!];

  // Mapiranje inicijalnih vrijednosti
  const initialFormValues = {
    ...client,
    clientType: (initialValues?.find((fi: InitialValueTypeClient) => fi.formItem === 'clientType')?.value as { id: number }) || client?.clientType, 
    name: (initialValues?.find((fi: InitialValueTypeClient) => fi.formItem === 'name')?.value as string) || client?.name,
    oib: (initialValues?.find((fi: InitialValueTypeClient) => fi.formItem === 'oib')?.value as string) || client?.oib,
    areas: (initialValues?.find((fi: InitialValueTypeClient) => fi.formItem === 'areas')?.value as Array<number>) || client?.areas?.map(a => (a.id)),
    address: (initialValues?.find((fi: InitialValueTypeClient) => fi.formItem === 'address')?.value as string) || client?.address,
    contacts: client?.contacts,
    user: (initialValues?.find((fi: InitialValueTypeClient) => fi.formItem === 'user')?.value as { id: number }) || client?.user,
    description: (initialValues?.find((fi: InitialValueTypeClient) => fi.formItem === 'description')?.value as string) || client?.description,
    active: (initialValues?.find((fi: InitialValueTypeClient) => fi.formItem === 'active')?.value as boolean) || client?.active,
  }

  // client?.areas ? { ...client, areas: client!.areas!.map(a => (a.id)) } : client;

  return (<>
    <Form
      name='client'
      form={form}
      onFinish={onFormSubmit}
      onFinishFailed={onClientFormFinishFailed}
      labelCol={{ span: 8, xxl: { span: 4 }, md: { span: 6 } }}
      wrapperCol={{ span: 14, xxl: { span: 8 }, md: { span: 10 } }}
      initialValues={initialFormValues}
      layout={md ? 'horizontal' : 'vertical'}
    >
      <Space direction='vertical' size={16} style={{ display: 'flex' }}>
        <Row justify={'space-between'}>
          <Col span={20} flex={'auto'}><Title level={2} style={{ margin: 0 }}>{title}</Title></Col>
          <Col flex={'none'}>
            <Space>
              <Button onClick={() => { navigate(-1) }}>{i18n.t('components.forms.common.cancel')}</Button>
              <Button type="primary" htmlType="submit">{submitBtnLabel}</Button>
            </Space>
          </Col>
        </Row>
        <div>
          {items.find(i => i === 'clientType') && <Form.Item
            name={['clientType', 'id']}
            label={i18n.translate(`components.forms.client.clientType`)}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            wrapperCol={{ span: 8, md: { span: 4 }, xxl: { span: 2 } }}
          >
            <Select
              disabled={!!disabled?.find(d => d === 'clientType')}
              placeholder="Odaberi"
            >
              {ClientTypes().map((ct: ClientType) => <Option key={ct.id} value={ct.id}>{ct.name}</Option>)}
            </Select>
          </Form.Item>}
          {items.find(i => i === 'name') && <Form.Item
            name="name"
            label={clientTypeId === ClientTypeEnum.PRIVATE ? i18n.translate(`components.forms.client.fullName`) : i18n.translate(`components.forms.client.name`)}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            wrapperCol={{ md: { span: 10}, xxl: { span: 6 }  }}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'name')} />
          </Form.Item>}
          {items.find(i => i === 'oib') && <Form.Item
            name='oib'
            label={i18n.translate(`components.forms.client.oib`)}
            wrapperCol={{ span: 8, md: { span: 5 }, xxl: { span: 3 } }}
            rules={[{ validator: oibValidator, message: i18n.translate(`components.forms.common.oib`) }]}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'oib')} />
          </Form.Item>}
          {items.find(i => i === 'areas') && <Form.Item
            name={['areas']}
            label={i18n.translate(`components.forms.client.area`)}
            wrapperCol={{ md: { span: 10 }, xxl: { span: 6 } }}
          >
            <TreeSelect
              placeholder="Odaberi"
              multiple
              treeData={areaTreeData}
              disabled={!!disabled?.find(d => d === 'areas')}
            />
          </Form.Item>}
          {items.find(i => i === 'address') && <Form.Item
            name='address'
            label={i18n.translate(`components.forms.client.address`)}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            wrapperCol={{ md: { span: 9}, xxl: { span: 5 }  }}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'address')} />
          </Form.Item>}
          {items.find(i => i === 'contacts') && clientTypeId === ClientTypeEnum.PRIVATE &&
            <>
              <Form.Item name={['contacts', 0, 'contactName']} label={i18n.translate(`components.forms.client.mail`)} wrapperCol={{ md: { span: 9}, xxl: { span: 5 }  }}>
                <Input size="small" disabled={!!disabled?.find(d => d === 'contacts')} />
              </Form.Item>
              <Form.Item name={['contacts', 0, 'phoneNumber']} label={i18n.translate(`components.forms.client.contactNumber`)} wrapperCol={{ md: { span: 9}, xxl: { span: 5 }  }}>
                <Input size="small" disabled={!!disabled?.find(d => d === 'contacts')} />
              </Form.Item>
            </>
          }
          {items.find(i => i === 'contacts') && clientTypeId === ClientTypeEnum.BUSINESS &&
            <>
              <Form.Item
                name='contacts'
                label={i18n.translate(`components.forms.client.contact`)}
                wrapperCol={{ span: 21 }}
              >
                <Row
                  style={{ backgroundColor: colors.greyBackground, marginBottom: '16px', marginInline: 0, padding: '16px 0' }}
                  gutter={16}
                >
                  <Col span={10} >{i18n.t('components.forms.client.contactName')}</Col>
                  <Col span={8}>{i18n.t('components.forms.client.mail')}</Col>
                  <Col span={6}>{i18n.t('components.forms.client.contactNumber')}</Col>
                </Row>
                <Form.List name="contacts">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }, index) => (
                        <Row key={key} gutter={16} style={{ marginInline: 0, marginBottom: index === fields.length - 1 ? 8 : 24 }}>
                          <Col span={10} style={{ paddingLeft: 0 }}>
                            <Form.Item {...restField} name={[name, 'contactName']} style={{ marginBottom: 0 }}>
                              <Input size="small" disabled={!!disabled?.find(d => d === 'contacts')} />
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item {...restField} name={[name, 'email']} style={{ marginBottom: 0 }}>
                              <Input size="small" disabled={!!disabled?.find(d => d === 'contacts')} />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Row justify={'space-between'} gutter={16}>
                              <Col flex='auto'>
                                <Form.Item {...restField} name={[name, 'phoneNumber']} style={{ marginBottom: 0 }}>
                                  <Input size="small" disabled={!!disabled?.find(d => d === 'contacts')} />
                                </Form.Item>
                              </Col>
                              <Col flex='32px'>
                                <Form.Item style={{ marginBottom: 0 }}>
                                  <DeleteOutlined style={{ color: 'red' }} onClick={() => remove(name)} />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ))}
                      <Row>
                        {!disabled?.find(d => d === 'contacts') && <Form.Item style={{ marginBottom: 0 }}>
                          <Button type="link" onClick={() => add()} style={{ padding: 0 }} icon={<PlusSquareOutlined />}>
                            {i18n.t('components.forms.client.addContactItem')}
                          </Button>
                        </Form.Item>}
                      </Row>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </>
          }
          {items.find(i => i === 'user') && <Form.Item
            name={['user', 'id']}
            label={i18n.translate(`components.forms.client.user`)}
            wrapperCol={{ md: { span: 10 }, xxl: { span: 6 } }}
          >
            <Select
              placeholder="Odaberi"
              allowClear
              disabled={!!disabled?.find(d => d === 'user')}
            >
              {userData && userData.map((user: UserInterface) =>
                <Option key={user.id} value={user.id}>
                  <Space style={{ display: 'flex' }}>
                    {user.profileImageUrl ? <Avatar src={user.profileImageUrl} size={20} /> : <Avatar size={20} icon={<UserIcon />} />}{user.fullName}
                  </Space>
                </Option>
              )}
            </Select>
          </Form.Item>}
          {items.find(i => i === 'description') && <Form.Item
            name='description'
            label={i18n.translate(`components.forms.client.description`)}
          >
            <Input.TextArea size="small" disabled={!!disabled?.find(d => d === 'description')} />
          </Form.Item>}
          {items.find(i => i === 'active') && GuardFunction({ domain: 'client', action: 'deactivate' }) &&
            <Form.Item
              name='active'
              label={i18n.translate(`components.forms.user.activity`)}
              valuePropName="checked"
            >
              <Space direction='vertical' size={16} style={{ display: 'flex' }}>
                <div><Text style={{ lineHeight: '32px' }}>Neaktivan</Text> <Switch defaultChecked={client?.active} onChange={(checked) => form.setFieldValue('active', checked)} disabled={!!disabled?.find(d => d === 'active')} /> <Text>Aktivan</Text></div>
              </Space>
            </Form.Item>}
        </div>
      </Space>
    </Form>
  </>
  );
}

export default ClientForm;


