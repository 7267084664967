import { Button, DatePicker, Form, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { DisplayFullFormat, QueryParams, ReservationCategoryInterface, ReservationInterface, RootState, deleteReservation, closeReservationDrawer, getReservationCategories, i18n, useAppDispatch, useAppSelector, ReservationOptionInterface, resetSubmitReservationForm } from '../../../common';

const { Option } = Select;

interface Props {
  onReservationFormFinish?: (values: any) => void;
  onReservationFormFinishFailed?: (errorInfo: any) => void;
  reservation?: ReservationInterface;
}

export default function ReservationForm({ onReservationFormFinish, onReservationFormFinishFailed, reservation }: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  // dohvacanje vrijednosti za padajuce izbornike
  const reservationCategories = useAppSelector((state: RootState) => state.reservationCategory.all.data);

  // Priprema parametara za dohvacanje vrijednosti za padajuce izbornike
  const reservationCategoryQueryParams: QueryParams = { includes: ['reservationOptions'], pagination: { current: 1, pageSize: 999999 } };

  // Slanje zahtjeva za dohvacanje vrijednosti za padajuce izbornike
  useEffect(() => {
    dispatch(getReservationCategories(reservationCategoryQueryParams));
  }, []);

  const submitForm = useAppSelector((state: RootState) => state.componentState.drawer.reservation.submit);

  // Kontrola submita forme
  useEffect(() => {
    if (submitForm) {
      form.submit();
      dispatch(resetSubmitReservationForm());
    }
  }, [submitForm]);

  // Mapiranje izlaznih vrijednosti
  const onFormSubmit = (values: ReservationInterface) => {
    const data = {
      ...values,
      id: reservation?.id,
      user: { id: currentUser!.id }
    }
    if (onReservationFormFinish) onReservationFormFinish(data);
  }

  const initialValues = {
    ...reservation,
    reservationCategory: reservation?.reservationCategory,
    reservationOption: reservation?.reservationOption,
    reservationStartDate: (reservation?.reservationStartDate && dayjs(reservation?.reservationStartDate)) || dayjs().set('minute', (Math.ceil(dayjs().minute() / 5)) * 5),
    reservationEndDate: (reservation?.reservationEndDate && dayjs(reservation.reservationEndDate)) || dayjs().add(1, 'hour').set('minute', (Math.ceil(dayjs().minute() / 5)) * 5),
  };

  const reservationCategoryId = Form.useWatch(['reservationCategory', 'id'], form);

  if (!reservationCategories) return null;

  const onReservationCategoryChange = (reservationCategoryIdValue: any) => {
    const { reservationOptions } = reservationCategories.find((rc: ReservationCategoryInterface) => rc.id! === reservationCategoryIdValue!)!;
    const reservationOptionId = reservationOptions && reservationOptions.length > 0 ? reservationOptions[0].id : undefined;
    form.setFieldValue(['reservationOption', 'id'], reservationOptionId);
  }

  const onReservationDelete = () => {
    dispatch(deleteReservation(reservation!.id!));
    dispatch(closeReservationDrawer());
  }

  const onStartDateChange = (startDate: dayjs.Dayjs | null) => {
    if (startDate) {
      const newEndDate = startDate.add(1, 'hour');
      form.setFieldValue('reservationEndDate', newEndDate);
    }
  }

  const disabledTime = () => (
    {
      disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 23, 24],
    }
  )

  const disabledStartDate = (current: any) =>
    // Can not select days before yesterday
    current && current < dayjs().startOf('day');

  const disabledEndDate = (current: any) =>
    // Can not select days before yesterday
    current && current < dayjs(form.getFieldValue('reservationStartDate'));

  return (
    <Form
      form={form}
      name="reservation"
      layout="vertical"
      onFinish={onFormSubmit}
      onFinishFailed={onReservationFormFinishFailed}
      initialValues={initialValues}
    >
      <Form.Item
        label={i18n.translate('components.forms.reservations.category')}
        name={['reservationCategory', 'id']}
        wrapperCol={{ span: 10 }}
        rules={[{ required: true, message: i18n.translate('components.forms.common.required') }]}
      >
        <Select
          onChange={onReservationCategoryChange}
        >
          {reservationCategories && reservationCategories.map((category, index) => (
            <Option key={index} value={category.id}>
              {category?.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      {reservationCategoryId && <Form.Item
        label={i18n.translate('components.forms.reservations.option')}
        name={['reservationOption', 'id']}
        wrapperCol={{ span: 10 }}
        rules={[{ required: true, message: i18n.translate('components.forms.common.required') }]}
      >
        <Select>
          {reservationCategories && reservationCategories.find((rc: ReservationCategoryInterface) => rc.id! === reservationCategoryId!)?.reservationOptions!.map((ro: ReservationOptionInterface) =>
            <Option key={ro.id} value={ro.id}>{ro.name}</Option>
          )}
        </Select>
      </Form.Item>}

      <Form.Item
        label={i18n.translate('components.forms.reservations.startDate')}
        name={'reservationStartDate'}
        wrapperCol={{ span: 14 }}
        rules={[{ type: 'object' as const, required: true, message: i18n.translate('components.forms.common.required') }]}
      >
        <DatePicker
          showTime={{ minuteStep: 5 }}
          format={DisplayFullFormat}
          disabledTime={disabledTime}
          disabledDate={disabledStartDate}
          onChange={onStartDateChange}
          allowClear={false}
        />
      </Form.Item>

      <Form.Item
        label={i18n.translate('components.forms.reservations.endDate')}
        name={'reservationEndDate'}
        wrapperCol={{ span: 14 }}
        rules={[{ type: 'object' as const, required: true, message: i18n.translate('components.forms.common.required') }]}
      >
        <DatePicker
          showTime={{ minuteStep: 5 }}
          format={DisplayFullFormat}
          disabledTime={disabledTime}
          disabledDate={disabledEndDate}
        />
      </Form.Item>

      {!reservation ? (
        <></>
      ) : (
        <Form.Item name={'delete'}>
          <Button
            type="primary"
            danger
            onClick={onReservationDelete}
          >
            {i18n.t('components.forms.reservations.delete')}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
}
