import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { HeaderSearchInterface } from '../../interfaces';
import type { AppDispatch } from '../store';
import { HeaderService } from '../../api';

// ============ INTERFACE ============

export interface HeaderSearchStateInterface {
  searchResults?: HeaderSearchInterface;
  error?: Error;
}

// ============ INIT STATE ============

const initialState: HeaderSearchStateInterface = {
  searchResults: undefined,
};


// ========= SLICE ==========

export const headerSearchSlice = createSlice({
  name: 'header_search',
  initialState,
  reducers: {
    setHeaderSearchResults: (state: HeaderSearchStateInterface, action: PayloadAction<HeaderSearchInterface>) => {
      state.searchResults = action.payload;
    },
    resetHeaderSearchResults: (state: HeaderSearchStateInterface) => {
      state.searchResults = undefined;
    },
    setHeaderSearchError: (state: HeaderSearchStateInterface, action: PayloadAction<Error>) => {
      state.error = action.payload;
    }
  }
})

export const { setHeaderSearchResults, resetHeaderSearchResults, setHeaderSearchError } = headerSearchSlice.actions;

export const headerSearch = (value: string) => (dispatch: AppDispatch) => {
  HeaderService.headerSearch(value)
    .subscribe({
      next: (response: HeaderSearchInterface) => {
        dispatch(setHeaderSearchResults(response));
      },
      error: (error: Error) => {
        dispatch(setHeaderSearchError(error));
      }
    })
}

export const resetHeaderSearch = () => (dispatch: AppDispatch) => {
  dispatch(resetHeaderSearchResults());
}

export default headerSearchSlice.reducer;