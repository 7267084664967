export type HttpParamsTypes = { [index: string]: string };

export type Filter = { name: string; operator: string; value: string | Array<string>; };

export interface Pagination {
  show?: boolean;
  current?: number;
  pageSize?: number;
  total?: number;
  showSizeChanger?: boolean;
  pageSizeOptions?: string[] | number[];
  showTotal?: (total: number, range: Array<number>) => string;
}

export interface Sorter {
  column?: {
    title: string;
    dataIndex: string;
    sorter: boolean;
  };
  order?: string;
  field?: string;
  columnKey: string;
}

export interface Search {
  keys: Array<string>;
  value: string;
}

export interface QueryParams {
  filters?: Array<Filter>;
  includes?: Array<string>;
  pagination?: Pagination;
  sorters?: Array<Sorter>;
  search?: Search;
}

export const DefaultPagination: Pagination = {
  show: true,
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
  pageSizeOptions: [5,10,20],
  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
};