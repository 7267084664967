import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Typography, Col, Row, Space, Select, UploadFile, UploadProps, InputNumber, notification, Grid, DatePicker } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import Dragger from 'antd/es/upload/Dragger';
import dayjs from 'dayjs';
import { ClientInterface, DisplayDateFormat, FileInterface, RootState, SalesOpportunityInterface, SalesOpportunityStatus, SalesOpportunityStatuses, UserInterface, getClientFilter, getUserFilter, i18n, useAppDispatch, useAppSelector } from '../../../common';
import { CustomFileRender } from '../../other';
import { SalesOpportunityStatusEnum } from '../../../common/enums';
import { GuardFunction } from '../../guard';

const { Title } = Typography;
const { Option } = Select;
const { useBreakpoint } = Grid;

export type SalesOpportunityFormItemType = 'name' | 'client' | 'users' | 'latestStatus' | 'endDate' | 'value' | 'filesDTO' | 'description';
export type InitialValueTypeSalesOpportunity = { formItem: SalesOpportunityFormItemType, value: number | string | Array<number> | { id: number } | { id: string } };

export const allSalesOpportunityFormItems: Array<SalesOpportunityFormItemType> = ['name', 'client', 'users', 'latestStatus', 'endDate', 'value', 'filesDTO', 'description'];

interface Props {
  onSalesOpportunityFormFinish?: (values: any) => void;
  onSalesOpportunityFormFinishFailed?: (errorInfo: any) => void;
  salesOpportunity?: SalesOpportunityInterface;
  formItems?: Array<SalesOpportunityFormItemType>;
  disabled?: Array<SalesOpportunityFormItemType>;
  initialValues?: Array<InitialValueTypeSalesOpportunity>;
  title?: string;
  submitBtnLabel?: string;
}

function SalesOpportunityForm({ onSalesOpportunityFormFinish, onSalesOpportunityFormFinishFailed, title, submitBtnLabel, salesOpportunity, formItems, disabled, initialValues }: Props) {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { md } = useBreakpoint();

  // dohvacanje vrijednosti za padajuce izbornike
  const clientValues = useAppSelector((state: RootState) => state.client.filter);
  const userValues = useAppSelector((state: RootState) => state.user.filter);
  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  // Slanje zahtjeva za dohvacanje vrijednosti za padajuce izbornike
  useEffect(() => {
    if (GuardFunction({ domain: 'sales_opportunity', action: 'set_any_user' })) dispatch(getUserFilter({ pagination: { current: 1, pageSize: 999999 } }));
    if (GuardFunction({ domain: 'sales_opportunity', action: 'set_any_client' })) dispatch(getClientFilter({ pagination: { current: 1, pageSize: 999999 }, includes: ['user'] }))
    else dispatch(getClientFilter({ pagination: { current: 1, pageSize: 999999 }, filters: [{ name: 'user.id', operator: 'EQ', value: currentUser!.id!.toString() }], includes: ['user'] }));
  }, []
  )

  const users: Array<UserInterface> = GuardFunction({ domain: 'sales_opportunity', action: 'set_any_user' }) ? userValues! : [currentUser!];
  const clients: Array<ClientInterface> | undefined = GuardFunction({ domain: 'sales_opportunity', action: 'set_any_client' }) ? clientValues : clientValues?.filter((c: ClientInterface) => (c.user?.id === currentUser?.id || salesOpportunity?.client?.id === c.id));

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    setFileList(salesOpportunity?.files?.length ? salesOpportunity?.files?.map((x: FileInterface) => ({ id: x.id, uid: x.uuid, name: x.name })) : [])
  }, [salesOpportunity]);

  const onRemoveFile = (file: any) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  }

  const props: UploadProps = {
    name: 'files',
    action: process.env.REACT_APP_DMS_URL,
    multiple: true,
    maxCount: 5,
    fileList,
    disabled: !!disabled?.find(d => d === 'filesDTO'),
    itemRender: (originNode, file) => (
      <CustomFileRender file={file} onRemoveFile={onRemoveFile} />
    ),
    beforeUpload: () => {
      if (fileList.length >= 5) {
        notification.error({ message: i18n.translate('components.forms.salesOpportunity.upload.limit') });
        return false;
      }

      return true;
    },
    onChange: (info) => {
      setFileList(info.fileList);
      if (info.file.status === 'done') {
        notification.success({ message: i18n.translate('components.forms.salesOpportunity.upload.success', { fileName: info.file.name }) });
      } else if (info.file.status === 'error') {
        notification.error({ message: i18n.translate('components.forms.salesOpportunity.upload.error', { fileName: info.file.name }) });
      }
    }
  };

  const onFinish = (values: any) => {
    const requestObj = {
      ...values,
      latestStatus: values.latestStatus?.id ? { id: values.latestStatus?.id } : { id: SalesOpportunityStatusEnum.CREATED },
      users: values.users.map((user: number) => ({ id: user })),
      filesDTO: fileList?.map((x: any) => ({ id: x.id ? x.id : undefined, uuid: x.response && x.response.length ? x.response[0].uuid : x.uid, name: x.name }))
    }
    return onSalesOpportunityFormFinish && onSalesOpportunityFormFinish(requestObj);
  };

  const items: Array<SalesOpportunityFormItemType> = formItems || allSalesOpportunityFormItems;

  const clientId = Form.useWatch(['client', 'id'], form);

  useEffect(() => {
    if (!disabled?.find(d => (d === 'users' || d === 'client')) && clients?.length) form.setFieldValue(['users'], clients?.find(x => x.id === clientId) ? [clients?.find(x => x.id === clientId)?.user?.id] : []);
  }, [clientId]);

  const disabledDate = (current: any) =>
    // Can not select days before yesterday
    current && current < dayjs().startOf('day');

  const initialFormValues = {
    ...salesOpportunity,
    name: (initialValues?.find((fi: InitialValueTypeSalesOpportunity) => fi.formItem === 'name')?.value as string) || salesOpportunity?.name,
    client: (initialValues?.find((fi: InitialValueTypeSalesOpportunity) => fi.formItem === 'client')?.value as { id: number }) || salesOpportunity?.client,
    users: (initialValues?.find((fi: InitialValueTypeSalesOpportunity) => fi.formItem === 'users')?.value as Array<number>) || salesOpportunity?.users?.map(a => (a.id)),
    latestStatus: (initialValues?.find((fi: InitialValueTypeSalesOpportunity) => fi.formItem === 'latestStatus')?.value as { id: number }) || salesOpportunity?.latestStatus || { id: SalesOpportunityStatusEnum.CREATED },
    value: (initialValues?.find((fi: InitialValueTypeSalesOpportunity) => fi.formItem === 'value')?.value as number) || salesOpportunity?.value,
    description: (initialValues?.find((fi: InitialValueTypeSalesOpportunity) => fi.formItem === 'description')?.value as string) || salesOpportunity?.description,
    endDate: (initialValues?.find((fi: InitialValueTypeSalesOpportunity) => fi.formItem === 'endDate')?.value as string && dayjs(initialValues?.find((fi: InitialValueTypeSalesOpportunity) => fi.formItem === 'endDate')?.value as string)) || (salesOpportunity?.endDate && dayjs(salesOpportunity?.endDate)) || dayjs(),
  }

  return (<>
    <Form
      name='salesOpportunity'
      form={form}
      onFinish={onFinish}
      onFinishFailed={onSalesOpportunityFormFinishFailed}
      labelCol={{ span: 8, xxl: { span: 4 }, md: { span: 6 } }}
      wrapperCol={{ span: 14, xxl: { span: 8 }, md: { span: 10 } }}
      initialValues={initialFormValues}
      layout={md ? 'horizontal' : 'vertical'}
    >
      <Space direction='vertical' size={16} style={{ display: 'flex' }}>
        <Row justify={'space-between'}>
          <Col span={20} flex={'auto'}><Title level={2} style={{ margin: 0 }}>{title}</Title></Col>
          <Col flex={'none'}>
            <Space>
              <Button onClick={() => { navigate(-1) }}>{i18n.t('buttons.cancel')}</Button>
              <Button type="primary" htmlType="submit">{submitBtnLabel}</Button>
            </Space>
          </Col>
        </Row>
        <div>
          {items.find(i => i === 'name') && <Form.Item
            name="name"
            label={i18n.translate(`components.forms.salesOpportunity.name`)}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            wrapperCol={{ md: { span: 10 }, xxl: { span: 6 } }}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'name') || !!initialFormValues.campaign} />
          </Form.Item>}
          {items.find(i => i === 'client') && <Form.Item
            name={['client', 'id']}
            label={i18n.translate(`components.forms.salesOpportunity.client`)}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            wrapperCol={{ sm: { span: 8 }, xxl: { span: 4 } }}
          >
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Odaberi"
              disabled={!!disabled?.find(d => d === 'client')}
            >
              {clients?.map((client: ClientInterface) => <Option key={client.id} value={client.id}>{client.name}</Option>)}
            </Select>
          </Form.Item>}
          {items.find(i => i === 'users') && <Form.Item
            name={['users']}
            label={i18n.translate(`components.forms.salesOpportunity.users`)}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            wrapperCol={{ sm: { span: 10 }, xxl: { span: 6 } }}
          >
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Odaberi"
              mode="multiple"
              disabled={(!!disabled?.find(d => d === 'users') || !GuardFunction({ domain: 'sales_opportunity', action: 'set_any_user' }))}
            >
              {users?.map((user: UserInterface) => <Option key={user.id} value={user.id}>{user.fullName}</Option>)}
            </Select>
          </Form.Item>}
          {items.find(i => i === 'latestStatus') && <Form.Item
            name={['latestStatus', 'id']}
            label={i18n.translate(`components.forms.salesOpportunity.latestStatus`)}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            wrapperCol={{ span: 9, md: { span: 6 }, xxl: { span: 3 } }}
          >
            <Select
              showSearch={false}
              disabled={!!disabled?.find(d => d === 'latestStatus')}
            >
              {SalesOpportunityStatuses().map((status: SalesOpportunityStatus) => <Option key={status.id} value={status.id}>{status.name}</Option>)}
            </Select>
          </Form.Item>}
          {items.find(i => i === 'endDate') && <Form.Item
            label={i18n.translate('components.forms.salesOpportunity.endDate')}
            name={'endDate'}
            wrapperCol={{ span: 16 }}
            rules={[{ type: 'object' as const, required: true, message: i18n.translate('components.forms.common.required') }]}
          >
            <DatePicker
              format={DisplayDateFormat}
              disabledDate={disabledDate}
              disabled={!!disabled?.find(d => d === 'endDate') || !!initialFormValues.campaign}
              allowClear={false}
            />
          </Form.Item>}
          {items.find(i => i === 'value') && <Form.Item
            name='value'
            label={i18n.translate(`components.forms.salesOpportunity.value`)}
            wrapperCol={{ sm: { span: 6 }, md: { span: 4 }, xxl: { span: 2 } }}
          >
            <InputNumber min={0} addonAfter="€" disabled={!!disabled?.find(d => d === 'value') || !!initialFormValues.campaign} />
          </Form.Item>}
          {items.find(i => i === 'filesDTO') && <Form.Item
            name='filesDTO'
            label={i18n.translate(`components.forms.salesOpportunity.files`)}
          >
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{i18n.translate(`components.forms.salesOpportunity.upload.text`)}</p>
              <p className="ant-upload-hint">{i18n.translate(`components.forms.salesOpportunity.upload.hint`)}</p>
            </Dragger>
          </Form.Item>}
          {items.find(i => i === 'description') && <Form.Item
            name='description'
            label={i18n.translate(`components.forms.salesOpportunity.description`)}
          >
            <TextArea autoSize={{ minRows: 3 }} maxLength={5000} disabled={!!disabled?.find(d => d === 'description')} />
          </Form.Item>}
        </div>
      </Space>
    </Form>
  </>
  );
}

export default SalesOpportunityForm;
