import React from 'react';
import { Breadcrumb, Card } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { SalesOpportunityForm } from '../../../components';
import { useAppDispatch, SalesOpportunityInterface, createSalesOpportunity, i18n } from '../../../common';
import { AppRoutes } from '../_router/app.routes';

const SalesOpportunityCreatePage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const onSalesOpportunityCreate = (values: SalesOpportunityInterface) => {
    dispatch(createSalesOpportunity(values, { includes: ['salesOpportunitySalesOpportunityStatuses', 'client', 'users', 'campaign', 'latestStatus'] }));
  };

  return (
    <>
      <Breadcrumb style={{ marginBottom: 24 }} items={[
        { title: <Link to={AppRoutes.DASHBOARD.fullPath} key={AppRoutes.DASHBOARD.fullPath}>{i18n.translate('breadcrumb.homepage')}</Link> },
        { title: <Link to={AppRoutes.SALES_OPPORTUNITIES.fullPath} key={AppRoutes.SALES_OPPORTUNITIES.fullPath}>{i18n.t('breadcrumb.salesOpportunities')}</Link> },
        { title: i18n.translate('breadcrumb.salesOpportunityCreate') }]}
      />
      <Card>
        <SalesOpportunityForm
          disabled={location.state ? location.state.map((f: { formItem: string, value: any }) => f.formItem) : undefined}
          initialValues={location.state ? location.state : undefined}
          onSalesOpportunityFormFinish={(values) => onSalesOpportunityCreate(values)}
          title={i18n.t('components.forms.salesOpportunity.createTitle')}
          submitBtnLabel={i18n.t('components.forms.salesOpportunity.create')}
        />
      </Card>
    </>
  );
}

export default SalesOpportunityCreatePage;
