import React from 'react';
import { Breadcrumb, Card } from 'antd';
import { Link } from 'react-router-dom';
import { CampaignForm } from '../../../components';
import { useAppDispatch, CampaignInterface, createCampaign, i18n } from '../../../common';
import { AppRoutes } from '../_router/app.routes';
import { allCampaignFormItems } from '../../../components/forms/campaignForm/campaignForm.component';

const CampaignCreatePage = () => {
  const dispatch = useAppDispatch();

  const onCampaignCreate = (values: CampaignInterface) => {
    dispatch(createCampaign(values, { includes: ['campaignStatus', 'files'] }));
  };

  const formItems = allCampaignFormItems.filter((f) => (f !== 'active' && f !== 'campaignStatus'));

  return (
    <>
      <Breadcrumb style={{ marginBottom: 24 }} items={[
        { title: <Link to={AppRoutes.DASHBOARD.fullPath} key={AppRoutes.DASHBOARD.fullPath}>{i18n.translate('breadcrumb.homepage')}</Link> },
        { title: <Link to={AppRoutes.CAMPAIGNS.fullPath} key={AppRoutes.CAMPAIGNS.fullPath}>{i18n.t('breadcrumb.campaigns')}</Link> },
        { title: i18n.translate('breadcrumb.campaignCreate') }]}
      />
      <Card>
        <CampaignForm formItems={formItems} onCampaignFormFinish={(values) => onCampaignCreate(values)} title={i18n.t('components.forms.campaign.createTitle')} submitBtnLabel={i18n.t('components.forms.campaign.create')}></CampaignForm>
      </Card>
    </>
  );
}

export default CampaignCreatePage;
