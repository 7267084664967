import { Button, Col, Drawer, Row, Space } from 'antd';
import { Color } from 'antd/es/color-picker';
import React, { useMemo, useState } from 'react';
import {
  QueryParams,
  ReservationCategoryInterface,
  createReservationCategory,
  i18n,
  updateReservationCategory,
  useAppDispatch,
} from '../../common';
import { ReservationCategoryForm } from '../forms';
import { AddIcon } from '../icons';
import { ReservationCategoryTable } from '../tables';

export default function ReservationCategories() {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const [trigger, setTrigger] = useState<number>(0);
  const [category, setCategory] = useState<ReservationCategoryInterface | undefined>(undefined);
  const [colorHex, setColorHex] = useState<Color | string>('#1677ff');

  const componentQueryParams: QueryParams = { includes: ['reservationOptions'] };
  const [queryParams] = useState<QueryParams>(componentQueryParams);

  const hexString = useMemo(() => (typeof colorHex === 'string' ? colorHex : colorHex.toHexString()), [colorHex]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setTrigger(0);
    setCategory(undefined);
  };

  const selectCategory = (selectedCategory: ReservationCategoryInterface) => {
    setCategory(selectedCategory);
    showDrawer();
  };

  const onFormFinish = (values: ReservationCategoryInterface) => {
    const data = {
      ...values,
      color: hexString,
    };

    if (category) {
      dispatch(updateReservationCategory(category.id!, data, queryParams));
    } else {
      dispatch(createReservationCategory(data, queryParams));
    }
    onClose();
  };

  return (
    <>
      <Row justify={'end'}>
        <Button type="primary" icon={<AddIcon />} onClick={() => showDrawer()}>
          {i18n.translate('pages.reservationCategory.createNew')}
        </Button>
      </Row>
      <Row>
        <Col span={24} flex={'auto'}>
          <ReservationCategoryTable setCategory={selectCategory} />
        </Col>
      </Row>
      <Drawer
        width={450}
        destroyOnClose
        title={
          category
            ? i18n.translate('pages.reservationCategory.editTitle')
            : i18n.translate('pages.reservationCategory.createNew')
        }
        placement="right"
        open={open}
        onClose={onClose}
        extra={
          <Space>
            <Button onClick={onClose}>{i18n.translate('buttons.cancel')}</Button>
            <Button onClick={() => setTrigger(trigger + 1)} type="primary">
              {i18n.translate('buttons.submit')}
            </Button>
          </Space>
        }
      >
        <ReservationCategoryForm
          trigger={trigger}
          onFinish={(values) => onFormFinish(values)}
          reservationCategory={category}
          setColorHex={setColorHex}
        />
      </Drawer>
    </>
  );
}
