import React from 'react';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { UserInterface } from '../../common';
import { colors } from '../../theme';

interface Props {
  user: UserInterface;
}

const UserStatisticsLineChart = ({ user }: Props) => {
  dayjs.extend(localeData);

  const labels = dayjs().localeData().monthsShort();

  const data = user.transientValues?.statistics?.lineChartData?.length ? user.transientValues?.statistics?.lineChartData?.map((statistic: any) => ({ name: labels[statistic.month - 1], value: statistic.value })) : [{ name: 0, value: 0 }];

  return (
    <div style={{ width: '100%', height: 404 }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data}>
          <XAxis dataKey="name" padding={{ left: 32, right: 32 }} />
          <YAxis />
          <Tooltip />
          <Line type="linear" dataKey="value" stroke={colors.blue} activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default UserStatisticsLineChart;
