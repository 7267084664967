import dayjs from 'dayjs';
import { Col, Row, Space, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../../../pages/app/_router/app.routes';
import { ActivityInterface, DisplayDateFormat, DisplayFullFormat, DisplayTimeShortFormat, getActivityTypeColor, getActivityTypeIcon, getActivityTypeName, i18n } from '../../../common';
import { colors } from '../../../theme';

const { Text } = Typography;

interface Props {
  activity: ActivityInterface;
}

export default function DashboardActivitiesCard({ activity }: Props) {
  return (
    <Row align="top" justify="space-between" style={{ marginBottom: 16}}>
      <Col>
        <Row>
          <Space>
            <div>
              <div
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: getActivityTypeColor(activity.activityType!.id!), color: 'white', borderRadius: '50%', width: 24, height: 24 }}
              >
                {getActivityTypeIcon(activity.activityType!.id!)}
              </div>
            </div>
            <div>
              <Text strong>{`”${getActivityTypeName(activity.activityType!.id!)}” `}</Text>
              <Text>{i18n.translate('common.constants.activityType.text.scheduledDash')}</Text>
              <Text strong>{` ${dayjs(activity.activityDate).format(DisplayDateFormat)} `}</Text>
              <Text strong>{i18n.translate('common.constants.activityType.text.at')}</Text>
              <Text strong>{` ${dayjs(activity.activityDate).format(DisplayTimeShortFormat)}`}</Text>
            </div>
          </Space>
        </Row>
        {activity.salesOpportunity && <Row style={{ paddingLeft: 32 }}>
          <div>
            <Text strong>{`${i18n.translate('common.constants.activityType.text.onSalesOpportunity')} `}</Text>
            <Link to={`${AppRoutes.SALES_OPPORTUNITIES.fullPath}/${activity?.salesOpportunity?.id}}`}>{`#${activity.salesOpportunity?.id} - ${activity.salesOpportunity?.name}`}</Link>
          </div>
        </Row>}
        {activity.description && <Row style={{ paddingLeft: 32 }}>
          <div>
            <Text style={{ color: colors.grey }}>{activity.description}</Text>
          </div>
        </Row>}
      </Col>
      <Col style={{ paddingLeft: 32 }}>
        <Text style={{ color: colors.grey }}>{dayjs(activity.createdDate).format(DisplayFullFormat)}</Text>
      </Col>
    </Row>
  );
}
