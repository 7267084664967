import { PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Col, Row, Space, Tabs, TabsProps, Typography } from 'antd';
import React, { useState } from 'react';
import { RootState, i18n, useAppSelector } from '../../../common';
import { AssignmentsTable, Guard, GuardFunction, allAssignmentsTableColumns, allAssignmentsTableFilters } from '../../../components';

const { Title } = Typography;

const AssignmentsPage = () => {

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  const renderMyAssignments = (): JSX.Element => (
    <AssignmentsTable
      columns={allAssignmentsTableColumns.filter((c) => (c !== 'user'))}
      filters={allAssignmentsTableFilters.filter((f) => (f !== 'user'))}
      injectedQueryParams={{ filters: [{ name: 'user.id', operator: 'EQ', value: currentUser!.id!.toString() }] }}
      isDrawerOpen={isDrawerOpen}
      closeDrawer={() => setIsDrawerOpen(false)}
    />
  );

  const renderAllAssignments = (): JSX.Element => (
    <AssignmentsTable
      isDrawerOpen={isDrawerOpen}
      closeDrawer={() => setIsDrawerOpen(false)}
    />
  );

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.t('pages.assignments.personal'),
      children: renderMyAssignments(),
    },
    {
      key: '2',
      label: i18n.t('pages.assignments.all'),
      children: renderAllAssignments(),
    }
  ];

  const renderContent = (): JSX.Element => {
    if (GuardFunction({ domain: 'assignment', action: 'view_all_any' })) return <Tabs items={tabs} destroyInactiveTabPane={true}></Tabs>;
    return renderMyAssignments();
  }

  return (
    <div>
      <Breadcrumb
        items={[{ title: i18n.translate('breadcrumb.homepage') }, { title: i18n.translate('breadcrumb.assignments') }]}
        style={{ marginBottom: 24 }}
      />
      <Card>
        <Space direction="vertical" size={16} style={{ display: 'flex' }}>
          <Row justify={'space-between'} align={'middle'}>
            <Col>
              <Title level={2} style={{ margin: 0 }}>{i18n.t('pages.assignments.personal')}</Title>
            </Col>
            <Col>
              <Guard permission={{ domain: 'assignment', action: 'create' }}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setIsDrawerOpen(true)}
                >
                  {i18n.translate('components.forms.assignment.create')}
                </Button>
              </Guard>
            </Col>
          </Row>
          {renderContent()}
        </Space>
      </Card>
    </div>
  )
};

export default AssignmentsPage;
