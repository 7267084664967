import { Row, Col, Space, Progress, theme, Typography } from 'antd';
import React from 'react';
import { SalesOpportunityStatus, SalesOpportunityStatuses, UserInterface, getSalesOpportunityStatusColor } from '../../common';

const { useToken } = theme;

const { Title } = Typography;

interface Props {
  user: UserInterface;
}

const UserStatisticsProgress = ({ user }: Props) => {
  const { token } = useToken();

  const statistics = user.transientValues?.statistics?.salesOpportunityStatusStatisticData;

  const getPercentage = (statusId: number) => statistics?.salesOpportunityStatusCounts.find((x: any) => x.id === statusId)?.percentage;

  const getCountUser = (statusId: number) => statistics?.salesOpportunityStatusCounts.find((x: any) => x.id === statusId)?.count;

  const getCountTotal = () => statistics?.total;

  return <Row justify={'space-between'}>
    <Col span={11}>
      {SalesOpportunityStatuses()?.map((status: SalesOpportunityStatus, index: number) =>
        index % 2 === 0 && (
          <Space size={8} key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginBottom: 24 }}>
            <Title level={4} style={{ margin: 0, color: token.colorTextDescription }}>{status.name}</Title>
            <Progress strokeColor={getSalesOpportunityStatusColor(status.id)} percent={getPercentage(status.id) ? Math.round(getPercentage(status.id)) : 0} style={{ margin: 0 }} />
            <Title level={4} style={{ margin: 0, color: token.colorTextDescription }}>{`${getCountUser(status.id) ? getCountUser(status.id) : 0}/${getCountTotal() ? getCountTotal() : 0}`}</Title>
          </Space>
        )
      )}
    </Col>
    <Col span={11}>
      {SalesOpportunityStatuses()?.map((status: SalesOpportunityStatus, index: number) =>
        index % 2 !== 0 && (
          <Space size={8} key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginBottom: 24 }}>
            <Title level={4} style={{ margin: 0, color: token.colorTextDescription }}>{status.name}</Title>
            <Progress strokeColor={getSalesOpportunityStatusColor(status.id)} percent={getPercentage(status.id) ? Math.round(getPercentage(status.id)) : 0} style={{ margin: 0 }} />
            <Title level={4} style={{ margin: 0, color: token.colorTextDescription }}>{`${getCountUser(status.id) ? getCountUser(status.id) : 0}/${getCountTotal() ? getCountTotal() : 0}`}</Title>
          </Space>
        )
      )}
    </Col>
  </Row>
}

export default UserStatisticsProgress;
