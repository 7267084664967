export const colors = {
  blueDark: '#1b2143',
  blue: '#3F69FF',
  blueLight: '#DCEAFF',
  bluePrimary: '#2F3677',
  red: '#F60003',
  green: '#059435',
  lightGreen: '#52c41a',
  yellow: '#FFA800',
  orange: '#FF6B00',
  purple: '#9747FF',
  pink: '#EE348D',
  white: '#ffffff',
  yellowBackground: '#FFF6E5',
  blueBackground: '#ECF0FF',
  blueDarkBackground: '#3D425F',
  blueLightBackground: '#4E548B',
  greyDark: '#595959',
  greyLight: '#D9D9D9',
  grey: '#A8A8A8',
  black: '#000000',
  greyBackground: '#F8F8F8',
  greyBackgroundDark: '#BFBFBF',
}
  
export const themeConfig = {
  "token": {
    "colorPrimary": "#2F3677",
    "colorError": "#F60003",
    "colorSuccess": "#059435",
    "colorWarning": "#FFA800",
    "colorLink": '#3F69FF',
    "fontFamily": "'HKGrotesk', sans-serif",
    "screenXXL": 1400,
    "screenXXLMin": 1400,
    "screenXLMax": 1399
  },
  "components": {
    "Button": {
      "colorLink": "#3F69FF",
      "borderRadius": 5,
      "controlHeight": 36,
      "controlOutlineWidth": 0,
      "marginXS": 10
    },
    "Typography": {
      "fontSizeHeading1": 24,
      "fontSizeHeading2": 18,
      "fontSizeHeading3": 16,
      "fontSizeHeading4": 14,
      "fontSizeHeading5": 12,
      "colorLink": "#3F69FF",
      "fontFamilyCode": "'HKGrotesk', sans-serif"
    },
    "Menu": {
      "colorBgContainer": "#2F3677",
      "itemBg": "#2F3677",
      "itemColor": "#A8A8A8",
      "itemHoverColor": "#ffffff",
      "itemActiveBg": "#3D425F",
      "itemSelectedBg": "#4E548B",
      "horizontalItemSelectedBg": "#3D425F",
      "colorActiveBarBorderSize": 0,
      "itemSelectedColor": "#ffffff",
      "horizontalItemSelectedColor": "#ffffff",
      "dropdownWidth": 260,
      "borderRadius": 0,
      "borderRadiusLG": 0,
      "borderRadiusSM": 0,
      "marginXXS": 0,
      "marginXS": 0,
      "margin": 0,
      "fontSize": 16,
      "padding": 40,
      "controlHeightLG": 64,
      "radiusItem": 0,
      "itemMarginInline": 0,
      "activeBarWidth": 4,
      "activeBarHeight": 1
    },
    "Input": {
      "controlOutline": "rgba(63, 105, 255, 0.53)",
      "borderRadius": 5,
      "colorIcon": "#d9d9d9",
      "colorPrimary": "#3F69FF",
      "colorPrimaryHover": "#3F69FF",
      "controlOutlineWidth": 1,
      "controlHeight": 36,
      "controlHeightSM": 32,
      "controlPaddingHorizontal": 16,
      "fontSizeIcon": 20,
      "lineHeight": 1,
      "paddingSM": 16
    },
    "Tabs": {
      "colorPrimary": "#3F69FF",
      "colorPrimaryHover": "rgba(63, 105, 255, 0.80)",
      "margin": 24
    },
    "Table": {
      "borderRadius": 0,
      "fontWeightStrong": 400,
      "lineWidth": 0,
      "controlItemBgActive": "#DCEAFF",
      "controlItemBgActiveHover": "#ECF0FF"
    },
    "Pagination": {
      "borderRadius": 2,
      "borderRadiusLG": 2,
      "colorPrimary": "#3F69FF"
    },
    "Checkbox": {
      "borderRadiusSM": 2
    },
    "Card": {
      "paddingLG": 32
    },
    "Switch": {
      "colorPrimary": "#3F69FF",
      "colorPrimaryHover": "#3F69FF"
    },
    "Badge": {
      "fontSizeSM": 10
    },
    "Avatar": {
      "colorTextPlaceholder": "#BFBFBF"
    }
  }
}