import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb, Card } from 'antd';
import { useAppDispatch, getUser, UserInterface, useAppSelector, RootState, updateUser, i18n } from '../../../common';
import { UserForm } from '../../../components';
import { AppRoutes } from '../_router/app.routes';

const ProfileEditPage = () => {
  const dispatch = useAppDispatch();

  const userAuth = useAppSelector((state: RootState) => state.auth);
  const user: UserInterface | undefined = useAppSelector((state: RootState) => state.user.one);

  useEffect(() => {
    if (userAuth.user?.id) dispatch(getUser(userAuth.user!.id, { includes: ['dbRole'] }));
  }, []
  );

  if (!user || !user.id) return null;

  const onUserEdit = (values: UserInterface) => {
    dispatch(updateUser(userAuth.user!.id!, values, true));
  };

  return (
    <>
      <Breadcrumb style={{ marginBottom: 24 }} items={[
        { title: <Link to={AppRoutes.DASHBOARD.fullPath} key={AppRoutes.DASHBOARD.fullPath}>{i18n.translate('breadcrumb.homepage')}</Link> },
        { title: i18n.translate('breadcrumb.profileEdit') }]} />
      <Card>
        <UserForm
          user={user}
          disabled={['dbRole', 'email']}
          onUserFormFinish={(values) => onUserEdit(values)}
          title={i18n.t('components.forms.user.editTitle')}
          submitBtnLabel={i18n.t('buttons.saveChanges')}
        />
      </Card>
    </>
  );
}

export default ProfileEditPage;
