import i18n from 'i18n-js';

import dayjs from 'dayjs';

import en from '../../assets/i18n/en.json';
import hr from '../../assets/i18n/hr.json';

import { changeLanguageAction, store } from '../redux';

const userAuth = store.getState().auth;

const lang = userAuth.lang || 'hr';

i18n.locale = lang;
i18n.fallbacks = true;
i18n.translations = { en, hr };

export const setLang = (l: 'en' | 'hr') => {
  i18n.locale = l;
  dayjs.locale(l);
  store.dispatch(changeLanguageAction(l));
};

export const euroFormat = (value: any) => {
  try {
    const euroConverter = Intl.NumberFormat('en-DE', { style: 'currency', currency: 'EUR' });
    return euroConverter.format(value || 0);
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
    return '??.??';
  }
}

export default i18n;
