import { from, Observable } from 'rxjs';
// import dayjs from 'dayjs';
// import { refreshTokenAction, store } from '../redux';
import { RootState, store } from '../redux';
import { HttpParamsTypes, Pagination, Filter, Sorter } from './queryParams.interface';


// ====== PRIVATE UTILS ======

function getUrlWithParams(url: string, params?: HttpParamsTypes) {
  if (params === null) return url;

  let p = '';
  if (params) {
    p = '?';

    Object.keys(params).forEach((key: string, i: number) => {
      if (p !== '?') p += '&';
      p += `${encodeURIComponent(key[i])}=${encodeURIComponent(params[key[i]])}`;
    })
  }

  return [url, p].join('');
}

/**
 * Requests header generator
 */
export function httpHeader(refresh?: boolean) {
  const header: any = {};
  let APP_TOKEN;
  let REFRESH_TOKEN;

  const currentState: RootState = store.getState();

  if (currentState?.auth?.isAuthenticated) {
    APP_TOKEN = currentState.auth.accessToken;
    REFRESH_TOKEN = currentState.auth.refreshToken;
  }

  if (APP_TOKEN && !refresh) header.Authorization = `Bearer ${APP_TOKEN}`;
  if (REFRESH_TOKEN && refresh) header.Authorization = `Bearer ${REFRESH_TOKEN}`;
  if (currentState?.auth?.xTenantUuid) header['X-TenantUUID'] = currentState?.auth?.xTenantUuid;

  return header;
}

function parseResponse(response: any) {
  return response.text().then((text: any) => {
    let data;

    try {
      data = text && JSON.parse(text);
    } catch (e) {
      data = null;
    }

    if (!response.ok) {
      if (!response.url.includes('/auth/login') && response.status === 401) {
        localStorage.clear();
        setTimeout(() => {
          window.location.href = '/';
        }, 100);
        throw response;
      }

      throw data;
    }

    return data;
  });
}
/**
function checkForRefresh() {
  const currentState: any = store.getState();

  if (currentState?.auth?.isAuthenticated) {
    const now = dayjs();
    const accessTokenValidTo = dayjs(currentState?.auth?.accessTokenValidTo);

    if (now.valueOf() >= accessTokenValidTo.subtract(15, 'minute').valueOf()) store.dispatch(refreshTokenAction());
  }
}
*/

let loaderCount = 0;

function startLoader(response?: any) {
  loaderCount++;
  const el: any = document.getElementById('global-loader');
  const el2: any = document.getElementById('global-loader-wrap');
  el.style.display = 'block';
  el2.style.display = 'block';
  return response;
}

function endLoader(response?: any) {
  loaderCount--;
  if (loaderCount === 0) {
    const el: any = document.getElementById('global-loader');
    const el2: any = document.getElementById('global-loader-wrap');
    el.style.display = 'none';
    el2.style.display = 'none';
  }
  return response;
}

// ====== UTILS ======
// TRENUTNO SE NE KORISTE OVE STVARI POŠTO ZAHTJEVI KOJI IMAJU PARAMETRE BI TREBALI IĆI PREKO JSONAPI-ja

export function getUrlQueryParams(
  pagination?: Pagination,
  sorter?: Sorter,
  filters?: Array<Filter>,
  other?: any
): HttpParamsTypes {
  let params: HttpParamsTypes = {};

  if (pagination && pagination.current && pagination.pageSize) {
    params.pagination = 'true';
    params.current = pagination.current.toString();
    params.perPage = pagination.pageSize.toString();
  } else {
    params.pagination = 'false';
  }

  if (sorter && sorter.columnKey) {
    params.sortAttr = sorter.columnKey;
    params.sortDir = sorter.order || 'ascend';
  }

  if (filters) {
    params.filter = filters.map((x) => [x.name, x.operator, x.value].join('!')).join(';');
  }

  if (other) {
    params = { ...params, ...other };
  }

  return params;
}

export const createPaginationFromRequest = (request: any): Pagination => ({
  current: request.current_page,
  pageSize: request.per_page,
  total: request.total,
});

/**
 * GET request factory
 *
 * @param {string}    path          Resource path
 * @param {object}    params        Request query params
 */
function GET(path: string, params?: HttpParamsTypes, showLoader?: boolean, refresh?: boolean): Observable<any> {
  // if (!refresh) checkForRefresh();

  const requestOptions = {
    method: 'GET',
    headers: httpHeader(refresh),
  };

  let url = [process.env.REACT_APP_API_URL, path].join('/');
  url = getUrlWithParams(url, params);

  showLoader = showLoader === undefined ? true : showLoader;

  if (showLoader) startLoader();

  try {
    return from(
      fetch(url, requestOptions)
        .then(parseResponse)
        .then(showLoader ? endLoader : (r) => r)
        .catch((error) => {
          endLoader(showLoader);
          throw error;
        })
    );
  } catch (error) {
    endLoader(showLoader);
    throw error;
  }
}

/**
 * POST request factory
 *
 * @param {string}    path          Resource path
 * @param {object}    body          Request payload
 * @param {object}    params        Request query params
 */
function POST(path: string, body: any, params?: HttpParamsTypes, hideLoader?: boolean): Observable<any> {
  // checkForRefresh();

  const requestOptions = {
    method: 'POST',
    headers: {
      ...httpHeader(),
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(body),
  };

  let url = [process.env.REACT_APP_API_URL, path].join('/');
  url = getUrlWithParams(url, params);

  if (!hideLoader) startLoader();

  return from(
    fetch(url, requestOptions)
      .then(parseResponse)
      .then(!hideLoader ? endLoader : (r) => r)
      .catch((error) => {
        endLoader(!hideLoader);
        throw error;
      })
  );
}

/**
 * POST JsonPatch request factory
 *
 * @param {string}    path          Resource path
 * @param {object}    body          Request payload
 * @param {object}    params        Request query params
 */
function POSTJsonPatch(path: string, body: any, params?: HttpParamsTypes): Observable<any> {
  // checkForRefresh();

  const requestOptions = {
    method: 'POST',
    headers: {
      ...httpHeader(),
      'Content-Type': 'application/json-patch+json',
      'Accept': 'application/json-patch+json',
    },
    body: JSON.stringify(body),
  };

  let url = [process.env.REACT_APP_API_URL, path].join('/');
  url = getUrlWithParams(url, params);

  startLoader();

  return from(
    fetch(url, requestOptions)
      .then(parseResponse)
      .then(endLoader)
      .catch((error) => {
        endLoader();
        throw error;
      })
  );
}

/**
 * PUT request factory
 *
 * @param {string}    path          Resource path
 * @param {object}    body          Request payload
 * @param {object}    params        Request query params
 */
function PUT(path: string, body: any, params?: HttpParamsTypes): Observable<any> {
  // checkForRefresh();

  const requestOptions = {
    method: 'PUT',
    headers: {
      ...httpHeader(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  let url = [process.env.REACT_APP_API_URL, path].join('/');
  url = getUrlWithParams(url, params);

  startLoader();

  return from(
    fetch(url, requestOptions)
      .then(parseResponse)
      .then(endLoader)
      .catch((error) => {
        endLoader();
        throw error;
      })
  );
}

function PATCH(path: string, body: any, params?: HttpParamsTypes): Observable<any> {
  // checkForRefresh();

  const requestOptions = {
    method: 'PATCH',
    headers: {
      ...httpHeader(),
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  let url = [process.env.REACT_APP_API_URL, path].join('/');
  url = getUrlWithParams(url, params);

  startLoader();

  return from(
    fetch(url, requestOptions)
      .then(parseResponse)
      .then(endLoader)
      .catch((error) => {
        endLoader();
        throw error;
      })
  );
}

/**
 * DELETE request factory
 *
 * @param {string}    path          Resource path
 * @param {object}    params        Request query params
 */
function DELETE(path: string, params?: HttpParamsTypes, hideLoader?: boolean): Observable<any> {
  // checkForRefresh();

  const requestOptions = {
    method: 'DELETE',
    headers: {
      ...httpHeader(),
      'Content-Type': 'application/json',
    },
  };

  let url = [process.env.REACT_APP_API_URL, path].join('/');
  url = getUrlWithParams(url, params);

  if (!hideLoader) startLoader();

  return from(
    fetch(url, requestOptions)
      .then(parseResponse)
      .then(!hideLoader ? endLoader : (r) => r)
      .catch((error) => {
        endLoader(!hideLoader);
        throw error;
      })
  );
}

export default {
  GET,
  POST,
  POSTJsonPatch,
  PUT,
  DELETE,
  PATCH,
  getUrlQueryParams,
};
