import { MinusCircleOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row } from 'antd';
import ColorPicker from 'antd/es/color-picker';
import React, { useEffect } from 'react';
import { ReservationCategoryInterface, i18n } from '../../../common';
import { colors } from '../../../theme';

interface Props {
  reservationCategory?: ReservationCategoryInterface;
  onFinish?: (values: any) => void;
  trigger: number;
  setColorHex: any;
}

export default function ReservationCategoryForm({ onFinish, reservationCategory, trigger, setColorHex }: Props) {
  const [form] = Form.useForm();

  const presets = [
    {
      label: i18n.t('components.forms.reservationCategory.reccomended'),
      colors: [
        colors.blueDark,
        colors.blue,
        colors.bluePrimary,
        colors.red,
        colors.green,
        colors.yellow,
        colors.orange,
        colors.purple,
        colors.pink,
        colors.greyDark
      ],
    },
  ];

  useEffect(() => {
    if (onFinish && trigger > 0) {
      form.submit();
    }
  }, [trigger]);

  const initialValues = {
    ...reservationCategory,
    reservationOptionDTOs: reservationCategory?.reservationOptions,
    color: reservationCategory?.color || colors.bluePrimary
  };

  return (
    <Form
      form={form}
      name="reservationCategoryForm"
      layout='vertical'
      onFinish={onFinish}
      initialValues={initialValues}
      preserve={false}
    >
      <Form.Item
        label={i18n.t('components.forms.reservationCategory.name')}
        name="name"
        rules={[
          { required: true, message: i18n.translate('components.forms.reservationCategory.messages.nameRequired') },
        ]}
      >
        <Input size="small" />
      </Form.Item>
      <Form.Item
        label={i18n.t('components.forms.reservationCategory.color')}
        name="color"
        rules={[
          { required: true, message: i18n.translate('components.forms.reservationCategory.messages.colorRequired') },
        ]}
      >
        <ColorPicker presets={presets} format="hex" onChange={setColorHex} />
      </Form.Item>
      <Form.List name="reservationOptionDTOs">
        {(fields, { add, remove }, { errors }) => (
          <>
            <Form.Item label={i18n.t('components.forms.reservationCategory.options')}>
              {fields.map(({ key, name, ...restField }, index) => (
                <Row align={'middle'} justify={'space-between'} key={index} wrap={false}>
                  <Form.Item style={{ width: '100%' }} {...restField} validateTrigger={['onChange', 'onBlur']} name={[name, 'name']}>
                    <Input placeholder={i18n.t('components.forms.reservationCategory.option')} size="small" />
                  </Form.Item>
                  <MinusCircleOutlined style={{ marginBottom: 24, marginLeft: 8 }} onClick={() => remove(name)} />
                </Row>
              ))}
            </Form.Item>
            <Form.Item>
              <Button type="link" onClick={() => add()} style={{ paddingLeft: 0 }} icon={<PlusSquareOutlined />}>
                {i18n.t('components.forms.reservationCategory.addOption')}
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
}
