import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, List, Pagination as AntPagination, Space, Typography, Row, Col } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { DefaultPagination, NewsInterface, Pagination, QueryParams, createNews, i18n, updateNews, useAppDispatch } from '../../common';
import { NewsForm } from '../forms';

const { Title, Text } = Typography;

interface Props {
  news: NewsInterface[];
  total: number;
  injectedQueryParams?: QueryParams;
  onPaginationChange: (page: number, pageSize: number) => void;
}

export default function NewsComponent({ news, total, injectedQueryParams, onPaginationChange }: Props) {
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState<boolean>(false);
  const [trigger, setTrigger] = useState<number>(0);
  const [selectedNews, setNews] = useState<NewsInterface | undefined>(undefined);
  const [pagination, setPagination] = useState<Pagination>(DefaultPagination);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setTrigger(0);
    setNews(undefined);
  };

  const selectNews = (select: NewsInterface) => {
    setNews(select);
    showDrawer();
  };

  const onFormFinish = (values: any) => {
    const requestObj = {
      ...values,
      filesDTO: values.filesDTO?.fileList?.map((x: any) => ({ id: x.id ? x.id : undefined, uuid: x.response && x.response.length ? x.response[0].uuid : x.uid, name: x.name }))
    }
    if (selectedNews) {
      dispatch(updateNews(selectedNews.id!, requestObj, injectedQueryParams));
    } else {
      dispatch(createNews(requestObj, injectedQueryParams));
    }
    onClose();
  };

  const onPageChange = (page: number, size: number) => {
    setPagination({ ...pagination, current: page, pageSize: size });
    onPaginationChange(page, size);
  };

  return (
    <>
      <Row justify={'end'} align={'middle'}>
        <Col>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              showDrawer();
            }}
          >
            {i18n.t('components.forms.news.create')}
          </Button>
        </Col>
      </Row>
      <List
        dataSource={news}
        pagination={false}
        renderItem={(n) => (
          <List.Item key={`news${n.id}`} onClick={() => selectNews(n)}>
            <Space direction="vertical" style={{ paddingLeft: 12 }}>
              <Title level={4} style={{ marginTop: 8 }}>
                {n.title}
              </Title>
              <Text>{n.content}</Text>
              <Space direction="horizontal" size={'large'}>
                <Text strong>{n.creator?.fullName}</Text>
                <Text type="secondary">{dayjs(n.createdDate).format('DD.MM.YYYY.')}</Text>
              </Space>
            </Space>
          </List.Item>
        )}
      />
      <AntPagination style={{ float: 'right', marginTop: 24 }} {...pagination} total={total} onChange={onPageChange} />
      <Drawer
        title={
          selectedNews ? i18n.translate('components.forms.news.edit') : i18n.translate('components.forms.news.create')
        }
        placement="right"
        open={open}
        onClose={onClose}
        destroyOnClose
        extra={
          <Space>
            <Button onClick={onClose}>{i18n.translate('buttons.cancel')}</Button>
            <Button onClick={() => setTrigger(trigger + 1)} type="primary">
              {i18n.translate('buttons.submit')}
            </Button>
          </Space>
        }
      >
        <NewsForm trigger={trigger} news={selectedNews} onFinish={onFormFinish} />
      </Drawer>
    </>
  );
}
