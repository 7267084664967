import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AreaInterface, AreaResponseInterface, AreasResponseInterface } from '../../interfaces';
import type { AppDispatch } from '../store';
import { JsonApiService, QueryParams } from '../../api';

// ============ INTERFACE ============

export interface AreaStateInterface {
  all: {
    data: Array<AreaInterface>;
    total?: number;
  };
  one?: AreaInterface;
  filter?: Array<AreaInterface>;
  error?: Error;
}

// ============ INIT STATE ============

const initialState: AreaStateInterface = {
  all: {data: []},
};


// ========= SLICE ==========

export const areaSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {
    setAreaAllData: (state: AreaStateInterface, action: PayloadAction<Array<AreaInterface>>) => {
      state.all.data = action.payload;
    },
    setAreaAllTotal: (state: AreaStateInterface, action: PayloadAction<number>) => {
      state.all.total = action.payload;
    },
    setAreaOne: (state: AreaStateInterface, action: PayloadAction<AreaInterface>) => {
      state.one = action.payload;
    },
    setAreaFilterData: (state: AreaStateInterface, action: PayloadAction<Array<AreaInterface>>) => {
      state.filter = action.payload;
    },
    setAreaError: (state: AreaStateInterface, action: PayloadAction<Error>) => {
      state.error = action.payload;
    }
  }
})

export const { setAreaAllData, setAreaOne, setAreaError, setAreaAllTotal, setAreaFilterData } = areaSlice.actions;

export const getAreas = (queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('area', undefined, queryParams)
    .subscribe({
      next: (response: AreasResponseInterface) => {
        dispatch(setAreaAllData(response.data));
        if (response.meta && (response.meta.totalResourceCount || response.meta.totalResourceCount === 0)) dispatch(setAreaAllTotal(response.meta.totalResourceCount));
      },
      error: (error: Error) => {
        dispatch(setAreaError(error));
        dispatch(setAreaAllTotal(0));
      }
    })
}

export const getAreaFilter = (queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('area', undefined, queryParams)
    .subscribe({
      next: (response: AreasResponseInterface) => {
        dispatch(setAreaFilterData(response.data));
      },
      error: (error: Error) => {
        dispatch(setAreaError(error));
      }
    })
}

export const getArea = (id: number, queryParams: QueryParams) => (dispatch: AppDispatch) =>
  JsonApiService.get('area', id, queryParams).subscribe({
    next: (response: AreaResponseInterface) => {
      dispatch(setAreaOne(response.data));
    },
    error: (error: Error) => {
      dispatch(setAreaError(error));
    },
  });

export default areaSlice.reducer;
