import { Col, Row, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import { RootState, getDashboardStatistics, i18n, useAppDispatch, useAppSelector } from '../../../common';
import {
  DashboardActivities,
  DashboardAssignments,
  DashboardCampaignStats,
  DashboardCampaigns,
  DashboardMonthlyStats,
  DashboardSalesOpportunities,
  GuardFunction
} from '../../../components';

const DashboardPage = () => {
  const { Title, Text } = Typography;

  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getDashboardStatistics());
  }, []);

  const dashboardStatistics = useAppSelector((state: RootState) => state.dashboard.statistics);

  return (
    <>
      <Row>
        <Space direction="vertical" style={{marginBottom: 24}} size={0}>
          <Title style={{ marginBottom: 0 }}>{i18n.translate('pages.dashboard.title', { user: currentUser?.fullName })}</Title>
          <Text strong type="secondary">
            {i18n.translate('pages.dashboard.subtitle')}
          </Text>
        </Space>
      </Row>
      <Row gutter={[32, 24]}>
        {dashboardStatistics &&
          <>
            {GuardFunction({ domain: 'campaign', action: 'view_all_any' }) && dashboardStatistics.totalSalesOpportunityStatusStatistic?.salesOpportunityStatusCounts?.length > 0 &&
              <Col span={24} xxl={12}>
                <DashboardCampaignStats statistics={dashboardStatistics.totalSalesOpportunityStatusStatistic} title={i18n.translate('pages.dashboard.salesOpportunitiesStatsAll')} />
              </Col>
            }
            {dashboardStatistics.totalSalesOpportunityStatusStatisticForUser?.salesOpportunityStatusCounts?.length > 0 &&
              <Col span={24} xxl={12}>
                <DashboardCampaignStats statistics={dashboardStatistics.totalSalesOpportunityStatusStatisticForUser} />
              </Col>
            }
            {GuardFunction({ domain: 'campaign', action: 'view_all_any' }) && dashboardStatistics.monthlySuccessRateStatistic?.length > 0 &&
              <Col span={24} xxl={12}>
                <DashboardMonthlyStats statistics={dashboardStatistics.monthlySuccessRateStatistic} title={i18n.translate('pages.dashboard.monthlyStatsAll')} />
              </Col>
            }
            {dashboardStatistics.monthlySuccessRateStatisticForUser?.length > 0 &&
              <Col span={24} xxl={12}>
                <DashboardMonthlyStats statistics={dashboardStatistics.monthlySuccessRateStatisticForUser} />
              </Col>
            }
          </>
        }
        <Col span={24} xl={12}>
          <DashboardActivities />
        </Col>
        <Col span={24} xl={12}>
          <DashboardAssignments />
        </Col>
        <Col span={24}>
          <DashboardSalesOpportunities />
        </Col>
        <Col span={24}>
          <DashboardCampaigns />
        </Col>
      </Row>
    </>
  );
};

export default DashboardPage;
