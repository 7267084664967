import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ApplicationSettingsInterface, ApplicationSettingsResponseInterface } from '../../interfaces';
import type { AppDispatch } from '../store';
import { JsonApiService, QueryParams } from '../../api';

// ============ INTERFACE ============

export interface ApplicationSettingsStateInterface {
  one?: ApplicationSettingsInterface;
  error?: Error;
}

// ============ INIT STATE ============

const initialState: ApplicationSettingsStateInterface = {
  one: undefined,
};


// ========= SLICE ==========

export const applicationSettingsSlice = createSlice({
  name: 'application_settings',
  initialState,
  reducers: {
    setApplicationSettingsOne: (state: ApplicationSettingsStateInterface, action: PayloadAction<ApplicationSettingsInterface>) => {
      state.one = action.payload;
    },
    setApplicationSettingsError: (state: ApplicationSettingsStateInterface, action: PayloadAction<Error>) => {
      state.error = action.payload;
    }
  }
})

export const { setApplicationSettingsOne, setApplicationSettingsError } = applicationSettingsSlice.actions;

export const getApplicationSettings = (queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('application_settings', 1, queryParams)
    .subscribe({
      next: (response: ApplicationSettingsResponseInterface) => {
        dispatch(setApplicationSettingsOne(response.data));
      },
      error: (error: Error) => {
        dispatch(setApplicationSettingsError(error));
      }
    })
}

export const updateApplicationSettings = (data: ApplicationSettingsInterface) => (dispatch: AppDispatch) => {
  JsonApiService.patch('application_settings', 1, data)
    .subscribe({
      next: (response: any) => {
        dispatch(setApplicationSettingsOne(response.data));
      },
      error: (error: Error) => {
        dispatch(setApplicationSettingsError(error));
      }
    })
}

export default applicationSettingsSlice.reducer;