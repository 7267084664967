import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { CampaignInterface, ClientInterface, ClientResponseInterface, ClientsResponseInterface, UserInterface } from '../../interfaces';
import type { AppDispatch } from '../store';
import { JsonApiService, JsonPatchService, QueryParams } from '../../api';
import { i18n, navigate } from '../../services';
import { SalesOpportunityStatusEnum } from '../../enums';

// ============ INTERFACE ============

export interface ClientStateInterface {
  all: {
    data: Array<ClientInterface>;
    total?: number;
  };
  one?: ClientInterface;
  filter?: Array<ClientInterface>; // neadekvatan naziv, takav je jer je in a drugim entitetima takav
  error?: Error;
}

// ============ INIT STATE ============

const initialState: ClientStateInterface = {
  all: { data: [] },
};


// ========= SLICE ==========

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientAllData: (state: ClientStateInterface, action: PayloadAction<Array<ClientInterface>>) => {
      state.all.data = action.payload;
    },
    setClientAllTotal: (state: ClientStateInterface, action: PayloadAction<number>) => {
      state.all.total = action.payload;
    },
    setClientOne: (state: ClientStateInterface, action: PayloadAction<ClientInterface>) => {
      state.one = action.payload;
    },
    updateClientAllData: (state: ClientStateInterface, action: PayloadAction<ClientInterface>) => {
      const index = state.all.data.findIndex(x => x.id === action.payload.id);
      state.all.data[index] = action.payload;
    },
    createClientAllData: (state: ClientStateInterface, action: PayloadAction<ClientInterface>) => {
      state.all.data.unshift(action.payload);
      if (state.all.total || state.all.total === 0) state.all.total += 1;
    },
    setClientFilterData: (state: ClientStateInterface, action: PayloadAction<Array<ClientInterface>>) => {
      state.filter = action.payload;
    },
    setClientError: (state: ClientStateInterface, action: PayloadAction<Error>) => {
      state.error = action.payload;
    }
  }
})

export const { setClientAllData, setClientError, setClientAllTotal, setClientOne, createClientAllData, updateClientAllData, setClientFilterData } = clientSlice.actions;

export const getClients = (queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('client', undefined, queryParams)
    .subscribe({
      next: (response: ClientsResponseInterface) => {
        dispatch(setClientAllData(response.data));
        if (response.meta && (response.meta.totalResourceCount || response.meta.totalResourceCount === 0)) dispatch(setClientAllTotal(response.meta.totalResourceCount));
      },
      error: (error: Error) => {
        dispatch(setClientError(error));
        dispatch(setClientAllTotal(0))
      }
    })
}

export const getClient = (id: number, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('client', id, queryParams)
    .subscribe({
      next: (response: ClientResponseInterface) => {
        dispatch(setClientOne(response.data));
      },
      error: (error: Error) => {
        dispatch(setClientError(error));
      }
    })
}

export const getClientFilter = (queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('client', undefined, queryParams)
    .subscribe({
      next: (response: ClientsResponseInterface) => {
        dispatch(setClientFilterData(response.data));
      },
      error: (error: Error) => {
        dispatch(setClientError(error));
      }
    })
}

export const createClient = (data: ClientInterface) => (dispatch: AppDispatch) => {
  JsonApiService.post('client', data)
    .subscribe({
      next: (response: any) => {
        dispatch(setClientOne(response.data));
        dispatch(createClientAllData(response.data));
        notification.success({ message: i18n.translate('components.banners.client.createSuccess'), duration: 2 });
        navigate(-1);
      },
      error: (error: Error) => {
        if (error.message === 'OIB must be provided.')
          notification.error({ message: i18n.translate('components.banners.client.oibNotNull'), duration: 2 });
        else if (error.message === 'The given OIB is already in use.')
          notification.error({ message: i18n.translate('components.banners.client.oibDuplicated'), duration: 2 });
        else notification.error({ message: i18n.translate('components.banners.client.createError'), duration: 2 });
        dispatch(setClientError(error));
      }
    })
}

export const updateClient = (id: number, data: ClientInterface) => (dispatch: AppDispatch) => {
  // example
  JsonApiService.patch('client', id, data)
    .subscribe({
      next: (response: any) => {
        dispatch(setClientOne(response.data));
        dispatch(updateClientAllData(response.data));
        notification.success({ message: i18n.translate('components.banners.client.editSuccess'), duration: 2 });
        navigate(-1);
      },
      error: (error: Error) => {
        dispatch(setClientError(error));
      }
    })
}

export const transferClientsToAgent = (selectedAgents: Array<UserInterface>, selectedClients: Array<ClientInterface>, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  if (!selectedClients.length || !selectedAgents.length) return;

  const request: Array<any> = [];

  selectedClients.forEach((client) => {
    request.push(selectedAgents.map((agent) => ({
      op: 'PATCH',
      path: `client/${client.id}`,
      value: {
        type: 'client',
        id: client.id,
        relationships: {
          user: {
            data: {
              id: agent.id,
              type: 'user'
            }
          }
        }
      }
    })))
  })

  JsonPatchService.jsonPatch(request.flat())
    .subscribe({
      next: (response: any) => {
        if (response.errors || response[0].errors) {
          notification.error({ message: i18n.translate('components.banners.client.transferClientsError'), duration: 2 });
          return;
        }
        dispatch(getClients(queryParams));
        notification.success({ message: i18n.translate('components.banners.client.transferClientsSuccess'), duration: 2 });
      },
      error: (error: Error) => {
        dispatch(setClientError(error));
        notification.error({ message: i18n.translate('components.banners.client.transferClientsError'), duration: 2 });
      }
    })
}

export const addCampaignsToClients = (selectedCampaigns: Array<CampaignInterface>, selectedClients: Array<ClientInterface>, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  if (!selectedClients.length || !selectedCampaigns.length) return;

  const request: Array<any> = [];

  selectedCampaigns.forEach((campaign) => {
    request.push(selectedClients.map((client) => ({
      op: 'POST',
      path: 'sales_opportunity',
      value: {
        type: 'sales_opportunity',
        // ovaj id bi idealno trebalo maknuti, dodan je privremeno jer JsonPatch BE poziv zasada radi čudno
        id: Math.floor(Math.random() * (9999999 - 1000000 + 1)) + 1000000,
        attributes: {
          // name: `K-${campaign.name}`,
          // value: campaign.value,
          // description: campaign.description
        },
        relationships: {
          client: {
            data: {
              id: client.id,
              type: 'client'
            }
          },
          campaign: {
            data: {
              id: campaign.id,
              type: 'campaign'
            }
          },
          latestStatus: {
            data: {
              id: SalesOpportunityStatusEnum.CREATED,
              type: 'sales_opportunity_status'
            }
          },
          users: {
            data: [
              {
                id: client.user?.id,
                type: 'user'
              }
            ]
          },
        }
      }
    })))
  })

  JsonPatchService.jsonPatch(request.flat())
    .subscribe({
      next: (response: any) => {
        if (response.errors || response[0].errors) {
          notification.error({ message: i18n.translate('components.banners.client.addClientsError'), duration: 2 });
          return;
        }
        dispatch(getClients(queryParams));
        notification.success({ message: i18n.translate('components.banners.client.addClientsSuccess'), duration: 2 });
      },
      error: (error: Error) => {
        dispatch(setClientError(error));
        notification.error({ message: i18n.translate('components.banners.client.addClientsError'), duration: 2 });
      }
    })
}

export default clientSlice.reducer;