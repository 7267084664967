export function isOibValid(oib: string): boolean {

  if (oib.includes('HR')) oib = oib.replace('HR', '');
  
  if (oib.length !== 11) return false;

  const b = parseInt(oib, 10);
  if (Number.isNaN(b)) return false;

  let a = 10;
  for (let i = 0; i < 10; i++) {
    a += parseInt(oib.substr(i, 1), 10);
    a %= 10;
    if (a === 0) a = 10;
    a *= 2;
    a %= 11;
  }

  let kontrolni = 11 - a;
  if (kontrolni === 10) kontrolni = 0;

  return kontrolni === parseInt(oib.substr(10, 1), 10);
}

export function oibValidator(rule: any, value: any) {
  return new Promise((resolve, reject) => {
    if (!value || value?.length === 0) resolve(true);
    if (isOibValid(value)) resolve(true)
    else reject(rule.message);
  });
}
