import { Avatar, Button, Col, Descriptions, Drawer, Grid, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { EditIcon, UserIcon } from '../icons';
import { useAppDispatch, useAppSelector, RootState, getApplicationSettings, i18n, updateApplicationSettings } from '../../common';
import { ApplicationSettingsForm } from '../forms';

const { useBreakpoint } = Grid;

export default function CompanyDetails() {
  const dispatch = useAppDispatch();

  const { md } = useBreakpoint();

  const [trigger, setTrigger] = useState<number>(0);

  const [applicationSettingsDrawerOpen, setApplicationSettingsDrawerOpen] = useState<boolean>(false);

  const applicationSettings = useAppSelector((state: RootState) => state.applicationSettings);

  useEffect(() => {
    dispatch(getApplicationSettings());
  }, []
  )

  const descriptionsItems: Array<{label: string, render: JSX.Element}> = [
    {
      label: i18n.translate('components.details.company.companyLogo'),
      render: <>{applicationSettings.one?.image ? <Avatar src={applicationSettings.one?.image} size={64}/> : <Avatar size={64} icon={<UserIcon />} />}</>
    },
    {
      label: i18n.translate('components.details.company.companyName'),
      render: <>{applicationSettings.one?.companyName}</>
    },
    {
      label: i18n.translate('components.details.company.oib'),
      render: <>{applicationSettings.one?.oib}</>
    },
    {
      label: i18n.translate('components.details.company.address'),
      render: <>{applicationSettings.one?.address}</>
    }
  ]

  const handleCloseChange = () => {
    setApplicationSettingsDrawerOpen(false);
    setTrigger(0);
  };

  const handleOpenChange = () => {
    setApplicationSettingsDrawerOpen(true);
  };

  const onApplicationSettingsFormFinish = (values: any) => {
    dispatch(updateApplicationSettings(values));
    handleCloseChange();
  };

  return (
    <>
      <Row justify={'space-between'}>
        <Col xs={24} md={20} flex={'auto'}>
          <Descriptions column={1} labelStyle={{ width: '200px', justifyContent: md ? 'flex-end' : 'flex-start', marginRight: 16 }} layout={md ? 'horizontal' : 'vertical'}>
            {descriptionsItems.map((item: {label: string, render: JSX.Element}, index: number) => (
              <Descriptions.Item key={index} label={item.label}>{item.render}</Descriptions.Item>
            ))}
          </Descriptions>
        </Col>
        <Col flex={'none'}><Button type='primary' icon={<EditIcon />} onClick={handleOpenChange}>{i18n.translate('buttons.edit')}</Button></Col>
      </Row>
      <Drawer
        title={i18n.translate('components.details.company.drawer.title')}
        onClose={handleCloseChange}
        open={applicationSettingsDrawerOpen}
        destroyOnClose
        width={425}
        extra={
          <Space>
            <Button onClick={handleCloseChange}>{i18n.translate('buttons.cancel')}</Button>
            <Button onClick={() => setTrigger(trigger + 1)} type="primary">
              {i18n.translate('buttons.submit')}
            </Button>
          </Space>
        }
      >
        <ApplicationSettingsForm trigger={trigger} applicationSettings={applicationSettings.one} onApplicationSettingsFormFinish={onApplicationSettingsFormFinish}></ApplicationSettingsForm>
      </Drawer>
    </>
  );
}
