import React from 'react';
import { Form, Input, Button, Typography, theme, } from 'antd';
import { i18n } from '../../../common';

const { useToken } = theme;

const { Title, Text } = Typography;

interface Props {
  onResetPasswordFormFinish?: (values: any) => void;
  onResetPasswordFormFinishFailed?: (errorInfo: any) => void;
}

function ResetPasswordForm({ onResetPasswordFormFinish, onResetPasswordFormFinishFailed }: Props) {
  const { token } = useToken();

  return (
    <div className="resetPasswordForm">
      <Title level={1}>{i18n.translate(`resetPassword.title`)}</Title>
      <Text style={{ color: token.colorTextDescription, marginBottom: 24 }}>{i18n.translate(`resetPassword.subtitle`)}</Text>
      <Form
        layout='vertical'
        size='large'
        name='resetPassword'
        onFinish={onResetPasswordFormFinish}
        onFinishFailed={onResetPasswordFormFinishFailed}
      >
        <Form.Item
          name="newPassword"
          rules={[{ required: true, message: i18n.translate(`resetPassword.form.validation.required`) }]}
        >
          <Input.Password style={{marginTop: 24}} placeholder={i18n.translate(`resetPassword.form.placeholders.newPassword`)} />
        </Form.Item>

        <Form.Item
          name="repeatNewPassword"
          rules={[{ required: true, message: i18n.translate(`resetPassword.form.validation.required`) }]}
        >
          <Input.Password placeholder={i18n.translate(`resetPassword.form.placeholders.repeatNewPassword`)} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" size="large" block htmlType="submit">
            {i18n.translate(`resetPassword.btns.resetPassword`)}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ResetPasswordForm;
