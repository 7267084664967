import React from 'react';
import { Breadcrumb, Card } from 'antd';
import { Link } from 'react-router-dom';
import { UserForm, allUserFormItems } from '../../../components';
import { useAppDispatch, UserInterface, createUser, i18n } from '../../../common';
import { AppRoutes } from '../_router/app.routes';

const UserCreatePage = () => {
  const dispatch = useAppDispatch();

  const onUserCreate = (values: UserInterface) => {
    dispatch(createUser(values));
  };

  return (
    <>
      <Breadcrumb style={{ marginBottom: 24 }} items={[
        { title: <Link to={AppRoutes.DASHBOARD.fullPath} key={AppRoutes.DASHBOARD.fullPath}>{i18n.translate('breadcrumb.homepage')}</Link> },
        { title: <Link to={AppRoutes.USERS.fullPath} key={AppRoutes.USERS.fullPath}>{i18n.t('breadcrumb.users')}</Link> },
        { title: i18n.translate('breadcrumb.userCreate') }]}
      />
      <Card>
        <UserForm
          onUserFormFinish={(values) => onUserCreate(values)}
          formItems={allUserFormItems.filter((f) => (f !== 'active' && f !== 'resetPassword'))}
          title={i18n.t('components.forms.user.createTitle')}
          submitBtnLabel={i18n.t('components.forms.user.create')}></UserForm>
      </Card>
    </>
  );
}

export default UserCreatePage;
