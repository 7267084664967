import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Avatar, Space } from 'antd';
import { useAppDispatch, useAppSelector, RootState, QueryParams, UserInterface, getUsers, i18n } from '../../common';
import { Search } from '../filters';
import { UserIcon } from '../icons';
import Table, { TableParams, TableSearch } from './table.component';

type AgentsTableColumnType = 'fullName';

export const allAgentsTableColumns: Array<AgentsTableColumnType> = ['fullName'];

interface Props {
  columnTitle: string;
  selectionType: 'radio' | 'checkbox';
  onAgentSelectionChanged?: (values: any) => void;
  injectedQueryParams?: QueryParams;
  columns?: Array<AgentsTableColumnType>;
}

const ToAgentTable = ({ columnTitle, selectionType, onAgentSelectionChanged, injectedQueryParams, columns }: Props) => {
  const dispatch = useAppDispatch();

  const componentQueryParams: QueryParams = {
    ...injectedQueryParams,
    includes: [
      ...(injectedQueryParams?.includes || []),
      'dbRole'
    ],
    pagination: {current: 1, pageSize: 999999}
  };

  const [queryParams, setQueryParams] = useState<QueryParams>(componentQueryParams);

  useEffect(() => {
    dispatch(getUsers(queryParams));
  }, [queryParams]
  )

  const rowSelection = {
    preserveSelectedRowKeys: true,
    type: selectionType,
    onChange: (selectedRowKeys: React.Key[], selectedRows: UserInterface[]) => onAgentSelectionChanged && onAgentSelectionChanged(selectedRows)
  };

  const agents = useAppSelector((state: RootState) => state.user.all);

  const tableColumns: Array<AgentsTableColumnType> = columns || allAgentsTableColumns;

  const agentsTableColumns: ColumnsType<UserInterface> = [
    {
      title: columnTitle,
      dataIndex: 'fullName',
      key: 'fullName',
      render: (dataIndex: string, agent: UserInterface) =>
        <Space>
          {agent.profileImageUrl ? <Avatar src={agent.profileImageUrl} /> : <Avatar icon={<UserIcon />} />}
          {agent.fullName}
        </Space>,
      width: 150
    }
  ].filter((c) => tableColumns.includes(c.key! as AgentsTableColumnType));

  const searchFields: Array<TableSearch> = [
    { component: Search, keys: ['fullName'], placeholder: i18n.translate('header.search.placeholder') }
  ]

  const handleTableChange = (tableParams: TableParams) => {
    setQueryParams({
      pagination: tableParams.pagination,
      filters: [
        ...(tableParams.filters || []),
        ...(componentQueryParams?.filters || []),
      ],
      sorters: tableParams.sorters,
      includes: componentQueryParams.includes,
      search: tableParams.search
    })
  };

  if (!agents) return null;
  return (
    <Table
      injectedRowSelection={rowSelection}
      columns={agentsTableColumns}
      dataSource={agents.data}
      rowKey={"id"}
      handleTableChange={handleTableChange}
      total={agents.total}
      searchFields={searchFields}
    />
  )
}

export default ToAgentTable;
