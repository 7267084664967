import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { JsonApiService, QueryParams } from '../../api';
import {
  ReservationCategoriesResponseInterface,
  ReservationCategoryInterface,
  ReservationCategoryResponseInterface,
} from '../../interfaces';
import { i18n } from '../../services';
import type { AppDispatch } from '../store';

export interface ReservationCategoryStateInterface {
  all: {
    data: Array<ReservationCategoryInterface>;
    total?: number;
  };
  one?: ReservationCategoryInterface;
  error?: Error;
}

const initialState: ReservationCategoryStateInterface = {
  all: { data: [] },
};

export const reservationCategorySlice = createSlice({
  name: 'reservationCategory',
  initialState,
  reducers: {
    setReservationCategoryAllData: (
      state: ReservationCategoryStateInterface,
      action: PayloadAction<Array<ReservationCategoryInterface>>
    ) => {
      state.all.data = action.payload;
    },
    setReservationCategoryOne: (
      state: ReservationCategoryStateInterface,
      action: PayloadAction<ReservationCategoryInterface>
    ) => {
      state.one = action.payload;
    },
    setReservationCategoryAllTotal: (state: ReservationCategoryStateInterface, action: PayloadAction<number>) => {
      state.all.total = action.payload;
    },
    setReservationCategoryError: (state: ReservationCategoryStateInterface, action: PayloadAction<Error>) => {
      state.error = action.payload;
    },
    createReservationCategoryAllData: (state: ReservationCategoryStateInterface, action: PayloadAction<ReservationCategoryInterface>) => {
      state.all.data.unshift(action.payload);
      if (state.all.total || state.all.total === 0) state.all.total += 1;
    },
    updateReservationCategoryAllData: (state: ReservationCategoryStateInterface, action: PayloadAction<ReservationCategoryInterface>) => {
      const index = state.all.data.findIndex((x) => x.id === action.payload.id);
      state.all.data[index] = action.payload;
    },
  },
});

export const {
  setReservationCategoryAllData,
  setReservationCategoryOne,
  setReservationCategoryError,
  setReservationCategoryAllTotal,
  createReservationCategoryAllData,
  updateReservationCategoryAllData,
} = reservationCategorySlice.actions;

export const getReservationCategories = (queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('reservation_category', undefined, queryParams).subscribe({
    next: (response: ReservationCategoriesResponseInterface) => {
      dispatch(setReservationCategoryAllData(response.data));
      if (response.meta && (response.meta.totalResourceCount || response.meta.totalResourceCount === 0))
        dispatch(setReservationCategoryAllTotal(response.meta.totalResourceCount));
    },
    error: (error: Error) => {
      dispatch(setReservationCategoryError(error));
      dispatch(setReservationCategoryAllTotal(0));
    },
  });
};

export const getReservationCategory = (id: number, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('reservation_category', id, queryParams).subscribe({
    next: (response: ReservationCategoryResponseInterface) => {
      dispatch(setReservationCategoryOne(response.data));
    },
    error: (error: Error) => {
      dispatch(setReservationCategoryError(error));
    },
  });
};

export const createReservationCategory = (data: ReservationCategoryInterface, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.post('reservation_category', data, queryParams).subscribe({
    next: (response: any) => {
      if (response.error) {
        notification.error({ message: i18n.translate('components.banners.reservationCategory.createError'), duration: 2 });
        return;
      }
      dispatch(setReservationCategoryOne(response.data));
      dispatch(createReservationCategoryAllData(response.data));
      notification.success({ message: i18n.translate('components.banners.reservationCategory.createSuccess'), duration: 2 });
    },
    error: (error: Error) => {
      dispatch(setReservationCategoryError(error));
      notification.error({ message: i18n.translate('components.banners.reservationCategory.createError'), duration: 2 });
    },
  });
};

export const updateReservationCategory =
  (id: number, data: ReservationCategoryInterface, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
    JsonApiService.patch('reservation_category', id, data, queryParams).subscribe({
      next: (response: any) => {
        if (response.error) {
          notification.error({ message: i18n.translate('components.banners.reservationCategory.editError'), duration: 2 });
          return;
        }
        dispatch(setReservationCategoryOne(response.data));
        dispatch(updateReservationCategoryAllData(response.data));
        notification.success({ message: i18n.translate('components.banners.reservationCategory.editSuccess'), duration: 2 });
      },
      error: (error: Error) => {
        dispatch(setReservationCategoryError(error));
        notification.error({ message: i18n.translate('components.banners.reservationCategory.editError'), duration: 2 });
      },
    });
  };

export default reservationCategorySlice.reducer;
