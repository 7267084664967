import { Button, Drawer, Space } from 'antd';
import React, { useState } from 'react';
import { ClientInterface, UserInterface, i18n, transferClientsToAgent, useAppDispatch } from '../../common';
import { ToAgentTable } from '../tables';

interface Props {
  isOpen: boolean;
  selectedClients: Array<ClientInterface>;
  onCloseDrawer: () => void;
}

const TransferClientsToAgentDrawer = ({ isOpen, selectedClients, onCloseDrawer }: Props) => {
  const dispatch = useAppDispatch();

  const [selectedAgents, setSelectedAgents] = useState<Array<UserInterface>>([]);

  const onAgentSelectionFinish = () => {
    if (selectedAgents?.length) dispatch(transferClientsToAgent(selectedAgents, selectedClients, {includes: ['clientType', 'user', 'salesOpportunities'], sorters: [{ columnKey: 'id', order: 'descend' }]}));
    onCloseDrawer();
  };

  const onAgentSelectionChanged = (agents: Array<UserInterface>) => {
    setSelectedAgents(agents);
  };

  return (
    <Drawer
      title={i18n.translate('components.other.transferClientToAgent.drawer.transfer')}
      onClose={onCloseDrawer}
      open={isOpen}
      destroyOnClose
      width={450}
      extra={
        <Space>
          <Button onClick={onCloseDrawer}>{i18n.translate('buttons.cancel')}</Button>
          <Button onClick={() => onAgentSelectionFinish()} type="primary">
            {i18n.translate('buttons.transfer')}
          </Button>
        </Space>
      }
    >
      <ToAgentTable columnTitle={i18n.t('components.table.transferClientToAgentTable.name')} selectionType='radio' onAgentSelectionChanged={onAgentSelectionChanged}/>
    </Drawer>
  );
}

export default TransferClientsToAgentDrawer;
