import { Table as AntTable, Button, Col, Row, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import type { ColumnsType, TablePaginationConfig, TableProps } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import { Filter, Pagination, Search, Sorter, i18n } from '../../common';
import { colors } from '../../theme';

interface Props {
  columns: ColumnsType<any>;
  dataSource: any[];
  rowKey?: string;
  paginationDefault?: Pagination;
  total?: number;
  filters?: Array<TableFilter>;
  searchFields?: Array<TableSearch>;
  injectedRowSelection?: any;
  groupActions?: any;
  handleGroupAction?: (selectedKeys: Array<any>, selectedGroupAction?: number) => void;
  handleTableChange: (tableParams: TableParams) => void;
}

export interface TableParams {
  filters?: Array<Filter>;
  pagination?: Pagination;
  sorters?: Array<Sorter>;
  search?: Search;
}

// Interface za definiranje koji filteri se koriste
export interface TableFilter {
  component: ({ dataKey, handleFilterChange, nullOption }: FilterProps) => JSX.Element,
  key: string,
  nullOption?: boolean,
}

// Interface za definiranje pojedinog filtera
export interface FilterProps {
  dataKey: string;
  handleFilterChange: (filter?: Filter) => void;
  nullOption?: boolean;
}

// Interface za definiranje koja polja za pretragu se koriste
export interface TableSearch {
  component: ({ dataKeys, placeholder, handleSearchChange }: SearchProps) => JSX.Element,
  keys: Array<string>,
  placeholder: string
}

// Interface za definiranje pojedina komponenta pretrage
export interface SearchProps {
  dataKeys: Array<string>;
  placeholder: string;
  handleSearchChange: (keys: Array<string>, value: string) => void;
}

const Table = ({ columns, dataSource, rowKey, paginationDefault, total, filters, searchFields, injectedRowSelection, groupActions, handleGroupAction, handleTableChange }: Props): JSX.Element => {

  const [tableParams, setTableParams] = useState<TableParams>({ pagination: paginationDefault });

  const [selectedRows, setSelectedRows] = useState<Array<any>>([]);
  const [selectedGroupAction, setSelectedGroupAction] = useState<number | undefined>(undefined);

  useEffect(() => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        total
      }
    })
  }, [total])

  const onTableChange: TableProps<any>['onChange'] = (
    paginationTable: TablePaginationConfig,
    filtersTable: Record<string, FilterValue | null>,
    sorterTable: SorterResult<any> | SorterResult<any>[],
  ) => {
    const tableParamsTmp: TableParams = { ...tableParams };

    // Filters
    if (Object.keys(filtersTable).length !== 0) {
      tableParamsTmp.filters = [];
      Object.entries(filtersTable).forEach((entry) => {
        if (entry[1] !== null) tableParamsTmp.filters!.push({ name: entry[0], operator: 'EQ', value: entry[1]!.toString() })
      })
    }

    // Pagination
    // TODO riješiti !
    if (paginationTable.current) tableParamsTmp.pagination!.current = paginationTable.current;
    if (paginationTable.pageSize) tableParamsTmp.pagination!.pageSize = paginationTable.pageSize;

    // Sorter
    if (Object.keys(sorterTable).length !== 0) {
      if ((sorterTable as SorterResult<any>).order) {
        tableParamsTmp.sorters = [{
          columnKey: (sorterTable as SorterResult<any>).columnKey!.toString(),
          order: (sorterTable as SorterResult<any>).order!.toString(),
          field: (sorterTable as SorterResult<any>).field!.toString(),
        }];
      } else {
        tableParamsTmp.sorters = [];
      }

    }

    setTableParams(tableParamsTmp);
    handleTableChange(tableParamsTmp);
  };

  const onFilterChange = (filter?: Filter) => {
    const tableParamsTmp: TableParams = { ...tableParams };
    if (filter) {
      if (!tableParamsTmp.filters) tableParamsTmp.filters = [];
      const findIndex = tableParamsTmp.filters.findIndex((f: Filter) => f.name === filter.name);
      if (findIndex > -1) {
        tableParamsTmp.filters[findIndex] = filter;
      } else {
        tableParamsTmp.filters.push(filter);
      }
    } else {
      tableParamsTmp.filters = [];
    }

    setTableParams(tableParamsTmp);
    handleTableChange(tableParamsTmp);
  }

  // Trenutno podržava samo jedan searchField zato što je u queryParams jedan search
  const onSearchChange = (keys: Array<string>, value: string) => {
    const tableParamsTmp: TableParams = { ...tableParams };
    if (!tableParamsTmp.search) tableParamsTmp.search = { keys, value };
    else tableParamsTmp.search.value = value;

    // Ovo ako se eksplicitno ne stavi će backend ponovno vratiti defaultnih 10 rezultata nakon searcha u slučajevima gdje ne želimo paginaciju
    if (!tableParams.pagination?.show) tableParamsTmp.pagination = {current: 1, pageSize: 999999};

    setTableParams(tableParamsTmp);
    handleTableChange(tableParamsTmp);
  };

  const renderFilters = (): React.ReactNode => {
    if (!filters || filters.length === 0) return <div></div>;
    return <Col>
      <Space size={16} wrap>
        {`${i18n.t('components.table.filter')}:`}
        {filters!.map((f, i) => <f.component key={i} dataKey={f.key} handleFilterChange={onFilterChange} nullOption={f.nullOption} />)}
      </Space>
    </Col>
  }

  const renderSearch = (): React.ReactNode => {
    if (!searchFields) return null;
    return <Col>
      {searchFields!.map((s, i) => <s.component key={i} dataKeys={s.keys} placeholder={s.placeholder} handleSearchChange={onSearchChange} />)}
    </Col>
  }

  const renderGroupActions = (): React.ReactNode => {
    if (!groupActions?.length || !selectedRows.length) return null;

    return <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: 16, backgroundColor: colors.blueLight }}>
      <Space direction='horizontal' size={24} wrap>
        <div style={{ color: colors.blue, fontWeight: 'bold' }}>{`${selectedRows.length} ${i18n.translate('components.table.selectedRows.selected')}`}</div>
        <Select style={{ minWidth: 220 }} placeholder={i18n.translate('components.table.selectedRows.placeholder')} onSelect={(value: number) => setSelectedGroupAction(value)}>
          {groupActions?.map((ga: { key: number, label: string, show: boolean }) => (
            <Select.Option key={ga.key} value={ga.key}>
              {ga.label}
            </Select.Option>
          ))}
        </Select>
        <Button type="primary" onClick={() => handleGroupAction && handleGroupAction(selectedRows, selectedGroupAction)}>{i18n.translate('components.table.selectedRows.button')}</Button>
      </Space>
    </div>
  }

  const tableRowSelection = {
    preserveSelectedRowKeys: true,
    onChange: (selectedTableRowKeys: Array<React.Key>, selectedTableRows: Array<any>) => setSelectedRows(selectedTableRows)
  };

  return (
    <Space direction='vertical' size={16} style={{ display: 'flex' }}>
      <Row justify={'space-between'} align={'middle'}>
        {renderFilters()}
        {renderSearch()}
      </Row>
      <Row justify={'space-between'} align={'middle'}>
        {renderGroupActions()}
      </Row>
      <AntTable
        rowSelection={groupActions?.length ? tableRowSelection : injectedRowSelection}
        columns={columns}
        dataSource={dataSource}
        rowKey={rowKey}
        onChange={onTableChange}
        pagination={tableParams.pagination?.show ? tableParams.pagination : false}
        scroll={{ x: true }}
      />
    </Space>
  )
}

export default Table;
