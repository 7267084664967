import { Tag } from 'antd';
import React from 'react';
import { colors } from '../../theme';
import { PriorityTypeEnum } from '../enums';
import { i18n } from '../services';

export interface PriorityType {
  id: number;
  name: string;
  color: string;
}

export const PriorityTypes = (): Array<PriorityType> => [
  { id: PriorityTypeEnum.LOW, name: i18n.translate('common.constants.priorityType.low'), color: colors.green },
  {
    id: PriorityTypeEnum.NORMAL,
    name: i18n.translate('common.constants.priorityType.normal'),
    color: colors.yellow,
  },
  { id: PriorityTypeEnum.HIGH, name: i18n.translate('common.constants.priorityType.high'), color: colors.orange },
  { id: PriorityTypeEnum.URGENT, name: i18n.translate('common.constants.priorityType.urgent'), color: colors.red},
];

export const getPriorityTag = (id: number) => {
  const priority = PriorityTypes().find((p: PriorityType) => p.id === id);
  return <Tag color={priority?.color} style={{width: 70, textAlign: 'center'}}>{priority?.name}</Tag>;
};
