import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { i18n, formatNumber } from '../../common';
import { OfferItemInterface } from '../../common/interfaces/offer/offer.interface';
import Table from './table.component';

interface Props {
  offerItems: Array<OfferItemInterface>;
}

const OfferItemTable = ({ offerItems }: Props) => {
  const offerItemsTableColumns: ColumnsType<OfferItemInterface> = [
    {
      title: i18n.t('components.table.offerItemTable.code'),
      dataIndex: 'itemCode',
      key: 'itemCode',
      render: (_total, item: OfferItemInterface) => `${item.itemCode ? item.itemCode : "-"}`,
    },
    {
      title: i18n.t('components.table.offerItemTable.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_total, item: OfferItemInterface) => `${item.name}`,
    },
    {
      title: i18n.t('components.table.offerItemTable.price'),
      dataIndex: 'price',
      key: 'price',
      render: (_total, item: OfferItemInterface) => `${formatNumber(item.price, '€')}`,
    },
    {
      title: i18n.t('components.table.offerItemTable.amount'),
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: i18n.t('components.table.offerItemTable.vat'),
      dataIndex: 'vat',
      key: 'vat',
      render: (_total, item: OfferItemInterface) => `${formatNumber(item.vat, '%')}`,
    },
    {
      title: i18n.t('components.table.offerItemTable.discount'),
      dataIndex: 'discount',
      key: 'discount',
      render: (_total, item: OfferItemInterface) => `${formatNumber(item.discount, '%')}`,
    },
    {
      title: i18n.t('components.table.offerItemTable.total'),
      dataIndex: 'total',
      key: 'total',
      render: (_total, item: OfferItemInterface) => `${formatNumber(item.total, '€')}`,
    },
  ];

  if (!offerItems) return null;
  return <Table columns={offerItemsTableColumns} dataSource={offerItems} paginationDefault={{show: false}} handleTableChange={() => {}} rowKey={'id'} />;
};

export default OfferItemTable;
