import React from 'react';
import { Button, Col, Row, Descriptions, Avatar, Grid, } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Role, Roles, UserInterface, getActiveLabel, i18n } from '../../common';
import { AppRoutes } from '../../pages/app';
import { EditIcon, UserIcon } from '../icons';

const { useBreakpoint } = Grid;

interface Props {
  user: UserInterface;
}

function ProfileDetails({ user }: Props) {
  const navigate = useNavigate();

  const { md } = useBreakpoint();

  const descriptionsItems: Array<{label: string, render: JSX.Element}> = [
    {
      label: i18n.t('components.details.profile.profileImage'),
      render: <>{user.profileImageUrl ? <Avatar src={user.profileImageUrl} size={64}/> : <Avatar size={64} icon={<UserIcon />} />}</>
    },
    {
      label: i18n.t('components.details.profile.firstName'),
      render: <>{user.firstName}</>
    },
    {
      label: i18n.t('components.details.profile.lastName'),
      render: <>{user.lastName}</>
    },
    {
      label: i18n.t('components.details.profile.mail'),
      render: <>{user.email}</>
    },
    {
      label: i18n.t('components.details.profile.phone'),
      render: <>{user.phoneNumber}</>
    },
    {
      label: i18n.t('components.details.profile.address'),
      render: <>{user.address}</>
    },
    {
      label: i18n.t('components.details.profile.role'),
      render: <>{user.dbRole && Roles().find((r: Role) => r.id.toString() === user.dbRole!.id.toString())?.name}</>
    },
    {
      label: i18n.t('components.details.profile.activity'),
      render: <>{getActiveLabel(user.active!)}</>
    }
  ]

  return (
    <Row justify={'space-between'}>
      <Col xs={24} md={20} flex={'auto'}>
        <Descriptions column={1} labelStyle={{ width: '200px', justifyContent: md ? 'flex-end' : 'flex-start', marginRight: 16 }} layout={md ? 'horizontal' : 'vertical'}>
          {descriptionsItems.map((item: {label: string, render: JSX.Element}, index: number) => (
            <Descriptions.Item key={index} label={item.label}>{item.render}</Descriptions.Item>
          ))}
        </Descriptions>
      </Col>
      <Col flex={'none'}><Button type='primary' icon={<EditIcon />} onClick={() => { navigate(AppRoutes.PROFILE_EDIT.fullPath) }}>{i18n.translate('buttons.edit')}</Button></Col>
    </Row>
  );
}

export default ProfileDetails;
