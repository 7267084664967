import dayjs from 'dayjs';
import { List, Pagination as AntPagination } from 'antd';
import React, { useState } from 'react';
import { AnnouncementInterface, DefaultPagination, DisplayFullFormat, Pagination } from '../../common';
import AnnouncementItem from './announcementItem.component';

interface Props {
  announcements: AnnouncementInterface[];
  total: number;
  onPaginationChange: (page: number, pageSize: number) => void;
}

export default function AnnouncementsComponent({ announcements, total, onPaginationChange }: Props) {

  const [pagination, setPagination] = useState<Pagination>(DefaultPagination);

  const onPageChange = (page: number, size: number) => {
    setPagination({...pagination, current: page, pageSize: size});
    onPaginationChange(page, size);
  };

  return (
    <>
      <List
        dataSource={announcements}
        pagination={false}
        renderItem={(a: AnnouncementInterface) => (
          <List.Item key={`announcement${a.id}`} style={{ display: 'flex', justifyContent: 'space-between'}}>
            <AnnouncementItem announcement={a} />
            {dayjs(a.createdDate).format(DisplayFullFormat)}
          </List.Item>
        )}
      />
      <AntPagination style={{ float: 'right', marginTop: 24 }} {...pagination} total={total} onChange={onPageChange} />
    </>
  );
}
