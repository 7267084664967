import React from 'react';
import { ResponsiveContainer, BarChart, XAxis, YAxis, Bar } from 'recharts';
import { Avatar, Tooltip } from 'antd';
import { CampaignInterface } from '../../common';
import { colors } from '../../theme';
import { UserIcon } from '../icons';

interface Props {
  campaign: CampaignInterface;
}

const CampaignStatisticsBarChart = ({ campaign }: Props) => {
  const data = campaign.topAgents?.length ? campaign.topAgents?.map((statistic: any) => ({ name: statistic.userId, value: statistic.percentage })) : [{ name: 0, value: 0 }];

  const getUser = (userId: number) => campaign.allUsers?.find(x => x.id === userId);

  const customizedGroupTick = (x: number, y: number, value: number) =>
    <foreignObject x={x - 12} y={y} style={{ width: 64, height: 64 }}>
      <Tooltip key={value} title={getUser(value)?.fullName} placement="top">
        {getUser(value)?.profileImageUrl ? <Avatar size={24} src={getUser(value)?.profileImageUrl} /> : <Avatar size={24} icon={<UserIcon />} />}
      </Tooltip>
    </foreignObject>

  return (
    <div style={{ width: '100%', height: 295 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data} >
          <XAxis dataKey="name" interval={0} tick={(tick: { x: number, y: number, payload: { value: number }}) => customizedGroupTick(tick.x, tick.y, tick.payload.value)} />
          <YAxis domain={[0, 100]} />
          <Bar dataKey="value" fill={colors.blue} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default CampaignStatisticsBarChart;
