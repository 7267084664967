import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { useAppDispatch, useAppSelector, RootState, QueryParams, CampaignInterface, getCampaigns, i18n } from '../../common';
import { Search } from '../filters';
import Table, { TableParams, TableSearch } from './table.component';

type CampaignsTableColumnType = 'id' | 'name';

export const allCampaignsTableColumns: Array<CampaignsTableColumnType> = ['id', 'name'];

interface Props {
  onCampaignSelectionChanged?: (values: any) => void;
  injectedQueryParams?: QueryParams;
  columns?: Array<CampaignsTableColumnType>;
}

const AddCampaignsToClientsTable = ({ onCampaignSelectionChanged, injectedQueryParams, columns }: Props) => {
  const dispatch = useAppDispatch();

  const componentQueryParams: QueryParams = {
    ...injectedQueryParams,
    includes: [
      ...(injectedQueryParams?.includes || []),
      'campaignStatus', 
      'files', 
      'salesOpportunityStatisticsFromView', 
      'topAgents'
    ],
    pagination: {current: 1, pageSize: 999999}
  };

  const [queryParams, setQueryParams] = useState<QueryParams>(componentQueryParams);

  useEffect(() => {
    dispatch(getCampaigns(queryParams));
  }, [queryParams]
  )

  const rowSelection = {
    preserveSelectedRowKeys: true,
    onChange: (selectedRowKeys: React.Key[], selectedRows: CampaignInterface[]) => onCampaignSelectionChanged && onCampaignSelectionChanged(selectedRows)
  };

  const campaigns = useAppSelector((state: RootState) => state.campaign.all);

  const tableColumns: Array<CampaignsTableColumnType> = columns || allCampaignsTableColumns;

  const campaignsTableColumns: ColumnsType<CampaignInterface> = [
    {
      title: i18n.t('components.table.addCampaignsToClientsTable.id'),
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      render: (dataIndex: string, campaign: CampaignInterface) => `#${campaign.id}`
    },
    {
      title: i18n.t('components.table.addCampaignsToClientsTable.name'),
      dataIndex: 'name',
      ellipsis: true,
      key: 'name',
    }
  ].filter((c) => tableColumns.includes(c.key! as CampaignsTableColumnType));

  const searchFields: Array<TableSearch> = [
    { component: Search, keys: ['name'], placeholder: i18n.translate('header.search.placeholder') }
  ]

  const handleTableChange = (tableParams: TableParams) => {
    setQueryParams({
      pagination: componentQueryParams.pagination,
      filters: [
        ...(tableParams.filters || []),
        ...(componentQueryParams?.filters || []),
      ],
      sorters: tableParams.sorters,
      includes: componentQueryParams.includes,
      search: tableParams.search
    })
  };

  if (!campaigns) return null;
  return (
    <Table
      injectedRowSelection={rowSelection}
      columns={campaignsTableColumns}
      dataSource={campaigns.data}
      rowKey={"id"}
      handleTableChange={handleTableChange}
      total={campaigns.total}
      searchFields={searchFields}
    />
  )
}

export default AddCampaignsToClientsTable;
