import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Descriptions, Avatar, Tag, Upload, UploadProps, UploadFile, Grid } from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { DisplayDateFormat, RootState, SalesOpportunityInterface, euroFormat, getSalesOpportunityStatusName, i18n, useAppSelector } from '../../common';
import { AppRoutes } from '../../pages/app';
import { CustomFileRender } from '../other';
import { EditIcon, UserIcon } from '../icons';
import { GuardFunction } from '../guard';

const { useBreakpoint } = Grid;

interface Props {
  salesOpportunity: SalesOpportunityInterface;
}

function SalesOpportunityDetails({ salesOpportunity }: Props) {
  const navigate = useNavigate();

  const { md } = useBreakpoint();

  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    setFileList(salesOpportunity!.files?.map((x: { uuid: string; name: string; }) => ({ uid: x.uuid, name: x.name })));
  }, [salesOpportunity]);

  const props: UploadProps = {
    defaultFileList: [],
    itemRender: (originNode, file) => (
      <CustomFileRender file={file} />
    ),
    fileList
  };

  const descriptionsItems: Array<{ label: string; render: JSX.Element }> = [
    {
      label: i18n.translate('components.details.salesOpportunity.name'),
      render: <>{salesOpportunity!.name}</>,
    },
    {
      label: i18n.translate('components.details.salesOpportunity.createdAt'),
      render: <>{`${dayjs(salesOpportunity!.createdAt).format(DisplayDateFormat)}`}</>,
    },
    {
      label: i18n.translate('components.details.salesOpportunity.endDate'),
      render: <>{salesOpportunity?.endDate && `${dayjs(salesOpportunity!.endDate).format(DisplayDateFormat)}`}</>,
    },
    {
      label: i18n.translate('components.details.salesOpportunity.users'),
      render: <>{salesOpportunity!.users?.map(user => <Link to={`${AppRoutes.USERS.fullPath}/${user?.id}}`} key={user.id}><Tag color="processing" style={{ borderRadius: 16 }} icon={user.profileImageUrl ? <Avatar style={{ marginRight: 4, marginBlock: 2 }} size="small" src={user.profileImageUrl} /> : <Avatar style={{ marginRight: 4, marginBlock: 2 }} size="small" icon={<UserIcon />} />}>{user.fullName}</Tag></Link>)}</>,
    },
    {
      label: i18n.translate('components.details.salesOpportunity.client'),
      render: <><Link to={`${AppRoutes.CLIENTS.fullPath}/${salesOpportunity!.client?.id}}`} key={salesOpportunity!.client?.id}>{salesOpportunity!.client?.name}</Link></>,
    },
    {
      label: i18n.translate('components.details.salesOpportunity.status'),
      render: <>{getSalesOpportunityStatusName(salesOpportunity!.latestStatus?.id)}</>,
    },
    {
      label: i18n.translate('components.details.salesOpportunity.value'),
      render: <>{euroFormat(salesOpportunity!.value)}</>,
    },
    {
      label: i18n.translate('components.details.salesOpportunity.documents'),
      render: <><Upload {...props}></Upload></>,
    },
    {
      label: i18n.translate('components.details.salesOpportunity.description'),
      render: <>{salesOpportunity!.description}</>,
    },
  ];

  return (
    <Row justify={'space-between'}>
      <Col xs={24} md={20} flex={'auto'}>
        <Descriptions column={1} labelStyle={{ width: '200px', justifyContent: md ? 'flex-end' : 'flex-start', marginRight: 16 }} layout={md ? 'horizontal' : 'vertical'}>
          {descriptionsItems.map((item: { label: string, render: JSX.Element }, index: number) => (
            <Descriptions.Item key={index} label={item.label}>{item.render}</Descriptions.Item>
          ))}
        </Descriptions>
      </Col>
      <Col flex={'none'}>
        {(GuardFunction({ domain: 'sales_opportunity', action: 'update_any' }) || salesOpportunity.users?.find(so => so.id === currentUser!.id)) &&
          <Button type='primary' icon={<EditIcon />} onClick={() => { navigate(`${AppRoutes.SALES_OPPORTUNITIES.fullPath}/${salesOpportunity!.id}/edit`) }}>{i18n.translate('buttons.edit')}</Button>
        }
      </Col>
    </Row>
  );
}

export default SalesOpportunityDetails;
