import { Button, DatePicker, Form, Input, Radio, Select, Space, TimePicker } from 'antd';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { ActivityInterface, QueryParams, RootState, i18n, useAppDispatch, useAppSelector, ActivityStatuses, ActivityStatus, ClientInterface, getClientFilter, ActivityStatusEnum, ActivityTypes, ActivityType, SalesOpportunityInterface, SalesOpportunityStatuses, SalesOpportunityStatus, DisplayTimeShortFormat, resetSubmitActivityForm } from '../../../common';

const { Option } = Select;

export type ActivityFormItemType = 'activityStatus' | 'activityStatusButton' | 'activityDate' | 'activityType' | 'client' | 'salesOpportunity' | 'salesOpportunityStatus' | 'description';
export type InitialValueTypeActivity = { formItem: ActivityFormItemType, value: number | string | Array<number> | { id: number } | { id: string } | boolean };

export const allActivityFormItems: Array<ActivityFormItemType> = ['activityStatus', 'activityStatusButton', 'activityDate', 'activityType', 'client', 'salesOpportunity', 'salesOpportunityStatus', 'description'];

interface Props {
  activity?: ActivityInterface;
  formItems?: Array<ActivityFormItemType>;
  disabled?: Array<ActivityFormItemType>;
  initialValues?: Array<InitialValueTypeActivity>;
  onActivityFormFinish?: (values: any) => void;
  onActivityFormFinishFailed?: (errorInfo: any) => void;
}

const ActivityForm = ({ activity, formItems, disabled, initialValues, onActivityFormFinish, onActivityFormFinishFailed }: Props) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  // dohvacanje vrijednosti za padajuce izbornike
  const clientValues = useAppSelector((state: RootState) => state.client.filter);

  // Priprema parametara za dohvacanje vrijednosti za padajuce izbornike
  const clientQueryParams: QueryParams = { includes: ['salesOpportunities', 'salesOpportunities.latestStatus'], pagination: { current: 1, pageSize: 999999 } };

  const submitForm = useAppSelector((state: RootState) => state.componentState.drawer.activity.submit);

  // Slanje zahtjeva za dohvacanje vrijednosti za padajuce izbornike
  useEffect(() => {
    dispatch(getClientFilter(clientQueryParams));
  }, []
  )

  // Kontrola submita forme
  useEffect(() => {
    if (submitForm) {
      form.submit();
      dispatch(resetSubmitActivityForm());
    }
  }, [submitForm]);

  // Mapiranje izlaznih vrijednosti
  const onFormSubmit = (values: ActivityInterface) => {
    const data = {
      ...values,
      id: activity?.id,
      salesOpportunity: values.salesOpportunity?.id ? values.salesOpportunity : undefined,
      user: { id: currentUser!.id }
    }
    if (onActivityFormFinish) onActivityFormFinish(data);
  }

  const clientId = Form.useWatch(['client', 'id'], form);
  const salesOpportunityId = Form.useWatch(['salesOpportunity', 'id'], form);
  const activityStatusId = Form.useWatch(['activityStatus', 'id'], form);

  const onClientChange = () => {
    form.setFieldValue(['salesOpportunity', 'id'], undefined);
  }

  const onSalesOpportunityChange = (salesOpportunityIdValue: any) => {
    const salesOpportunity = clientValues!.find((c: ClientInterface) => c.id === clientId)!.salesOpportunities!.find((so: SalesOpportunityInterface) => so.id === salesOpportunityIdValue);
    form.setFieldValue(['newSalesOpportunityStatus', 'id'], salesOpportunity?.latestStatus.id)
  }

  // Ovdje su datumi custom hendlani jer je odvojen date i time picker
  const onDateChange = (value: dayjs.Dayjs | null) => {
    let ad = dayjs(form.getFieldValue('activityDate'));
    if (!ad) ad = dayjs();
    if (value) {
      const newAd = ad.set('date', value!.date()).set('month', value!.month()).set('year', value!.year());
      form.setFieldValue('activityDate', newAd.format());
    } else {
      form.setFieldValue('activityDate', undefined);
    }
  }

  const onTimeChange = (value: dayjs.Dayjs | null) => {
    let ad = dayjs(form.getFieldValue('activityDate'));
    if (!ad) ad = dayjs();
    if (value) {
      const newAd = ad.set('hour', value!.hour()).set('minute', value!.minute()).set('second', value!.second());
      form.setFieldValue('activityDate', newAd.format());
    } else {
      form.setFieldValue('activityDate', undefined);
    }
  }

  const onAactivityStatusButtonClick = () => {
    form.setFieldValue(['activityStatus', 'id'], ActivityStatusEnum.DONE)
  }

  const disabledTime = () => (
    {
      disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 22, 23, 24],
    }
  )

  const disabledDate = (current: any) => 
    // Can not select days before yesterday
    current && current < dayjs().subtract(1, 'day').startOf('day');

  const items: Array<ActivityFormItemType> = formItems || allActivityFormItems;

  // Mapiranje inicijalnih vrijednosti
  const initialFormValues = {
    ...activity,
    activityStatus: (initialValues?.find((fi: InitialValueTypeActivity) => fi.formItem === 'activityStatus')?.value as { id: number }) || activity?.activityStatus,
    activityType: (initialValues?.find((fi: InitialValueTypeActivity) => fi.formItem === 'activityType')?.value as { id: number }) || activity?.activityType,
    activityDate: (initialValues?.find((fi: InitialValueTypeActivity) => fi.formItem === 'activityDate')?.value as string) || activity?.activityDate || dayjs().set('minute', (Math.ceil(dayjs().minute() / 5)) * 5).format(),
    salesOpportunity: (initialValues?.find((fi: InitialValueTypeActivity) => fi.formItem === 'salesOpportunity')?.value as { id: number }) || activity?.salesOpportunity,
    newSalesOpportunityStatus: (initialValues?.find((fi: InitialValueTypeActivity) => fi.formItem === 'salesOpportunityStatus')?.value as { id: number }) || activity?.newSalesOpportunityStatus,
    client: (initialValues?.find((fi: InitialValueTypeActivity) => fi.formItem === 'client')?.value as { id: number }) || activity?.client,
    description: (initialValues?.find((fi: InitialValueTypeActivity) => fi.formItem === 'description')?.value as string) || activity?.description
  }

  return (<>
    <Form
      name='activity'
      form={form}
      onFinish={onFormSubmit}
      onFinishFailed={onActivityFormFinishFailed}
      layout='vertical'
      initialValues={initialFormValues}
    >
      <Form.Item
        name={['activityStatus', 'id']}
        label={i18n.translate(`components.forms.activity.activityStatus`)}
        rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
        wrapperCol={{ span: 24 }}
        hidden={!items.find(i => i === 'activityStatus')}
      >
        <Radio.Group disabled={!!disabled?.find(d => d === 'activityStatus')}>
          <Space direction="vertical">
            {ActivityStatuses().filter((as: ActivityStatus) => as.id === ActivityStatusEnum.PLANNED || as.id === ActivityStatusEnum.DONE)
              .map((as: ActivityStatus) => <Radio key={as.id} value={as.id}>{as.name}</Radio>)}
          </Space>
        </Radio.Group>
      </Form.Item>
      {items.find(i => i === 'activityDate') && <Form.Item
        name="activityDate"
        label={i18n.translate(`components.forms.activity.activityDate`)}
        wrapperCol={{ span: 24 }}
      >
        <Space size={16} style={{ display: 'flex' }}>
          <DatePicker defaultValue={dayjs(initialFormValues.activityDate)} onChange={onDateChange} disabledDate={disabledDate} disabled={!!disabled?.find(d => d === 'activityDate')} allowClear={false} />
          <TimePicker
            defaultValue={dayjs(initialFormValues.activityDate)}
            onChange={onTimeChange} disabled={!!disabled?.find(d => d === 'activityDate')}
            allowClear={false}
            minuteStep={5}
            format={DisplayTimeShortFormat}
            disabledTime={disabledTime}
            hideDisabledOptions
          />
        </Space>
      </Form.Item>}
      {items.find(i => i === 'activityType') && <Form.Item
        name={['activityType', 'id']}
        label={i18n.translate(`components.forms.activity.activityType`)}
        rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
        wrapperCol={{ span: 10 }}
      >
        <Select
          placeholder="Odaberi"
          allowClear
          disabled={!!disabled?.find(d => d === 'activityType')}
        >
          {ActivityTypes().map((at: ActivityType) =>
            <Option key={at.id} value={at.id}>{at.name}</Option>
          )}
        </Select>
      </Form.Item>}
      {items.find(i => i === 'client') && <Form.Item
        name={['client', 'id']}
        label={i18n.translate(`components.forms.activity.client`)}
        rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
        wrapperCol={{ span: 12 }}
      >
        <Select
          placeholder="Odaberi"
          allowClear
          disabled={!!disabled?.find(d => d === 'client')}
          onChange={onClientChange}
        >
          {clientValues && clientValues.map((client: ClientInterface) =>
            <Option key={client.id} value={client.id}>{client.name}</Option>
          )}
        </Select>
      </Form.Item>}
      {items.find(i => i === 'salesOpportunity') && clientId && !(!!disabled?.find(d => d === 'salesOpportunity') && !initialFormValues.salesOpportunity) && <Form.Item
        name={['salesOpportunity', 'id']}
        label={i18n.translate(`components.forms.activity.salesOpportunity`)}
        preserve={false}
        wrapperCol={{ span: 14 }}
      >
        <Select
          placeholder="Odaberi"
          allowClear
          disabled={!!disabled?.find(d => d === 'salesOpportunity')}
          onChange={onSalesOpportunityChange}
        >
          {clientValues && clientValues.find((client: ClientInterface) => client.id! === clientId!) && clientValues.find((client: ClientInterface) => client.id! === clientId!)?.salesOpportunities?.map((so: SalesOpportunityInterface) =>
            <Option key={so.id} value={so.id}>{so.name}</Option>
          )}
        </Select>
      </Form.Item>}
      {items.find(i => i === 'salesOpportunityStatus') && salesOpportunityId && <Form.Item
        name={['newSalesOpportunityStatus', 'id']}
        label={i18n.translate(`components.forms.activity.salesOpportunityStatus`)}
        wrapperCol={{ span: 12 }}
      >
        <Select
          placeholder="Odaberi"
          allowClear
          disabled={!!disabled?.find(d => d === 'salesOpportunityStatus')}
        >
          {SalesOpportunityStatuses().map((sos: SalesOpportunityStatus) =>
            <Option key={sos.id} value={sos.id}>{sos.name}</Option>
          )}
        </Select>
      </Form.Item>}
      {items.find(i => i === 'description') && <Form.Item
        name='description'
        label={i18n.translate(`components.forms.activity.description`)}
        wrapperCol={{ span: 16 }}
      >
        <Input.TextArea size="small" disabled={!!disabled?.find(d => d === 'description')} />
      </Form.Item>}
      {items.find(i => i === 'activityStatusButton') && activityStatusId === ActivityStatusEnum.PLANNED &&
        <Button type='primary' onClick={onAactivityStatusButtonClick}>{i18n.translate(`components.forms.activity.activityStatusButton`)}</Button>}
    </Form>
  </>
  );
}

export default ActivityForm;
