import { Row, Col, Space, theme, Typography } from 'antd';
import React from 'react';
import { CampaignInterface, SalesOpportunityStatus, SalesOpportunityStatuses, getSalesOpportunityStatusColor } from '../../common';

const { useToken } = theme;

const { Title } = Typography;

interface Props {
  campaign: CampaignInterface;
  selectedUserId?: number;
}

const CampaignStatisticsLegend = ({ campaign, selectedUserId }: Props) => {
  const { token } = useToken();

  const statistics = selectedUserId ? campaign.salesOpportunityStatisticsFromView?.filter((x: any) => x.userId === selectedUserId) : campaign.salesOpportunityStatisticsFromView?.filter((item: any, index: number, array: any) => array.findIndex((item2: any) => (item2.statusId === item.statusId)) === index);

  const getValue = (statusId: number) => selectedUserId ? statistics?.find((x: any) => x.statusId === statusId)?.totalPerStatusForUser : statistics?.find((x: any) => x.statusId === statusId)?.totalPerStatus;

  return <Row justify={'space-between'}>
    <Col span={12}>
      {SalesOpportunityStatuses()?.map((status: SalesOpportunityStatus, index: number) =>
        index % 2 === 0 && (
          <Space size={8} key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginBottom: 24 }}>
            <Space align='baseline'>
              <span style={{ display: 'inline-block', width: 10, height: 10, borderRadius: '50%', backgroundColor: getSalesOpportunityStatusColor(status.id)}}></span>
              <Title level={4} style={{ margin: 0, color: token.colorTextDescription }}>{status.name}</Title>
            </Space>
            <Title level={4} style={{ margin: 0, paddingLeft: 18 }}>{getValue(status.id) ? getValue(status.id) : 0}</Title>
          </Space>
        )
      )}
    </Col>
    <Col span={12}>
      {SalesOpportunityStatuses()?.map((status: SalesOpportunityStatus, index: number) =>
        index % 2 !== 0 && (
          <Space size={8} key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginBottom: 24 }}>
            <Space align='baseline'>
              <span style={{ display: 'inline-block', width: 10, height: 10, borderRadius: '50%', backgroundColor: getSalesOpportunityStatusColor(status.id)}}></span>
              <Title level={4} style={{ margin: 0, color: token.colorTextDescription }}>{status.name}</Title>
            </Space>
            <Title level={4} style={{ margin: 0, paddingLeft: 18 }}>{getValue(status.id) ? getValue(status.id) : 0}</Title>
          </Space>
        )
      )}
    </Col>
  </Row>
}

export default CampaignStatisticsLegend;
