import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ApiProvider } from 'jsonapi-react';

import { store, persistor, JsonApiService } from "./common";

import MainRouter from "./pages/_router/main.router";
import AntProvider from "./antProvider";

const App = () => 
  <ApiProvider client={JsonApiService.getInstance().getClient()}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AntProvider>
          <MainRouter></MainRouter>
        </AntProvider>
      </PersistGate>
    </Provider>
  </ApiProvider>


export default App;