import { Button, Result } from 'antd';
import React from 'react';
import { i18n } from '../../../common';

export const NotFoundPage = () => (
  <Result
    status="404"
    title="404"
    subTitle={i18n.translate(`pages.notFound.subtitle`)}
    extra={<Button type="primary">{i18n.translate(`pages.notFound.button`)}</Button>}
  />
)