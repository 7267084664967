import { Button, Drawer, Space } from 'antd';
import React, { useState } from 'react';
import { i18n, useAppDispatch, AssignmentInterface, createAssignment, updateAssignment, QueryParams, AssignmentStatusEnum } from '../../common';
import { AssignmentForm } from '../forms';

interface Props {
  onCloseDrawer: () => void;
  isOpen: boolean;
  selectedAssignment?: AssignmentInterface;
  queryParams?: QueryParams;
}

const AssignmentDrawer = ({ onCloseDrawer, isOpen, selectedAssignment, queryParams }: Props) => {
  const dispatch = useAppDispatch();

  const [trigger, setTrigger] = useState<number>(0);

  const onClose = () => {
    setTrigger(0);
    onCloseDrawer();
  };

  const onFormFinish = (values: AssignmentInterface) => {
    if (selectedAssignment) {
      dispatch(updateAssignment(selectedAssignment.id!, values, queryParams));
    } else {
      const newAssignment = {
        ...values,
        assignmentStatus: { id: AssignmentStatusEnum.CREATED.toString() },
      };
      dispatch(createAssignment(newAssignment, queryParams));
    }
    onClose();
  };

  return (
    <Drawer
      title={selectedAssignment ? i18n.translate('components.forms.assignment.edit') : i18n.translate('components.forms.assignment.create')}
      placement="right"
      open={isOpen}
      onClose={onClose}
      destroyOnClose
      width={450}
      extra={
        <Space>
          <Button onClick={onClose}>{i18n.translate('buttons.cancel')}</Button>
          <Button onClick={() => setTrigger(trigger + 1)} type="primary">
            {i18n.translate('buttons.submit')}
          </Button>
        </Space>
      }
    >
      <AssignmentForm onFinish={(values) => onFormFinish(values)} trigger={trigger} assignment={selectedAssignment} />
    </Drawer>
  );
}

export default AssignmentDrawer;
