import { RoleEnum } from "../enums";
import { i18n } from "../services";

export interface Role {
  id: number;
  name: string;
}

export const Roles = (): Array<Role> => [
  { id: RoleEnum.ADMIN, name: i18n.translate("common.constants.role.admin") },
  { id: RoleEnum.MARKETING, name: i18n.translate("common.constants.role.marketing") },
  { id: RoleEnum.SALES, name: i18n.translate("common.constants.role.sales") }
]
