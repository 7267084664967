import { notification } from 'antd';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NewsInterface, NewsResponseInterface, NewsSeenInterface, NewsSingleResponseInterface, i18n } from '../..';
import { JsonApiService, QueryParams } from '../../api';
import type { AppDispatch } from '../store';

export interface NewsStateInterface {
  all: {
    data: Array<NewsInterface>;
    dataNotification: Array<NewsInterface>;
    seen?: Array<NewsSeenInterface>;
    total?: number;
  };
  one?: NewsInterface;
  error?: Error;
}

const initialState: NewsStateInterface = {
  all: { data: [], dataNotification: [] },
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNewsAllData: (state: NewsStateInterface, action: PayloadAction<Array<NewsInterface>>) => {
      state.all.data = action.payload;
    },
    setNewsNotificationAllData: (state: NewsStateInterface, action: PayloadAction<Array<NewsInterface>>) => {
      state.all.dataNotification = action.payload;
    },
    setNewsSeenAllData: (state: NewsStateInterface, action: PayloadAction<Array<NewsSeenInterface>>) => {
      state.all.seen = action.payload;
    },
    setNewsAllTotal: (state: NewsStateInterface, action: PayloadAction<number>) => {
      state.all.total = action.payload;
    },
    setNewsOne: (state: NewsStateInterface, action: PayloadAction<NewsInterface>) => {
      state.one = action.payload;
    },
    setNewsError: (state: NewsStateInterface, action: PayloadAction<Error>) => {
      state.error = action.payload;
    },
    createNewsAllData: (state: NewsStateInterface, action: PayloadAction<NewsInterface>) => {
      state.all.data.unshift(action.payload);
      if (state.all.total || state.all.total === 0) state.all.total += 1;
    },
    updateNewsAllData: (state: NewsStateInterface, action: PayloadAction<NewsInterface>) => {
      const index = state.all.data.findIndex((x) => x.id === action.payload.id);
      state.all.data[index] = action.payload;
    },
  },
});

export const { setNewsAllData, setNewsNotificationAllData, setNewsSeenAllData, setNewsAllTotal, setNewsOne, setNewsError, createNewsAllData, updateNewsAllData } = newsSlice.actions;

export const getNews = (queryParams?: QueryParams) => (dispatch: AppDispatch) =>
  JsonApiService.get('news', undefined, queryParams).subscribe({
    next: (response: NewsResponseInterface) => {
      dispatch(setNewsAllData(response.data));
      if (response.meta && (response.meta.totalResourceCount || response.meta.totalResourceCount === 0))
        dispatch(setNewsAllTotal(response.meta.totalResourceCount));
    },
    error: (error: Error) => {
      dispatch(setNewsError(error));
      dispatch(setNewsAllTotal(0));
    },
  });

export const getNewsNotification = (queryParams?: QueryParams) => (dispatch: AppDispatch) =>
  JsonApiService.get('news', undefined, queryParams).subscribe({
    next: (response: NewsResponseInterface) => {
      dispatch(setNewsNotificationAllData(response.data));
    },
    error: (error: Error) => {
      dispatch(setNewsError(error));
    },
  });

export const getNewsSingle = (id: number, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('news', id, queryParams).subscribe({
    next: (response: NewsSingleResponseInterface) => {
      dispatch(setNewsOne(response.data));
    },
    error: (error: Error) => {
      dispatch(setNewsError(error));
    },
  });
};

export const createNews = (data: NewsInterface, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.post('news', data, queryParams).subscribe({
    next: (response: any) => {
      dispatch(setNewsOne(response.data));
      dispatch(createNewsAllData(response.data));
      notification.success({ message: i18n.translate('components.banners.news.createSuccess'), duration: 2 });
    },
    error: (error: Error) => {
      dispatch(setNewsError(error));
      notification.error({ message: i18n.translate('components.banners.news.createError'), duration: 2 });
    },
  });
};

export const updateNews = (id: number, data: any, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.patch('news', id, data, queryParams).subscribe({
    next: (response: any) => {
      dispatch(setNewsOne(response.data));
      dispatch(updateNewsAllData(response.data));
      notification.success({ message: i18n.translate('components.banners.news.editSuccess'), duration: 2 });
    },
    error: (error: Error) => {
      dispatch(setNewsError(error));
      notification.error({ message: i18n.translate('components.banners.news.editError'), duration: 2 });
    },
  });
};

export const getNewsSeenFromUser = (queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('news_seen', undefined, queryParams).subscribe({
    next: (response: any) => {
      dispatch(setNewsSeenAllData(response.data));
    },
    error: (error: Error) => {
      dispatch(setNewsError(error));
    },
  });
};

export const updateNewsSeen = (id: number, data: any, newsQueryParams?: QueryParams, newsSeenQueryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.patch('news_seen', id, data).subscribe({
    next: () => {
      dispatch(getNewsNotification(newsQueryParams));
      dispatch(getNewsSeenFromUser(newsSeenQueryParams));
    },
    error: (error: Error) => {
      dispatch(setNewsError(error));
    },
  });
};

export default newsSlice.reducer;
