import { Avatar, Button, Col, Descriptions, Grid, Row, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AreaInterface,
  ClientInterface,
  ContactInterface,
  RootState,
  getActiveLabel,
  getClientTypeName,
  i18n,
  useAppSelector,
} from '../../common';
import { AppRoutes } from '../../pages/app';
import { EditIcon, UserIcon } from '../icons';
import { GuardFunction } from '../guard';

const { useBreakpoint } = Grid;

interface Props {
  client: ClientInterface;
}

function ClientDetails({ client }: Props) {
  const navigate = useNavigate();

  const { md } = useBreakpoint();

  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  const contactsTableColumns: ColumnsType<ContactInterface> = [
    {
      title: i18n.t('components.table.contactTable.name'),
      dataIndex: 'contactName',
      key: 'contactName',
      width: 500
    },
    {
      title: i18n.t('components.table.contactTable.mail'),
      dataIndex: 'email',
      key: 'email',
      width: 500
    },
    {
      title: i18n.t('components.table.contactTable.number'),
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 300
    },
  ];

  const descriptionsItems: Array<{ label: string; render: JSX.Element } | null> = [
    {
      label: i18n.t('components.details.client.name'),
      render: <>{client.name}</>,
    },
    {
      label: i18n.t('components.details.client.clientType'),
      render: <>{client.clientType && getClientTypeName(client.clientType.id)}</>,
    },
    {
      label: i18n.t('components.details.client.oib'),
      render: <>{client.oib}</>,
    },
    {
      label: i18n.t('components.details.client.area'),
      render: (
        <>
          {client.areas &&
            client.areas.map((area: AreaInterface) => (
              <Tag key={area.id} style={{ borderRadius: 16 }}>
                {area.name}
              </Tag>
            ))}
        </>
      ),
    },
    {
      label: i18n.t('components.details.client.address'),
      render: <>{client.address}</>,
    },
    {
      label: i18n.t('components.details.client.contact'),
      render: (
        <>
          {client.contacts && (
            <Table rowKey={'id'} dataSource={client.contacts} columns={contactsTableColumns} pagination={false} scroll={{ x: true }}></Table>
          )}
        </>
      ),
    },
    {
      label: i18n.t('components.details.client.user'),
      render: (
        <>
          {client.user && (
            <Tag
              color="processing"
              style={{ display: 'flex', alignItems: 'center', borderRadius: 16 }}
              icon={
                client.user!.profileImageUrl ? (
                  <Avatar size="small" style={{ marginRight: 4, marginBlock: 2 }} src={client.user!.profileImageUrl} />
                ) : (
                  <Avatar size="small" style={{ marginRight: 4, marginBlock: 2 }} ><UserIcon /></Avatar>
                )
              }
            >
              {client.user!.fullName}
            </Tag>
          )}
        </>
      ),
    },
    {
      label: i18n.t('components.details.client.note'),
      render: <>{client.description}</>,
    },
    GuardFunction({ domain: 'client', action: 'view_active' }) ? {
      label: i18n.t('components.details.client.active'),
      render: <>{getActiveLabel(client.active!)}</>,
    } : null,
  ];

  return (
    <Row justify={'space-between'}>
      <Col xs={24} md={20} flex={'auto'}>
        <Descriptions column={1} labelStyle={{ width: '200px', justifyContent: md ? 'flex-end' : 'flex-start', marginRight: 16 }} layout={md ? 'horizontal' : 'vertical'}>
          {descriptionsItems.map((item: { label: string; render: JSX.Element } | null, index: number) => (
            item !== null ? (
              <Descriptions.Item key={index} label={item.label}>
                {item.render}
              </Descriptions.Item>
            ) : null
          ))}
        </Descriptions>
      </Col>
      <Col flex={'none'}>
        {(GuardFunction({ domain: 'client', action: 'update_any' }) || !client.user || client.user.id === currentUser!.id!) &&
          <Button
            type="primary"
            icon={<EditIcon />}
            onClick={() => {
              navigate(`${AppRoutes.CLIENTS.fullPath}/${client.id}/edit`);
            }}
          >
            {i18n.translate('buttons.edit')}
          </Button>
        }
      </Col>
    </Row>
  );
}

export default ClientDetails;
