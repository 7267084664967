import { Button, Drawer, Space } from 'antd';
import React, { useState } from 'react';
import { SalesOpportunityInterface, UserInterface, i18n, salesOpportunitiesToAgents, useAppDispatch } from '../../common';
import { ToAgentTable } from '../tables';

interface Props {
  isOpen: boolean;
  drawerType: 'transfer' | 'add';
  selectedSalesOpportunities: SalesOpportunityInterface[];
  onCloseDrawer: () => void;
}

const SalesOpportunitiesToAgentsDrawer = ({ isOpen, drawerType, selectedSalesOpportunities, onCloseDrawer }: Props) => {
  const dispatch = useAppDispatch();

  const [selectedAgents, setSelectedAgents] = useState<UserInterface[]>([]);

  const onAgentSelectionFinish = () => {
    if (selectedAgents?.length) dispatch(salesOpportunitiesToAgents(drawerType === 'transfer', selectedAgents, selectedSalesOpportunities, {includes: ['salesOpportunitySalesOpportunityStatuses', 'latestStatus', 'client', 'users', 'campaign'], sorters: [{ columnKey: 'id', order: 'descend' }]}));
    onCloseDrawer();
  };

  const onAgentSelectionChanged = (agents: UserInterface[]) => {
    setSelectedAgents(agents);
  };

  return (
    <Drawer
      title={drawerType === 'transfer' ? i18n.translate('components.other.transferSalesOpportunityToAgent.drawer.transfer') : i18n.translate('components.other.transferSalesOpportunityToAgent.drawer.add')}
      onClose={onCloseDrawer}
      open={isOpen}
      destroyOnClose
      width={550}
      extra={
        <Space>
          <Button onClick={onCloseDrawer}>{i18n.translate('buttons.cancel')}</Button>
          <Button onClick={() => onAgentSelectionFinish()} type="primary">
            {drawerType === 'transfer' ? i18n.translate('buttons.transfer') : i18n.translate('buttons.add')}
          </Button>
        </Space>
      }
    >
      <ToAgentTable columnTitle={i18n.t('components.table.transferSalesOpportunityToAgentTable.name')} selectionType='checkbox' onAgentSelectionChanged={onAgentSelectionChanged}/>
    </Drawer>
  );
}

export default SalesOpportunitiesToAgentsDrawer;
