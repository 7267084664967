import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { JsonApiService, QueryParams } from '../../api';
import {
  AssignmentInterface,
  AssignmentResponseInterface,
  AssignmentsResponseInterface,
} from '../../interfaces/assignment/assignment.interface';
import { i18n } from '../../services';
import type { AppDispatch } from '../store';

export interface AssignmentStateInterface {
  all: {
    data: Array<AssignmentInterface>;
    total?: number;
  };
  one?: AssignmentInterface;
  error?: Error;
}

const initialState: AssignmentStateInterface = {
  all: { data: [] },
};

const assignmentSlice = createSlice({
  name: 'assignments',
  initialState,
  reducers: {
    setAssignmentAllData: (state: AssignmentStateInterface, action: PayloadAction<Array<AssignmentInterface>>) => {
      state.all.data = action.payload;
    },
    setAssignmentAllTotal: (state: AssignmentStateInterface, action: PayloadAction<number>) => {
      state.all.total = action.payload;
    },
    setAssignmentOne: (state: AssignmentStateInterface, action: PayloadAction<AssignmentInterface>) => {
      state.one = action.payload;
    },
    setAssignmentError: (state: AssignmentStateInterface, action: PayloadAction<Error>) => {
      state.error = action.payload;
    },
    createAssignmentAllData: (state: AssignmentStateInterface, action: PayloadAction<AssignmentInterface>) => {
      state.all.data.unshift(action.payload);
      if (state.all.total || state.all.total === 0) state.all.total += 1;
    },
    updateAssignmentAllData: (state: AssignmentStateInterface, action: PayloadAction<AssignmentInterface>) => {
      const index = state.all.data.findIndex((x) => x.id === action.payload.id);
      state.all.data[index] = action.payload;
    },
  },
});

export const {
  setAssignmentAllData,
  setAssignmentAllTotal,
  setAssignmentOne,
  setAssignmentError,
  createAssignmentAllData,
  updateAssignmentAllData,
} = assignmentSlice.actions;

export const getAllAssignments = (queryParams?: QueryParams) => (dispatch: AppDispatch) =>
  JsonApiService.get('assignment', undefined, queryParams).subscribe({
    next: (response: AssignmentsResponseInterface) => {
      dispatch(setAssignmentAllData(response.data));
      if (response.meta && (response.meta.totalResourceCount || response.meta.totalResourceCount === 0))
        dispatch(setAssignmentAllTotal(response.meta.totalResourceCount));
    },
    error: (error: Error) => {
      dispatch(setAssignmentError(error));
      dispatch(setAssignmentAllTotal(0));
    },
  });

export const getAssignment = (id: number, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('assignment', id, queryParams).subscribe({
    next: (response: AssignmentResponseInterface) => {
      dispatch(setAssignmentOne(response.data));
    },
    error: (error: Error) => {
      dispatch(setAssignmentError(error));
    },
  });
};

export const createAssignment = (data: AssignmentInterface, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.post('assignment', data, queryParams).subscribe({
    next: (response: any) => {
      dispatch(setAssignmentOne(response.data));
      dispatch(createAssignmentAllData(response.data));
      notification.success({ message: i18n.translate('components.banners.assignment.createSuccess'), duration: 2 });
    },
    error: (error: Error) => {
      dispatch(setAssignmentError(error));
      notification.error({ message: i18n.translate('components.banners.assignment.createError'), duration: 2 });
    },
  });
};

export const updateAssignment = (id: number, data: any, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.patch('assignment', id, data, queryParams).subscribe({
    next: (response: any) => {
      dispatch(setAssignmentOne(response.data));
      dispatch(updateAssignmentAllData(response.data));
      notification.success({ message: i18n.translate('components.banners.assignment.editSuccess'), duration: 2 });
    },
    error: (error: Error) => {
      dispatch(setAssignmentError(error));
      notification.error({ message: i18n.translate('components.banners.assignment.editError'), duration: 2 });
    },
  });
};

export default assignmentSlice.reducer;
