import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Breadcrumb, Card } from 'antd';
import { useAppDispatch, getSalesOpportunity, SalesOpportunityInterface, useAppSelector, RootState, updateSalesOpportunity, i18n } from '../../../common';
import { GuardFunction, SalesOpportunityForm, SalesOpportunityFormItemType } from '../../../components';
import { AppRoutes } from '../_router/app.routes';

const SalesOpportunityEditPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    if (params.sales_opportunity_id) {
      dispatch(getSalesOpportunity(parseInt(params.sales_opportunity_id!, 10), { includes: ['files', 'users', 'client', 'campaign', 'latestStatus'] }));
    }
  }, [params]
  );

  const salesOpportunity: SalesOpportunityInterface | undefined = useAppSelector((state: RootState) => state.salesOpportunity.one);
  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  if (!salesOpportunity || !salesOpportunity.id) return null;

  if (!(GuardFunction({ domain: 'sales_opportunity', action: 'update_any' }) || salesOpportunity.users?.find(so => so.id === currentUser!.id))) navigate(AppRoutes.DASHBOARD.fullPath);

  const onSalesOpportunityEdit = (values: SalesOpportunityInterface) => {
    dispatch(updateSalesOpportunity(salesOpportunity.id!, values, { includes: ['salesOpportunitySalesOpportunityStatuses', 'client', 'users', 'campaign', 'latestStatus'] }));
  };

  const disabledFields: Array<SalesOpportunityFormItemType> = GuardFunction({domain: 'sales_opportunity', action: 'set_any_user'}) ? ['client'] : ['client', 'users'];

  return (
    <>
      <Breadcrumb style={{ marginBottom: 24 }} items={[
        { title: <Link to={AppRoutes.DASHBOARD.fullPath} key={AppRoutes.DASHBOARD.fullPath}>{i18n.translate('breadcrumb.homepage')}</Link> },
        { title: <Link to={AppRoutes.SALES_OPPORTUNITIES.fullPath} key={AppRoutes.SALES_OPPORTUNITIES.fullPath}>{i18n.t('breadcrumb.salesOpportunities')}</Link> },
        { title: i18n.translate('breadcrumb.salesOpportunityEdit') }]} />
      <Card>
        <SalesOpportunityForm
          salesOpportunity={salesOpportunity}
          disabled={disabledFields}
          onSalesOpportunityFormFinish={(values) => onSalesOpportunityEdit(values)}
          title={i18n.t('components.forms.salesOpportunity.editTitle')}
          submitBtnLabel={i18n.t('buttons.saveChanges')}></SalesOpportunityForm>
      </Card>
    </>
  );
}

export default SalesOpportunityEditPage;
