import React from 'react';
import { Form, Input, Button, Typography, theme, } from 'antd';
import { useNavigate } from 'react-router-dom';
import { i18n } from '../../../common';
import { AuthRoutes } from '../../../pages/auth';

const { useToken } = theme;

const { Title, Text } = Typography;

interface Props {
  onForgotPasswordFormFinish?: (values: any) => void;
  onForgotPasswordFormFinishFailed?: (errorInfo: any) => void;
}

function ForgotPasswordForm({ onForgotPasswordFormFinish, onForgotPasswordFormFinishFailed }: Props) {
  const { token } = useToken();

  const navigate = useNavigate();

  return (
    <div className="forgotPasswordForm">
      <Title level={1}>{i18n.translate(`forgotPassword.title`)}</Title>
      <Text style={{ color: token.colorTextDescription, marginBottom: 24 }}>{i18n.translate(`forgotPassword.subtitle`)}</Text>
      <Form
        layout='vertical'
        size='large'
        name='forgotPassword'
        onFinish={onForgotPasswordFormFinish}
        onFinishFailed={onForgotPasswordFormFinishFailed}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: i18n.translate(`forgotPassword.form.validation.required`) }, { type: 'email', message: i18n.translate(`forgotPassword.form.validation.email`) }]}
        >
          <Input style={{marginTop: 24}} placeholder={i18n.translate(`forgotPassword.form.placeholders.email`)} />
        </Form.Item>

        <Button onClick={() => navigate(AuthRoutes.LOGIN.fullPath)} style={{textAlign: 'left', marginBottom: 24}} type="link" block size="small">
          {i18n.translate(`forgotPassword.btns.loginReturn`)}
        </Button>

        <Form.Item>
          <Button type="primary" size="large" block htmlType="submit">
            {i18n.translate(`forgotPassword.btns.resetPassword`)}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default ForgotPasswordForm;
