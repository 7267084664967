import type { TabsProps } from 'antd';
import { Breadcrumb, Button, Card, Col, Row, Space, Tabs, Typography } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RootState, i18n, useAppSelector } from '../../../common';
import {
  AddIcon,
  CampaignsTable,
  Guard,
  GuardFunction,
  allCampaignsTableColumns,
  allCampaignsTableFilters,
} from '../../../components';
import { AppRoutes } from '../_router/app.routes';

const { Title } = Typography;

const CampaignsPage = () => {
  const navigate = useNavigate();

  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  const renderMyCampaigns = (): JSX.Element => (
    <CampaignsTable
      columns={allCampaignsTableColumns.filter((c) => c !== 'users')}
      injectedQueryParams={{
        filters: [{ name: 'salesOpportunities.users.id', operator: 'EQ', value: currentUser!.id!.toString() }],
      }}
      filters={allCampaignsTableFilters.filter((f) => f !== 'user')}
    ></CampaignsTable>
  );

  const renderAllCampaigns = (): JSX.Element => <CampaignsTable></CampaignsTable>;

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.t('pages.campaigns.myCampaigns'),
      children: renderMyCampaigns(),
    },
    {
      key: '2',
      label: i18n.t('pages.campaigns.allCampaigns'),
      children: renderAllCampaigns(),
    },
  ];

  const renderContent = (): JSX.Element | null => {
    if (GuardFunction({ domain: 'campaign', action: 'view_all_any' })) return <Tabs items={tabs} destroyInactiveTabPane={true}></Tabs>;
    return renderMyCampaigns();
  };

  return (
    <>
      <Breadcrumb
        items={[
          { title: <Link to={AppRoutes.DASHBOARD.fullPath} key={AppRoutes.DASHBOARD.fullPath}>{i18n.t('breadcrumb.homepage')}</Link> },
          { title: i18n.t('breadcrumb.campaigns') },
        ]}
        style={{ marginBottom: 24 }}
      />
      <Card>
        <Space direction="vertical" size={16} style={{ display: 'flex' }}>
          <Row justify={'space-between'} align={'middle'}>
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                {i18n.t('pages.campaigns.title')}
              </Title>
            </Col>
            <Col>
              <Guard permission={{ domain: 'campaign', action: 'create' }}>
                <Button
                  type="primary"
                  icon={<AddIcon />}
                  onClick={() => {
                    navigate(`${AppRoutes.CAMPAIGNS.fullPath}/new`);
                  }}
                >
                  {i18n.t('components.forms.campaign.create')}
                </Button>
              </Guard>
            </Col>
          </Row>
          {renderContent()}
        </Space>
      </Card>
    </>
  );
};

export default CampaignsPage;
