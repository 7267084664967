/* eslint-disable max-classes-per-file */
import { Observable } from 'rxjs';
import { ChangePasswordInterface, DashboardStatisticsInterface, ForgotPasswordInterface, HeaderSearchInterface, LoginInterface, LoginResponseInterface, ResetPasswordInterface } from '../interfaces';
import HttpFactory from './abstract.api.service';
import { apiRoutes } from './routes';

export class UserService {
  static login(data: LoginInterface): Observable<LoginResponseInterface> {
    return HttpFactory.POST(apiRoutes.login, data);
  }

  static refreshToken(): Observable<LoginResponseInterface['auth']> {
    return HttpFactory.GET(apiRoutes.refreshToken, undefined, false, true);
  }

  static forgotPassword(data: ForgotPasswordInterface) {
    return HttpFactory.POST(apiRoutes.forgotPassword, data);
  }

  static resetPassword(data: ResetPasswordInterface) {
    return HttpFactory.POST(apiRoutes.resetPassword, data);
  }

  static changePassword(data: ChangePasswordInterface) {
    return HttpFactory.POST(apiRoutes.changePassword, data);
  }

  static getInfiniteUrl(uuid: string): Observable<any> {
    const url = apiRoutes.infiniteUrl.replace('{uuid}', uuid);
    return HttpFactory.GET(url);
  }

  static getTempUrl(uuid: string): Observable<any> {
    const url = apiRoutes.tempUrl.replace('{uuid}', uuid);
    return HttpFactory.GET(url);
  }
}

export class DashboardService {
  static getStatistics(): Observable<DashboardStatisticsInterface> {
    return HttpFactory.GET(apiRoutes.dashboardStatistics, undefined, false);
  }
}

export class HeaderService {
  static headerSearch(value: string): Observable<HeaderSearchInterface> {
    const url = apiRoutes.headerSearch.replace('{value}', value);
    return HttpFactory.POST(url, undefined, undefined, true);
  }
}

export class DeleteService {
  static deleteEntity(entity: string, id: number): Observable<any> {
    let url = apiRoutes.delete.replace('{entity}', entity);
    url = url.replace('{id}', id.toString());
    return HttpFactory.DELETE(url, undefined, true);
  }
}

export class SalesOpportunityAgentService {
  static add(data: any): Observable<any> {
    return HttpFactory.POST(apiRoutes.salesOpportunityAgentAdd, data);
  }

  static transfer(data: any): Observable<any> {
    return HttpFactory.POST(apiRoutes.salesOpportunityAgentTransfer, data);
  }
}

export class JsonPatchService {
  static jsonPatch(data: any): Observable<any> {
    return HttpFactory.POSTJsonPatch(apiRoutes.jsonPatch, data);
  }
}
