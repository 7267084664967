import React, { useState } from 'react';
import { Button, Checkbox, Dropdown, MenuProps, Space, Typography, theme } from "antd";
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { DownOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';

import { FilterProps } from '../../tables/table.component';
import { AssignmentType, AssignmentTypes, i18n } from '../../../common';

const { Title } = Typography;
const { useToken } = theme;

interface FilterValue {
  id: number;
  checked: boolean;
}

const AssignmentTypeFilter = ({ dataKey, handleFilterChange }: FilterProps): JSX.Element => {

  const [filterValues, setFilterValues] = useState<Array<FilterValue>>(AssignmentTypes().map((assignmentType: AssignmentType) => ({ id: assignmentType.id, checked: false })))

  const [open, setOpen] = useState(false);

  const onValueChange = (value: boolean, assignmentType: AssignmentType) => {
    setFilterValues(filterValues.map((filterValue: FilterValue) => filterValue.id === assignmentType.id
      ? { ...filterValue, checked: value }
      : { ...filterValue }))
  }

  const onFilterConfirm = () => {
    if (filterValues.filter((fv: FilterValue) => fv.checked).length === 0) handleFilterChange();
    else handleFilterChange({name: dataKey, operator: 'EQ', value: filterValues.filter((f: FilterValue) => f.checked).map((f: FilterValue) => f.id.toString())});
    setOpen(false);
  }

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  // konfiguracija menu-a unutar dropdowna
  const items: MenuProps['items'] = AssignmentTypes().map((assignmentType: AssignmentType, index: number) => {
    const item: ItemType = {
      label: (
        <Checkbox
          onChange={(e: CheckboxChangeEvent) => {
            onValueChange(e.target.checked, assignmentType);
          }}
          checked={filterValues.find((fv: FilterValue) => fv.id === assignmentType.id)?.checked}
        >
          {assignmentType.name}
        </Checkbox>
      ),
      key: index
    }
    return item;
  })

  // Dropdown i menu style - nažalost ant design nije ponudio kvalitetnije rješenje, a nisam htio iz nule raditi dropdown i menu
  const { token } = useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8
  };

  const menuStyle = {
    boxShadow: 'none',
    padding: 0,
    margin: 0
  };

  return (
    <Dropdown
      menu={{ items }}
      open={open}
      onOpenChange={handleOpenChange}
      trigger={['click']} dropdownRender={(menu) => (
        <div style={contentStyle}>
          <Title level={3} style={{ paddingLeft: 12, paddingRight: 12, paddingBottom: 8 }}>{i18n.t('components.filters.assignmentType')}</Title>
          {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
          <Space style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 16 }}>
            <Button type='link' onClick={onFilterConfirm}>
              Filtriraj
            </Button>
          </Space>
        </div>
      )}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          {i18n.t('components.filters.assignmentType')}{filterValues.find((f: FilterValue) => f.checked) && `(${filterValues.filter((f: FilterValue) => f.checked).length})`}
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  )
}

export default AssignmentTypeFilter;
