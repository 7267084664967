import { AuthStateInterface, RouteInterface } from "../../common";
import { AppRouter, AppRouting, ROOT_PATH_APP } from "../app";
import { AuthRouter, AuthRouting, ROOT_PATH_AUTH } from "../auth";

export const MainRouting = (userAuth: AuthStateInterface): Array<RouteInterface> => [
  !userAuth.isAuthenticated ? {
    path: ROOT_PATH_AUTH,
    element: AuthRouter,
    // functions: [{ domain: 'route-auth', action: 'read', isAllowed: true }], // EXAMPLE
    functions: [],
    children: AuthRouting
  } :
    {
      path: ROOT_PATH_APP,
      element: AppRouter,
      functions: [],
      children: AppRouting
    }
];