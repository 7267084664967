import { notification } from "antd";
import { FileExtensionEnum } from "../enums";
import { AuthStateInterface, UserService } from "..";
import { store } from '../redux';
import i18n from "./translate.service";

async function downloadFile(user: AuthStateInterface, path: string, filename: string, extension: FileExtensionEnum) {

  const url = [process.env.REACT_APP_API_URL, path].join('/');

  try {
    const res = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        Accept: '*/*',
        'X-TenantUUID': store.getState().auth.xTenantUuid
      }
    });

    const blob = await res.blob();
    const newBlob = new Blob([blob]);

    const blobUrl = window.URL.createObjectURL(newBlob);

    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', `${filename}.${extension}`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);

    // clean up Url
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    notification.error({ message: i18n.translate('api.error'), duration: 2 });
    console.error(error); // eslint-disable-line no-console
  }
}

async function downloadFileDms(uuid: string) {

  UserService.getTempUrl(uuid).subscribe({
    next: (response: any) => {
      const link = document.createElement('a');
      link.href = response.url;
      link.setAttribute('download', uuid);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);

      // clean up Url
      window.URL.revokeObjectURL(response.url);
    },
    error: (error: Error) => {
      notification.error({ message: error.message, duration: 2 });
    }
  })
}

export default {
  downloadFile,
  downloadFileDms
};
