import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { UserInterface, UserResponseinterface, UsersResponseInterface } from '../../interfaces';
import type { AppDispatch } from '../store';
import { JsonApiService, QueryParams } from '../../api';
import { i18n, navigate } from '../../services';
import { setAuthUser } from '../auth/auth.slice';

// ============ INTERFACE ============

export interface UserStateInterface {
  all: {
    data: Array<UserInterface>;
    total?: number;
  };
  one?: UserInterface;
  filter?: Array<UserInterface>;
  error?: string;
}

// ============ INIT STATE ============

const initialState: UserStateInterface = {
  all: { data: [] },
};

// ========= SLICE ==========

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAllData: (state: UserStateInterface, action: PayloadAction<Array<UserInterface>>) => {
      state.all.data = action.payload;
    },
    setUserAllTotal: (state: UserStateInterface, action: PayloadAction<number>) => {
      state.all.total = action.payload;
    },
    setUserOne: (state: UserStateInterface, action: PayloadAction<UserInterface>) => {
      state.one = action.payload;
    },
    updateUserAllData: (state: UserStateInterface, action: PayloadAction<UserInterface>) => {
      const index = state.all.data.findIndex(x => x.id === action.payload.id);
      state.all.data[index] = action.payload;
    },
    createUserAllData: (state: UserStateInterface, action: PayloadAction<UserInterface>) => {
      state.all.data.unshift(action.payload);
      if (state.all.total || state.all.total === 0) state.all.total += 1;
    },
    setUserFilterData: (state: UserStateInterface, action: PayloadAction<Array<UserInterface>>) => {
      state.filter = action.payload;
    },
    setUserError: (state: UserStateInterface, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const { setUserAllData, setUserError, setUserAllTotal, setUserOne, setUserFilterData, createUserAllData, updateUserAllData } = userSlice.actions;

export const getUsers = (queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('user', undefined, queryParams)
    .subscribe({
      next: (response: UsersResponseInterface) => {
        dispatch(setUserAllData(response.data));
        if (response.meta && (response.meta.totalResourceCount || response.meta.totalResourceCount === 0)) dispatch(setUserAllTotal(response.meta.totalResourceCount));
      },
      error: (error: any) => {
        dispatch(setUserError(error.message));
        dispatch(setUserAllTotal(0));
      }
    })
}

export const getUserFilter = (queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('user', undefined, queryParams)
    .subscribe({
      next: (response: UsersResponseInterface) => {
        dispatch(setUserFilterData(response.data));
      },
      error: (error: any) => {
        dispatch(setUserError(error.message));
      }
    })
}

export const getUser = (id: number, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('user', id, queryParams)
    .subscribe({
      next: (response: UserResponseinterface) => {
        dispatch(setUserOne(response.data));
      },
      error: (error: any) => {
        dispatch(setUserError(error.message));
      }
    })
}

export const createUser = (data: UserInterface) => (dispatch: AppDispatch) => {
  JsonApiService.post('user', data)
    .subscribe({
      next: (response: any) => {
        dispatch(setUserOne(response.data));
        dispatch(createUserAllData(response.data));
        navigate(-1);
        notification.success({ message: i18n.translate('pages.users.createSuccess'), duration: 2 });
      },
      error: (error: any) => {
        dispatch(setUserError(error.message));
        notification.error({ message: error.message, duration: 5 });
      }
    })
}

export const updateUser = (id: number, data: UserInterface, updateAuth: boolean) => (dispatch: AppDispatch) => {
  JsonApiService.patch('user', id, data)
    .subscribe({
      next: (response: any) => {
        dispatch(setUserOne(response.data));
        dispatch(updateUserAllData(response.data));
        if (updateAuth) dispatch(setAuthUser(response.data));
        navigate(-1);
        notification.success({ message: i18n.translate('api.saved'), duration: 2 });
      },
      error: (error: any) => {
        dispatch(setUserError(error.message));
        notification.error({ message: error.message, duration: 5 });
      }
    })
}

export const updateUserLang = (id: number, data: UserInterface) => (dispatch: AppDispatch) => {
  JsonApiService.patch('user', id, data)
    .subscribe({
      next: (response: any) => {
        dispatch(setUserOne(response.data));
        dispatch(updateUserAllData(response.data));
        notification.success({ message: i18n.translate('api.saved'), duration: 2 });
      },
      error: (error: any) => {
        dispatch(setUserError(error.message));
        notification.error({ message: error.message, duration: 5 });
      }
    })
}

export default userSlice.reducer;
