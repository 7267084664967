import { List, Row, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { Link } from 'react-router-dom';
import { NewsInterface, NewsSeenInterface, QueryParams, i18n, updateNewsSeen, useAppDispatch } from '../../../common';
import { AppRoutes } from '../../../pages/app';
import { colors } from '../../../theme';
import { ArrowRightIcon } from '../../icons';

const { Title, Text } = Typography;

interface Props {
  news: Array<NewsInterface>;
  unseenNews?: Array<NewsSeenInterface>;
  newsQueryParams?: QueryParams;
  newsSeenQueryParams?: QueryParams;
}

export default function NewsNotificationComponent({ news, unseenNews, newsQueryParams, newsSeenQueryParams }: Props) {
  const dispatch = useAppDispatch();

  const getStyle = (seen: boolean) => {
    let style = '';
    if (!seen) style = colors.yellowBackground;
    return style;
  };

  const findCurrentUserNewsSeen = (newsProp: NewsInterface) => unseenNews?.find(un => un.news?.id === newsProp.id);

  const updateSeen = (n: NewsInterface) => {
    const foundNews = findCurrentUserNewsSeen(n);
    const newsSeen: NewsSeenInterface = {
      seen: true,
    };
    if (foundNews) dispatch(updateNewsSeen(foundNews.id!, newsSeen, newsQueryParams, newsSeenQueryParams));
  };

  return (
    <>
      <List
        dataSource={news}
        pagination={false}
        style={{
          overflow: 'auto',
          maxWidth: 500,
          maxHeight: 300,
        }}
        renderItem={(n: NewsInterface) => (
          <List.Item
            key={`newsNotification${n.id}`}
            style={{ backgroundColor: getStyle(!!findCurrentUserNewsSeen(n)?.seen), marginBottom: 4, cursor: 'pointer' }}
            onClick={() => updateSeen(n)}
          >
            <Space direction="vertical" style={{ paddingLeft: 12 }}>
              <Title level={4} style={{ marginTop: 8 }}>
                {n.title}
              </Title>
              <Text>{n.content}</Text>
              <Text strong>{n.creator?.fullName}</Text>{' '}
              <Text type="secondary">{dayjs(n.createdDate).format('DD.MM.YYYY')}</Text>
            </Space>
          </List.Item>
        )}
      />
      <Row justify="center">
        <Link to={`${AppRoutes.NOTIFICATIONS.fullPath}`}>
          <div style={{ display: 'flex'}}><span style={{ marginRight: 8}}>{i18n.t('header.notifications.seeAll')}</span> <ArrowRightIcon/></div>
        </Link>
      </Row>
    </>
  );
}
