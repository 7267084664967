import { List, Row } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  AnnouncementInterface,
  AnnouncementSeenInterface,
  QueryParams,
  i18n,
  updateAnnouncementSeen,
  useAppDispatch,
} from '../../../common';
import { AppRoutes } from '../../../pages/app';
import { colors } from '../../../theme';
import AnnouncementItem from '../announcementItem.component';
import { ArrowRightIcon } from '../../icons';

interface Props {
  announcements: Array<AnnouncementInterface>;
  unseenAnnouncements?: Array<AnnouncementSeenInterface>;
  announcementQueryParams?: QueryParams;
  announcementSeenQueryParams?: QueryParams;
}

export default function AnnouncementsNotificationComponent({ announcements, unseenAnnouncements, announcementQueryParams, announcementSeenQueryParams }: Props) {

  dayjs.extend(relativeTime);

  const dispatch = useAppDispatch();

  const getStyle = (seen: boolean) => {
    let style = '';
    if (!seen) style = colors.yellowBackground;
    return style;
  };

  const getStyleText = (seen: boolean) => {
    let style = '';
    if (seen) style = colors.grey;
    return style;
  };

  const findCurrentUserAnnoucementSeen = (announcement: AnnouncementInterface) => unseenAnnouncements?.find(ua => ua.announcement?.id === announcement.id);

  const updateSeen = (a: AnnouncementInterface) => {
    const foundAnnouncement = findCurrentUserAnnoucementSeen(a);
    const announcementSeen: AnnouncementSeenInterface = {
      seen: true,
    };
    if (foundAnnouncement) dispatch(updateAnnouncementSeen(foundAnnouncement.id!, announcementSeen, announcementQueryParams, announcementSeenQueryParams));
  };

  return (
    <>
      <List
        dataSource={announcements}
        pagination={false}
        style={{
          overflow: 'auto',
          maxWidth: 500,
          maxHeight: 300,
        }}
        renderItem={(a) => (
          <List.Item
            key={`announcementNotification${a.id}`}
            style={{ marginBottom: 4, backgroundColor: getStyle(!!findCurrentUserAnnoucementSeen(a)?.seen), display: 'flex', flexDirection: 'column', alignItems: 'flex-start', cursor: 'pointer'}}
            onClick={() => updateSeen(a)}
          >
            <AnnouncementItem announcement={a} />
            <div style={{ marginTop: 8, paddingLeft: 8, paddingRight: 8, color: getStyleText(!!findCurrentUserAnnoucementSeen(a)?.seen) }}>{dayjs(a.createdDate).fromNow()}</div>
          </List.Item>
        )}
      />
      <Row justify="center">
        <Link to={`${AppRoutes.NOTIFICATIONS.fullPath}`}>
          <div style={{ display: 'flex'}}><span style={{ marginRight: 8}}>{i18n.t('header.notifications.seeAll')}</span> <ArrowRightIcon/></div>
        </Link>
      </Row>
    </>
  );
}
