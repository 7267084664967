import { AnyAction, Reducer, combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import announcementReducer from './announcement/announcement.slice';
import applicationSettingsReducer from './applicationSettings/applicationSettings.slice';
import areaReducer from './area/area.slice';
import assignmentReducer from './assignment/assignment.slice';
import authReducer from './auth/auth.slice';
import campaignReducer from './campaign/campaign.slice';
import clientReducer from './client/client.slice';
import newsReducer from './news/news.slice';
import offerReducer from './offer/offer.slice';
import reservationCategoryReducer from './reservationCategory/reservationCategory.slice';
import reservationReducer from './reservations/reservation.slice';
import salesOpportunityReducer from './salesOpportunity/salesOpportunity.slice';
import serviceReducer from './service/service.slice';
import userReducer from './user/user.slice';
import activityReducer from './activity/activity.slice';
import dashboardReducer from './dashboard/dashboard.slice';
import headerSearchReducer from './headerSearch/headerSearch.slice';
import drawerReducer from './componentState/drawer/drawer.slice';

// === List of all redducers
const combinedReducer = combineReducers({
  auth: authReducer,
  activity: activityReducer,
  announcements: announcementReducer,
  assignments: assignmentReducer,
  area: areaReducer,
  client: clientReducer,
  campaign: campaignReducer,
  dashboard: dashboardReducer,
  offer: offerReducer,
  user: userReducer,
  service: serviceReducer,
  news: newsReducer,
  reservationCategory: reservationCategoryReducer,
  reservation: reservationReducer,
  salesOpportunity: salesOpportunityReducer,
  applicationSettings: applicationSettingsReducer,
  headerSearch: headerSearchReducer,
  componentState: combineReducers({
    drawer: drawerReducer
  })
});

const rootReducer: Reducer = (state: RootState, action: AnyAction): RootState => {
  if (action.type === 'auth/logoutSuccess') {
    state = {} as RootState;
  }

  return combinedReducer(state, action);
};

// export const AppReducers = (state: any, action: any): RootState => {
//   return AllReducers(state, action);
// };

// === LocalSotrage persist definition
const persistConfig = {
  key: 'my-app',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const initialState = {};

// const middleware = [thunk];

export const store = configureStore(
  {
    reducer: persistedReducer,
    devTools: process.env.REACT_APP_IS_DEV === 'true',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }),
  }
  // persistedReducer,
  // initialState,
  // composeWithDevTools(applyMiddleware(...middleware))
  // compose(applyMiddleware(...middleware))
);

export type RootState = ReturnType<typeof combinedReducer>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);

// export type RootState = ReturnType<typeof AllReducers>;

// Q: how to clear redux state on logout? 
// A: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store  
// Q: it is too old solution, for older version of redux, how to do it with redux-toolkit?
// A: https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
