import { Breadcrumb, Button, Card, Col, Row, Space, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, QueryParams, getClient, ClientInterface, useAppSelector, RootState, i18n, openActivityDrawerAndSetInitialValues } from '../../../common';
import { AddIcon, SalesOpportunitiesTable, allSalesOpportunitiesTableColumns, allSalesOpportunitiesTableFilters, CampaignsTable, allCampaignsTableColumns, ClientDetails, Services, ActivitiesCalendar, GuardFunction } from '../../../components';
import { AppRoutes } from '../_router/app.routes';

const { Title } = Typography;

const ClientPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const params = useParams();

  const queryParams: QueryParams = { includes: ['clientType', 'areas', 'user', 'contacts'] };

  useEffect(() => {
    if (params.client_id) dispatch(getClient(parseInt(params.client_id!, 10), queryParams));
  }, [params]);

  const client: ClientInterface | undefined = useAppSelector((state: RootState) => state.client.one);
  const salesOpportunitiesTotal = useAppSelector((state: RootState) => state.salesOpportunity.all.total);
  const campaignsTotal = useAppSelector((state: RootState) => state.campaign.all.total);
  const activitiesTotal = useAppSelector((state: RootState) => state.activity.all.total);
  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  if (!client || !client!.id) return null;

  if (!(GuardFunction({domain: 'client', action: 'view_one_any'}) || !client.user || client.user?.id === currentUser!.id)) navigate(AppRoutes.DASHBOARD.fullPath);

  const renderActivitiesTab = (): JSX.Element => (
    <Space direction="vertical" size={16} style={{ display: 'flex' }}>
      <Row justify={'space-between'} align={'middle'}>
        <Col>
          <Title level={2} style={{ margin: 0 }}>
            {activitiesTotal} aktivnosti
          </Title>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<AddIcon />}
            onClick={() => dispatch(openActivityDrawerAndSetInitialValues({ initialValues: [{ formItem: 'client', value: { id: client.id! } }], disabled: ['client']}))}
          >
            {i18n.t('pages.activities.createNew')}
          </Button>
        </Col>
      </Row>
      <ActivitiesCalendar injectedQueryParams={{ filters: [{ name: 'client.id', operator: 'EQ', value: client!.id!.toString() }], pagination: { current: 1, pageSize: 999999 } }}></ActivitiesCalendar>
    </Space>
  );

  const renderSalesOpportunitiesTab = (): JSX.Element => (
    <Space direction="vertical" size={16} style={{ display: 'flex' }}>
      <Row justify={'space-between'} align={'middle'}>
        <Col>
          <Title level={2} style={{ margin: 0 }}>
            {salesOpportunitiesTotal} prodajnih prilika
          </Title>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<AddIcon />}
            onClick={() => {
              navigate(`${AppRoutes.SALES_OPPORTUNITIES.fullPath}/new`, { state: [{ formItem: 'client', value: {id: client.id} }] });
            }}
          >
            {i18n.t('components.forms.salesOpportunity.create')}
          </Button>
        </Col>
      </Row>
      <SalesOpportunitiesTable
        columns={allSalesOpportunitiesTableColumns.filter((c) => c !== 'client' && c !== 'actions')}
        injectedQueryParams={{ filters: [{ name: 'client.id', operator: 'EQ', value: client!.id!.toString() }] }}
        filters={allSalesOpportunitiesTableFilters.filter((f) => f !== 'campaign')}
      ></SalesOpportunitiesTable>
    </Space>
  );

  const renderCampaignsTab = (): JSX.Element => (
    <Space direction="vertical" size={16} style={{ display: 'flex' }}>
      <Row justify={'space-between'} align={'middle'}>
        <Col>
          <Title level={2} style={{ margin: 0 }}>
            {campaignsTotal} kampanji
          </Title>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<AddIcon />}
            onClick={() => {
              navigate(`${AppRoutes.CAMPAIGNS.fullPath}/new`);
            }}
          >
            {i18n.t('components.forms.campaign.create')}
          </Button>
        </Col>
      </Row>
      <CampaignsTable
        columns={allCampaignsTableColumns.filter((c) => c !== 'numberOfClients')}
        injectedQueryParams={{
          filters: [{ name: 'salesOpportunities.client.id', operator: 'EQ', value: client!.id!.toString() }],
        }}
      />
    </Space>
  );

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.t('pages.clients.tabs.clientDetails'),
      children: <ClientDetails client={client}></ClientDetails>,
    },
    {
      key: '2',
      label: i18n.t('pages.clients.tabs.activities'),
      children: renderActivitiesTab(),
    },
    {
      key: '3',
      label: i18n.t('pages.clients.tabs.salesOpportunities'),
      children: renderSalesOpportunitiesTab(),
    },
    {
      key: '4',
      label: i18n.t('pages.clients.tabs.campaigns'),
      children: renderCampaignsTab(),
    },
    {
      key: '5',
      label: i18n.t('pages.clients.tabs.services'),
      children: <Services clientProp={client!} />,
    },
  ];

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to={AppRoutes.DASHBOARD.fullPath} key={AppRoutes.DASHBOARD.fullPath}>
                {i18n.t('breadcrumb.homepage')}
              </Link>
            ),
          },
          {
            title: (
              <Link to={AppRoutes.CLIENTS.fullPath} key={AppRoutes.CLIENTS.fullPath}>
                {i18n.t('breadcrumb.clients')}
              </Link>
            ),
          },
          { title: client.name },
        ]}
        style={{ marginBottom: 24 }}
      />
      <Card>
        <Space direction="vertical" size={16} style={{ display: 'flex' }}>
          <Title level={2} style={{margin: 0}}>{client.name}</Title>
          <Tabs items={items} destroyInactiveTabPane={true} />
        </Space>
      </Card>
    </>
  );
};

export default ClientPage;
