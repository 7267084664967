import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { NavigateFunction } from 'react-router-dom';
import { AppRoutes } from '../../../pages/app/_router/app.routes';
import { AuthRoutes } from '../../../pages/auth';
import { getLanguageId } from '../../constants/language.constant';
import { JsonApiService, UserService } from '../../api';
import { ChangePasswordInterface, ForgotPasswordInterface, LoginInterface, LoginResponseInterface, ResetPasswordInterface, UserFunctionInterface, UserInterface } from '../../interfaces';
import { i18n } from '../../services';
import type { AppDispatch } from '../store';

declare const CHAT_START: any;
declare const CHAT_STOP: any;

// ============ INTERFACE ============

export interface AuthStateInterface {
  lang: 'hr' | 'en';
  langTime: number;
  isAuthenticated: boolean;
  xTenantUuid?: string;
  accessToken?: string;
  refreshToken?: string;
  accessTokenValidTo?: string;
  refreshTokenValidTo?: string;
  user?: UserInterface;
  userFunctions?: Array<UserFunctionInterface>;
  error?: Error;
}

// ============ INIT STATE ============

const initialState: AuthStateInterface = {
  lang: 'hr',
  langTime: 0,
  isAuthenticated: false,
  xTenantUuid: ''
};


// ========= SLICE ==========

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess: (state: AuthStateInterface, action: PayloadAction<LoginResponseInterface>) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload.auth.access_token;
      state.refreshToken = action.payload.auth.refresh_token;
      state.accessTokenValidTo = action.payload.auth.accessTokenValidTo;
      state.refreshTokenValidTo = action.payload.auth.refreshTokenValidTo;
      state.user = action.payload.user;
      state.userFunctions = action.payload.functions;
    },
    loginError: (state: AuthStateInterface, action: PayloadAction<Error>) => {
      state.error = action.payload;
    },
    setLangAction: (state: AuthStateInterface, action: PayloadAction<'hr' | 'en'>) => {
      state.lang = action.payload;
      state.langTime = Date.now();
    },
    setAuthUser: (state: AuthStateInterface, action: PayloadAction<UserInterface>) => {
      state.user = action.payload;
    },
    setTenant: (state: AuthStateInterface, action: PayloadAction<string>) => {
      state.xTenantUuid = action.payload;
    },
    logoutSuccess: (state: AuthStateInterface) => {
      state.isAuthenticated = false;
      state.accessToken = undefined;
      state.refreshToken = undefined;
      state.accessTokenValidTo = undefined;
      state.refreshTokenValidTo = undefined;
      state.user = undefined;
      state.userFunctions = undefined;
    },
    refreshSuccess: (state: AuthStateInterface, action: PayloadAction<LoginResponseInterface['auth']>) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload.access_token;
      state.refreshToken = action.payload.refresh_token;
      state.accessTokenValidTo = action.payload.accessTokenValidTo;
      state.refreshTokenValidTo = action.payload.refreshTokenValidTo;
    },
  }
})

export const { loginSuccess, loginError, logoutSuccess, setLangAction, setAuthUser, setTenant, refreshSuccess } = authSlice.actions;

export const changeLanguageAction = (data: 'hr' | 'en') => (dispatch: AppDispatch) => {
  const lang = getLanguageId(data);
  dispatch(setLangAction(lang));
}

export const loginAction = (data: LoginInterface, navigate: NavigateFunction) => (dispatch: AppDispatch) =>
  UserService.login(data).subscribe({
    next: (response: LoginResponseInterface) => {
      dispatch(loginSuccess(response));
      dispatch(changeLanguageAction(response.user.language!));
      notification.success({ message: i18n.translate('login.msgs.login'), duration: 2 });
      JsonApiService.getInstance().initClient();
      CHAT_START(process.env.REACT_APP_CHAT_URL, response.auth.access_token);
      navigate(AppRoutes.DASHBOARD.fullPath);
    },
    error: (error: Error) => {
      dispatch(loginError(error));
      notification.error({ message: i18n.translate('login.msgs.error'), duration: 2 });
    }
  });

export const logoutAction = (navigate: NavigateFunction) => (dispatch: AppDispatch) => {
  dispatch(logoutSuccess());
  CHAT_STOP();
  notification.success({ message: i18n.translate('login.msgs.logout'), duration: 2 });
  navigate(AuthRoutes.LOGIN.fullPath);
};

export const refreshTokenAction = () => (dispatch: AppDispatch) => {
  UserService.refreshToken().subscribe({
    next: (response: LoginResponseInterface['auth']) => {
      dispatch(refreshSuccess(response));
      JsonApiService.getInstance().initClient();
      CHAT_START(process.env.REACT_APP_CHAT_URL, response.access_token);
    }
  });
};

export const forgotPasswordAction = (data: ForgotPasswordInterface, navigate?: NavigateFunction) => (dispatch: AppDispatch) =>
  UserService.forgotPassword(data).subscribe({
    next: () => {
      notification.success({ message: i18n.translate('forgotPassword.msgs.success'), duration: 5 });
      if (navigate) navigate(AuthRoutes.LOGIN.fullPath);
    },
    error: (error: Error) => {
      dispatch(loginError(error));
      notification.error({ message: i18n.translate('forgotPassword.msgs.error'), duration: 5 });
    }
  });

export const resetPasswordAction = (data: ResetPasswordInterface, navigate: NavigateFunction) => (dispatch: AppDispatch) =>
  UserService.resetPassword(data).subscribe({
    next: () => {
      notification.success({ message: i18n.translate('resetPassword.msgs.success'), duration: 5 });
      navigate(AuthRoutes.LOGIN.fullPath);
    },
    error: (error: Error) => {
      dispatch(loginError(error));
      notification.error({ message: i18n.translate('resetPassword.msgs.error'), duration: 5 });
    }
  });

export const changePasswordAction = (data: ChangePasswordInterface, navigate: NavigateFunction) => (dispatch: AppDispatch) =>
  UserService.changePassword(data).subscribe({
    next: () => {
      notification.success({ message: i18n.translate('components.other.changePassword.msgs.success'), duration: 3 });
      navigate(AppRoutes.PROFILE.fullPath);
    },
    error: (error: Error) => {
      dispatch(loginError(error));
      notification.error({ message: i18n.translate('components.other.changePassword.msgs.error'), duration: 3 });
    }
  });  

export default authSlice.reducer;
