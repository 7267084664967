import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { CampaignInterface, CampaignResponseInterface, CampaignsResponseInterface, ClientInterface } from '../../interfaces';
import type { AppDispatch } from '../store';
import { JsonApiService, JsonPatchService, QueryParams } from '../../api';
import { i18n, navigate } from '../../services';
import { AppRoutes } from '../../../pages/app';
import { getSalesOpportunities } from '../salesOpportunity/salesOpportunity.slice';
import { SalesOpportunityStatusEnum } from '../../enums';

// ============ INTERFACE ============

export interface CampaignStateInterface {
  all: {
    data: Array<CampaignInterface>;
    total?: number;
  };
  one?: CampaignInterface;
  error?: Error;
}

// ============ INIT STATE ============

const initialState: CampaignStateInterface = {
  all: {data: []},
};


// ========= SLICE ==========

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaignAllData: (state: CampaignStateInterface, action: PayloadAction<Array<CampaignInterface>>) => {
      state.all.data = action.payload;
    },
    setCampaignAllTotal: (state: CampaignStateInterface, action: PayloadAction<number>) => {
      state.all.total = action.payload;
    },
    setCampaignOne: (state: CampaignStateInterface, action: PayloadAction<CampaignInterface>) => {
      state.one = action.payload;
    },
    updateCampaignAllData: (state: CampaignStateInterface, action: PayloadAction<CampaignInterface>) => {
      const index = state.all.data.findIndex(x => x.id === action.payload.id);
      state.all.data[index] = action.payload;
    },
    createCampaignAllData: (state: CampaignStateInterface, action: PayloadAction<CampaignInterface>) => {
      state.all.data.unshift(action.payload);
      if (state.all.total || state.all.total === 0) state.all.total += 1;
    },
    setCampaignError: (state: CampaignStateInterface, action: PayloadAction<Error>) => {
      state.error = action.payload;
    }
  }
})

export const { setCampaignAllData, setCampaignError, setCampaignAllTotal, setCampaignOne, updateCampaignAllData, createCampaignAllData } = campaignSlice.actions;

export const getCampaigns = (queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('campaign', undefined, queryParams)
    .subscribe({
      next: (response: CampaignsResponseInterface) => {
        dispatch(setCampaignAllData(response.data));
        if (response.meta && (response.meta.totalResourceCount || response.meta.totalResourceCount === 0)) dispatch(setCampaignAllTotal(response.meta.totalResourceCount));
      },
      error: (error: Error) => {
        dispatch(setCampaignError(error));
        dispatch(setCampaignAllTotal(0));
      }
    })
}

export const getCampaign = (id: number, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('campaign', id, queryParams)
    .subscribe({
      next: (response: CampaignResponseInterface) => {
        dispatch(setCampaignOne(response.data));
      },
      error: (error: Error) => {
        dispatch(setCampaignError(error));
      }
    })
}

export const createCampaign = (data: CampaignInterface, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.post('campaign', data, queryParams)
    .subscribe({
      next: (response: any) => {
        if (response.error) {
          notification.error({ message: i18n.translate('components.banners.campaign.createError'), duration: 2 });
          return;
        }
        dispatch(setCampaignOne(response.data));
        dispatch(createCampaignAllData(response.data));
        notification.success({ message: i18n.translate('components.banners.campaign.createSuccess'), duration: 2 });
        navigate(`${AppRoutes.CAMPAIGNS.fullPath}/${response.data?.id}`, { state: { tab: '3' } });
      },
      error: (error: Error) => {
        dispatch(setCampaignError(error));
        notification.error({ message: i18n.translate('components.banners.campaign.createError'), duration: 2 });
      }
    })
}

export const updateCampaign = (id: number, data: CampaignInterface, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.patch('campaign', id, data, queryParams)
    .subscribe({
      next: (response: any) => {
        if (response.error) {
          notification.error({ message: i18n.translate('components.banners.campaign.editError'), duration: 2 });
          return;
        }
        dispatch(setCampaignOne(response.data));
        dispatch(updateCampaignAllData(response.data));
        notification.success({ message: i18n.translate('components.banners.campaign.editSuccess'), duration: 2 });
        navigate(-1);
      },
      error: (error: Error) => {
        dispatch(setCampaignError(error));
        notification.error({ message: i18n.translate('components.banners.campaign.editError'), duration: 2 });
      }
    })
}

export const addClientsToCampaign = (campaign: CampaignInterface, selectedClients: Array<ClientInterface>, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  if (!selectedClients.length) return;
  
  const request = selectedClients.map((client) => ({
    op: 'POST',
    path: 'sales_opportunity',
    value: {
      type: 'sales_opportunity',
      // ovaj id bi idealno trebalo maknuti, dodan je privremeno jer JsonPatch BE poziv zasada radi čudno
      id: Math.floor(Math.random() * (9999999 - 1000000 + 1)) + 1000000,
      attributes: {
        // name: `K-${campaign.name}`,
        // value: campaign.value,
        // description: campaign.description
      },
      relationships: {
        client: {
          data: {
            id: client.id,
            type: 'client'
          }
        },
        campaign: {
          data: {
            id: campaign.id,
            type: 'campaign'
          }
        },
        latestStatus: {
          data: {
            id: SalesOpportunityStatusEnum.CREATED,
            type: 'sales_opportunity_status'
          }
        },
        users: {
          data: [
            {
              id: client.user?.id,
              type: 'user'
            }
          ]
        },
      }
    }
  }))

  JsonPatchService.jsonPatch(request)
    .subscribe({
      next: (response: any) => {
        if (response.errors || response[0].errors) {
          notification.error({ message: i18n.translate('components.banners.campaign.addClientsError'), duration: 2 });
          return;
        }
        dispatch(getSalesOpportunities(queryParams));
        notification.success({ message: i18n.translate('components.banners.campaign.addClientsSuccess'), duration: 2 });
      },
      error: (error: Error) => {
        dispatch(setCampaignError(error));
        notification.error({ message: i18n.translate('components.banners.campaign.addClientsError'), duration: 2 });
      }
    })
}

export default campaignSlice.reducer;