import React from "react";
import { Tag } from "antd";
import { CampaignStatusEnum } from "../enums";
import { i18n } from "../services";

export interface CampaignStatus {
  id: number;
  name: string;
  color: string;
}

export const CampaignStatuses = (): Array<CampaignStatus> => [
  { id: CampaignStatusEnum.IN_PROGRESS, name: i18n.translate("common.constants.campaignStatus.inProgress"), color: 'blue' }, // TODO: status tag se ne prevede kad se promijeni jezik
  { id: CampaignStatusEnum.FINISHED, name: i18n.translate("common.constants.campaignStatus.finished"), color: 'green' },
]

export const getCampaignStatusTag = (id: string): JSX.Element => {
  const cs = CampaignStatuses().find((c: CampaignStatus) => c.id === parseInt(id, 10));
  return <Tag bordered={false} color={cs?.color}>{cs?.name.toUpperCase()}</Tag>
}

