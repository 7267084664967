import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Row, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { Pagination, RootState, i18n, navigate, useAppSelector } from '../../../common';
import { SalesOpportunitiesTable, allSalesOpportunitiesTableColumns } from '../../tables';
import { AppRoutes } from '../../../pages/app';
import { ArrowRightIcon } from '../../icons';
import { Guard } from '../../guard';

const { Title } = Typography;

export default function DashboardSalesOpportunities() {
  const pagination: Pagination = {
    current: 1,
    pageSize: 5,
    show: false
  };

  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  return (
    <Card bodyStyle={{ padding: 24 }}>
      <Row justify={'space-between'} align={'middle'}>
        <Title level={2} style={{ margin: 0 }}>{i18n.translate('pages.dashboard.salesOpps')}</Title>
        <Guard permission={{ domain: 'sales_opportunity', action: 'create' }}>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate(`${AppRoutes.SALES_OPPORTUNITIES.fullPath}/new`)}>
            {i18n.translate('components.forms.salesOpportunity.create')}
          </Button>
        </Guard>
      </Row>
      <SalesOpportunitiesTable
        columns={allSalesOpportunitiesTableColumns.filter((c) => c !== 'campaign' && c !== 'actions')}
        pagination={pagination}
        filters={[]}
        injectedQueryParams={{ 
          filters: [{ name: 'users.id', operator: 'EQ', value: currentUser!.id!.toString() }], 
          pagination: { current: 1, pageSize: 5 }
        }}
      />
      <Row justify={'center'}>
        <Link to={AppRoutes.SALES_OPPORTUNITIES.fullPath}>
          <div style={{ display: 'flex' }}><span style={{ marginRight: 8 }}>{i18n.t('pages.dashboard.seeAll')}</span> <ArrowRightIcon /></div>
        </Link>
      </Row>
    </Card>
  );
}
