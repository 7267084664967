import React from 'react';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ChangePasswordInterface, UserInterface, changePasswordAction, i18n, useAppDispatch } from '../../common';
import ChangePasswordForm from '../forms/changePasswordForm/changePasswordForm.component';

interface Props {
  user: UserInterface;
}

const ChangePassword = ({ user }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onChangePassword = (values: any) => {
    if (values.newPassword !== values.repeatNewPassword) {
      notification.error({ message: i18n.translate('components.other.changePassword.msgs.passwordNoMatch'), duration: 2 });
      return;
    }

    const changePasswordData: ChangePasswordInterface = {
      email: user.email,
      oldPassword: values.oldPassword,
      newPassword: values.newPassword
    };

    dispatch(changePasswordAction(changePasswordData, navigate));
  };

  const onChangePasswordFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); // eslint-disable-line no-console
  };

  return (
    <div className="changePassword">
      <ChangePasswordForm onChangePasswordFormFinish={(values: any) => onChangePassword(values)} onChangePasswordFormFinishFailed={(errorInfo: any) => onChangePasswordFailed(errorInfo)}></ChangePasswordForm>
    </div>
  );
}

export default ChangePassword;
