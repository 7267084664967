import { Button, Drawer, Space } from 'antd';
import React, { useState } from 'react';
import { ClientInterface, CampaignInterface, i18n, useAppDispatch, addCampaignsToClients } from '../../common';
import { AddCampaignsToClientsTable } from '../tables';

interface Props {
  isOpen: boolean;
  selectedClients: ClientInterface[];
  onCloseDrawer: () => void;
}

const AddClientsToCampaignsDrawer = ({ isOpen, selectedClients, onCloseDrawer }: Props) => {
  const dispatch = useAppDispatch();

  const [selectedCampaigns, setSelectedCampaigns] = useState<CampaignInterface[]>([]);

  const onCampaignSelectionFinish = () => {
    if (selectedCampaigns?.length) dispatch(addCampaignsToClients(selectedCampaigns, selectedClients, {includes: ['clientType', 'user', 'salesOpportunities'], sorters: [{ columnKey: 'id', order: 'descend' }]}));
    onCloseDrawer();
  };

  const onCampaignSelectionChanged = (campaigns: CampaignInterface[]) => {
    setSelectedCampaigns(campaigns);
  };

  return (
    <Drawer
      title={i18n.translate('components.other.addClientsToCampaigns.drawer.add')}
      onClose={onCloseDrawer}
      open={isOpen}
      destroyOnClose
      width={500}
      extra={
        <Space>
          <Button onClick={onCloseDrawer}>{i18n.translate('buttons.cancel')}</Button>
          <Button onClick={() => onCampaignSelectionFinish()} type="primary">
            {i18n.translate('buttons.add')}
          </Button>
        </Space>
      }
    >
      <AddCampaignsToClientsTable onCampaignSelectionChanged={onCampaignSelectionChanged}/>
    </Drawer>
  );
}

export default AddClientsToCampaignsDrawer;
