import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { DeleteService, JsonApiService, QueryParams } from '../../api';
import { ReservationInterface, ReservationResponseInterface, ReservationsResponseInterface } from '../../interfaces';
import { i18n } from '../../services';
import type { AppDispatch } from '../store';

export interface ReservationStateInterface {
  all: {
    data: Array<ReservationInterface>;
    total?: number;
  };
  one?: ReservationInterface;
  error?: Error;
}

const initialState: ReservationStateInterface = {
  all: { data: [] },
};

export const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    setReservationAllData: (state: ReservationStateInterface, action: PayloadAction<Array<ReservationInterface>>) => {
      state.all.data = action.payload;
    },
    setReservationAllTotal: (state: ReservationStateInterface, action: PayloadAction<number>) => {
      state.all.total = action.payload;
    },
    setReservationOne: (state: ReservationStateInterface, action: PayloadAction<ReservationInterface>) => {
      state.one = action.payload;
    },
    setReservationError: (state: ReservationStateInterface, action: PayloadAction<Error>) => {
      state.error = action.payload;
    },
    createReservationAllData: (state: ReservationStateInterface, action: PayloadAction<ReservationInterface>) => {
      state.all.data.unshift(action.payload);
      if (state.all.total || state.all.total === 0) state.all.total += 1;
    },
    updateReservationAllData: (state: ReservationStateInterface, action: PayloadAction<ReservationInterface>) => {
      const index = state.all.data.findIndex((x) => x.id === action.payload.id);
      state.all.data[index] = action.payload;
    }
  },
});

export const {
  setReservationAllData,
  setReservationError,
  setReservationAllTotal,
  setReservationOne,
  createReservationAllData,
  updateReservationAllData,
} = reservationSlice.actions;

export const getReservations = (queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('reservation', undefined, queryParams).subscribe({
    next: (response: ReservationsResponseInterface) => {
      dispatch(setReservationAllData(response.data));
      if (response.meta && (response.meta.totalResourceCount || response.meta.totalResourceCount === 0))
        dispatch(setReservationAllTotal(response.meta.totalResourceCount));
    },
    error: (error: Error) => {
      dispatch(setReservationError(error));
      dispatch(setReservationAllTotal(0));
    },
  });
};

export const getReservation = (id: number, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('reservation', id, queryParams).subscribe({
    next: (response: ReservationResponseInterface) => {
      dispatch(setReservationOne(response.data));
    },
    error: (error: Error) => {
      dispatch(setReservationError(error));
    },
  });
};

export const createReservation = (data: ReservationInterface, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.post('reservation', data, queryParams).subscribe({
    next: (response: any) => {
      if (response.error) {
        notification.error({ message: i18n.translate('components.banners.reservation.createError'), duration: 2 });
        return;
      }
      dispatch(setReservationOne(response.data));
      dispatch(createReservationAllData(response.data));
      notification.success({ message: i18n.translate('components.banners.reservation.createSuccess'), duration: 2 });
    },
    error: (error: Error) => {
      dispatch(setReservationError(error));
      notification.error({ message: i18n.translate('components.banners.reservation.createError'), duration: 2 });
    },
  });
};

export const updateReservation = (id: number, data: ReservationInterface, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.patch('reservation', id, data, queryParams).subscribe({
    next: (response: any) => {
      if (response.error) {
        notification.error({ message: i18n.translate('components.banners.reservation.editError'), duration: 2 });
        return;
      }
      dispatch(setReservationOne(response.data));
      dispatch(updateReservationAllData(response.data));
      notification.success({ message: i18n.translate('components.banners.reservation.editSuccess'), duration: 2 });
    },
    error: (error: Error) => {
      dispatch(setReservationError(error));
      notification.error({ message: i18n.translate('components.banners.reservation.editError'), duration: 2 });
    },
  });
};

export const deleteReservation = (id: number) => (dispatch: AppDispatch) => {
  DeleteService.deleteEntity('reservation', id).subscribe({
    next: (response: any) => {
      if (response.error) {
        notification.error({ message: i18n.translate('components.banners.reservation.deleteError'), duration: 2 });
        return;
      }
      dispatch(getReservations({ includes: ['reservationOption,reservationCategory'], pagination: {current: 1, pageSize: 999999} }));
      notification.success({ message: i18n.translate('components.banners.reservation.deleteSuccess'), duration: 2 });
    },
    error: (error: Error) => {
      dispatch(setReservationError(error));
      notification.error({ message: i18n.translate('components.banners.reservation.deleteError'), duration: 2 });
    },
  });
};

export default reservationSlice.reducer;
