import React, { useState } from 'react';
import { Card, Col, Dropdown, MenuProps, Radio, RadioChangeEvent, Row, Space, Typography, theme } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { CampaignInterface, UserInterface, i18n } from '../../common';
import CampaignStatisticsBarChart from './campaignStatisticsBarChart.component';
import CampaignStatisticsLegend from './campaignStatisticsLegend.component';
import CampaignStatisticsPieChart from './campaignStatisticsPieChart.component';

const { Title } = Typography;
const { useToken } = theme;

interface Props {
  campaign: CampaignInterface;
}

const CampaignStatisticsBlock = ({ campaign }: Props) => {

  const { token } = useToken();

  const [selectedUserId, setSelectedUserId] = useState<number>(0);

  const onValueChange = (value: number) => {
    setSelectedUserId(value);
  }

  const items: MenuProps['items'] = campaign.allUsers?.map((user: UserInterface, index: number) => {
    const item: ItemType = {
      label: (
        <Radio
          onChange={(e: RadioChangeEvent) => {
            onValueChange(e.target.value);
          }}
          checked={selectedUserId === user.id}
          value={user.id}
        >
          {user.fullName}
        </Radio>
      ),
      key: index + 1
    }
    return item;
  })

  items?.unshift({
    label: (
      <Radio
        onChange={(e: RadioChangeEvent) => {
          onValueChange(e.target.value);
        }}
        checked={selectedUserId === 0}
        value={0}
      >
        {i18n.t('pages.campaigns.statistics.filter.defaultSelection')}
      </Radio>
    ),
    key: 0
  },)

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8
  };

  const menuStyle = {
    boxShadow: 'none',
    padding: 0,
    margin: 0
  };

  return (
    <Row justify={'space-between'} gutter={[24, 24]}>
      <Col xs={24} xxl={12}>
        <Card>
          <Space style={{ display: 'flex', justifyContent: 'space-between' }} align="baseline">
            <Title level={2} style={{ marginTop: 8, marginBottom: 24 }}>{`${campaign.name}`}</Title>
            <Dropdown
              menu={{ items }}
              trigger={['click']} dropdownRender={(menu) => (
                <div style={contentStyle}>
                  <Title level={3} style={{ paddingLeft: 12, paddingBottom: 8 }}>{i18n.t('pages.campaigns.statistics.filter.title')}</Title>
                  {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
                </div>
              )}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {i18n.t('pages.campaigns.statistics.filter.title')}{`(${selectedUserId === 0 ? campaign.allUsers?.length : 1})`}
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
          </Space>
          <Row justify={'space-between'} gutter={16}>
            <Col sm={24} md={12}>
              <CampaignStatisticsPieChart campaign={campaign} selectedUserId={selectedUserId} />
            </Col>
            <Col sm={24} md={12}>
              <CampaignStatisticsLegend campaign={campaign} selectedUserId={selectedUserId} />
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24} xxl={12}>
        <Card>
          <Title level={2} style={{ marginTop: 8, marginBottom: 24 }}>{i18n.t('pages.campaigns.statistics.topAgents')}</Title>
          <CampaignStatisticsBarChart campaign={campaign} />
        </Card>
      </Col>
    </Row>
  );
}

export default CampaignStatisticsBlock;
