import { RouteInterface } from "../../../common";
import LoginPageComponent from "../login/login.page";
import ForgotPasswordPageComponent from "../forgotPassword/forgotPassword.page";
import ResetPasswordPageComponent from "../resetPassword/resetPassword.page";

export const ROOT_PATH_AUTH = '';

export const AuthRoutes = {
  LOGIN: { path: 'login', fullPath: `${ROOT_PATH_AUTH}/` },
  FORGOT_PASSWORD: { path: 'forgot-password', fullPath: `${ROOT_PATH_AUTH}/forgot-password` },
  RESET_PASSWORD: { path: 'reset-password', fullPath: `${ROOT_PATH_AUTH}/reset-password` },
};

export const AuthRouting: Array<RouteInterface> = [
  {
    path: AuthRoutes.LOGIN.fullPath,
    element: LoginPageComponent,
    functions: []
  },
  {
    path: AuthRoutes.FORGOT_PASSWORD.fullPath,
    element: ForgotPasswordPageComponent,
    functions: []
  },
  {
    path: AuthRoutes.RESET_PASSWORD.fullPath,
    element: ResetPasswordPageComponent,
    functions: []
  },
];