import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Row, Typography } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Pagination, i18n } from '../../../common';
import { AppRoutes } from '../../../pages/app';
import { AssignmentsTable, allAssignmentsTableColumns } from '../../tables';
import { ArrowRightIcon } from '../../icons';
import { Guard } from '../../guard';

const { Title } = Typography;

export default function DashboardAssignments() {

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const pagination: Pagination = {
    show: false,
    current: 1,
    pageSize: 5
  };

  return (
    <Card bodyStyle={{ padding: 24 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title level={2} style={{ margin: 0 }}>{i18n.t('pages.assignments.title')}</Title>
        <Guard permission={{ domain: 'assignment', action: 'create' }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsDrawerOpen(true)}
          >
            {i18n.translate('components.forms.assignment.create')}
          </Button>
        </Guard>
      </div>
      <AssignmentsTable
        columns={allAssignmentsTableColumns.filter(
          (c) => c !== 'id' && c !== 'deadlineDate' && c !== 'assignmentStatus' && c !== 'user'
        )}
        pagination={pagination}
        filters={[]}
        isDrawerOpen={isDrawerOpen}
        closeDrawer={() => setIsDrawerOpen(false)}
        injectedQueryParams={{ pagination: { current: 1, pageSize: 5 } }}
      />
      <Row justify={'center'}>
        <Link to={AppRoutes.ASSIGNMENTS.fullPath}>
          <div style={{ display: 'flex' }}><span style={{ marginRight: 8 }}>{i18n.t('pages.dashboard.seeAll')}</span> <ArrowRightIcon /></div>
        </Link>
      </Row>
    </Card>
  );
}
