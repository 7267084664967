import type { TabsProps } from 'antd';
import { Breadcrumb, Button, Card, Col, Row, Space, Tabs, Typography } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { RootState, i18n, useAppSelector } from '../../../common';
import { AddIcon, ClientsTable, Guard, GuardFunction, allClientsTableColumns, allClientsTableFilters } from '../../../components';
import { AppRoutes } from '../_router/app.routes';

const { Title } = Typography;

const ClientsPage = () => {
  const navigate = useNavigate();

  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  const renderMyClients = (): JSX.Element => (
    <ClientsTable columns={allClientsTableColumns.filter((c) => (c !== 'user' && c !== 'active' && c !== 'actions'))} filters={allClientsTableFilters.filter((f) => (f !== 'user' && f !== 'active'))} injectedQueryParams={{ filters: [{ name: 'user.id', operator: 'EQ', value: currentUser!.id!.toString() }] }}></ClientsTable>
  );

  const renderAllClients = (): JSX.Element => (
    <ClientsTable></ClientsTable>
  );

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.t('pages.clients.myClients'),
      children: renderMyClients(),
    },
    {
      key: '2',
      label: i18n.t('pages.clients.allClients'),
      children: renderAllClients(),
    }
  ];

  const renderContent = (): JSX.Element => {
    if (GuardFunction({ domain: 'client', action: 'view_all_any' })) return <Tabs items={tabs} destroyInactiveTabPane={true}></Tabs>;
    return renderMyClients();
  };

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: 24 }}
        items={[
          { title: <Link to={AppRoutes.DASHBOARD.fullPath} key={AppRoutes.DASHBOARD.fullPath}>{i18n.t('breadcrumb.homepage')}</Link> },
          { title: i18n.t('breadcrumb.clients') },
        ]}
      />
      <Card>
        <Space direction="vertical" size={16} style={{ display: 'flex' }}>
          <Row justify={'space-between'} align={'middle'}>
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                {i18n.t('pages.clients.title')}
              </Title>
            </Col>
            <Col>
              <Guard permission={{ domain: 'client', action: 'create' }}>
                <Button
                  type="primary"
                  icon={<AddIcon />}
                  onClick={() => {
                    navigate(`${AppRoutes.CLIENTS.fullPath}/new`);
                  }}
                >
                  {i18n.t('components.forms.client.create')}
                </Button>
              </Guard>
            </Col>
          </Row>
          {renderContent()}
        </Space>
      </Card>
    </>

  );
}

export default ClientsPage;
