import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector, RootState, QueryParams, DefaultPagination, i18n, getServices, ServiceInterface, DisplayDateFormat } from '../../common';
import Table, { TableParams } from './table.component';

type ServicesTableColumnType = 'name' | 'startDate' | 'expirationDate';

export const allServicesTableColumns: Array<ServicesTableColumnType> = ['name', 'startDate', 'expirationDate'];

interface Props {
  injectedQueryParams?: QueryParams;
  columns?: Array<ServicesTableColumnType>;
  onServiceSelect?: (values: any) => void;
}

const ServicesTable = ({ injectedQueryParams, columns, onServiceSelect }: Props) => {
  const dispatch = useAppDispatch();

  const componentQueryParams: QueryParams = {
    ...injectedQueryParams,
    includes: [
      ...(injectedQueryParams?.includes || []),
      'client'
    ]
  };

  const [queryParams, setQueryParams] = useState<QueryParams>(componentQueryParams);

  useEffect(() => {
    dispatch(getServices(queryParams));
  }, [queryParams]
  )

  const services = useAppSelector((state: RootState) => state.service.all);

  const tableColumns: Array<ServicesTableColumnType> = columns || allServicesTableColumns;

  const servicesTableColumns: ColumnsType<ServiceInterface> = [
    {
      title: i18n.t('components.table.serviceTable.name'),
      dataIndex: 'name',
      key: 'name',
      render: (dataIndex: string, service: ServiceInterface) => <span onClick={() => onServiceSelect && onServiceSelect(service)} style={{ fontWeight: 'bold', cursor: 'pointer' }}>{service.name}</span>,
      sorter: true
    },
    {
      title: i18n.t('components.table.serviceTable.startDate'),
      dataIndex: 'startDate',
      key: 'startDate',
      render: (dataIndex: string, service: ServiceInterface) => service.startDate && `${dayjs(service.startDate).format(DisplayDateFormat)}`,
      sorter: true
    },
    {
      title: i18n.t('components.table.serviceTable.expirationDate'),
      dataIndex: 'expirationDate',
      key: 'expirationDate',
      render: (dataIndex: string, service: ServiceInterface) => service.expirationDate && `${dayjs(service.expirationDate).format(DisplayDateFormat)}`,
      sorter: true
    },
  ].filter((c) => tableColumns.includes(c.key! as ServicesTableColumnType));

  // Potrebno prilagoditi funkciju ovisno o tome koje su sve mogućnosti
  const handleTableChange = (tableParams: TableParams) => {
    setQueryParams({
      pagination: tableParams.pagination,
      filters: [
        ...(tableParams.filters || []),
        ...(componentQueryParams?.filters || []),
      ],
      sorters: tableParams.sorters,
      includes: componentQueryParams.includes,
      search: tableParams.search
    })
  };

  if (!services) return null;
  return (
    <Table
      columns={servicesTableColumns}
      dataSource={services.data}
      rowKey={"id"}
      handleTableChange={handleTableChange}
      paginationDefault={DefaultPagination}
      total={services.total}
    />
  )
}

export default ServicesTable;
