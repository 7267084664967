import React from 'react';
import { Form, Input, Button, Typography, } from 'antd';
import { useNavigate } from 'react-router-dom';
import { i18n } from '../../../common';
import { AuthRoutes } from '../../../pages/auth';

const { Title } = Typography;

interface Props {
  onLoginFormFinish?: (values: any) => void;
  onLoginFormFinishFailed?: (errorInfo: any) => void;
}

function LoginForm({ onLoginFormFinish, onLoginFormFinishFailed }: Props) {
  const navigate = useNavigate();
  return (
    <div className="loginForm">
      <Title style={{marginBottom: 24}}  level={1}>{i18n.translate(`login.title`)}</Title>
      <Form
        layout="vertical"
        size="large"
        name="login"
        // initialValues={{ username: 'superadmin@superadmin.com', password: 'test' }}
        onFinish={onLoginFormFinish}
        onFinishFailed={onLoginFormFinishFailed}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: i18n.translate(`login.form.validation.required`) }, { type: 'email', message: i18n.translate(`login.form.validation.mailFormat`)}]}
        >
          <Input placeholder={i18n.translate(`login.form.placeholders.username`)} />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[{ required: true, message: i18n.translate(`login.form.validation.required`) }]}
          className='form-label-flex'
        >
          <Input.Password placeholder={i18n.translate(`login.form.placeholders.password`)} />
        </Form.Item>

        <Button onClick={() => navigate(AuthRoutes.FORGOT_PASSWORD.path)} style={{textAlign: 'left', marginBottom: 24}} type="link" block size="small">
          {i18n.translate(`login.btns.forgotPassword`)}
        </Button>

        <Form.Item>
          <Button type="primary" size="large" block htmlType="submit">
            {i18n.translate(`login.btns.login`)}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default LoginForm;
