import { Tag } from 'antd';
import React from 'react';
import { AssignmentStatusEnum } from '../enums';
import { i18n } from '../services';

export interface AssignmentStatus {
  id: number;
  name: string;
  color: string;
}

export const AssignmentStatuses = (): Array<AssignmentStatus> => [
  {
    id: AssignmentStatusEnum.CREATED,
    name: i18n.translate('common.constants.assignmentStatus.created'),
    color: 'blue',
  },
  {
    id: AssignmentStatusEnum.TAKEN,
    name: i18n.translate('common.constants.assignmentStatus.taken'),
    color: 'default',
  },
  { id: AssignmentStatusEnum.DONE, name: i18n.translate('common.constants.assignmentStatus.done'), color: 'green' },
];

export const getAssignmentStatusTag = (id: number) => {
  const stat = AssignmentStatuses().find((p: AssignmentStatus) => p.id === id);
  return <Tag color={stat?.color}>{stat?.name.toUpperCase()}</Tag>;
};
