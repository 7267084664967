import { Breadcrumb, Card } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from '..';
import { RootState, getOffer, i18n, updateOffer, useAppDispatch, useAppSelector, QueryParams } from '../../../common';
import { OfferInterface } from '../../../common/interfaces/offer/offer.interface';
import { GuardFunction, OfferForm } from '../../../components';

const OfferEditPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();

  const [priceTotal, setTotal] = useState<number>(0);
  const [totalVat, setVat] = useState<number>(0);

  const componentQueryParams: QueryParams = { includes: ['client,offerItems'] };

  useEffect(() => {
    if (params.offer_id) dispatch(getOffer(parseInt(params.offer_id!, 10), componentQueryParams));
  }, [params]);

  const onFormFinish = (values: any) => {
    const editOffer = {
      ...values,
      vat: totalVat,
      total: priceTotal
    };
    dispatch(updateOffer(parseInt(params.offer_id!, 10), editOffer));
    navigate(AppRoutes.OFFERS.fullPath);
  };

  const offer: OfferInterface | undefined = useAppSelector((state: RootState) => state.offer.one);
  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  if (!offer) return null;

  if (!(GuardFunction({ domain: 'offer', action: 'update_any' }) || offer.user?.id === currentUser!.id)) navigate(AppRoutes.DASHBOARD.fullPath);

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: 24 }}
        items={[
          {
            title: <Link to={AppRoutes.DASHBOARD.fullPath}>{i18n.translate('breadcrumb.homepage')}</Link>,
          },
          {
            title: <Link to={AppRoutes.OFFERS.fullPath}>{i18n.translate('breadcrumb.offer')}</Link>,
          },
          { title: i18n.translate('breadcrumb.offerCreate') },
        ]}
      />
      <Card>
        <OfferForm
          onFinish={(values) => onFormFinish(values)}
          offer={offer}
          total={priceTotal}
          setTotal={setTotal}
          vat={totalVat}
          setVat={setVat}
        />
      </Card>
    </>
  );
};

export default OfferEditPage;
