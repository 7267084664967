import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Typography, Col, Row, Space, Select, Avatar, UploadProps, Upload, Switch, notification, Grid, } from 'antd';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { Role, Roles, UserInterface, UserService, forgotPasswordAction, i18n, useAppDispatch } from '../../../common';
import { UserIcon } from '../../icons';

const { Title, Text } = Typography;
const { Option } = Select;
const { useBreakpoint } = Grid;

export type UserFormItemType = 'profileImageUrl' | 'firstName' | 'lastName' | 'email' | 'phoneNumber' | 'address' | 'dbRole' | 'resetPassword' | 'active';
export type InitialValueTypeUser = { formItem: UserFormItemType, value: string | number | boolean | { id: number } | { id: string } };

export const allUserFormItems: Array<UserFormItemType> = ['profileImageUrl', 'firstName', 'lastName', 'email', 'phoneNumber', 'address', 'dbRole', 'resetPassword', 'active'];

interface Props {
  onUserFormFinish?: (values: any) => void;
  onUserFormFinishFailed?: (errorInfo: any) => void;
  user?: UserInterface;
  formItems?: Array<UserFormItemType>;
  disabled?: Array<UserFormItemType>;
  initialValues?: Array<InitialValueTypeUser>;
  title?: string;
  submitBtnLabel?: string;
}

function UserForm({ onUserFormFinish, onUserFormFinishFailed, user, formItems, disabled, initialValues, title, submitBtnLabel }: Props) {
  const dispatch = useAppDispatch();

  const { md } = useBreakpoint();

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState<string | undefined>(user?.profileImageUrl ? user?.profileImageUrl : undefined);

  useEffect(() => {
    form.setFieldValue('profileImageUrl', imageUrl)
  }, [imageUrl]);

  const handleImageChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'done') {
      UserService.getInfiniteUrl(info.file.response[0].uuid).subscribe({
        next: (response: any) => {
          setImageUrl(response.url);
        },
        error: (error: Error) => {
          notification.error({ message: error.message, duration: 2 });
        }
      })
    }
  };

  // Mapiranje izlaznih vrijednosti
  const onFormSubmit = (values: any) => {
    const data = {
      ...values,
      profileImageUrl: values.profileImageUrl ?? null
    }
    if (onUserFormFinish) onUserFormFinish(data);
  }

  const items: Array<UserFormItemType> = formItems || allUserFormItems;

  // Mapiranje inicijalnih vrijednosti
  const initialFormValues = {
    ...user,
    dbRole: (initialValues?.find((i: InitialValueTypeUser) => i.formItem === 'dbRole')?.value as { id: number }) || user?.dbRole,
    profileImageUrl: (initialValues?.find((fi: InitialValueTypeUser) => fi.formItem === 'profileImageUrl')?.value as string) || user?.profileImageUrl,
    firstName: (initialValues?.find((fi: InitialValueTypeUser) => fi.formItem === 'firstName')?.value as string) || user?.firstName,
    lastName: (initialValues?.find((fi: InitialValueTypeUser) => fi.formItem === 'lastName')?.value as string) || user?.lastName,
    email: (initialValues?.find((fi: InitialValueTypeUser) => fi.formItem === 'email')?.value as string) || user?.email,
    phoneNumber: (initialValues?.find((fi: InitialValueTypeUser) => fi.formItem === 'phoneNumber')?.value as string) || user?.phoneNumber,
    address: (initialValues?.find((fi: InitialValueTypeUser) => fi.formItem === 'address')?.value as string) || user?.address,
    active: (initialValues?.find((fi: InitialValueTypeUser) => fi.formItem === 'active')?.value as boolean) || user?.active
  }

  const mail = Form.useWatch("email", form);

  return (<>
    <Form
      name='user'
      form={form}
      onFinish={onFormSubmit}
      onFinishFailed={onUserFormFinishFailed}
      labelCol={{ span: 8, xxl: { span: 4 }, md: { span: 6 } }}
      wrapperCol={{ span: 14, xxl: { span: 8 }, md: { span: 10 } }}
      initialValues={initialFormValues}
      layout={md ? 'horizontal' : 'vertical'}
    >
      <Space direction='vertical' size={16} style={{ display: 'flex' }}>
        <Row justify={'space-between'}>
          <Col span={20} flex={'auto'}><Title level={2} style={{ margin: 0 }}>{title}</Title></Col>
          <Col flex={'none'}>
            <Space>
              <Button onClick={() => { navigate(-1) }}>{i18n.t('components.forms.common.cancel')}</Button>
              <Button type="primary" htmlType="submit">{submitBtnLabel}</Button>
            </Space>
          </Col>
        </Row>
        <div>
          <Form.Item
            name="profileImageUrl"
            label={i18n.translate(`components.forms.user.profileImage`)}
            hidden={!items.find(i => i === 'profileImageUrl')}
          >
            <Space direction='vertical' size={16} style={{ display: 'flex' }}>
              {imageUrl ? <Avatar size={64} src={imageUrl} /> : <Avatar size={64} icon={<UserIcon />} />}
              <Space>
                <Upload
                  name='files'
                  action={process.env.REACT_APP_DMS_URL}
                  showUploadList={false}
                  onChange={handleImageChange}
                >
                  {imageUrl ? <Button>{i18n.t('components.forms.user.editImage')}</Button> : <Button>{i18n.t('components.forms.user.addImage')}</Button>}
                </Upload>
                {imageUrl ? <Button type="link" danger onClick={() => { setImageUrl(undefined) }}>{i18n.t('components.forms.user.deleteImage')}</Button> : <></>}
              </Space>
            </Space>
          </Form.Item>
          <Form.Item
            name="firstName"
            label={i18n.translate(`components.forms.user.firstName`)}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            wrapperCol={{ md: { span: 10 }, xxl: { span: 6 } }}
            hidden={!items.find(i => i === 'firstName')}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'firstName')} />
          </Form.Item>
          <Form.Item
            name="lastName"
            label={i18n.translate(`components.forms.user.lastName`)}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            wrapperCol={{ md: { span: 10 }, xxl: { span: 6 } }}
            hidden={!items.find(i => i === 'lastName')}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'lastName')} />
          </Form.Item>
          <Form.Item
            name='email'
            label={i18n.translate(`components.forms.user.mail`)}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }, { type: 'email', message: i18n.translate(`components.forms.common.mailFormat`) }]}
            wrapperCol={{ md: { span: 10 }, xxl: { span: 6 } }}
            hidden={!items.find(i => i === 'email')}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'lastName')} />
          </Form.Item>
          <Form.Item
            name='phoneNumber'
            label={i18n.translate(`components.forms.user.phone`)}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]} // { type: 'number', message: i18n.translate(`components.forms.common.phoneFormat`) }
            wrapperCol={{ md: { span: 7 }, xxl: { span: 3 } }}
            hidden={!items.find(i => i === 'phoneNumber')}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'phoneNumber')} />
          </Form.Item>
          <Form.Item
            name='address'
            label={i18n.translate(`components.forms.user.address`)}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            wrapperCol={{ md: { span: 8 }, xxl: { span: 4 } }}
            hidden={!items.find(i => i === 'address')}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'address')} />
          </Form.Item>
          <Form.Item
            name={['dbRole', 'id']}
            label={i18n.translate(`components.forms.user.role`)}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            wrapperCol={{ md: { span: 6 }, xxl: { span: 2 } }}
            hidden={!items.find(i => i === 'dbRole')}
          >
            <Select
              disabled={!!disabled?.find(d => d === 'dbRole')}
              placeholder="Odaberi"
            >
              {Roles().map((role: Role) => <Option key={role.id} value={role.id}>{role.name}</Option>)}
            </Select>
          </Form.Item>

          <Form.Item
            name='resetPassword'
            label={i18n.translate(`components.forms.user.resetPassword`)}
            hidden={!items.find(i => i === 'resetPassword')}
          >
            <Space direction='vertical' size={16} style={{ display: 'flex' }}>
              <Button onClick={() => { dispatch(forgotPasswordAction({ email: mail })) }} disabled={!!disabled?.find(d => d === 'resetPassword')}>{i18n.translate(`components.forms.user.resetPasswordBtn`)}</Button>
              <Text type='secondary'>{i18n.translate(`components.forms.user.resetPasswordDesc`)}</Text>
            </Space>
          </Form.Item>
          <Form.Item
            name='active'
            label={i18n.translate(`components.forms.user.activity`)}
            valuePropName="checked"
            hidden={!items.find(i => i === 'active')}
          >
            <Space direction='vertical' size={16} style={{ display: 'flex' }}>
              <div><Text style={{ lineHeight: '32px' }}>{i18n.t('common.constants.active.inactive')}</Text> <Switch defaultChecked={user?.active} onChange={(checked) => form.setFieldValue('active', checked)} disabled={!!disabled?.find(d => d === 'active')} /> <Text>{i18n.t('common.constants.active.active')}</Text></div>
              <Text type='secondary'>{i18n.translate(`components.forms.user.activityDesc`)}</Text>
            </Space>
          </Form.Item>
        </div>
      </Space>
    </Form>
  </>
  );
}

export default UserForm;
