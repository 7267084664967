import { Layout } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { RouteInterface } from "../../../common/interfaces";
import { ContentComponent, HeaderComponent, SiderComponent } from '../../../components';
import ActivitiesPage from '../activities/activities.page';
import AssignmentsPage from '../assignments/assignments.page';
import CampaignsPage from "../campaigns/campaigns.page";
import ClientsPage from "../clients/clients.page";
import ClientPage from '../clients/client.page';
import ClientCreatePage from '../clients/clientCreate.page';
import ClientEditPage from '../clients/clientEdit.page';
import DashboardPageComponent from "../dashboard/dashboard.page";
import OfferPage from '../offers/offer.page';
import OfferCreatePage from '../offers/offerCreate.page';
import OffersPage from "../offers/offers.page";
import OfferEditPage from '../offers/offerEdit.page';
import ReservationsPage from "../reservations/reservations.page";
import SalesOpportunitiesPage from "../salesOpportunities/salesOpportunities.page";
import ApplicationSettingsPage from '../applicationSettings/applicationSettings.page';
import UserPage from '../users/user.page';
import UserCreatePage from '../users/userCreate.page';
import UserEditPage from '../users/userEdit.page';
import UsersPage from "../users/users.page";
import ProfilePage from "../profile/profile.page";
import ProfileEditPage from '../profile/profileEdit.page';
import CampaignPage from '../campaigns/campaign.page';
import CampaignCreatePage from '../campaigns/campaignCreate.page';
import CampaignEditPage from '../campaigns/campaignEdit.page';
import SalesOpportunityPage from '../salesOpportunities/salesOpportunity.page';
import NotificationsPage from '../notifications/notifications.page';
import SalesOpportunityCreatePage from '../salesOpportunities/salesOpportunityCreate.page';
import SalesOpportunityEditPage from '../salesOpportunities/salesOpportunityEdit.page';

import { AppRoutes } from './app.routes';

export const AppRouting: Array<RouteInterface> = [
  {
    path: AppRoutes.DASHBOARD.fullPath,
    element: DashboardPageComponent,
    functions: []
  },
  {
    path: AppRoutes.USERS.fullPath,
    element: UsersPage,
    functions: [{ domain: 'user', action: 'view_all_any' }]
  },
  {
    path: `${AppRoutes.USERS.fullPath}/:user_id/edit`,
    element: UserEditPage,
    functions: [{ domain: 'user', action: 'update'}]
  },
  {
    path: `${AppRoutes.USERS.fullPath}/:user_id`,
    element: UserPage,
    functions: [{ domain: 'user', action: 'view_one_any'}]
  },
  {
    path: `${AppRoutes.USERS.fullPath}/new`,
    element: UserCreatePage,
    functions: [{ domain: 'user', action: 'create'}]
  },
  {
    path: AppRoutes.CLIENTS.fullPath,
    element: ClientsPage,
    functions: [{ domain: 'client', action: 'view_all' }]
  },
  {
    path: `${AppRoutes.CLIENTS.fullPath}/:client_id/edit`,
    element: ClientEditPage,
    functions: [{ domain: 'client', action: 'update' }]
  },
  {
    path: `${AppRoutes.CLIENTS.fullPath}/:client_id`,
    element: ClientPage,
    functions: [{ domain: 'client', action: 'view_one' }]
  },
  {
    path: `${AppRoutes.CLIENTS.fullPath}/new`,
    element: ClientCreatePage,
    functions: [{ domain: 'client', action: 'create' }]
  },
  {
    path: AppRoutes.SALES_OPPORTUNITIES.fullPath,
    element: SalesOpportunitiesPage,
    functions: [{ domain: 'sales_opportunity', action: 'view_all' }]
  },
  {
    path: `${AppRoutes.SALES_OPPORTUNITIES.fullPath}/:sales_opportunity_id`,
    element: SalesOpportunityPage,
    functions: [{ domain: 'sales_opportunity', action: 'view_one' }]
  },
  {
    path: `${AppRoutes.SALES_OPPORTUNITIES.fullPath}/:sales_opportunity_id/edit`,
    element: SalesOpportunityEditPage,
    functions: [{ domain: 'sales_opportunity', action: 'update' }]
  },
  {
    path: `${AppRoutes.SALES_OPPORTUNITIES.fullPath}/new`,
    element: SalesOpportunityCreatePage,
    functions: [{ domain: 'sales_opportunity', action: 'create' }]
  },
  {
    path: AppRoutes.CAMPAIGNS.fullPath,
    element: CampaignsPage,
    functions: [{ domain: 'campaign', action: 'view_all' }]
  },
  {
    path: `${AppRoutes.CAMPAIGNS.fullPath}/:campaign_id`,
    element: CampaignPage,
    functions: [{ domain: 'campaign', action: 'view_one' }]
  },
  {
    path: `${AppRoutes.CAMPAIGNS.fullPath}/:campaign_id/edit`,
    element: CampaignEditPage,
    functions: [{ domain: 'campaign', action: 'update' }]
  },
  {
    path: `${AppRoutes.CAMPAIGNS.fullPath}/new`,
    element: CampaignCreatePage,
    functions: [{ domain: 'campaign', action: 'create' }]
  },
  {
    path: AppRoutes.ACTIVITIES.fullPath,
    element: ActivitiesPage,
    functions: [{ domain: 'activity', action: 'view_all' }]
  },
  {
    path: AppRoutes.OFFERS.fullPath,
    element: OffersPage,
    functions: [{ domain: 'offer', action: 'view_all' }]
  },
  {
    path: `${AppRoutes.OFFERS.fullPath}/new`,
    element: OfferCreatePage,
    functions: [{ domain: 'offer', action: 'create' }]
  },
  {
    path: `${AppRoutes.OFFERS.fullPath}/:offer_id`,
    element: OfferPage,
    functions: [{ domain: 'offer', action: 'view_one' }]
  },
  {
    path: `${AppRoutes.OFFERS.fullPath}/:offer_id/edit`,
    element: OfferEditPage,
    functions: [{ domain: 'offer', action: 'update' }]
  },
  {
    path: AppRoutes.RESERVATIONS.fullPath,
    element: ReservationsPage,
    functions: [{ domain: 'reservation', action: 'view_all' }]
  },
  {
    path: AppRoutes.ASSIGNMENTS.fullPath,
    element: AssignmentsPage,
    functions: [{ domain: 'assignment', action: 'view_all' }]
  },
  {
    path: AppRoutes.APPLICATION_SETTINGS.fullPath,
    element: ApplicationSettingsPage,
    functions: [{ domain: 'application_settings', action: 'view_one' }]
  },
  {
    path: AppRoutes.PROFILE.fullPath,
    element: ProfilePage,
    functions: []
  },
  {
    path: AppRoutes.PROFILE_EDIT.fullPath,
    element: ProfileEditPage,
    functions: []
  },
  {
    path: AppRoutes.NOTIFICATIONS.fullPath,
    element: NotificationsPage,
    functions: []
  },
];

const AppRouter = () => (
  <Layout style={{ minHeight: '100vh' }} hasSider>
    <SiderComponent />
    <Layout id="app-container">
      <HeaderComponent />
      <ContentComponent>
        <Outlet />
      </ContentComponent>
    </Layout>
  </Layout>
);

export default AppRouter;
