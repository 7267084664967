import { Breadcrumb, Button, Card, Col, Row, Space, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from '../_router/app.routes';
import { useAppDispatch, QueryParams, getUser, UserInterface, useAppSelector, RootState, i18n } from '../../../common';
import { AddIcon, ClientsTable, allClientsTableColumns, allClientsTableFilters, SalesOpportunitiesTable, allSalesOpportunitiesTableColumns, allSalesOpportunitiesTableFilters, AssignmentsTable, CampaignsTable, allCampaignsTableColumns, allCampaignsTableFilters, UserStatisticsLineChart, UserStatisticsProgress, UserDetails, ActivitiesCalendar, allActivitiesCalendarFilters } from '../../../components';

const { Title } = Typography;

const UserPage = () => {
  const dispatch = useAppDispatch();

  const params = useParams();
  const navigate = useNavigate();

  const queryParams: QueryParams = { includes: ['dbRole'] };

  const [isAssignmentsDrawerOpen, setIsAssignmentsDrawerOpen] = useState<boolean>(false);

  useEffect(() => {
    if (params.user_id) dispatch(getUser(parseInt(params.user_id!, 10), queryParams));
  }, [params]
  );

  const user: UserInterface | undefined = useAppSelector((state: RootState) => state.user.one);
  const clientsTotal = useAppSelector((state: RootState) => state.client.all.total);
  const salesOpportunitiesTotal = useAppSelector((state: RootState) => state.salesOpportunity.all.total);
  const assignmentsTotal = useAppSelector((state: RootState) => state.assignments.all.total);
  const campaignsTotal = useAppSelector((state: RootState) => state.campaign.all.total);
  const activitiesTotal = useAppSelector((state: RootState) => state.activity.all.total);

  if (!user || !user.id) return null;

  const renderActivitiesTab = (): JSX.Element => (
    <Space direction="vertical" size={16} style={{ display: 'flex' }}>
      <Row justify={'space-between'} align={'middle'}>
        <Col>
          <Title level={2} style={{ margin: 0 }}>
            {i18n.t('pages.user.activities', { total: activitiesTotal || 0 })}
          </Title>
        </Col>
        <Col>
          
        </Col>
      </Row>
      <ActivitiesCalendar injectedQueryParams={{ filters: [{ name: 'user.id', operator: 'EQ', value: user.id!.toString() }], pagination: { current: 1, pageSize: 999999 } }} filters={allActivitiesCalendarFilters.filter((c) => (c !== 'user'))}></ActivitiesCalendar>
    </Space>
  );
  const renderClientsTab = (): JSX.Element =>
    <Space direction="vertical" size={16} style={{ display: 'flex' }}>
      <Row justify={'space-between'} align={'middle'}>
        <Col>
          <Title level={2} style={{ margin: 0 }}>
            {i18n.t('pages.user.clients', { total: clientsTotal || 0 })}
          </Title>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<AddIcon />}
            onClick={() => {
              navigate(`${AppRoutes.CLIENTS.fullPath}/new`, { state: [{ formItem: 'user', value: { id: user.id } }] });
            }}
          >
            {i18n.t('components.forms.client.create')}
          </Button>
        </Col>
      </Row>
      <ClientsTable columns={allClientsTableColumns.filter((c) => (c !== 'user' && c !== 'active' && c !== 'actions'))} filters={allClientsTableFilters.filter((f) => (f !== 'user' && f !== 'active'))} injectedQueryParams={{ filters: [{ name: 'user.id', operator: 'EQ', value: user.id!.toString() }] }}></ClientsTable>
    </Space>

  const renderSalesOpportunitiesTab = (): JSX.Element =>
    <Space direction="vertical" size={16} style={{ display: 'flex' }}>
      <Row justify={'space-between'} align={'middle'}>
        <Col>
          <Title level={2} style={{ margin: 0 }}>
            {i18n.t('pages.user.salesOpps', { total: salesOpportunitiesTotal || 0  })}
          </Title>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<AddIcon />}
            onClick={() => {
              navigate(`${AppRoutes.SALES_OPPORTUNITIES.fullPath}/new`, { state: [{ formItem: 'users', value: [user.id] }] });
            }}
          >
            {i18n.t('components.forms.salesOpportunity.create')}
          </Button>
        </Col>
      </Row>
      <SalesOpportunitiesTable columns={allSalesOpportunitiesTableColumns.filter((c) => c !== 'users' && c !== 'campaign' && c !== 'actions')} injectedQueryParams={{ filters: [{ name: 'users.id', operator: 'EQ', value: user!.id!.toString() }] }} filters={allSalesOpportunitiesTableFilters.filter((f) => (f !== 'campaign'))}></SalesOpportunitiesTable>
    </Space>

  const renderAssignmentsTab = (): JSX.Element =>
    <Space direction="vertical" size={16} style={{ display: 'flex' }}>
      <Row justify={'space-between'} align={'middle'}>
        <Col>
          <Title level={2} style={{ margin: 0 }}>
            {i18n.t('pages.user.assignments', { total: assignmentsTotal || 0  })}
          </Title>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<AddIcon />}
            onClick={() => setIsAssignmentsDrawerOpen(true)}
          >
            {i18n.t('components.forms.assignment.create')}
          </Button>
        </Col>
      </Row>
      <AssignmentsTable
        injectedQueryParams={{ filters: [{ name: 'user.id', operator: 'EQ', value: user!.id!.toString() }] }}
        isDrawerOpen={isAssignmentsDrawerOpen}
        closeDrawer={() => setIsAssignmentsDrawerOpen(false)}
      />
    </Space>

  const renderCampaignsTab = (): JSX.Element =>
    <Space direction="vertical" size={16} style={{ display: 'flex' }}>
      <Row justify={'space-between'} align={'middle'}>
        <Col>
          <Title level={2} style={{ margin: 0 }}>
            {i18n.t('pages.user.campaigns', { total: campaignsTotal || 0  })}
          </Title>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<AddIcon />}
            onClick={() => {
              navigate(`${AppRoutes.CAMPAIGNS.fullPath}/new`);
            }}
          >
            {i18n.t('components.forms.campaign.create')}
          </Button>
        </Col>
      </Row>
      <CampaignsTable columns={allCampaignsTableColumns.filter((c) => c !== 'users')} injectedQueryParams={{ filters: [{ name: 'salesOpportunities.users.id', operator: 'EQ', value: user!.id!.toString() }] }} filters={allCampaignsTableFilters.filter((f) => (f !== 'user'))} />
    </Space>

  const renderStatisticsTab = (): JSX.Element => <Space direction="vertical" size={16} style={{ display: 'flex' }}>
    <Row justify={'space-between'}>
      <Col xs={24} sm={24} md={24} lg={24} xl={11}>
        <Card>
          <Title level={2} style={{ marginTop: 8, marginBottom: 24 }}>{'Mjesečna statistika uspješnosti (€)'}</Title>
          <UserStatisticsLineChart user={user} />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={12}>
        <Card>
          <Title level={2} style={{ marginTop: 8, marginBottom: 24 }}>{'Statistike u prodajnim prilikama'}</Title>
          <UserStatisticsProgress user={user} />
        </Card>
      </Col>
    </Row>
  </Space>

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.t('pages.user.tabs.userDetails'),
      children: <UserDetails user={user!}></UserDetails>,
    },
    {
      key: '2',
      label: i18n.t('pages.user.tabs.userStats'),
      children: renderStatisticsTab(),
    },
    {
      key: '3',
      label: i18n.t('pages.user.tabs.userActivities'),
      children: renderActivitiesTab(),
    },
    {
      key: '4',
      label: i18n.t('pages.user.tabs.userClients'),
      children: renderClientsTab(),
    },
    {
      key: '5',
      label: i18n.t('pages.user.tabs.userAssignments'),
      children: renderAssignmentsTab(),
    },
    {
      key: '6',
      label: i18n.t('pages.user.tabs.userSalesOpps'),
      children: renderSalesOpportunitiesTab(),
    },
    {
      key: '7',
      label: i18n.t('pages.user.tabs.userCampaigns'),
      children: renderCampaignsTab(),
    },
  ];

  return (
    <>
      <Breadcrumb items={[{ title: <Link to={AppRoutes.DASHBOARD.fullPath} key={AppRoutes.DASHBOARD.fullPath}>{i18n.t('breadcrumb.homepage')}</Link> }, { title: <Link to={AppRoutes.USERS.fullPath} key={AppRoutes.USERS.fullPath}>{i18n.t('breadcrumb.users')}</Link> }, { title: user.fullName }]} style={{ marginBottom: 24 }} />
      <Card>
        <Space direction="vertical" size={16} style={{ display: 'flex' }}>
          <Title level={2} style={{ margin: 0 }}>{user.fullName}</Title>
          <Tabs items={items} destroyInactiveTabPane={true} />
        </Space>
      </Card>
    </>
  );
};

export default UserPage;
