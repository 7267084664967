import { Drawer, Space, Button } from "antd";
import React from "react";
import { QueryParams, useAppDispatch, useAppSelector, RootState, ReservationInterface, createReservation, updateReservation, i18n, closeReservationDrawer, submitReservationForm } from "../../common";
import { ReservationForm } from "../forms";

interface Props {
  queryParams?: QueryParams;
}

const ReservationDrawer = ({ queryParams }: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  const reservationDrawer = useAppSelector((state: RootState) => state.componentState.drawer.reservation);

  const handleClose = () => {
    dispatch(closeReservationDrawer());
  };

  const onReservationCreate = (data: ReservationInterface) => {
    dispatch(createReservation(data, queryParams));
    dispatch(closeReservationDrawer());
  };

  const onReservationUpdate = (data: ReservationInterface) => {
    dispatch(updateReservation(data.id!, data, queryParams));
    dispatch(closeReservationDrawer());
  };

  if (!reservationDrawer.data) {
    return (
      <Drawer
        width={650}
        title={i18n.translate('components.forms.reservations.create')}
        open={reservationDrawer.open}
        onClose={handleClose}
        destroyOnClose
        extra={
          <Space>
            <Button onClick={handleClose}>{i18n.translate('components.drawer.buttons.cancel')}</Button>
            <Button onClick={() => dispatch(submitReservationForm())} type="primary">
              {i18n.translate('components.drawer.buttons.create')}
            </Button>
          </Space>
        }
      >
        <ReservationForm
          onReservationFormFinish={(values) => onReservationCreate(values)}
        />
      </Drawer>
    )
  }
  return (
    <Drawer
      width={650}
      title={i18n.translate('components.forms.reservations.edit')}
      placement="right"
      open={reservationDrawer.open}
      onClose={handleClose}
      destroyOnClose
      extra={
        <Space>
          <Button onClick={handleClose}>{i18n.translate('components.drawer.buttons.cancel')}</Button>
          <Button onClick={() => dispatch(submitReservationForm())} type="primary">
            {i18n.translate('components.drawer.buttons.edit')}
          </Button>
        </Space>
      }
    >
      <ReservationForm
        reservation={reservationDrawer.data}
        onReservationFormFinish={(values) => onReservationUpdate(values)}
      />
    </Drawer>
  )
}

export default ReservationDrawer;