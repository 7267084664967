import { Row, Space, Typography, theme } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PlumLogo from '../../../assets/images/plum_logo.svg';
import { LoginInterface, i18n, loginAction, useAppDispatch } from '../../../common';
import { LoginForm } from '../../../components';

const { useToken } = theme;

const { Title, Text } = Typography;

const LoginPage = () => {
  const { token } = useToken();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onLogin = (values: any) => {
    const loginData: LoginInterface = {
      username: values.username,
      password: values.password,
    };
    dispatch(loginAction(loginData, navigate));
  };

  const onLoginFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="login">
      <Space direction='vertical' size={24}>
        <Row justify="center">
          <Row>
            <Space size={16} align="center">
              <img width={33} height={37} src={PlumLogo} alt="" />
              <Title className="main-title" style={{ color: token.colorPrimary }}>
                {i18n.translate(`login.top.title`)}
              </Title>
            </Space>
          </Row>
          <Row className="text-align-center">
            <Text style={{ color: token.colorTextDescription }}>{i18n.translate(`login.top.header`)}</Text>
          </Row>
        </Row>
        <LoginForm onLoginFormFinish={(values) => onLogin(values)} onLoginFormFinishFailed={(errorInfo) => onLoginFailed(errorInfo)}></LoginForm>
      </Space>
    </div>
  );
};

export default LoginPage;
