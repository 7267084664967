import { Button, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import {
  DefaultPagination,
  QueryParams,
  ReservationCategoryInterface,
  ReservationOptionInterface,
  RootState,
  getReservationCategories,
  i18n,
  useAppDispatch,
  useAppSelector,
} from '../../common';
import Table, { TableParams } from './table.component';

const { Text } = Typography;

interface Props {
  setCategory: (values: any) => void;
}

const ReservationCategoryTable = ({ setCategory }: Props) => {
  const dispatch = useAppDispatch();

  const componentQueryParams: QueryParams = { includes: ['reservationOptions'], sorters: [{ columnKey: 'id', order: 'descend' }] };
  const [queryParams, setQueryParams] = useState<QueryParams>(componentQueryParams);

  useEffect(() => {
    dispatch(getReservationCategories(queryParams));
  }, [queryParams]);

  const categories = useAppSelector((state: RootState) => state.reservationCategory.all);

  if (!categories?.data) return null;

  const reservationCategoryTableColumns: ColumnsType<ReservationCategoryInterface> = [
    {
      title: i18n.t('components.table.reservationCategoryTable.category'),
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      render: (dataIntex: string, category: ReservationCategoryInterface) => (
        <Button
          type="link"
          onClick={() => {
            setCategory(category);
          }}
        >
          {category.name}
        </Button>
      ),
    },
    {
      title: i18n.t('components.table.reservationCategoryTable.options'),
      dataIndex: 'reservationOptions',
      key: 'reservationOptions',
      ellipsis: true,
      render: (reservationOptions) => (
        <>
          {reservationOptions && reservationOptions.map((option: ReservationOptionInterface, index: any) => (
            <Text key={index}>{(index ? ', ' : '') + option.name}</Text>
          ))}
        </>
      ),
    },
  ];

  const handleTableChange = (tableParams: TableParams) => {
    setQueryParams({
      pagination: tableParams.pagination,
      filters:
        [
          ...(tableParams.filters || []),
          ...(componentQueryParams?.filters || []),
        ],
      sorters:
        [
          ...(tableParams.sorters || []),
          ...(componentQueryParams?.sorters || []),
        ],
      includes: componentQueryParams.includes,
      search: tableParams.search
    })
  };

  return (
    <Table
      columns={reservationCategoryTableColumns}
      dataSource={categories.data}
      rowKey={'id'}
      handleTableChange={handleTableChange}
      paginationDefault={DefaultPagination}
      total={categories.total}
    />
  );
};

export default ReservationCategoryTable;
