import { Breadcrumb, Button, Card, Col, Row, Space, Tabs, TabsProps, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../_router/app.routes';
import { RootState, i18n, openActivityDrawer, useAppDispatch, useAppSelector } from '../../../common';
import { ActivitiesCalendar, AddIcon, Guard, GuardFunction, allActivitiesCalendarFilters } from '../../../components';

const { Title } = Typography;

const ActivitiesPage = () => {
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  const renderMyActivities = (): JSX.Element => (
    <ActivitiesCalendar injectedQueryParams={{ filters: [{ name: 'user.id', operator: 'EQ', value: currentUser!.id!.toString() }], sorters: [{ columnKey: 'activityDate', order: 'descend' }], pagination: { current: 1, pageSize: 999999 } }} filters={allActivitiesCalendarFilters.filter((c) => (c !== 'user'))}></ActivitiesCalendar>
  );

  const renderAllActivities = (): JSX.Element => (
    <ActivitiesCalendar injectedQueryParams={{ sorters: [{ columnKey: 'activityDate', order: 'descend' }], pagination: { current: 1, pageSize: 999999 } }}></ActivitiesCalendar>
  );

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.t('pages.activities.myActivities'),
      children: renderMyActivities(),
    },
    {
      key: '2',
      label: i18n.t('pages.activities.allActivities'),
      children: renderAllActivities(),
    }
  ];

  const renderContent = (): JSX.Element => {
    if (GuardFunction({ domain: 'activity', action: 'view_all_any' })) return <Tabs items={tabs} destroyInactiveTabPane={true}></Tabs>;
    return renderMyActivities();
  };

  return (
    <>
      <Breadcrumb
        items={[
          { title: <Link to={AppRoutes.DASHBOARD.fullPath} key={AppRoutes.DASHBOARD.fullPath}>{i18n.t('breadcrumb.homepage')}</Link> },
          { title: i18n.t('breadcrumb.activities') },
        ]}
        style={{ marginBottom: 24 }}
      />
      <Card>
        <Space direction="vertical" size={16} style={{ display: 'flex' }}>
          <Row justify={'space-between'} align={'middle'}>
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                {i18n.t('pages.activities.title')}
              </Title>
            </Col>
            <Col>
              <Guard permission={{ domain: 'activity', action: 'create' }}>
                <Button
                  type="primary"
                  icon={<AddIcon />}
                  onClick={() => {
                    dispatch(openActivityDrawer());
                  }}
                >
                  {i18n.t('pages.activities.createNew')}
                </Button>
              </Guard>
            </Col>
          </Row>
          {renderContent()}
        </Space>
      </Card>
    </>

  );
}

export default ActivitiesPage;
