import React from "react";
import { Avatar } from "antd";
import { CallIcon, EmailIcon, MeetingIcon, NoteIcon, OfferIcon, VideoCallIcon } from "../../components";
import { ActivityStatusEnum, ActivityTypeEnum } from "../enums";
import { i18n } from "../services";
import { colors } from "../../theme";


export interface ActivityType {
  id: number;
  name: string;
  color: string;
  icon: JSX.Element;
  actionTextKey?: string;
}

export const ActivityTypes = (): Array<ActivityType> => [
  { id: ActivityTypeEnum.CALL, name: i18n.t("common.constants.activityType.call"), color: colors.blueDark, icon: <CallIcon />, actionTextKey: "made" },
  { id: ActivityTypeEnum.MAIL, name: i18n.t("common.constants.activityType.mail"), color: colors.blue, icon: <EmailIcon />, actionTextKey: "sent" },
  { id: ActivityTypeEnum.VIDEO, name: i18n.t("common.constants.activityType.video"), color: colors.orange, icon: <VideoCallIcon />, actionTextKey: "made" },
  { id: ActivityTypeEnum.NOTE, name: i18n.t("common.constants.activityType.note"), color: colors.purple, icon: <NoteIcon />, actionTextKey: "wrote" },
  { id: ActivityTypeEnum.MEETING, name: i18n.t("common.constants.activityType.meeting"), color: colors.yellow, icon: <MeetingIcon />, actionTextKey: "held" },
  { id: ActivityTypeEnum.OFFER, name: i18n.t("common.constants.activityType.offer"), color: colors.pink, icon: <OfferIcon />, actionTextKey: "sent" },
]

export const getActivityType = (id: number): ActivityType => 
  ActivityTypes().find((at: ActivityType) => at.id === id)!

export const getActivityTypeAvatar = (id: number, statusId: number) => {
  const activityType = getActivityType(id);
  return <Avatar style={{ backgroundColor: activityType.color, opacity: statusId === ActivityStatusEnum.DONE ? '1' : '0.7'}} icon={activityType.icon} />;
};

export const getActivityTypeAction = (id: number, statusId: number) => {
  const activityType = getActivityType(id);
  return <> {statusId === ActivityStatusEnum.DONE ? i18n.t(`common.constants.activityType.text.${activityType.actionTextKey}`) : i18n.t("common.constants.activityType.text.scheduled")} <b>&quot;{activityType.name.toLowerCase()}&quot;</b></>;
};

export const getActivityTypeColor = (id: number): any => {
  const activityType = getActivityType(id);
  return activityType!.color;
}

export const getActivityTypeName = (id: number): any => {
  const activityType = getActivityType(id);
  return activityType!.name;
}

export const getActivityTypeIcon = (id: number): any => {
  const activityType = getActivityType(id);
  return activityType!.icon;
}
