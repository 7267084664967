import { Empty, Select, Typography } from "antd";
import React, { useState } from 'react';
import { HeaderSearchEnum, RootState, headerSearch, i18n, navigate, resetHeaderSearch, useAppDispatch, useAppSelector } from "../../common";
import { getFullRouteByPath } from "../../common/constants/route.constant";
import { AppRoutes } from "../../pages/app";
import { colors } from "../../theme";

const { Text } = Typography;

const HeaderSearch = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const searchResults = useAppSelector((state: RootState) => state.headerSearch.searchResults);

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');

  const navigateToEntity = (option: {value: number, type: string}) => {
    navigate(`${getFullRouteByPath(option.type)!}/${option.value}`);
  };

  const onSelect = (value: number, option: any) => {
    setDropdownOpen(false);
    setSearchValue('');
    setSelectedValue(null);
    dispatch(resetHeaderSearch());
    navigateToEntity(option);
  };

  const onMoreClick = (route: string) => {
    setDropdownOpen(false);
    setSearchValue('');
    setSelectedValue(null);
    dispatch(resetHeaderSearch());
    navigate(route);
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
    if (value.length === 0) setDropdownOpen(false);
    if (value.length > 2) { 
      dispatch(headerSearch(value));
      setDropdownOpen(true);
    }
  };

  const options = [
    {
      label: <Text style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}><span style={{color: colors.grey}}>{i18n.translate(`components.other.headerSearch.clients`)}</span><a onClick={() => onMoreClick(AppRoutes.CLIENTS.fullPath)}>{i18n.translate(`header.search.more`)}</a></Text>,
      options: searchResults?.clients?.items?.map(client => ({ key: `client${client.id}`, label: <span style={{ fontWeight: 'bold' }}>{client.name}</span>, value: client.id, type: HeaderSearchEnum.CLIENTS })),
    },
    {
      label: <Text style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}><span style={{color: colors.grey}}>{i18n.translate(`components.other.headerSearch.campaigns`)}</span><a onClick={() => onMoreClick(AppRoutes.CAMPAIGNS.fullPath)}>{i18n.translate(`header.search.more`)}</a></Text>,
      options: searchResults?.campaigns?.items?.map(campaign => ({ key: `campaign${campaign.id}`, label: <span style={{ fontWeight: 'bold' }}>{campaign.name}</span>, value: campaign.id, type: HeaderSearchEnum.CAMPAIGNS })),
    },
    {
      label: <Text style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}><span style={{color: colors.grey}}>{i18n.translate(`components.other.headerSearch.salesOpportunities`)}</span><a onClick={() => onMoreClick(AppRoutes.SALES_OPPORTUNITIES.fullPath)}>{i18n.translate(`header.search.more`)}</a></Text>,
      options: searchResults?.salesOpportunities?.items?.map(salesOpportunity => ({ key: `salesOpportunity${salesOpportunity.id}`, label: <span style={{ fontWeight: 'bold' }}>{salesOpportunity.name}</span>, value: salesOpportunity.id, type: HeaderSearchEnum.SALES_OPPORTUNITIES })),
    },
    {
      label: <Text style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}><span style={{color: colors.grey}}>{i18n.translate(`components.other.headerSearch.offers`)}</span><a onClick={() => onMoreClick(AppRoutes.OFFERS.fullPath)}>{i18n.translate(`header.search.more`)}</a></Text>,
      options: searchResults?.offers?.items?.map(offer => ({ key: `offer${offer.id}`, label: <span style={{ fontWeight: 'bold' }}>{offer.name}</span>, value: offer.id, type: HeaderSearchEnum.OFFERS })),
    },
  ].filter(x => x.options && x.options.length > 0)

  return (
    <Select
      showSearch
      filterOption={false}
      suffixIcon={null}
      bordered={false}
      size="large"
      placeholder={i18n.translate('header.search.placeholder')}
      style={{ width: '100%' }}
      options={options}
      notFoundContent={<Empty description={i18n.translate('header.search.noResults')} image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      searchValue={searchValue}
      value={selectedValue}
      onSelect={onSelect}
      onSearch={onSearch}
      open={dropdownOpen}
    />
  )
}

export default HeaderSearch;
