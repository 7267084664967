export const apiRoutes: { [key: string]: string } = {
  login: `auth/login`,
  refreshToken: `auth/token/refresh`,
  forgotPassword: `auth/send-reset-key`,
  resetPassword: `auth/reset-password-key`,
  changePassword: `auth/reset-password`,
  infiniteUrl: `dms/{uuid}/infinite-url`,
  tempUrl: `dms/{uuid}/temp-url`,
  clientsTemplate: `excel/clients-template`,
  exportClients: `excel/clients`,
  importClients: `excel/clients-upload`,
  dashboardStatistics: `dashboard/statistics`,
  headerSearch: `header_search/search?name={value}`,
  delete: `{entity}/{id}`,
  exportOffer: `pdf/offer`,
  salesOpportunityAgentAdd: `sales_opportunity_agent/add`,
  salesOpportunityAgentTransfer: `sales_opportunity_agent/transfer`,
  jsonPatch: ``,
};
