import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { Form, Input, Button, Typography, Col, Row, Space, UploadFile, UploadProps, InputNumber, Switch, DatePicker, Select, notification, Grid } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import Dragger from 'antd/es/upload/Dragger';
import { CampaignInterface, CampaignStatus, CampaignStatusEnum, CampaignStatuses, DisplayDateFormat, FileInterface, i18n } from '../../../common';
import { CustomFileRender } from '../../other';
import { GuardFunction } from '../../guard';

const { RangePicker } = DatePicker;
const { Title, Text } = Typography;
const { Option } = Select;
const { useBreakpoint } = Grid;

export type CampaignsFormItemType = 'name' | 'beginningDate' | 'value' | 'filesDTO' | 'description' | 'active' | 'campaignStatus';
export type InitialValueType = {formItem: CampaignsFormItemType, value: number | string | Array<dayjs.Dayjs> | boolean | {id: number} | {id: string}};

export const allCampaignFormItems: Array<CampaignsFormItemType> = ['name', 'beginningDate', 'value', 'filesDTO', 'description', 'active', 'campaignStatus'];

interface Props {
  onCampaignFormFinish?: (values: any) => void;
  onCampaignFormFinishFailed?: (errorInfo: any) => void;
  campaign?: CampaignInterface;
  formItems?: Array<CampaignsFormItemType>;
  disabled?: Array<CampaignsFormItemType>;
  initialValues?: Array<InitialValueType>;
  title?: string;
  submitBtnLabel?: string;
}

function CampaignForm({ onCampaignFormFinish, onCampaignFormFinishFailed, title, submitBtnLabel, campaign, formItems, disabled, initialValues }: Props) {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { md } = useBreakpoint();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    setFileList(campaign?.files?.length ? campaign?.files?.map((x: FileInterface) => ({ id: x.id, uid: x.uuid, name: x.name })) : [])
  }, [campaign]);

  const onRemoveFile = (file: any) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  }

  const props: UploadProps = {
    name: 'files',
    action: process.env.REACT_APP_DMS_URL,
    multiple: true,
    maxCount: 5,
    fileList,
    disabled: !!disabled?.find(d => d === 'filesDTO'),
    itemRender: (originNode, file) => (
      <CustomFileRender file={file} onRemoveFile={onRemoveFile} />
    ),
    beforeUpload: () => {
      if (fileList.length >= 5) {
        notification.error({ message: i18n.translate('components.forms.campaign.upload.limit') });
        return false;
      }

      return true;
    },
    onChange: (info) => {
      setFileList(info.fileList);
      if (info.file.status === 'done') {
        notification.success({ message: i18n.translate('components.forms.campaign.upload.success', {fileName: info.file.name})});
      } else if (info.file.status === 'error') {
        notification.error({ message: i18n.translate('components.forms.campaign.upload.error', {fileName: info.file.name})});
      }
    },
  };

  const onFinish = (values: any) => {
    const requestObj = {
      ...values,
      beginningDate: values.beginningDate[0],
      expirationDate: values.beginningDate[1],
      filesDTO: fileList?.map((x: any) => ({ id: x.id ? x.id : undefined, uuid: x.response && x.response.length ? x.response[0].uuid : x.uid, name: x.name })),
      campaignStatus: values.campaignStatus?.id ? { id: values.campaignStatus?.id } : { id: CampaignStatusEnum.IN_PROGRESS }
    }
    return onCampaignFormFinish && onCampaignFormFinish(requestObj);
  };

  const items: Array<CampaignsFormItemType> = formItems || allCampaignFormItems;

  const initialFormValues = {
    ...campaign,
    name: (initialValues?.find((fi: InitialValueType) => fi.formItem === 'name')?.value as string) || campaign?.name,
    beginningDate: (initialValues?.find((fi: InitialValueType) => fi.formItem === 'beginningDate')?.value as Array<dayjs.Dayjs>) || [dayjs(campaign?.beginningDate), dayjs(campaign?.expirationDate)],
    value: (initialValues?.find((fi: InitialValueType) => fi.formItem === 'value')?.value as number) || campaign?.value,
    description: (initialValues?.find((fi: InitialValueType) => fi.formItem === 'description')?.value as string) || campaign?.description,
    active: (initialValues?.find((fi: InitialValueType) => fi.formItem === 'active')?.value as boolean) || campaign?.active,
  }

  return (<>
    <Form
      name='campaign'
      form={form}
      onFinish={onFinish}
      onFinishFailed={onCampaignFormFinishFailed}
      labelCol={{ span: 8,  xxl: {span: 4}, md: {span: 6} }}
      wrapperCol={{ span: 14,  xxl: {span: 8}, md: {span: 10} }}
      initialValues={initialFormValues}
      layout={md ? 'horizontal' : 'vertical'}
    >
      <Space direction='vertical' size={16} style={{ display: 'flex' }}>
        <Row justify={'space-between'}>
          <Col span={20} flex={'auto'}><Title level={2} style={{ margin: 0 }}>{title}</Title></Col>
          <Col flex={'none'}>
            <Space>
              <Button onClick={() => { navigate(-1) }}>{i18n.t('buttons.cancel')}</Button>
              <Button type="primary" htmlType="submit">{submitBtnLabel}</Button>
            </Space>
          </Col>
        </Row>
        <div>
          {items.find(i => i === 'name') && <Form.Item
            name="name"
            label={i18n.translate(`components.forms.campaign.name`)}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            wrapperCol={{ md: { span: 10}, xxl: { span: 6 }  }}
          >
            <Input size="small" disabled={!!disabled?.find(d => d === 'name')} />
          </Form.Item>}
          {items.find(i => i === 'beginningDate') && <Form.Item
            name="beginningDate"
            label={i18n.translate(`components.forms.campaign.duration`)}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
          >
            <RangePicker format={DisplayDateFormat} disabled={!!disabled?.find(d => d === 'beginningDate')} />
          </Form.Item>}
          {items.find(i => i === 'value') && <Form.Item
            name='value'
            label={i18n.translate(`components.forms.campaign.value`)}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
            wrapperCol={{ md: { span: 4}, xxl: { span: 2 }  }}
          >
            <InputNumber min={0} addonAfter="€" disabled={!!disabled?.find(d => d === 'value')} />
          </Form.Item>}
          {items.find(i => i === 'campaignStatus') && GuardFunction({ domain: 'campaign', action: 'deactivate' }) && <Form.Item
            name={['campaignStatus', 'id']}
            label={i18n.translate(`components.forms.campaign.campaignStatus`)}
            wrapperCol={{ md: { span: 4}, xxl: { span: 2 }  }}
          >
            <Select
              disabled={!!disabled?.find(d => d === 'campaignStatus')}
            >
              {CampaignStatuses().map((campaignStatus: CampaignStatus) =>
                <Option key={campaignStatus.id} value={campaignStatus.id}>
                  <Space style={{ display: 'flex' }}>
                    {campaignStatus.name}
                  </Space>
                </Option>
              )}
            </Select>
          </Form.Item>}
          {items.find(i => i === 'filesDTO') && <Form.Item
            name='filesDTO'
            label={i18n.translate(`components.forms.campaign.files`)}
          >
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">{i18n.translate(`components.forms.campaign.upload.text`)}</p>
              <p className="ant-upload-hint">{i18n.translate(`components.forms.campaign.upload.hint`)}</p>
            </Dragger>
          </Form.Item>}
          {items.find(i => i === 'description') && <Form.Item
            name='description'
            label={i18n.translate(`components.forms.campaign.description`)}
          >
            <TextArea autoSize={{ minRows: 3 }} maxLength={5000} disabled={!!disabled?.find(d => d === 'description')} />
          </Form.Item>}
          {items.find(i => i === 'active') && GuardFunction({ domain: 'campaign', action: 'deactivate' }) && <Form.Item
            name='active'
            label={i18n.translate(`components.forms.campaign.activity.title`)}
            valuePropName="checked"
          >
            <Space direction='vertical' size={16} style={{ display: 'flex' }}>
              <div><Text style={{ lineHeight: '32px' }}>{i18n.t('components.forms.campaign.activity.inactive')}</Text> <Switch defaultChecked={campaign?.active} onChange={(checked) => form.setFieldValue('active', checked)} disabled={!!disabled?.find(d => d === 'active')} /> <Text>{i18n.t('components.forms.campaign.activity.active')}</Text></div>
            </Space>
          </Form.Item>}
        </div>
      </Space>
    </Form>
  </>
  );
}

export default CampaignForm;
