import Icon from '@ant-design/icons';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import React from 'react';
import { ReactComponent as ActivitiesSvg } from '../../assets/icons/activities.svg';
import { ReactComponent as AddSvg } from '../../assets/icons/add.svg';
import { ReactComponent as AssignmentsSvg } from '../../assets/icons/assignments.svg';
import { ReactComponent as BellSvg } from '../../assets/icons/bell.svg';
import { ReactComponent as CallSvg } from '../../assets/icons/call.svg';
import { ReactComponent as CampaignsSvg } from '../../assets/icons/campaigns.svg';
import { ReactComponent as CancelSvg } from '../../assets/icons/cancel.svg';
import { ReactComponent as ClientsSvg } from '../../assets/icons/clients.svg';
import { ReactComponent as DashboardSvg } from '../../assets/icons/dashboard.svg';
import { ReactComponent as DeleteSvg } from '../../assets/icons/delete.svg';
import { ReactComponent as DoneSvg } from '../../assets/icons/done.svg';
import { ReactComponent as EditSvg } from '../../assets/icons/edit.svg';
import { ReactComponent as EmailSvg } from '../../assets/icons/email.svg';
import { ReactComponent as HideSvg } from '../../assets/icons/hide.svg';
import { ReactComponent as VideoCallSvg } from '../../assets/icons/videoCall.svg';
import { ReactComponent as MeetingSvg } from '../../assets/icons/meeting.svg';
import { ReactComponent as NewMessageSvg } from '../../assets/icons/newMsg.svg';
import { ReactComponent as NoteSvg } from '../../assets/icons/note.svg';
import { ReactComponent as OfferSvg } from '../../assets/icons/offer.svg';
import { ReactComponent as OffersSvg } from '../../assets/icons/offers.svg';
import { ReactComponent as ReservationsSvg } from '../../assets/icons/reservations.svg';
import { ReactComponent as SalesSvg } from '../../assets/icons/salesOpps.svg';
import { ReactComponent as SearchSvg } from '../../assets/icons/search.svg';
import { ReactComponent as SendSvg } from '../../assets/icons/send.svg';
import { ReactComponent as SettingsSvg } from '../../assets/icons/settings.svg';
import { ReactComponent as ShowSvg } from '../../assets/icons/show.svg';
import { ReactComponent as UsersSvg } from '../../assets/icons/users.svg';
import { ReactComponent as UserSvg } from '../../assets/icons/user.svg';
import { ReactComponent as ArrowRightSvg } from '../../assets/icons/arrowRight.svg';

export const ActivitiesIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ActivitiesSvg} {...props} style={{ color: 'initial' }} />
);

export const AddIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={AddSvg} {...props} style={{ color: 'transparent' }} />
);

export const AssignmentsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={AssignmentsSvg} {...props} style={{ color: 'transparent' }} />
);

export const BellIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={BellSvg} {...props} style={{ color: 'transparent' }} />
);

export const CallIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CallSvg} {...props} style={{ color: 'transparent' }} />
);

export const CampaignsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CampaignsSvg} {...props} style={{ color: 'transparent' }} />
);

export const CancelIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CancelSvg} {...props} style={{ color: 'transparent' }} />
);

export const ClientsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ClientsSvg} {...props} style={{ color: 'transparent' }} />
);

export const DashboardIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DashboardSvg} {...props} style={{ color: 'transparent' }} />
);

export const DeleteIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DeleteSvg} {...props} style={{ color: 'transparent' }} />
);

export const DoneIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={DoneSvg} {...props} style={{ color: 'transparent' }} />
);

export const EditIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={EditSvg} {...props} style={{ color: 'transparent' }} />
);

export const EmailIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={EmailSvg} {...props} style={{ color: 'transparent' }} />
);

export const HideIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={HideSvg} {...props} style={{ color: 'transparent' }} />
);

export const VideoCallIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={VideoCallSvg} {...props} style={{ color: 'transparent' }} />
);

export const MeetingIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={MeetingSvg} {...props} style={{ color: 'transparent' }} />
);

export const NewMessageIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={NewMessageSvg} {...props} style={{ color: 'transparent' }} />
);

export const NoteIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={NoteSvg} {...props} style={{ color: 'transparent' }} />
);

export const OfferIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={OfferSvg} {...props} style={{ color: 'transparent' }} />
);

export const OffersIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={OffersSvg} {...props} style={{ color: 'transparent' }} />
);

export const ReservationsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ReservationsSvg} {...props} style={{ color: 'transparent' }} />
);

export const SalesIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SalesSvg} {...props} style={{ color: 'transparent' }} />
);

export const SearchIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SearchSvg} {...props} style={{ color: 'transparent' }} />
);

export const SendIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SendSvg} {...props} style={{ color: 'transparent' }} />
);

export const SettingsIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={SettingsSvg} {...props} style={{ color: 'transparent' }} />
);

export const ShowIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ShowSvg} {...props} style={{ color: 'transparent' }} />
);

export const UsersIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={UsersSvg} {...props} style={{ color: 'transparent' }} />
);

export const UserIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={UserSvg} {...props} style={{ color: 'transparent' }} />
);

export const ArrowRightIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ArrowRightSvg} {...props} style={{ color: 'transparent' }} />
);
