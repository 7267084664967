import React, { useEffect } from 'react';
import { Typography, Row, Space, theme, notification } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ResetPasswordInterface, resetPasswordAction, i18n, useAppDispatch } from '../../../common';
import PlumLogo from '../../../assets/images/plum_logo.svg';
import ResetPasswordForm from '../../../components/forms/resetPasswordForm/resetPasswordForm.component';
import { AuthRoutes } from '..';

const { useToken } = theme;

const { Title } = Typography;

const ResetPasswordPage = () => {
  const { token } = useToken();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.get('key') || !searchParams.get('email')) {
      navigate(AuthRoutes.LOGIN.fullPath);
    }
  }, [])

  const onResetPassword = (values: any) => {
    if (values.newPassword !== values.repeatNewPassword) {
      notification.error({ message: i18n.translate('resetPassword.msgs.passwordNoMatch'), duration: 2 });
      return;
    }

    const resetPasswordData: ResetPasswordInterface = {
      email: searchParams.get('email')!,
      key: searchParams.get('key')!,
      newPassword: values.newPassword
    };
    dispatch(resetPasswordAction(resetPasswordData, navigate));
  };

  const onResetPasswordFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="resetPassword">
      <Row justify='center'>
        <Row>
          <Space size={16} align='center'>
            <img width={33} height={37} src={PlumLogo} alt="" />
            <Title className='main-title' style={{ color: token.colorPrimary }}>{i18n.translate(`login.top.title`)}</Title>
          </Space>
        </Row>
      </Row>
      <ResetPasswordForm onResetPasswordFormFinish={(values) => onResetPassword(values)} onResetPasswordFormFinishFailed={(errorInfo) => onResetPasswordFailed(errorInfo)}></ResetPasswordForm>
    </div>
  );
}

export default ResetPasswordPage;
