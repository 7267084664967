import { Breadcrumb, Button, Card, Col, Row, Space, Typography } from 'antd';
import React from 'react';
import { i18n, openReservationDrawer, useAppDispatch } from '../../../common';
import { AddIcon, Guard, ReservationCalendar } from '../../../components';

const { Title } = Typography;

const ReservationsPage = () => {
  const dispatch = useAppDispatch();

  // const showDrawer = () => {
  //   setOpen(true);
  // };

  // const onClose = () => {
  //   setOpen(false);
  //   setTrigger(0);
  //   setReservation(undefined);
  // };

  // const onDelete = (id: number) => {
  //   dispatch(deleteReservation(id));
  //   onClose();
  // };

  // const selectReservation = (selectedReservation: ReservationInterface) => {
  //   const processedReservation = { ...selectedReservation };
  //   const categoryWithOptions = categories.find(c => c.id === selectedReservation.reservationCategory?.id);
  //   if (categoryWithOptions) processedReservation.reservationCategory = categoryWithOptions;
  //   setReservation(processedReservation);
  //   showDrawer();
  // };

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: 24 }}
        items={[{ title: i18n.t('breadcrumb.homepage') }, { title: i18n.t('breadcrumb.reservations') }]}
      />
      <Card>
        <Space direction="vertical" size={16} style={{ display: 'flex' }}>
          <Row justify={'space-between'} align={'middle'}>
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                {i18n.translate('pages.reservations.title')}
              </Title>
            </Col>
            <Col>
              <Guard permission={{ domain: 'reservation', action: 'create' }}>
                <Button
                  type="primary"
                  icon={<AddIcon />}
                  onClick={() => {
                    dispatch(openReservationDrawer());
                  }}
                >
                  {i18n.t('components.forms.reservations.create')}
                </Button>
              </Guard>
            </Col>
          </Row>
          <ReservationCalendar />
        </Space>
      </Card>
    </>
  );
};

export default ReservationsPage;
