import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Row, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { Pagination, RootState, i18n, navigate, useAppSelector } from '../../../common';
import { CampaignsTable, allCampaignsTableColumns } from '../../tables';
import { AppRoutes } from '../../../pages/app';
import { ArrowRightIcon } from '../../icons';
import { Guard } from '../../guard';

const { Title } = Typography;

export default function DashboardCampaigns() {
  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  const pagination: Pagination = {
    current: 1,
    pageSize: 5,
    show: false
  };

  const renderMyCampaigns = (): JSX.Element => (
    <CampaignsTable
      columns={allCampaignsTableColumns.filter((c) => c !== 'users')}
      injectedQueryParams={{
        filters: [{ name: 'salesOpportunities.users.id', operator: 'EQ', value: currentUser!.id!.toString() }],
        pagination: { current: 1, pageSize: 5 }
      }}
      pagination={pagination}
      filters={[]}
    ></CampaignsTable>
  );

  const renderContent = (): JSX.Element =>
    renderMyCampaigns();

  return (
    <Card bodyStyle={{ padding: 24 }}>
      <Row justify={'space-between'} align={'middle'} style={{ marginBottom: 16 }}>
        <Title level={2} style={{ margin: 0 }}>{i18n.translate('pages.campaigns.title')}</Title>
        <Guard permission={{ domain: 'campaign', action: 'create' }}>
          <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate(`${AppRoutes.CAMPAIGNS.fullPath}/new`)}>
            {i18n.translate('components.forms.campaign.create')}
          </Button>
        </Guard>
      </Row>
      {renderContent()}
      <Row justify={'center'}>
        <Link to={AppRoutes.CAMPAIGNS.fullPath}>
          <div style={{ display: 'flex' }}><span style={{ marginRight: 8 }}>{i18n.t('pages.dashboard.seeAll')}</span> <ArrowRightIcon /></div>
        </Link>
      </Row>
    </Card>
  );
}