import { LogoutOutlined, ProfileOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Layout, MenuProps, Row, Space, Typography, Avatar, theme } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState, i18n, logoutAction, useAppDispatch, useAppSelector } from '../../../common';
import { AppRoutes } from '../../../pages/app';
import { SearchIcon, SettingsIcon, UserIcon } from '../../icons';
import { Notifications, HeaderSearch } from '../../other';
import { GuardFunction } from '../../guard';

const { useToken } = theme;

const { Header } = Layout;
const { Text } = Typography;

export default function HeaderComponent() {
  const { token } = useToken();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const userAuth = useAppSelector((state: RootState) => state.auth);

  function onLogOutBtnClick() {
    dispatch(logoutAction(navigate));
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: <a onClick={() => navigate(AppRoutes.PROFILE.path)}>{i18n.translate(`header.menu.profile`)}</a>,
      icon: <ProfileOutlined />,
    },
    GuardFunction({ domain: 'application_settings', action: 'view_one' }) ? {
      key: '2',
      label: <a onClick={() => navigate(AppRoutes.APPLICATION_SETTINGS.path)}>{i18n.translate(`header.menu.applicationSettings`)}</a>,
      icon: <SettingsIcon />,
    } : null,
    {
      key: '3',
      danger: true,
      label: <a onClick={onLogOutBtnClick}>{i18n.translate(`pages.dashboard.logoutBtn`)}</a>,
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <Header
      className="header"
      style={{
        position: 'sticky',
        top: 0,
        zIndex: 4,
        width: '100%',
        backgroundColor: token.colorBgContainer,
        boxShadow: token.boxShadowTertiary
      }}>
      <Row justify='space-between' wrap={false}>
        <Col style={{display: 'flex', alignItems: 'center'}} xs={16} md={8}>
          <Space.Compact block style={{display: 'flex', alignItems: 'center'}}>
            <span style={{fontSize: 16}}>
              <SearchIcon />
            </span>
            <HeaderSearch />
          </Space.Compact>
        </Col>
        <Col>
          <Space size={token.sizeLG}>
            <Notifications />
            <Space size='small'>
              <Dropdown menu={{ items }} trigger={['click']}>
                <Button style={{ margin: 0, padding: 0 }} onClick={(e) => e.preventDefault()} type="default" shape="circle" icon={userAuth.user?.profileImageUrl ? <Avatar src={userAuth.user?.profileImageUrl} /> : <Avatar icon={<UserIcon />} />} />
              </Dropdown>
              <Text className='fullName'>{`${userAuth.user?.firstName} ${userAuth.user?.lastName}`}</Text>
            </Space>
          </Space>
        </Col>
      </Row>
    </Header>
  );
}
