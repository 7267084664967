import { CheckCircleFilled, CloseOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Drawer,
  Modal,
  Row,
  Space,
  Tabs,
  TabsProps,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { CampaignInterface, ClientInterface, RootState, getCampaign, i18n, useAppDispatch, useAppSelector, addClientsToCampaign, navigate, SalesOpportunityInterface, getSalesOpportunities } from '../../../common';
import { AddClientsToCampaignTable, AddIcon, CampaignDetails, CampaignStatisticsBlock, Guard, GuardFunction, SalesOpportunitiesTable, allSalesOpportunitiesTableColumns, allSalesOpportunitiesTableFilters } from '../../../components';
import { AppRoutes } from '../_router/app.routes';
import { colors } from '../../../theme';

const { Title } = Typography;
const { confirm } = Modal;

const CampaignPage = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [addClientsDrawerOpen, setAddClientsDrawerOpen] = useState<boolean>(false);

  const [selectedClients, setSelectedClients] = useState<ClientInterface[]>([]);

  const params = useParams();

  const handleCloseChange = () => {
    setSelectedClients([]);
    setAddClientsDrawerOpen(false);
  };

  const handleOpenChange = () => {
    setAddClientsDrawerOpen(true);
  };

  const onAddClientsSelectionChanged = (clients: ClientInterface[]) => {
    setSelectedClients(clients);
  };

  const showAddClients = () => {
    confirm({
      title: i18n.t('pages.campaigns.modal.title'),
      content: i18n.t('pages.campaigns.modal.content'),
      icon: <CheckCircleFilled style={{ color: colors.lightGreen }} />,
      okText: i18n.t('pages.campaigns.modal.buttons.ok'),
      cancelText: i18n.t('pages.campaigns.modal.buttons.cancel'),
      closeIcon: <CloseOutlined />,
      onOk() {
        handleOpenChange();
      },
      onCancel() {
        navigate(-2);
      },
    });
  };

  useEffect(() => {
    if (params.campaign_id) {
      dispatch(getCampaign(parseInt(params.campaign_id!, 10), { includes: ['campaignStatus', 'files', 'salesOpportunityStatisticsFromView', 'topAgents'] }));
      dispatch(getSalesOpportunities({ includes: ['client', 'latestStatus'], filters: [{ name: 'campaign.id', operator: 'EQ', value: params.campaign_id!.toString() }] }));
    }
  }, [params]
  );

  useEffect(() => {
    if (location.state?.tab) showAddClients();
  }, [location.state?.tab]
  );

  const currentUserId = useAppSelector((state: RootState) => state.auth.user?.id);
  const campaign: CampaignInterface | undefined = useAppSelector((state: RootState) => state.campaign.one);
  const salesOpportunities = useAppSelector((state: RootState) => state.salesOpportunity.all.data);
  const salesOpportunitiesTotal = useAppSelector((state: RootState) => state.salesOpportunity.all.total);

  const excludedClientIds = salesOpportunities?.map((so: SalesOpportunityInterface) => so.client?.id);

  if (!campaign || !campaign.id) return null;

  if (!(GuardFunction({ domain: 'campaign', action: 'view_one_any' }) || campaign.allUsers?.find(u => u.id === currentUserId))) navigate(AppRoutes.DASHBOARD.fullPath);

  const salesFilters = [
    { name: 'campaign.id', operator: 'EQ', value: campaign!.id!.toString() },
    { name: 'users.id', operator: 'EQ', value: currentUserId!.toString() },
  ];
  const salesFiltersAll = [{ name: 'campaign.id', operator: 'EQ', value: campaign!.id!.toString() }];

  const onAddClientsSelectionFinish = () => {
    dispatch(addClientsToCampaign(campaign, selectedClients, { filters: GuardFunction({ domain: 'campaign', action: 'view_all' }) ? salesFiltersAll : salesFilters, includes: ['salesOpportunitySalesOpportunityStatuses', 'latestStatus', 'client', 'users'], sorters: [{ columnKey: 'id', order: 'descend' }] }));
    handleCloseChange();
  };

  const renderSalesOpportunitiesTab = (): JSX.Element =>
    <Space direction="vertical" size={16} style={{ display: 'flex' }}>
      <Row justify={'space-between'} align={'middle'}>
        <Col>
          <Title level={2} style={{ margin: 0 }}>
            {i18n.t('pages.salesOpportunities.clients', { total: salesOpportunitiesTotal })}
          </Title>
        </Col>
        <Col>
          <Guard permission={{ domain: 'campaign', action: 'create' }}>
            <Button onClick={handleOpenChange} type="primary" icon={<AddIcon />}>
              {i18n.t('pages.campaigns.addClient')}
            </Button>
          </Guard>
        </Col>
      </Row>
      <SalesOpportunitiesTable injectedQueryParams={{ filters: GuardFunction({ domain: 'campaign', action: 'view_all_any' }) ? salesFiltersAll : salesFilters, sorters: [{ columnKey: 'id', order: 'descend' }] }} columns={allSalesOpportunitiesTableColumns.filter((c) => c !== 'campaign' && c !== 'actions')} filters={allSalesOpportunitiesTableFilters.filter((f) => (f !== 'campaign'))}></SalesOpportunitiesTable>
    </Space>

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.t('pages.campaigns.tabs.details'),
      children: <CampaignDetails campaign={campaign} />,
    },
    {
      key: '2',
      label: i18n.t('pages.campaigns.tabs.statistics'),
      children: <CampaignStatisticsBlock campaign={campaign} />,
    },
    {
      key: '3',
      label: i18n.t('pages.campaigns.tabs.clients'),
      children: renderSalesOpportunitiesTab(),
    },
  ];

  return (
    <>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to={AppRoutes.DASHBOARD.fullPath} key={AppRoutes.DASHBOARD.fullPath}>
                {i18n.t('breadcrumb.homepage')}
              </Link>
            ),
          },
          {
            title: (
              <Link to={AppRoutes.CAMPAIGNS.fullPath} key={AppRoutes.CAMPAIGNS.fullPath}>
                {i18n.t('breadcrumb.campaigns')}
              </Link>
            ),
          },
          { title: campaign.name },
        ]}
        style={{ marginBottom: 24 }}
      />
      <Card>
        <Space direction="vertical" size={16} style={{ display: 'flex' }}>
          <Title level={2} style={{ margin: 0 }}>{`#${campaign.id} - ${campaign.name}`}</Title>
          <Tabs items={items} activeKey={location.state?.tab} />
        </Space>
      </Card>
      <Drawer
        title={i18n.translate('pages.campaigns.drawer.title')}
        onClose={handleCloseChange}
        open={addClientsDrawerOpen}
        destroyOnClose
        width={750}
        extra={
          <Space>
            <Button onClick={handleCloseChange}>{i18n.translate('pages.campaigns.drawer.buttons.cancel')}</Button>
            <Button onClick={() => onAddClientsSelectionFinish()} type="primary">
              {i18n.translate('pages.campaigns.drawer.buttons.ok')}
            </Button>
          </Space>
        }
      >
        <AddClientsToCampaignTable onAddClientsSelectionChanged={onAddClientsSelectionChanged} excludedClientIds={excludedClientIds}></AddClientsToCampaignTable>
      </Drawer>
    </>
  );
};

export default CampaignPage;
