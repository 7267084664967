import { Avatar, Button, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  AssignmentInterface,
  DefaultPagination,
  DisplayDateFormat,
  Pagination,
  QueryParams,
  RootState,
  getAllAssignments,
  getAssignmentStatusTag,
  getPriorityTag,
  i18n,
  useAppDispatch,
  useAppSelector,
} from '../../common';
import { AssignmentStatusFilter, AssignmentTypeFilter, PriorityTypeFilter, Search, UserFilter } from '../filters';
import { AssignmentDrawer } from '../other';import { UserIcon } from '../icons';
import Table, { TableFilter, TableParams, TableSearch } from './table.component';

interface Props {
  columns?: Array<AssignmentsTableColumnType>;
  filters?: Array<AssignmentsTableFilterType>;
  injectedQueryParams?: QueryParams;
  pagination?: Pagination;
  isDrawerOpen?: boolean;
  closeDrawer?: () => void;
}

type AssignmentsTableColumnType =
  | 'id'
  | 'name'
  | 'assignmentType'
  | 'deadlineDate'
  | 'priorityType'
  | 'user'
  | 'assignmentStatus';

type AssignmentsTableFilterType = 'priorityType' | 'assignmentStatus' | 'assignmentType' | 'user' | 'search';

export const allAssignmentsTableColumns: Array<AssignmentsTableColumnType> = [
  'id',
  'name',
  'assignmentType',
  'deadlineDate',
  'priorityType',
  'user',
  'assignmentStatus',
];

export const allAssignmentsTableFilters: Array<AssignmentsTableFilterType> = ['priorityType', 'assignmentStatus', 'assignmentType', 'user', 'search'];

const AssignmentsTable = ({ columns, filters, injectedQueryParams, pagination, isDrawerOpen, closeDrawer }: Props) => {
  const dispatch = useAppDispatch();

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [selectedAssignment, setSelectedAssignment] = useState<AssignmentInterface | undefined>(undefined);

  useEffect(() => {
    setDrawerOpen(!!isDrawerOpen);
  }, [isDrawerOpen]);

  const selectAssignment = (selected: AssignmentInterface) => {
    setSelectedAssignment(selected);
  };

  const onCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedAssignment(undefined);
    return closeDrawer && closeDrawer();
  };

  const componentQueryParams: QueryParams = {
    ...injectedQueryParams,
    includes: [...(injectedQueryParams?.includes || []), 'assignmentStatus', 'priorityType', 'assignmentType', 'user'],
    sorters: [
      {columnKey: 'id', order: 'descend', field: 'id'}
    ]
  };
  const [queryParams, setQueryParams] = useState<QueryParams>(componentQueryParams);

  useEffect(() => {
    dispatch(getAllAssignments(queryParams));
  }, [queryParams]);

  const assignments = useAppSelector((state: RootState) => state.assignments.all);

  if (!assignments) return null;

  const tableColumns: Array<AssignmentsTableColumnType> = columns || allAssignmentsTableColumns;

  const assignmentTableColumns: ColumnsType<AssignmentInterface> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (dataIntex: string, a: AssignmentInterface) => (
        <Button
          type="link"
          onClick={() => {
            selectAssignment(a);
          }}
        >
          #{a.id}
        </Button>
      ),
    },
    {
      title: i18n.translate('components.table.assignmentsTable.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: i18n.translate('components.table.assignmentsTable.type'),
      dataIndex: 'assignmentType',
      key: 'assignmentType',
      render: (dataIndex: string, assignment: AssignmentInterface) => assignment?.assignmentType?.name,
      sorter: true,
    },
    {
      title: i18n.translate('components.table.assignmentsTable.deadline'),
      dataIndex: 'deadlineDate',
      key: 'deadlineDate',
      render: (dataIndex: string, assignment: AssignmentInterface) =>
        `${dayjs(assignment.deadlineDate).format(DisplayDateFormat)}`,
      sorter: true,
    },
    {
      title: i18n.translate('components.table.assignmentsTable.priority'),
      key: 'priorityType',
      dataIndex: 'priorityType',
      sorter: true,
      render: (dataIndex: string, a: AssignmentInterface) =>
        a.priorityType && getPriorityTag(parseInt(a.priorityType.id!, 10)),
    },
    {
      title: i18n.translate('components.table.assignmentsTable.agent'),
      key: 'user',
      dataIndex: 'user',
      render: (dataIndex: string, assignment: AssignmentInterface) => (
        <Tooltip key={assignment.user?.id} title={assignment.user?.fullName} placement="top">
          {assignment.user?.profileImageUrl ? (
            <Avatar src={assignment.user.profileImageUrl} />
          ) : (
            <Avatar icon={<UserIcon />} />
          )}
        </Tooltip>
      ),
    },
    {
      title: i18n.translate('components.table.assignmentsTable.status'),
      key: 'assignmentStatus',
      dataIndex: 'assignmentStatus',
      render: (dataIndex: string, a: AssignmentInterface) =>
        a.assignmentStatus && getAssignmentStatusTag(parseInt(a.assignmentStatus.id!, 10)),
      sorter: true,
    },
  ].filter((c) => tableColumns.includes(c.key! as AssignmentsTableColumnType));

  // TODO: filter: priorityType, assignmentType, assignmentStatus, user
  interface Filter {
    filterKey: AssignmentsTableFilterType;
    tableFilter: TableFilter;
  }

  const tableFilters: Array<AssignmentsTableFilterType> = filters || allAssignmentsTableFilters;

  const assignmentTableFilters: Array<Filter> = [
    { filterKey: 'priorityType' as AssignmentsTableFilterType, tableFilter: { component: PriorityTypeFilter, key: 'priorityType.id' } },
    { filterKey: 'assignmentType' as AssignmentsTableFilterType, tableFilter: { component: AssignmentTypeFilter, key: 'assignmentType.id' } },
    { filterKey: 'assignmentStatus' as AssignmentsTableFilterType, tableFilter: { component: AssignmentStatusFilter, key: 'assignmentStatus.id' } },
    { filterKey: 'user' as AssignmentsTableFilterType, tableFilter: { component: UserFilter, key: 'user.id' } },
  ].filter((f) => tableFilters.includes(f.filterKey as AssignmentsTableFilterType));

  const searchFields: Array<TableSearch> | undefined = tableFilters.includes('search' as AssignmentsTableFilterType) ? [
    { component: Search, keys: ['name', 'user.fullName'], placeholder: i18n.t('components.table.assignmentsTable.search') },
  ] : undefined

  const handleTableChange = (tableParams: TableParams) => {
    setQueryParams({
      pagination: tableParams.pagination,
      filters: [...(tableParams.filters || []), ...(componentQueryParams?.filters || [])],
      sorters: tableParams.sorters || componentQueryParams.sorters,
      includes: componentQueryParams.includes,
      search: tableParams.search,
    });
  };

  return (
    <>
      <Table
        columns={assignmentTableColumns}
        dataSource={assignments.data}
        rowKey={'id'}
        handleTableChange={handleTableChange}
        paginationDefault={pagination || DefaultPagination}
        total={assignments.total}
        filters={assignmentTableFilters.map((filter: Filter) => filter.tableFilter)}
        searchFields={searchFields}
      />
      <AssignmentDrawer onCloseDrawer={onCloseDrawer} isOpen={!!drawerOpen || !!selectedAssignment} selectedAssignment={selectedAssignment} queryParams={queryParams} />
    </>
    
  );
};

export default AssignmentsTable;
