import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Row, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ActivityStatusEnum, JavaFormat, QueryParams, RootState, getActivities, i18n, openActivityDrawer, useAppDispatch, useAppSelector } from '../../../common';
import { ActivityDrawer } from '../../drawers';
import { AppRoutes } from '../../../pages/app';
import { ArrowRightIcon } from '../../icons';
import { Guard } from '../../guard';
import { ActivityItem } from '../../other';

const { Title, Text } = Typography;

export default function DashboardActivities() {
  const dispatch = useAppDispatch();

  const componentQueryParams: QueryParams = { filters: [{ name: 'activityStatus.id', operator: 'EQ', value: ActivityStatusEnum.PLANNED.toString() }, { name: 'activityDate', operator: 'GT', value: dayjs().startOf('day').format(JavaFormat) }], includes: ['user', 'activityType', 'activityStatus', 'salesOpportunity', 'client'], pagination: { current: 1, pageSize: 5 }, sorters: [{ columnKey: 'activityDate', order: 'ascend' }] };

  useEffect(() => {
    dispatch(getActivities(componentQueryParams));
  }, []
  );

  dayjs.extend(isToday);

  const activities = useAppSelector((state: RootState) => state.activity.all.data);

  const activitiesToday = activities.filter(activity => dayjs(activity.activityDate).isToday());
  const activitiesAfter = activities.filter(activity => dayjs(activity.activityDate).isAfter(dayjs(), 'day'));

  return (
    <>
      <Card bodyStyle={{ padding: 24 }} style={{ height: '100%' }}>
        <Row justify={'space-between'} align={'middle'}>
          <Title level={2} style={{ margin: 0 }}>{i18n.translate('pages.dashboard.plannedActivities')}</Title>
          <Guard permission={{ domain: 'activity', action: 'create' }}>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => { dispatch(openActivityDrawer()) }}>
              {i18n.translate('components.forms.activities.create')}
            </Button>
          </Guard>
        </Row>
        <Space size={16} direction='vertical' style={{ marginTop: 16, width: '100%' }}>
          {activitiesToday.length > 0 ?
            <Text strong key='title' >{i18n.t('pages.dashboard.activitiesToday')}</Text>
            : null}
          {activitiesToday.map((activity) => (
            <ActivityItem key={`activity${activity.id}`} activity={activity} />
          ))}
          {activitiesAfter.length > 0 ?
            <Text strong>{i18n.t('pages.dashboard.activitiesAfter')}</Text>
            : null}
          {activitiesAfter.map((activity) => (
            <ActivityItem key={`activity${activity.id}`} activity={activity} />
          ))}
          {activities.length === 0 ? <Text style={{ display: 'flex', justifyContent: 'center', marginBottom: 32 }}>{i18n.t('pages.dashboard.noActivities')}</Text> : null}
          <Row key='row' justify={'center'}>
            <Link to={AppRoutes.ACTIVITIES.fullPath}>
              <div style={{ display: 'flex' }}><span style={{ marginRight: 8 }}>{i18n.t('pages.dashboard.seeAll')}</span> <ArrowRightIcon /></div>
            </Link>
          </Row>
        </Space>
      </Card>
      <ActivityDrawer queryParams={componentQueryParams} />
    </>
  );
}
