import React, { useEffect } from 'react';
import { DatePicker, Form, Input } from 'antd';
import dayjs from 'dayjs';
import { DisplayDateFormat, ServiceInterface, i18n } from '../../../common';

interface Props {
  trigger: number;
  service?: ServiceInterface;
  onServiceFormFinish?: (values: any) => void;
}

function ServiceForm({ trigger, service, onServiceFormFinish }: Props) {
  const [form] = Form.useForm();

  useEffect(() => {
    if (onServiceFormFinish && trigger > 0) {
      form.submit()
    }
  }, [trigger]);

  const initValues = {
    ...service,
    startDate: dayjs(service?.startDate),
    expirationDate: dayjs(service?.expirationDate),
  }
  
  return (<>
    <Form
      name='service'
      form={form}
      colon={false}
      layout='vertical'
      initialValues={initValues}
      onFinish={onServiceFormFinish}
    >
      <Form.Item
        name="name"
        label={i18n.translate(`components.forms.services.name`)} 
        rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
      >
        <Input size="small" />
      </Form.Item>
      <Form.Item
        name='startDate'
        label={i18n.translate(`components.forms.services.startDate`)}
      >
        <DatePicker format={DisplayDateFormat} allowClear={false} />
      </Form.Item>
      <Form.Item
        name='expirationDate'
        label={i18n.translate(`components.forms.services.expirationDate`)}
      >
        <DatePicker format={DisplayDateFormat} allowClear={false} />
      </Form.Item>
    </Form>
  </>
  );
}

export default ServiceForm;
