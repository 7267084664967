import React from 'react';
import { UploadFile } from 'antd';
import { FileTextTwoTone, CloseOutlined } from '@ant-design/icons';
import { DownloadService } from '../../common';

interface Props {
  file: UploadFile<any>;
  onRemoveFile?: (file: UploadFile<any>) => void;
}

const CustomFileRender = ({ file, onRemoveFile }: Props) => (
  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: onRemoveFile ? 8 : 0 }}>
    <div>
      <FileTextTwoTone style={{ marginRight: 8 }} />
      <a onClick={() => DownloadService.downloadFileDms(file.response && file.response.length ? file.response[0].uuid : file.uid)}>{file.name}</a>
    </div>
    {onRemoveFile && <div>
      <CloseOutlined onClick={() => onRemoveFile && onRemoveFile(file)} />
    </div>}
  </div>
);

export default CustomFileRender;
