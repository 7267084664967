import { Row, Col, Space, Progress, theme, Typography } from 'antd';
import React from 'react';
import { CampaignInterface, SalesOpportunityStatus, SalesOpportunityStatuses, getSalesOpportunityStatusColor } from '../../common';

const { useToken } = theme;

const { Title } = Typography;

interface Props {
  campaign: CampaignInterface;
  selectedUserId?: number;
}

const CampaignStatisticsProgress = ({ campaign, selectedUserId }: Props) => {
  const { token } = useToken();

  const statistics = selectedUserId ? campaign.salesOpportunityStatisticsFromView?.filter((x: any) => x.userId === selectedUserId) : campaign.salesOpportunityStatisticsFromView?.filter((item: any, index: number, array: any) => array.findIndex((item2: any) => (item2.statusId === item.statusId)) === index);

  const getPercentage = (statusId: number) => statistics?.find((x: any) => x.statusId === statusId)?.percentage;

  const getValue = (statusId: number) => selectedUserId ? statistics?.find((x: any) => x.statusId === statusId)?.totalPerStatusForUser : statistics?.find((x: any) => x.statusId === statusId)?.totalPerStatus;

  return <Row justify={'space-between'}>
    <Col span={11}>
      {SalesOpportunityStatuses()?.map((status: SalesOpportunityStatus, index: number) =>
        index % 2 === 0 && (
          <Space size={8} key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginBottom: 24 }}>
            <Title level={4} style={{ margin: 0, color: token.colorTextDescription }}>{status.name}</Title>
            <Progress strokeColor={getSalesOpportunityStatusColor(status.id)} percent={getPercentage(status.id) ? Math.round(getPercentage(status.id)) : 0} style={{ margin: 0 }} />
            <Title level={4} style={{ margin: 0, color: token.colorTextDescription }}>{getValue(status.id) ? getValue(status.id) : 0}</Title>
          </Space>
        )
      )}
    </Col>
    <Col span={11}>
      {SalesOpportunityStatuses()?.map((status: SalesOpportunityStatus, index: number) =>
        index % 2 !== 0 && (
          <Space size={8} key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', marginBottom: 24 }}>
            <Title level={4} style={{ margin: 0, color: token.colorTextDescription }}>{status.name}</Title>
            <Progress strokeColor={getSalesOpportunityStatusColor(status.id)} percent={getPercentage(status.id) ? Math.round(getPercentage(status.id)) : 0} style={{ margin: 0 }} />
            <Title level={4} style={{ margin: 0, color: token.colorTextDescription }}>{getValue(status.id) ? getValue(status.id) : 0}</Title>
          </Space>
        )
      )}
    </Col>
  </Row>
}

export default CampaignStatisticsProgress;
