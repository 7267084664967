import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Avatar, Button, Dropdown, MenuProps, Space, Tooltip } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector, RootState, QueryParams, DefaultPagination, i18n, getClients, ClientInterface, DisplayDateFormat, UserInterface, getActiveShortLabel, getSalesOpportunityStatusName, getClientTypeName } from '../../common';
import { ActiveFilter, AreaFilter, ClientTypeFilter, Search, UserFilter } from '../filters';
import { GuardFunction } from '../guard';
import { AppRoutes } from '../../pages/app';
import { TransferClientsToAgentDrawer } from '../other';
import AddClientsToCampaignsDrawer from '../other/addClientsToCampaignsDrawer.component';
import { UserIcon } from '../icons';
import Table, { TableFilter, TableParams, TableSearch } from './table.component';

type ClientsTableColumnType = 'name' | 'oib' | 'clientType' | 'user' | 'latestClientActivity' | 'activeSalesOpportunity' | 'activeSalesOpportunityStatus' | 'active' | 'actions';
type ClientsTableFilterType = 'clientType' | 'activeSalesOpportunityStatus' | 'area' | 'user' | 'active';

export const allClientsTableColumns: Array<ClientsTableColumnType> = ['name', 'oib', 'clientType', 'user', 'latestClientActivity', 'activeSalesOpportunity', 'activeSalesOpportunityStatus', 'active', 'actions'];
export const allClientsTableFilters: Array<ClientsTableFilterType> = ['clientType', 'activeSalesOpportunityStatus', 'area', 'user', 'active'];

interface Props {
  injectedQueryParams?: QueryParams;
  columns?: Array<ClientsTableColumnType>;
  filters?: Array<ClientsTableFilterType>;
}

const ClientsTable = ({ injectedQueryParams, columns, filters }: Props) => {
  const dispatch = useAppDispatch();

  const componentQueryParams: QueryParams = {
    ...injectedQueryParams,
    includes: [
      ...(injectedQueryParams?.includes || []),
      'clientType',
      'userView',
      'salesOpportunities',
      'salesOpportunities.latestStatus'
    ],
    sorters: [
      { columnKey: 'id', order: 'descend', field: 'id' }
    ]
  };

  const [transferDrawerOpen, setTransferDrawerOpen] = useState<boolean>(false);
  const [addDrawerOpen, setAddDrawerOpen] = useState<boolean>(false);

  const [queryParams, setQueryParams] = useState<QueryParams>(componentQueryParams);
  const [selectedClients, setSelectedClients] = useState<Array<ClientInterface>>([]);

  useEffect(() => {
    dispatch(getClients(queryParams));
  }, [queryParams]
  )

  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  const havePermissionToOpen = (clientUser?: UserInterface): boolean =>
    (GuardFunction({ domain: 'client', action: 'view_one_any' }) || !clientUser || clientUser.id === currentUser!.id!);

  const havePermissionToEdit = (clientUser?: UserInterface): boolean =>
    (GuardFunction({ domain: 'client', action: 'update_any' }) || !clientUser || clientUser.id === currentUser!.id!);

  const clients = useAppSelector((state: RootState) => state.client.all);

  let tableColumns: Array<ClientsTableColumnType> = columns || allClientsTableColumns;

  if (!GuardFunction({ domain: 'client', action: 'view_active' })) tableColumns = tableColumns.filter((c) => c !== 'active');

  const menu = (tableClient: ClientInterface) => {
    const items: MenuProps['items'] = [
      GuardFunction({ domain: 'client', action: 'set_any_user' }) ? {
        key: '1',
        label: <a onClick={() => { setSelectedClients([tableClient]); setTransferDrawerOpen(true) }}>{i18n.translate(`components.table.clientTable.actions.transferClientToAgent`)}</a>,
      } : null,
      GuardFunction({ domain: 'client', action: 'set_any_user' }) ? {
        key: '2',
        label: <a onClick={() => { setSelectedClients([tableClient]); setAddDrawerOpen(true) }}>{i18n.translate(`components.table.clientTable.actions.addClientsToCampaigns`)}</a>,
      } : null,
      havePermissionToEdit(tableClient.user) ? {
        key: '3',
        label: <Link to={`${AppRoutes.CLIENTS.fullPath}/${tableClient.id}/edit`}>{i18n.translate(`components.table.clientTable.actions.edit`)}</Link>,
      } : null
    ];

    return { items };
  }

  const groupActions: Array<{ key: number, label: string, show: boolean, action: () => void }> = [
    {
      key: 1,
      label: i18n.translate(`components.table.clientTable.actions.transferClientToAgent`),
      show: GuardFunction({ domain: 'client', action: 'update_any' }), // TODO korigirati akcije
      action: () => { setTransferDrawerOpen(true) }
    },
    {
      key: 2,
      label: i18n.translate(`components.table.clientTable.actions.addClientsToCampaigns`),
      show: GuardFunction({ domain: 'client', action: 'update_any' }), // TODO korigirati akcije
      action: () => { setAddDrawerOpen(true) }
    },
  ].filter(x => x.show);

  const clientsTableColumns: ColumnsType<ClientInterface> = [
    {
      title: i18n.t('components.table.clientTable.name'),
      dataIndex: 'name',
      key: 'name',
      render: (dataIndex: string, client: ClientInterface) => havePermissionToOpen(client.userView) ? <Link to={`${AppRoutes.CLIENTS.fullPath}/${client.id}`} key={`${AppRoutes.CLIENTS.fullPath}/${client.id}`}>{client.name}</Link> : client.name,
      sorter: true,
      width: 300
    },
    {
      title: i18n.t('components.table.clientTable.oib'),
      dataIndex: 'oib',
      key: 'oib',
      render: (dataIndex: string, client: ClientInterface) => client.oib,
      width: 300
    },
    {
      title: i18n.t('components.table.clientTable.clientType'),
      dataIndex: 'clientType.id',
      key: 'clientType',
      render: (dataIndex: string, client: ClientInterface) => client.clientType && getClientTypeName(client.clientType.id),
      width: 300
    },
    {
      title: i18n.t('components.table.clientTable.user'),
      dataIndex: 'user.fullName',
      key: 'user',
      render: (dataIndex: string, client: ClientInterface) => client.userView && <Tooltip title={client.userView.fullName} placement="top">{client.userView.profileImageUrl ? <Avatar src={client.userView.profileImageUrl} /> : <Avatar icon={<UserIcon />} />}</Tooltip>,
      width: 150
    },
    {
      title: i18n.t('components.table.clientTable.lastActivity'),
      dataIndex: 'transientValues.latestClientActivity',
      key: 'latestClientActivity',
      render: (dataIndex: string, client: ClientInterface) => client.transientValues?.latestClientActivity && dayjs(client.transientValues?.latestClientActivity).format(DisplayDateFormat),
      // sorter: true,
      width: 300
    },
    {
      title: i18n.t('components.table.clientTable.activeSalesOpportunity'),
      dataIndex: 'transientValues.activeSalesOpportunity',
      key: 'activeSalesOpportunity',
      render: (dataIndex: string, client: ClientInterface) => havePermissionToOpen(client.user) ? <Link to={`${AppRoutes.SALES_OPPORTUNITIES.fullPath}/${client.transientValues?.activeSalesOpportunity?.id}`} key={`${AppRoutes.SALES_OPPORTUNITIES.fullPath}/${client.transientValues?.activeSalesOpportunity?.id}`}>{client.transientValues?.activeSalesOpportunity?.name}</Link> : client.transientValues?.activeSalesOpportunity?.name,
      width: 300
    },
    {
      title: i18n.t('components.table.clientTable.activeSalesOpportunityStatus'),
      dataIndex: 'transientValues.activeSalesOpportunityStatus',
      key: 'activeSalesOpportunityStatus',
      render: (dataIndex: string, client: ClientInterface) => client.transientValues?.activeSalesOpportunity && getSalesOpportunityStatusName(client.transientValues.activeSalesOpportunity!.latestStatus.id),
      width: 300
    },
    {
      title: i18n.t('components.table.clientTable.active'),
      dataIndex: 'active',
      key: 'active',
      render: (dataIndex: string, client: ClientInterface) => getActiveShortLabel(client.active!),
      width: 150
    },
    {
      title: i18n.translate(`components.table.clientTable.actions`),
      dataIndex: 'actions',
      key: 'actions',
      render: (dataIndex: string, client: ClientInterface) => (havePermissionToOpen(client.user) ?
        <Dropdown
          menu={menu(client)}
          trigger={['click']}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <Button type="link" icon={<EllipsisOutlined />} />
            </Space>
          </a>
        </Dropdown>
        : null)
    },
  ].filter((c) => tableColumns.includes(c.key! as ClientsTableColumnType));

  interface Filter {
    filterKey: ClientsTableFilterType;
    tableFilter: TableFilter;
  }

  const tableFilters: Array<ClientsTableFilterType> = filters || allClientsTableFilters;

  const clientsTableFilters: Array<Filter> = [
    // { filterKey: 'activeSalesOpportunityStatus' as ClientsTableFilterType, tableFilter: {component: SalesOpportunityStatusFilter, key: 'transientValues.activeSalesOpportunity!.latestStatus.id' }},
    { filterKey: 'clientType' as ClientsTableFilterType, tableFilter: { component: ClientTypeFilter, key: 'clientType.id' } },
    { filterKey: 'area' as ClientsTableFilterType, tableFilter: { component: AreaFilter, key: 'areas.id' } },
    { filterKey: 'user' as ClientsTableFilterType, tableFilter: { component: UserFilter, key: 'user.id', nullOption: true } },
    ...(GuardFunction({ domain: 'client', action: 'view_active' }) ? [{ filterKey: 'active' as ClientsTableFilterType, tableFilter: { component: ActiveFilter, key: 'active' } }] : []),
  ].filter((f) => tableFilters.includes(f.filterKey as ClientsTableFilterType))

  const searchFields: Array<TableSearch> = [
    { component: Search, keys: ['name', 'oib'], placeholder: i18n.t('components.table.clientTable.searchPlaceholder') }
  ]

  // Potrebno prilagoditi funkciju ovisno o tome koje su sve mogućnosti
  const handleTableChange = (tableParams: TableParams) => {
    setQueryParams({
      pagination: tableParams.pagination,
      filters: [
        ...(tableParams.filters || []),
        ...(componentQueryParams?.filters || []),
      ],
      sorters: tableParams.sorters || componentQueryParams.sorters,
      includes: componentQueryParams.includes,
      search: tableParams.search
    })
  };

  const handleGroupAction = (selectedRows: Array<ClientInterface>, selectedGroupAction?: number) => {
    setSelectedClients(selectedRows);
    groupActions?.find(x => x.key === selectedGroupAction)?.action();
  };

  if (!clients) return null;
  return (
    <>
      <Table
        rowKey={"id"}
        groupActions={tableColumns.includes('actions' as ClientsTableColumnType) ? groupActions : undefined}
        handleGroupAction={handleGroupAction}
        columns={clientsTableColumns}
        dataSource={clients.data}
        handleTableChange={handleTableChange}
        paginationDefault={DefaultPagination}
        total={clients.total}
        filters={clientsTableFilters.map((filter: Filter) => filter.tableFilter)}
        searchFields={searchFields}
      />
      <TransferClientsToAgentDrawer isOpen={transferDrawerOpen} selectedClients={selectedClients} onCloseDrawer={() => { setSelectedClients([]); setTransferDrawerOpen(false) }} />
      <AddClientsToCampaignsDrawer isOpen={addDrawerOpen} selectedClients={selectedClients} onCloseDrawer={() => { setSelectedClients([]); setAddDrawerOpen(false) }} />
    </>
  )
}

export default ClientsTable;
