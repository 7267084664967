import React from 'react';
import { Button, Col, Row, Descriptions, Upload, UploadProps, Grid } from 'antd';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { DisplayDateFormat, CampaignInterface, i18n, getActiveLabel } from '../../common';
import { AppRoutes } from '../../pages/app';
import { CustomFileRender } from '../other';
import { EditIcon } from '../icons';
import { GuardFunction } from '../guard';

const { useBreakpoint } = Grid;

interface Props {
  campaign: CampaignInterface;
}

function CampaignDetails({ campaign }: Props) {
  const navigate = useNavigate();

  const { md } = useBreakpoint();

  const props: UploadProps = {
    defaultFileList: campaign!.files?.map((x: { uuid: string; name: string; }) => ({ uid: x.uuid, name: x.name })),
    itemRender: (originNode, file) => (
      <CustomFileRender file={file} />
    ),
  };

  const descriptionsItems: Array<{ label: string; render: JSX.Element } | null> = [
    {
      label: i18n.translate('components.details.campaign.name'),
      render: <>{campaign!.name}</>,
    },
    {
      label: i18n.translate('components.details.campaign.duration'),
      render: <>{`${dayjs(campaign!.beginningDate).format(DisplayDateFormat)} - ${dayjs(campaign!.expirationDate).format(DisplayDateFormat)}`}</>,
    },
    {
      label: i18n.translate('components.details.campaign.value'),
      render: <>{campaign!.value}</>,
    },
    {
      label: i18n.translate('components.details.campaign.documents'),
      render: <><Upload {...props}></Upload></>,
    },
    {
      label: i18n.translate('components.details.campaign.description'),
      render: <>{campaign!.description}</>,
    },
    GuardFunction({ domain: 'campaign', action: 'deactivate' }) ? {
      label: i18n.translate('components.details.campaign.activity'),
      render: <>{getActiveLabel(campaign.active ? campaign.active : false)}</>,
    } : null,
    // {
    //   label: i18n.translate('components.details.campaign.createdBy'),
    //   render: <>{campaign!.allUsers?.find(x => x.id === campaign!.createdBy) ? <Tag color="processing" style={{ borderRadius: 16 }} key={campaign!.allUsers?.find(x => x.id === campaign!.createdBy)!.id} icon={campaign!.allUsers?.find(x => x.id === campaign!.createdBy)!.profileImageUrl ? <Avatar size="small" style={{ marginRight: 4, marginBlock: 2 }} src={campaign!.allUsers?.find(x => x.id === campaign!.createdBy)!.profileImageUrl} /> : <Avatar style={{ marginRight: 4, marginBlock: 2 }} size="small" icon={<UserIcon />} />} />}>{campaign!.allUsers?.find(x => x.id === campaign!.createdBy)!.fullName}</Tag> : null}</>,
    // },
    {
      label: i18n.translate('components.details.campaign.createdAt'),
      render: <>{`${dayjs(campaign!.createdAt).format(DisplayDateFormat)}`}</>,
    },
  ];

  return (
    <Row justify={'space-between'}>
      <Col xs={24} md={20} flex={'auto'}>
        <Descriptions column={1} labelStyle={{ width: '200px', justifyContent: md ? 'flex-end' : 'flex-start', marginRight: 16 }} layout={md ? 'horizontal' : 'vertical'}>
          {descriptionsItems.map((item: { label: string, render: JSX.Element } | null, index: number) => (
            item !== null ? (
              <Descriptions.Item key={index} label={item.label}>
                {item.render}
              </Descriptions.Item>
            ) : null
          ))}
        </Descriptions>
      </Col>
      {GuardFunction({ domain: 'campaign', action: 'update' }) &&
        <Col flex={'none'}>
          <Button
            type="primary"
            icon={<EditIcon />}
            onClick={() => {
              navigate(`${AppRoutes.CAMPAIGNS.fullPath}/${campaign!.id}/edit`);
            }}
          >
            {i18n.translate('buttons.edit')}
          </Button>
        </Col>
      }
    </Row>
  );
}

export default CampaignDetails;
