import React from 'react';
import { Col, Row } from 'antd';
import { AnnouncementInterface, getAnnouncementElement } from '../../common';

interface Props {
  announcement: AnnouncementInterface;
}

const AnnouncementItem = ({ announcement }: Props) => {

  if (!announcement.announcementType || !announcement.announcementAction) return null;

  return <Row style={{ paddingLeft: 8, paddingRight: 8 }}>
    <Col>
      {getAnnouncementElement(announcement)}
    </Col>
  </Row>
}

export default AnnouncementItem;