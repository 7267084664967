import React, { useEffect } from 'react';
import { Breadcrumb, Button, Card, Col, Row, Space, Tabs, TabsProps, Typography } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, getSalesOpportunity, SalesOpportunityInterface, useAppSelector, RootState, i18n, openActivityDrawerAndSetInitialValues } from '../../../common';
import { AppRoutes } from '../_router/app.routes';
import { ActivitiesCalendar, AddIcon, GuardFunction, SalesOpportunityDetails } from '../../../components';

const { Title } = Typography;

const SalesOpportunityPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    if(params.sales_opportunity_id) dispatch(getSalesOpportunity(parseInt(params.sales_opportunity_id!, 10), { includes: ['files', 'users', 'client', 'latestStatus'] }));
  }, [params]
  );

  const salesOpportunity: SalesOpportunityInterface | undefined = useAppSelector((state: RootState) => state.salesOpportunity.one);
  const activitiesTotal = useAppSelector((state: RootState) => state.activity.all.total);
  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  const onTabChange = (activeKey: string) => {
    if (activeKey === '1') dispatch(getSalesOpportunity(parseInt(params.sales_opportunity_id!, 10), { includes: ['files', 'users', 'client', 'latestStatus'] }));
  }

  if (!salesOpportunity || !salesOpportunity.id) return null;

  if (!(GuardFunction({domain: 'sales_opportunity', action: 'view_one_any'}) || salesOpportunity.users?.find(so => so.id === currentUser!.id))) navigate(AppRoutes.DASHBOARD.fullPath);

  const renderActivitiesTab = (): JSX.Element => (
    <Space direction="vertical" size={16} style={{ display: 'flex' }}>
      <Row justify={'space-between'} align={'middle'}>
        <Col>
          <Title level={2} style={{ margin: 0 }}>
            {activitiesTotal} aktivnosti
          </Title>
        </Col>
        <Col>
          <Button
            type="primary"
            icon={<AddIcon />}
            onClick={() => dispatch(openActivityDrawerAndSetInitialValues({ initialValues: [{ formItem: 'client', value: { id: salesOpportunity.client!.id! } }, { formItem: 'salesOpportunity', value: { id: salesOpportunity.id!} }, { formItem: 'salesOpportunityStatus', value: { id: salesOpportunity.latestStatus.id} }], disabled: ['client', 'salesOpportunity'] }))}
          >
            {i18n.t('pages.activities.createNew')}
          </Button>
        </Col>
      </Row>
      <ActivitiesCalendar injectedQueryParams={{ filters: [{ name: 'salesOpportunity.id', operator: 'EQ', value: salesOpportunity!.id!.toString() }], pagination: { current: 1, pageSize: 999999 } }}></ActivitiesCalendar>
    </Space>
  );

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.t('pages.salesOpportunities.tabs.details'),
      children: <SalesOpportunityDetails salesOpportunity={salesOpportunity} />,
    },
    {
      key: '2',
      label: i18n.t('pages.salesOpportunities.tabs.activities'),
      children: renderActivitiesTab(),
    }
  ];

  return (
    <>
      <Breadcrumb items={[{ title: <Link to={AppRoutes.DASHBOARD.fullPath} key={AppRoutes.DASHBOARD.fullPath}>{i18n.t('breadcrumb.homepage')}</Link> }, { title: <Link to={AppRoutes.SALES_OPPORTUNITIES.fullPath} key={AppRoutes.SALES_OPPORTUNITIES.fullPath}>{i18n.t('breadcrumb.salesOpportunities')}</Link>}, { title: salesOpportunity.name }]}  style={{marginBottom: 24}}/>
      <Card>
        <Space direction="vertical" size={16} style={{ display: 'flex' }}>
          <Title level={2} style={{margin: 0}}>{`#${salesOpportunity.id} - ${salesOpportunity.name}`}</Title>
          <Tabs items={items} destroyInactiveTabPane={true} onChange={onTabChange} />
        </Space>
      </Card>
    </>
  );
}

export default SalesOpportunityPage;
