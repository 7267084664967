import { Button, Row, Space, Typography, Upload, UploadFile, UploadProps, notification, theme } from 'antd';
import React, { useState } from 'react';
import { RcFile } from 'antd/es/upload';
import { useAppSelector, RootState, DownloadService, i18n, store, apiRoutes, FileExtensionEnum } from '../../common';

const { Text } = Typography;
const { useToken } = theme;

export default function ImportClients() {  
  const { token } = useToken();

  const userAuth = useAppSelector((state: RootState) => state.auth);

  const onClientsTemplate = () => {
    DownloadService.downloadFile(userAuth, apiRoutes.clientsTemplate, 'import_clients_template', FileExtensionEnum.EXCEL);
  };

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('file', file as RcFile);
    });
    setUploading(true);
    fetch([process.env.REACT_APP_API_URL, apiRoutes.importClients].join('/'), {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${userAuth.accessToken}`,
        Accept: '*/*',
        'X-TenantUUID': store.getState().auth.xTenantUuid
      },
    })
      .then((res) => {
        if (res.ok) {
          setFileList([]);
          notification.success({ message: i18n.translate('components.other.importClients.uploadSuccessMsg')});
        } else {
          notification.error({ message: i18n.translate('components.other.importClients.uploadErrorMsg')});
        }
      })
      .catch(() => {
        notification.error({ message: i18n.translate('components.other.importClients.uploadErrorMsg')});
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const props: UploadProps = {
    accept: FileExtensionEnum.EXCEL,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);

      return false;
    },
    fileList,
  };

  return (
    <>
      <Row justify={'center'}>
        <Text style={{ color: token.colorTextDescription }}>{i18n.translate('components.other.importClients.templateDescription')}</Text>
      </Row>
      <Row className='mt16' justify={'center'}>
        <Button onClick={onClientsTemplate} type="default">
          {i18n.translate('components.other.importClients.templateBtn')}
        </Button>
      </Row>
      <Row className='mt16' justify={'center'}>
        <Text style={{ color: token.colorTextDescription }}>{i18n.translate('components.other.importClients.uploadDescription')}</Text>
      </Row>
      <Row justify={'center'}>
        <Text style={{ color: token.colorTextDescription }}>{i18n.translate('components.other.importClients.importDescription')}</Text>
      </Row>
      <Row className='mt16' justify={'center'}>
        <Space size={16} style={{ display: 'flex', alignItems: 'baseline'}}>
          <Upload {...props}>
            <Button type="primary">
              {i18n.translate('components.other.importClients.uploadBtn')}
            </Button>
          </Upload>
          <Button onClick={handleUpload} disabled={fileList.length === 0} loading={uploading} type="default">
            {i18n.translate('components.other.importClients.importBtn')}
          </Button>
        </Space>
      </Row>
    </>
  );
}
