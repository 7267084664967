import { DatePicker, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import {
  AssignmentInterface,
  AssignmentStatuses,
  AssignmentTypes,
  DisplayDateFormat,
  PriorityTypes,
  RootState,
  getUsers,
  i18n,
  useAppDispatch,
  useAppSelector,
} from '../../../common';

const { TextArea } = Input;

interface Props {
  assignment?: AssignmentInterface;
  onFinish?: (values: any) => void;
  onFinishFail?: (values: any) => void;
  trigger: number;
}

export default function AssignmentForm({ assignment, onFinish, onFinishFail, trigger }: Props) {
  const dispatch = useAppDispatch();

  const users = useAppSelector((state: RootState) => state.user.all);

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getUsers({ pagination: {current: 1, pageSize: 999999} }));
  }, []);

  useEffect(() => {
    if (onFinish && trigger > 0) {
      form.submit();
    }
  }, [trigger]);

  const initialValues = {
    ...assignment,
    priorityType: { id: assignment?.priorityType?.id?.toString() },
    assignmentType: { id: assignment?.assignmentType?.id?.toString() },
    assignmentStatus: { id: assignment?.assignmentStatus?.id?.toString() },
    deadlineDate: dayjs(assignment?.deadlineDate),
  };

  return (
    <Form
      form={form}
      name="assignmentForm"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFail}
      initialValues={initialValues}
      preserve={false}
    >
      <Form.Item
        label={i18n.translate('components.forms.assignment.name')}
        name="name"
        rules={[{ required: true, message: i18n.translate('components.forms.assignment.msgs.nameRequired') }]}
        wrapperCol={{ span: 24 }}
      >
        <Input size="small" />
      </Form.Item>

      <Form.Item
        label={i18n.translate('components.forms.assignment.agent')}
        name={['user', 'id']}
        rules={[{ required: true, message: i18n.translate('components.forms.assignment.msgs.agentRequired') }]}
        wrapperCol={{ span: 12 }}
      >
        <Select>
          {users.data.map((user) => (
            <Select.Option key={`user${user.id}`} value={user.id}>
              {user.fullName}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label={i18n.translate('components.forms.assignment.priority')}
        name={['priorityType', 'id']}
        rules={[{ required: true, message: i18n.translate('components.forms.assignment.msgs.priorityRequired') }]}
        wrapperCol={{ span: 12 }}
      >
        <Select>
          {PriorityTypes().map((priority) => (
            <Select.Option key={`priority${priority.id}`} value={priority.id.toString()}>
              {priority.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label={i18n.translate('components.forms.assignment.type')}
        name={['assignmentType', 'id']}
        rules={[{ required: true, message: i18n.translate('components.forms.assignment.msgs.typeRequired') }]}
        wrapperCol={{ span: 12 }}
      >
        <Select>
          {AssignmentTypes().map((type) => (
            <Select.Option key={`assignmentType${type.id}`} value={type.id.toString()}>
              {type.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label={i18n.translate('components.forms.assignment.deadline')}
        name="deadlineDate"
        rules={[{ required: true, message: i18n.translate('components.forms.assignment.msgs.deadlineRequired') }]}
        wrapperCol={{ span: 11 }}
      >
        <DatePicker
          format={DisplayDateFormat}
          allowClear={false}
          disabledDate={(current) => dayjs().add(-1, 'days') >= current}
        />
      </Form.Item>

      <Form.Item label={i18n.translate('components.forms.assignment.description')} name="description">
        <TextArea autoSize={{ minRows: 3 }} maxLength={5000} />
      </Form.Item>

      {assignment ? (
        <Form.Item label={i18n.translate('components.forms.assignment.status')} name={['assignmentStatus', 'id']}>
          <Select>
            {AssignmentStatuses().map((status) => (
              <Select.Option key={`status${status.id}`} value={status.id.toString()}>
                {status.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      ) : (
        <></>
      )}
    </Form>
  );
}
