import { Typography } from 'antd';
import React from 'react';
import localeData from 'dayjs/plugin/localeData';
import dayjs from 'dayjs';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { MonthlySuccessRateStatisticInterface, i18n } from '../../../common';
import { colors } from '../../../theme';

interface Props {
  statistics: Array<MonthlySuccessRateStatisticInterface>;
  title?: string;
}

const { Title } = Typography;

export default function DashboardMonthlyStats({ statistics, title }: Props) {

  dayjs.extend(localeData);

  const labels = dayjs().localeData().monthsShort();

  const data = statistics?.length ? statistics?.map((statistic: MonthlySuccessRateStatisticInterface) => ({ name: labels[statistic.month - 1], value: statistic.value })) : [{ name: 0, value: 0 }];

  return (
    <div className="monthlyStats">
      <Title level={2}>{title ?? i18n.translate('pages.dashboard.monthlyStats')}</Title>
      <ResponsiveContainer width="100%" height="80%">
        <LineChart margin={{ top: 15, left: 0, right: 0, bottom: 0 }} data={data}>
          <XAxis dataKey="name" padding={{ left: 30, right: 30 }} />
          <YAxis />
          <Tooltip />
          <Line type="linear" dataKey="value" stroke={colors.blue} activeDot={{ r: 8 }} isAnimationActive={false} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
