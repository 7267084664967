import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { ActivityInterface, ActivityResponseInterface, ActivitiesResponseInterface } from '../../interfaces';
import type { AppDispatch } from '../store';
import { JsonApiService, QueryParams } from '../../api';
import { i18n } from '../../services';

// ============ INTERFACE ============

export interface ActivityStateInterface {
  all: {
    data: Array<ActivityInterface>;
    total?: number;
  };
  one?: ActivityInterface;
  error?: Error;
}

// ============ INIT STATE ============

const initialState: ActivityStateInterface = {
  all: { data: [] },
};


// ========= SLICE ==========

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setActivityAllData: (state: ActivityStateInterface, action: PayloadAction<Array<ActivityInterface>>) => {
      state.all.data = action.payload;
    },
    setActivityAllTotal: (state: ActivityStateInterface, action: PayloadAction<number>) => {
      state.all.total = action.payload;
    },
    setActivityOne: (state: ActivityStateInterface, action: PayloadAction<ActivityInterface>) => {
      state.one = action.payload;
    },
    updateActivityAllData: (state: ActivityStateInterface, action: PayloadAction<ActivityInterface>) => {
      const index = state.all.data.findIndex(x => x.id === action.payload.id);
      state.all.data[index] = action.payload;
    },
    createActivityAllData: (state: ActivityStateInterface, action: PayloadAction<ActivityInterface>) => {
      state.all.data.unshift(action.payload);
      if (state.all.total || state.all.total === 0) state.all.total += 1;
    },
    setActivityError: (state: ActivityStateInterface, action: PayloadAction<Error>) => {
      state.error = action.payload;
    }
  }
})

export const { setActivityAllData, setActivityError, setActivityAllTotal, setActivityOne, createActivityAllData, updateActivityAllData } = activitySlice.actions;

export const getActivities = (queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('activity', undefined, queryParams)
    .subscribe({
      next: (response: ActivitiesResponseInterface) => {
        dispatch(setActivityAllData(response.data));
        if (response.meta && (response.meta.totalResourceCount || response.meta.totalResourceCount === 0)) dispatch(setActivityAllTotal(response.meta.totalResourceCount));
      },
      error: (error: Error) => {
        dispatch(setActivityError(error));
        dispatch(setActivityAllTotal(0));
      }
    })
}

export const getActivity = (id: number, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('activity', id, queryParams)
    .subscribe({
      next: (response: ActivityResponseInterface) => {
        dispatch(setActivityOne(response.data));
      },
      error: (error: Error) => {
        dispatch(setActivityError(error));
      }
    })
}

export const createActivity = (data: ActivityInterface, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.post('activity', data, queryParams)
    .subscribe({
      next: (response: any) => {        
        dispatch(setActivityOne(response.data));
        dispatch(createActivityAllData(response.data));
        notification.success({ message: i18n.translate('components.banners.activity.createSuccess'), duration: 2 });
      },
      error: (error: Error) => {
        dispatch(setActivityError(error));
      }
    })
}

export const updateActivity = (id: number, data: ActivityInterface, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.patch('activity', id, data, queryParams)
    .subscribe({
      next: (response: any) => {
        dispatch(setActivityOne(response.data));
        dispatch(updateActivityAllData(response.data));
        notification.success({ message: i18n.translate('components.banners.activity.editSuccess'), duration: 2 });
      },
      error: (error: Error) => {
        dispatch(setActivityError(error));
      }
    })
}

export default activitySlice.reducer;