import React, { useEffect, useState } from "react";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import hrLocale from '@fullcalendar/core/locales/hr';
import gbLocale from '@fullcalendar/core/locales/en-gb';
import { Col, Row, Space } from "antd";
import { ActivityInterface, Filter, QueryParams, RootState, getActivities, getActivityTypeColor, getActivityTypeName, getLanguageLocaleCalendar, i18n, openActivityDrawerAndSetActivity, useAppDispatch, useAppSelector } from "../../common";
import ActivityItem from "../other/activityItem.component";
import { ActivityStatusFilter, ActivityTypeFilter, UserFilter } from "../filters";
import { TableFilter } from "../tables/table.component";
import { ActivityDrawer } from "../drawers";
import './calendar.style.scss';

type ActivitiesCalendarFilterType = 'activityType' | 'user' | 'activityStatus';

export const allActivitiesCalendarFilters: Array<ActivitiesCalendarFilterType> = ['activityType', 'user', 'activityStatus'];

interface Props {
  injectedQueryParams?: QueryParams;
  filters?: Array<ActivitiesCalendarFilterType>;
}

const ActivitiesCalendar = ({ injectedQueryParams, filters }: Props) => {
  const dispatch = useAppDispatch();

  const includesParams: QueryParams = {
    includes: [
      'user',
      'activityType',
      'activityStatus',
      'salesOpportunity',
      'client',
      'oldSalesOpportunityStatus',
      'newSalesOpportunityStatus'
    ]
  }

  const componentQueryParams: QueryParams = {
    ...injectedQueryParams,
    includes: [
      ...(injectedQueryParams?.includes || []),
      ...(includesParams.includes || [])
    ]
  };

  const [queryParams, setQueryParams] = useState<QueryParams>(componentQueryParams);
  const [selectedFilters, setSelectedFilters] = useState<Array<Filter>>([]);

  useEffect(() => {
    dispatch(getActivities(queryParams));
  }, [queryParams]
  )

  interface FilterWrapper {
    filterKey: ActivitiesCalendarFilterType;
    tableFilter: TableFilter;
  }

  const calendarFilters: Array<ActivitiesCalendarFilterType> = filters || allActivitiesCalendarFilters;

  const calendarActivitiesFilters: Array<FilterWrapper> = [
    { filterKey: 'activityType' as ActivitiesCalendarFilterType, tableFilter: { component: ActivityTypeFilter, key: 'activityType.id' } },
    { filterKey: 'user' as ActivitiesCalendarFilterType, tableFilter: { component: UserFilter, key: 'user.id' } },
    { filterKey: 'activityStatus' as ActivitiesCalendarFilterType, tableFilter: { component: ActivityStatusFilter, key: 'activityStatus.id' } },
  ].filter((f) => calendarFilters.includes(f.filterKey as ActivitiesCalendarFilterType));

  const handleTableChange = (cf: Array<Filter>) => {
    setQueryParams({
      filters: [
        ...(cf || []),
        ...(componentQueryParams?.filters || []),
      ],
      includes: componentQueryParams.includes,
      pagination: componentQueryParams.pagination
    })
  };

  const onFilterChange = (filter?: Filter) => {
    let selectedFiltersTmp: Array<Filter> = [...selectedFilters];
    if (filter) {
      if (!selectedFiltersTmp) selectedFiltersTmp = [];
      const findIndex = selectedFiltersTmp.findIndex((f: Filter) => f.name === filter.name);
      if (findIndex > -1) {
        selectedFiltersTmp[findIndex] = filter;
      } else {
        selectedFiltersTmp.push(filter);
      }
    } else {
      selectedFiltersTmp = [];
    }

    setSelectedFilters(selectedFiltersTmp);
    handleTableChange(selectedFiltersTmp);
  }

  const renderFilters = (): React.ReactNode => {
    if (!calendarActivitiesFilters || calendarActivitiesFilters.length === 0) return null;
    return <Space size={16} wrap>
      {`${i18n.t('components.table.filter')}:`}
      {calendarActivitiesFilters!.map((f: FilterWrapper, i: number) => <f.tableFilter.component key={i} dataKey={f.tableFilter.key} handleFilterChange={onFilterChange} />)}
    </Space>
  }

  const onEventClick = (eventInfo: any) => {
    const activity = eventInfo.event.extendedProps;
    dispatch(openActivityDrawerAndSetActivity(activity));
  }

  const renderListEvent = (eventInfo: any) => {
    const activity = eventInfo.event.extendedProps;
    return (
      <ActivityItem activity={activity} key={activity.id} />
    )
  }

  const activities = useAppSelector((state: RootState) => state.activity.all);
  const lang = useAppSelector((state: RootState) => state.auth.lang);

  const locale = getLanguageLocaleCalendar(lang);
  
  if (!activities) return null;
  
  return (
    <>
      <Space direction='vertical' size={32} style={{ display: 'flex' }}>
        <Row justify={'space-between'} align={'middle'}>
          <Col>{renderFilters()}</Col>
        </Row>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
          locales={[hrLocale, gbLocale]}
          locale={locale}
          initialView="dayGridMonth"
          headerToolbar={{
            left: 'dayGridMonth,timeGridWeek,listDay prev',
            center: 'title',
            right: 'next',
          }}
          views={{
            dayGridMonth: {
              fixedWeekCount: false,
            },
            timeGridWeek: {
              allDaySlot: false,
              slotMinTime: '08:00:00',
              slotMaxTime: '22:00:00',
              slotLabelFormat: {
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: false
              },
              nowIndicator: true
            },
            listDay: {
              listDayFormat: false,
              eventContent: renderListEvent
            }
          }}
          events={activities.data.map((activity: ActivityInterface) => ({
            title: getActivityTypeName(activity.activityType!.id!),
            start: activity.activityDate,
            color: getActivityTypeColor(activity.activityType!.id!),
            extendedProps: activity,
          }))}
          navLinks={true}
          eventClick={onEventClick}
          height='auto'
          eventTimeFormat= {{
            hour: '2-digit',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false
          }}
        />
      </Space>
      <ActivityDrawer queryParams={includesParams} />
    </>
  )
}

export default ActivitiesCalendar;
