import { Avatar, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  DefaultPagination,
  DisplayDateFormat,
  QueryParams,
  RootState,
  getOffers,
  i18n,
  useAppDispatch,
  useAppSelector,
  formatNumber
} from '../../common';
import { OfferInterface } from '../../common/interfaces/offer/offer.interface';
import { AppRoutes } from '../../pages/app';
import { Search, UserFilter } from '../filters';
import { UserIcon } from '../icons';
import Table, { TableFilter, TableParams, TableSearch } from './table.component';

type OffersTableColumnType = 'id' | 'name' | 'createdDate' | 'client' | 'agent' | 'total';
type OffersTableFilterType = 'user';

export const allOffersTableColumns: Array<OffersTableColumnType> = ['id', 'name', 'createdDate', 'client', 'agent', 'total'];
export const allOffersTableFilters: Array<OffersTableFilterType> = ['user'];

interface Props {
  injectedQueryParams?: QueryParams;
  columns?: Array<OffersTableColumnType>;
  filters?: Array<OffersTableFilterType>;
}

const OfferTable = ({ injectedQueryParams, columns, filters }: Props) => {
  const dispatch = useAppDispatch();

  const componentQueryParams: QueryParams = {
    ...injectedQueryParams,
    includes: [
      ...(injectedQueryParams?.includes || []),
      'client',
      'user'
    ],
    sorters: [
      {columnKey: 'id', order: 'descend', field: 'id'}
    ]
  };

  const [queryParams, setQueryParams] = useState<QueryParams>(componentQueryParams);

  useEffect(() => {
    dispatch(getOffers(queryParams));
  }, [queryParams]);

  const offers = useAppSelector((state: RootState) => state.offer.all);

  const tableColumns: Array<OffersTableColumnType> = columns || allOffersTableColumns;

  const offerTableColumns: ColumnsType<OfferInterface> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: (dataIndex: string, offer: OfferInterface) => (
        <Link
          to={`${AppRoutes.OFFERS.fullPath}/${offer.id}`}
        >
          #{offer.id}
        </Link>
      ),
    },
    {
      title: i18n.t('components.table.offerTable.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (dataIndex: string, offer: OfferInterface) => (
        <Link
          to={`${AppRoutes.OFFERS.fullPath}/${offer.id}`}
        >
          {offer.name}
        </Link>
      ),
    },
    {
      title: i18n.t('components.table.offerTable.createdDate'),
      dataIndex: 'createdDate',
      key: 'createdDate',
      sorter: true,
      render: (dataIndex: string, offer: OfferInterface) => `${dayjs(offer.createdDate).format(DisplayDateFormat)}`,
    },
    {
      title: i18n.t('components.table.offerTable.client'),
      dataIndex: 'client',
      key: 'client',
      render: (dataIndex: string, offer: OfferInterface) => <Link to={`${AppRoutes.CLIENTS.fullPath}/${offer.client?.id}`} key={`${AppRoutes.CLIENTS.fullPath}/${offer.client?.id}`}>{offer.client?.name}</Link>,
    },
    {
      title: i18n.t('components.table.offerTable.agent'),
      dataIndex: 'agent',
      key: 'agent',
      render: (dataIndex: string, offer: OfferInterface) => offer.user && <Tooltip title={offer.user?.fullName} placement="top">{offer.user.profileImageUrl ? <Avatar src={offer.user?.profileImageUrl} /> : <Avatar icon={<UserIcon />} />}</Tooltip>,
    },
    {
      title: i18n.t('components.table.offerTable.total'),
      dataIndex: 'total',
      key: 'total',
      sorter: true,
      render: (dataIndex: string, offer: OfferInterface) => `${formatNumber(offer.total, '€')}`,
    },
  ].filter((c) => tableColumns.includes(c.key! as OffersTableColumnType));

  interface Filter {
    filterKey: OffersTableFilterType;
    tableFilter: TableFilter;
  }

  const tableFilters: Array<OffersTableFilterType> = filters || allOffersTableFilters;

  const offersTableFilters: Array<Filter> = [
    { filterKey: 'user' as OffersTableFilterType, tableFilter: {component: UserFilter, key: 'user.id' }},
  ].filter((f) => tableFilters.includes(f.filterKey as OffersTableFilterType))

  const searchFields: Array<TableSearch> = [
    {
      component: Search,
      keys: ['name', 'user.fullName', 'client.name'],
      placeholder: i18n.translate('components.table.offerTable.search'),
    },
  ];

  // Potrebno prilagoditi funkciju ovisno o tome koje su sve mogućnosti
  const handleTableChange = (tableParams: TableParams) => {
    setQueryParams({
      pagination: tableParams.pagination,
      filters: [
        ...(tableParams.filters || []),
        ...(componentQueryParams?.filters || []),
      ],
      sorters: tableParams.sorters || componentQueryParams.sorters,
      includes: componentQueryParams.includes,
      search: tableParams.search
    })
  };

  if (!offers) return null;
  return (
    <Table
      columns={offerTableColumns}
      dataSource={offers.data}
      rowKey={'id'}
      handleTableChange={handleTableChange}
      paginationDefault={DefaultPagination}
      total={offers.total}
      filters={offersTableFilters.map((filter: Filter) => filter.tableFilter)}
      searchFields={searchFields}
    />
  );
};

export default OfferTable;
