import { Input } from "antd";
import React from 'react';
import { SearchIcon } from '../../icons';
import { SearchProps } from '../../tables/table.component';

const Search = ({ dataKeys, placeholder, handleSearchChange }: SearchProps): JSX.Element => {

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleSearchChange(dataKeys, e.target.value);
  }

  return (
    <Input
      placeholder={placeholder}
      prefix={<SearchIcon />}
      onChange={onSearchChange}
      style={{ maxWidth: 300 }}
    />
  )
}

export default Search;
