export const ROOT_PATH_APP = '';

export const AppRoutes = {
  DASHBOARD: { path: 'dashboard', fullPath: `${ROOT_PATH_APP}/` },
  USERS: { path: 'users', fullPath: `${ROOT_PATH_APP}/users` },
  TEST: { path: 'test', fullPath: `${ROOT_PATH_APP}/test` },
  CLIENTS: { path: 'clients', fullPath: `${ROOT_PATH_APP}/clients` },
  SALES_OPPORTUNITIES: { path: 'sales-opportunities', fullPath: `${ROOT_PATH_APP}/sales-opportunities` },
  CAMPAIGNS: { path: 'campaigns', fullPath: `${ROOT_PATH_APP}/campaigns` },
  ACTIVITIES: { path: 'activities', fullPath: `${ROOT_PATH_APP}/activities` },
  OFFERS: { path: 'offers', fullPath: `${ROOT_PATH_APP}/offers` },
  RESERVATIONS: { path: 'reservations', fullPath: `${ROOT_PATH_APP}/reservations` },
  ASSIGNMENTS: { path: 'assignments', fullPath: `${ROOT_PATH_APP}/assignments` },
  APPLICATION_SETTINGS: { path: 'application-settings', fullPath: `${ROOT_PATH_APP}/application-settings` },
  PROFILE: { path: 'profile', fullPath: `${ROOT_PATH_APP}/profile` },
  PROFILE_EDIT: { path: 'profile-edit', fullPath: `${ROOT_PATH_APP}/profile-edit` },
  NOTIFICATIONS: { path: 'notifications', fullPath: `${ROOT_PATH_APP}/notifications` },
};
