import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { Avatar, Progress, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector, RootState, QueryParams, DefaultPagination, i18n, getCampaigns, CampaignInterface, DisplayDateFormat, getCampaignStatusTag, Pagination, SalesOpportunityInterface, SalesOpportunityStatusEnum } from '../../common';
import { CampaignStatusFilter, Search, UserFilter } from '../filters';
import { AppRoutes } from '../../pages/app';
import { colors } from '../../theme';
import { UserIcon } from '../icons';
import Table, { TableFilter, TableParams, TableSearch } from './table.component';

interface Props {
  injectedQueryParams?: QueryParams;
  columns?: Array<CampaignsTableColumnType>;
  filters?: Array<CampaignsTableFilterType>;
  pagination?: Pagination;
}

type CampaignsTableColumnType = 'id' | 'name' | 'duration' | 'users' | 'numberOfClients' | 'processed' | 'status';
type CampaignsTableFilterType = 'status' | 'user' | 'search';

export const allCampaignsTableColumns: Array<CampaignsTableColumnType> = ['id', 'name', 'duration', 'users', 'numberOfClients', 'processed', 'status'];
export const allCampaignsTableFilters: Array<CampaignsTableFilterType> = ['status', 'user', 'search'];

const CampaignsTable = ({ injectedQueryParams, columns, filters, pagination}: Props) => {
  const dispatch = useAppDispatch();

  const componentQueryParams: QueryParams = {
    ...injectedQueryParams,
    includes: [
      ...(injectedQueryParams?.includes || []),
      'salesOpportunities',
      'salesOpportunities.latestStatus',
      'campaignStatus'
    ],
    sorters: [
      {columnKey: 'id', order: 'descend', field: 'id'}
    ]
  };

  const [queryParams, setQueryParams] = useState<QueryParams>(componentQueryParams);

  useEffect(() => {
    dispatch(getCampaigns(queryParams));
  }, [queryParams]
  )

  const campaigns = useAppSelector((state: RootState) => state.campaign.all);

  if (!campaigns) return null;

  const tableColumns: Array<CampaignsTableColumnType> = columns || allCampaignsTableColumns;

  const campaignsTableColumns: ColumnsType<CampaignInterface> = [
    {
      title: i18n.t('components.table.campaignTable.id'),
      dataIndex: 'id',
      key: 'id',
      render: (dataIndex: string, campaign: CampaignInterface) =>
        <Link to={`${AppRoutes.CAMPAIGNS.fullPath}/${campaign.id}`} key={`${AppRoutes.CAMPAIGNS.fullPath}/${campaign.id}`}>
          {`#${campaign.id}`}
        </Link>,
      sorter: true,
      width: 100
    },
    {
      title: i18n.t('components.table.campaignTable.name'),
      dataIndex: 'name',
      key: 'name',
      render: (dataIndex: string, campaign: CampaignInterface) =>
        <Link to={`${AppRoutes.CAMPAIGNS.fullPath}/${campaign.id}`} key={`${AppRoutes.CAMPAIGNS.fullPath}/${campaign.id}`}>
          {campaign.name}
        </Link>,
      sorter: true,
      width: 300
    },
    {
      title: i18n.t('components.table.campaignTable.duration'),
      dataIndex: 'beginningDate',
      key: 'duration',
      render: (dataIndex: string, campaign: CampaignInterface) => `${dayjs(campaign.beginningDate).format(DisplayDateFormat)} - ${dayjs(campaign.expirationDate).format(DisplayDateFormat)}`,
      // sorter: true,
      width: 300
    },
    {
      title: i18n.t('components.table.campaignTable.users'),
      dataIndex: 'users',
      key: 'users',
      render: (dataIndex: string, campaign: CampaignInterface) => campaign.allUsers && <Avatar.Group maxCount={2} maxStyle={{ color: colors.blue, backgroundColor: colors.blueBackground }}>{campaign.allUsers.map(user => <Tooltip key={user.id} title={user.fullName} placement="top">{user.profileImageUrl ? <Avatar src={user.profileImageUrl} /> : <Avatar icon={<UserIcon />} />}</Tooltip>)}</Avatar.Group>,
      width: 150
    },
    {
      title: i18n.t('components.table.campaignTable.numberOfClients'),
      dataIndex: 'campaignStatistics.campaignStatisticsTableView.numberOfClients',
      key: 'numberOfClients',
      render: (dataIndex: string, campaign: CampaignInterface) => campaign.salesOpportunities?.length,
      width: 150
    },
    {
      title: i18n.t('components.table.campaignTable.processed'),
      dataIndex: 'campaignStatistics.salesOpportunityStatusStatistics.salesOpportunityStatusCounts',
      key: 'processed',
      render: (dataIndex: string, campaign: CampaignInterface) => campaign.salesOpportunities && <Progress percent={Math.round((campaign.salesOpportunities!.filter((x: SalesOpportunityInterface) => x.latestStatus?.id !== SalesOpportunityStatusEnum.CREATED)!.length / campaign.salesOpportunities!.length)  * 100)} size="small" />,
      width: 150
    },
    {
      title: i18n.t('components.table.campaignTable.status'),
      dataIndex: 'campaignStatus.id',
      key: 'status',
      render: (dataIndex: string, campaign: CampaignInterface) => getCampaignStatusTag(campaign.campaignStatus?.id),
      width: 150
    },
  ].filter((c) => tableColumns.includes(c.key! as CampaignsTableColumnType));

  interface Filter {
    filterKey: CampaignsTableFilterType;
    tableFilter: TableFilter;
  }

  const tableFilters: Array<CampaignsTableFilterType> = filters || allCampaignsTableFilters;

  const campaignsTableFilters: Array<Filter> = [
    { filterKey: 'status' as CampaignsTableFilterType, tableFilter: {component: CampaignStatusFilter, key: 'campaignStatus.id'} },
    { filterKey: 'user' as CampaignsTableFilterType, tableFilter: { component: UserFilter, key: 'salesOpportunities.users.id' } },
  ].filter((f) => tableFilters.includes(f.filterKey as CampaignsTableFilterType))


  const searchFields: Array<TableSearch> | undefined = tableFilters.includes('search' as CampaignsTableFilterType) ? [
    { component: Search, keys: ['name'], placeholder: i18n.t('components.table.campaignTable.searchPlaceholder') }
  ] : undefined

  // Potrebno prilagoditi funkciju ovisno o tome koje su sve mogućnosti
  const handleTableChange = (tableParams: TableParams) => {
    setQueryParams({
      pagination: tableParams.pagination,
      filters:
        [
          ...(tableParams.filters || []),
          ...(componentQueryParams?.filters || []),
        ],
      sorters: tableParams.sorters || componentQueryParams.sorters,
      includes: componentQueryParams.includes,
      search: tableParams.search
    })
  };

  return (
    <Table
      columns={campaignsTableColumns}
      dataSource={campaigns.data}
      rowKey={"id"}
      handleTableChange={handleTableChange}
      paginationDefault={pagination || DefaultPagination}
      total={campaigns.total}
      filters={campaignsTableFilters.map((filter: Filter) => filter.tableFilter)}
      searchFields={ searchFields}
    />
  )
}

export default CampaignsTable;
