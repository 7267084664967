import { AssignmentTypeEnum } from "../enums";
import { i18n } from "../services";

export interface AssignmentType {
  id: number;
  name: string;
}

export const AssignmentTypes = (): Array<AssignmentType> => [
  { id: AssignmentTypeEnum.GENERAL, name: i18n.translate("common.constants.assignmentType.general") },
  { id: AssignmentTypeEnum.COMPLAINT, name: i18n.translate("common.constants.assignmentType.complaint") },
  { id: AssignmentTypeEnum.INCIDENT, name: i18n.translate("common.constants.assignmentType.incident") },
]
