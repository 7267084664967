import { colors } from "../../theme";
import { SalesOpportunityStatusEnum } from "../enums";
import { i18n } from "../services";

export interface SalesOpportunityStatus {
  id: number;
  name: string;
  color: string;
}

export const SalesOpportunityStatuses = (): Array<SalesOpportunityStatus> => [
  { id: SalesOpportunityStatusEnum.CREATED, name: i18n.translate("common.constants.salesOpportunity.created"), color: colors.blue },
  { id: SalesOpportunityStatusEnum.CONTACT, name: i18n.translate("common.constants.salesOpportunity.contact"), color: colors.blueDark },
  { id: SalesOpportunityStatusEnum.OFFER, name: i18n.translate("common.constants.salesOpportunity.offer"), color: colors.orange },
  { id: SalesOpportunityStatusEnum.MEETING, name: i18n.translate("common.constants.salesOpportunity.meeting"), color: colors.purple },
  { id: SalesOpportunityStatusEnum.ACCEPTED, name: i18n.translate("common.constants.salesOpportunity.accepted"), color: colors.green },
  { id: SalesOpportunityStatusEnum.CANCELED, name: i18n.translate("common.constants.salesOpportunity.canceled"), color: colors.red },
  { id: SalesOpportunityStatusEnum.OPPORTUNITY, name: i18n.translate("common.constants.salesOpportunity.opportunity"), color: colors.grey },
]

export const getSalesOpportunityStatusName = (id: number): string => 
  SalesOpportunityStatuses().find((sos: SalesOpportunityStatus) => sos.id === id) ? SalesOpportunityStatuses().find((sos: SalesOpportunityStatus) => sos.id === id)!.name : "";

export const getSalesOpportunityStatusColor = (id: number): string => 
  SalesOpportunityStatuses().find((sos: SalesOpportunityStatus) => sos.id === id)!.color