import React from 'react';
import dayjs from 'dayjs';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { ActivityInterface, DisplayDateFormat, DisplayFullFormat, DisplayTimeShortFormat, getActivityTypeAction, getActivityTypeAvatar, i18n } from '../../common';
import { AppRoutes } from '../../pages/app';
import { colors } from '../../theme';

interface Props {
  activity: ActivityInterface;
}

const ActivityItem = ({ activity }: Props) => {

  if (!activity.activityType || !activity.activityStatus) return null;

  const activityText = (): JSX.Element => {
    const textParts: Array<JSX.Element> = [];

    const userText = <Link to={`${AppRoutes.USERS.fullPath}/${activity?.user?.id}}`}>{activity?.user?.fullName}</Link>;
    textParts.push(userText);
    
    const actionText = getActivityTypeAction(activity.activityType!.id!, activity.activityStatus!.id!);
    textParts.push(actionText);

    const dateAndTimeText = <> <b>{dayjs(activity.activityDate).format(DisplayDateFormat)}</b> {i18n.t("components.other.activity.in")} <b>{dayjs(activity.activityDate).format(DisplayTimeShortFormat)}</b></>;
    textParts.push(dateAndTimeText);

    if (activity.salesOpportunity) {
      const salesOpportunityText = <> {i18n.t("components.other.activity.in")} <b>{i18n.t("components.other.activity.salesOpportunity")}</b> <Link to={`${AppRoutes.SALES_OPPORTUNITIES.fullPath}/${activity?.salesOpportunity!.id}}`}>#{activity?.salesOpportunity!.id} - {activity?.salesOpportunity!.name}</Link></>;
      textParts.push(salesOpportunityText);
    }
    
    if (activity.client) {
      const clientText = <> {i18n.t("components.other.activity.with")} <b>{i18n.t("components.other.activity.client")}</b> <Link to={`${AppRoutes.CLIENTS.fullPath}/${activity?.client!.id}}`}>{activity?.client!.name}</Link></>;
      textParts.push(clientText);
    }

    return <>{textParts}</>;
  }

  return <Row style={{ flexWrap: 'nowrap' }}>
    <Col flex="16px">{getActivityTypeAvatar(activity.activityType!.id!, activity.activityStatus!.id!)}</Col>
    <Col flex="auto" style={{marginInline: '16px', alignSelf: 'center'}}>
      {activityText()}
    </Col>
    <Col style={{ color: colors.grey, alignSelf: 'center' }}>{dayjs(activity.createdDate).format(DisplayFullFormat)}</Col>
  </Row>
}

export default ActivityItem;