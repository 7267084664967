import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardService } from '../../api';
import { DashboardStatisticsInterface } from '../../interfaces';
import type { AppDispatch } from '../store';

// ============ INTERFACE ============

export interface DashboardStateInterface {
  statistics?: DashboardStatisticsInterface;
  error?: Error;
}

// ============ INIT STATE ============

const initialState: DashboardStateInterface = {
  statistics: undefined,
};

// ========= SLICE ==========

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setStatistics: (state: DashboardStateInterface, action: PayloadAction<DashboardStatisticsInterface>) => {
      state.statistics = action.payload;
    },
    setStatisticsError: (state: DashboardStateInterface, action: PayloadAction<Error>) => {
      state.error = action.payload;
    }
  }
})

export const { setStatistics, setStatisticsError } = dashboardSlice.actions;

export const getDashboardStatistics = () => (dispatch: AppDispatch) => {
  DashboardService.getStatistics().subscribe({
    next: (response: DashboardStatisticsInterface) => {
      dispatch(setStatistics(response));
    },
    error: (error: Error) => {
      dispatch(setStatisticsError(error));
    }
  });
};

export default dashboardSlice.reducer;
