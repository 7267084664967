import React from 'react';
import {  NavigateFunction, NavigateOptions, Route } from 'react-router-dom';
import { NotFoundPage } from '../../pages/global/notFound/notFound.page';
import { RouteInterface } from '../interfaces';
import { AuthStateInterface } from '../redux';
import { GuardFunction } from '../../components';

let navigateFunction: any;

const setNavigateFunction = (navigate: NavigateFunction) => {
  navigateFunction = navigate;
}

export const navigate = (route: string | number, options?: NavigateOptions) => {
  navigateFunction(route, options);
}

function generateRoutes(routes: Array<RouteInterface>, userAuth: AuthStateInterface): React.ReactNode {

  const allowedRoutes = routes.filter((r: RouteInterface) => {
    if (r.functions && r.functions.length > 0) {
      const accessGranted = GuardFunction(r.functions);
      if (!accessGranted) return false;
    }
    return true;
  });
  
  return (
    <>
      {allowedRoutes.map((route: RouteInterface, index: number) =>
        (
          <Route key={index} path={route.path} element={
            <route.element>
            </route.element>
          }>
            {!!route.children && generateRoutes(route.children, userAuth)}
          </Route>
        )
      )}
      
      <Route path="*" element={<NotFoundPage />}></Route>
    </>
  )
}



export const NavigationService = {
  generateRoutes,
  setNavigateFunction,
  navigate
};
