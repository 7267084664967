import type { TabsProps } from 'antd';
import { Breadcrumb, Button, Card, Col, Row, Space, Tabs, Typography } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AppRoutes } from '..';
import { RootState, i18n, useAppSelector } from '../../../common';
import { AddIcon, Guard, GuardFunction, OfferTable, allOffersTableColumns, allOffersTableFilters } from '../../../components';

const { Title } = Typography;

const OffersPage = () => {
  const navigate = useNavigate();

  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  const renderMyOffers = (): JSX.Element => (
    <OfferTable injectedQueryParams={{ filters: [{ name: 'user.id', operator: 'EQ', value: currentUser!.id!.toString() }] }} columns={allOffersTableColumns.filter((a) => a !== 'agent')} filters={allOffersTableFilters.filter((f) => f !== 'user')}></OfferTable>
  );

  const renderAllOffers = (): JSX.Element => (
    <OfferTable></OfferTable>
  );

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.translate('pages.offers.tabs.personal'),
      children: renderMyOffers(),
    },
    {
      key: '2',
      label: i18n.translate('pages.offers.tabs.all'),
      children: renderAllOffers(),
    },
  ];

  const renderContent = (): JSX.Element => {
    if (GuardFunction({ domain: 'offer', action: 'view_all_any' })) return <Tabs items={tabs} destroyInactiveTabPane={true}></Tabs>;
    return renderMyOffers();
  };

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: 24 }}
        items={[
          {
            title: (
              <Link to={AppRoutes.DASHBOARD.fullPath}>
                {i18n.translate('breadcrumb.homepage')}
              </Link>
            ),
          },
          { title: i18n.translate('breadcrumb.offers') },
        ]}
      />
      <Card>
        <Space direction="vertical" size={16} style={{ display: 'flex' }}>
          <Row justify={'space-between'} align={'middle'}>
            <Col>
              <Title level={2} style={{ margin: 0 }}>{i18n.translate('pages.offers.title')}</Title></Col>
            <Col>
              <Guard permission={{ domain: 'offer', action: 'create' }}>
                <Button
                  type="primary"
                  icon={<AddIcon />}
                  onClick={() => navigate(`${AppRoutes.OFFERS.fullPath}/new`)}
                >
                  {i18n.translate('pages.offers.createNew')}
                </Button>
              </Guard>
            </Col>
          </Row>
          {renderContent()}
        </Space>
      </Card>
    </>
  );
};

export default OffersPage;
