import { Breadcrumb, Card } from 'antd';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ClientInterface, QueryParams, RootState, getClient, i18n, updateClient, useAppDispatch, useAppSelector } from '../../../common';
import { ClientForm, ClientFormItemType, GuardFunction } from '../../../components';
import { AppRoutes } from '../_router/app.routes';

const ClientEditPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const params = useParams();

  const queryParams: QueryParams = { includes: ['clientType', 'areas', 'user', 'contacts'] };

  // TODO možda dodatno zaštititi da se ne dohvaća s api-ja ako je već u reduxu
  useEffect(() => {
    if (params.client_id) dispatch(getClient(parseInt(params.client_id!, 10), queryParams));
  }, [params]
  );

  const client: ClientInterface | undefined = useAppSelector((state: RootState) => state.client.one);
  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  if (!client) return null;

  if (!(GuardFunction({ domain: 'client', action: 'update_any' }) || !client.user || client.user?.id === currentUser!.id)) navigate(AppRoutes.DASHBOARD.fullPath);

  const onUserEdit = (values: ClientInterface) => {
    dispatch(updateClient(client.id!, values));
  };

  const disabledFields: Array<ClientFormItemType> = GuardFunction({ domain: 'client', action: 'update_oib' }) ? ['clientType'] : ['clientType', 'oib'];

  return (
    <>
      <Breadcrumb style={{ marginBottom: 24 }} items={[
        { title: <Link to={AppRoutes.DASHBOARD.fullPath} key={AppRoutes.DASHBOARD.fullPath}>{i18n.translate('breadcrumb.homepage')}</Link> },
        { title: <Link to={AppRoutes.CLIENTS.fullPath} key={AppRoutes.CLIENTS.fullPath}>{i18n.t('breadcrumb.clients')}</Link> },
        { title: i18n.translate('breadcrumb.clientEdit') }]} />
      <Card>
        <ClientForm
          client={client}
          disabled={disabledFields}
          onClientFormFinish={(values) => onUserEdit(values)}
          title='Uređivanje klijenta'
          submitBtnLabel='Spremi promjene'></ClientForm>
      </Card>
    </>
  );
}

export default ClientEditPage;