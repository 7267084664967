import gbLocale from '@fullcalendar/core/locales/en-gb';
import hrLocale from '@fullcalendar/core/locales/hr';
import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Space } from 'antd';
import 'dayjs/locale/en-gb';
import React, { useEffect } from 'react';
import { QueryParams, ReservationInterface, RootState, getLanguageLocaleCalendar, getReservations, openReservationDrawerAndSetReservation, useAppDispatch, useAppSelector } from '../../common';
import { ReservationDrawer } from '../drawers';
import './calendar.style.scss';



const ReservationCalendar = () => {
  const dispatch = useAppDispatch();

  const reservationComponentQueryParams: QueryParams = { includes: ['reservationOption,reservationCategory'], pagination: { current: 1, pageSize: 999999 } };

  useEffect(() => {
    dispatch(getReservations(reservationComponentQueryParams));
  }, []);

  const onEventClick = (eventInfo: any) => {
    const reservation = eventInfo.event.extendedProps;
    dispatch(openReservationDrawerAndSetReservation(reservation));
  }

  const reservations = useAppSelector((state: RootState) => state.reservation.all);
  const lang = useAppSelector((state: RootState) => state.auth.lang);

  const locale = getLanguageLocaleCalendar(lang);

  if (!reservations) return null;

  console.log(locale)
  return (
    <>
      <Space direction='vertical' size={32} style={{ display: 'flex' }}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin]}
          locales={[hrLocale, gbLocale]}
          locale={locale}
          initialView="dayGridMonth"
          headerToolbar={{
            left: 'dayGridMonth,timeGridWeek,timeGridDay prev',
            center: 'title',
            right: 'next',
          }}
          views={{
            dayGridMonth: {
              fixedWeekCount: false,
            },
            timeGridWeek: {
              allDaySlot: false,
              slotMinTime: '06:00:00',
              slotMaxTime: '23:00:00',
              slotLabelFormat: {
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: false
              },
              nowIndicator: true
            }
          }}
          eventDisplay='block'
          navLinks={true}
          events={reservations.data.map((res: ReservationInterface) => ({
            title: res.reservationCategory?.name,
            start: res.reservationStartDate,
            color: res.reservationCategory?.color,
            end: res.reservationEndDate,
            extendedProps: res,
          }))}
          eventClick={onEventClick}
          height='auto'
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false
          }}
        />
      </Space>
      <ReservationDrawer queryParams={reservationComponentQueryParams} />
    </>
  );
}

export default ReservationCalendar;
