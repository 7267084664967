import { ClientTypeEnum } from "../enums";
import { i18n } from "../services";

export interface ClientType {
  id: number;
  name: string;
}

export const ClientTypes = (): Array<ClientType> => [
  { id: ClientTypeEnum.BUSINESS, name: i18n.translate("common.constants.clientType.business") },
  { id: ClientTypeEnum.PRIVATE, name: i18n.translate("common.constants.clientType.private") }
]

export const getClientTypeName = (id: number) =>
  ClientTypes().find((ct: ClientType) => ct.id === id)?.name;
