import React from 'react';
import { Form, Input, Button, theme, Typography, Col, Row } from 'antd';
import { i18n } from '../../../common';

const { Text } = Typography;
const { useToken } = theme;

interface Props {
  onChangePasswordFormFinish?: (values: any) => void;
  onChangePasswordFormFinishFailed?: (errorInfo: any) => void;
}

function ChangePasswordForm({ onChangePasswordFormFinish, onChangePasswordFormFinishFailed }: Props) {
  const { token } = useToken();
  
  return (
    <div className="changePasswordForm">
      <Form
        layout='horizontal'
        name='changePassword'
        requiredMark={false}
        onFinish={onChangePasswordFormFinish}
        onFinishFailed={onChangePasswordFormFinishFailed}
        labelWrap
        labelCol={{ xs: { span: 24 }, sm: { span: 6 }, xl: { span: 4 } }}
        wrapperCol={{ xs: { span: 24 }, sm: { span: 12 }, xl: { span: 8 } }}
      >
        <Row justify={'space-between'}>
          <Col xs={24} sm={20} flex={'auto'}>
            <Form.Item
              name="oldPassword"
              label={<Text style={{ color: token.colorTextDescription }}>{i18n.translate(`components.forms.changePassword.oldPassword`)}</Text>}
              rules={[{ required: true, message: i18n.translate(`components.forms.changePassword.validation.required`) }]}
            >
              <Input.Password size='small' />
            </Form.Item>

            <Form.Item
              name="newPassword"
              label={<Text style={{ color: token.colorTextDescription }}>{i18n.translate(`components.forms.changePassword.newPassword`)}</Text>}
              rules={[{ required: true, message: i18n.translate(`components.forms.changePassword.validation.required`) }]}
            >
              <Input.Password size='small' />
            </Form.Item>

            <Form.Item
              name="repeatNewPassword"
              label={<Text style={{ color: token.colorTextDescription }}>{i18n.translate(`components.forms.changePassword.repeatNewPassword`)}</Text>}
              rules={[{ required: true, message: i18n.translate(`components.forms.changePassword.validation.required`) }]}
            >
              <Input.Password size='small' />
            </Form.Item>
          </Col>

          <Col flex={'none'}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {i18n.translate(`components.forms.changePassword.changePasswordBtn`)}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default ChangePasswordForm;
