import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ActivityInterface, ReservationInterface } from '../../../interfaces';
import { ActivityFormItemType, InitialValueTypeActivity } from '../../../../components';

// ============ INTERFACE ============

export interface DrawerStateInterface {
  activity: {
    open: boolean;
    data?: ActivityInterface;
    submit: boolean;
    initialValues?: Array<InitialValueTypeActivity>;
    disabled?: Array<ActivityFormItemType>;
  },
  reservation: {
    open: boolean;
    data?: ReservationInterface;
    submit: boolean;
  }
}

// ============ INIT STATE ============

const initialState: DrawerStateInterface = {
  activity: {
    open: false,
    submit: false
  },
  reservation: {
    open: false,
    submit: false
  }
};


// ========= SLICE ==========

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    openActivityDrawer: (state: DrawerStateInterface) => {
      state.activity.open = true;
    },
    closeActivityDrawer: (state: DrawerStateInterface) => {
      state.activity.open = false;
      state.activity.data = undefined;
      state.activity.initialValues = undefined;
      state.activity.disabled = undefined;
    },
    openActivityDrawerAndSetActivity: (state: DrawerStateInterface, action: PayloadAction<ActivityInterface>) => {
      state.activity.data = action.payload;
      state.activity.open = true;
    },
    openActivityDrawerAndSetInitialValues: (state: DrawerStateInterface, action: PayloadAction<{initialValues: Array<InitialValueTypeActivity>, disabled: Array<ActivityFormItemType>}>) => {
      state.activity.initialValues = action.payload.initialValues;
      state.activity.disabled = action.payload.disabled;
      state.activity.open = true;
    },
    submitActivityForm: (state: DrawerStateInterface) => {
      state.activity.submit = true;
    },
    resetSubmitActivityForm: (state: DrawerStateInterface) => {
      state.activity.submit = false;
    },
    openReservationDrawer: (state: DrawerStateInterface) => {
      state.reservation.open = true;
    },
    closeReservationDrawer: (state: DrawerStateInterface) => {
      state.reservation.open = false;
      state.reservation.data = undefined;
    },
    openReservationDrawerAndSetReservation: (state: DrawerStateInterface, action: PayloadAction<ReservationInterface>) => {
      state.reservation.data = action.payload;
      state.reservation.open = true;
    },
    submitReservationForm: (state: DrawerStateInterface) => {
      state.reservation.submit = true;
    },
    resetSubmitReservationForm: (state: DrawerStateInterface) => {
      state.reservation.submit = false;
    }
  }
})

export const { openActivityDrawer, closeActivityDrawer, openActivityDrawerAndSetActivity, openActivityDrawerAndSetInitialValues, submitActivityForm, resetSubmitActivityForm, openReservationDrawer, closeReservationDrawer, openReservationDrawerAndSetReservation, submitReservationForm, resetSubmitReservationForm } = drawerSlice.actions;

export default drawerSlice.reducer;