import { Badge, Button, Popover, Tabs, TabsProps } from 'antd';
import React, { useEffect } from 'react';
import {
  QueryParams,
  RootState,
  getAnnouncementSeenFromUser,
  getAnnouncementsNotification,
  getNewsNotification,
  getNewsSeenFromUser,
  i18n,
  useAppDispatch,
  useAppSelector
} from '../../../common';
import { BellIcon } from '../../icons';
import AnnouncementsNotificationComponent from './announcementsNotification.component';
import NewsNotificationComponent from './newsNotification.component';

export default function Notifications() {
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  const news = useAppSelector((state: RootState) => state.news.all.dataNotification);
  const announcements = useAppSelector((state: RootState) => state.announcements.all.dataNotification);
  const unseenNews = useAppSelector((state: RootState) => state.news.all.seen);
  const unseenAnnouncements = useAppSelector((state: RootState) => state.announcements.all.seen);
  
  const newsComponentQueryParams: QueryParams = {
    includes: ['newsSeen,users'],
    filters: [{ name: 'users.id', operator: 'EQ', value: currentUser!.id!.toString() }, { name: 'newsSeen.user.id', operator: 'EQ', value: currentUser!.id!.toString() }, { name: 'newsSeen.seen', operator: 'EQ', value: 'false' }],
    pagination: {current: 1, pageSize: 999999},
    sorters: [{ columnKey: 'id', order: 'descend' }]
  };

  const newsComponentSeenQueryParams: QueryParams = {
    includes: ['news'],
    filters: [{ name: 'user.id', operator: 'EQ', value: currentUser!.id!.toString()}, { name: 'seen', operator: 'EQ', value: 'false'}],
    pagination: {current: 1, pageSize: 999999},
    sorters: [{ columnKey: 'id', order: 'descend' }]
  };

  const announcementComponentQueryParams: QueryParams = {
    includes: ['announcementSeen,users,announcementType,announcementAction'],
    filters: [{ name: 'users.id', operator: 'EQ', value: currentUser!.id!.toString() }, { name: 'announcementSeen.user.id', operator: 'EQ', value: currentUser!.id!.toString() }, { name: 'announcementSeen.seen', operator: 'EQ', value: 'false' }],
    pagination: {current: 1, pageSize: 999999},
    sorters: [{ columnKey: 'id', order: 'descend' }]
  };

  const announcementComponentSeenQueryParams: QueryParams = {
    includes: ['announcement'],
    filters: [{ name: 'user.id', operator: 'EQ', value: currentUser!.id!.toString()}, { name: 'seen', operator: 'EQ', value: 'false'}],
    pagination: {current: 1, pageSize: 999999},
    sorters: [{ columnKey: 'id', order: 'descend' }]
  };

  const getAnnouncements = () => {
    dispatch(getAnnouncementsNotification(announcementComponentQueryParams));
    dispatch(getAnnouncementSeenFromUser(announcementComponentSeenQueryParams));
  };

  const getNews = () => {
    dispatch(getNewsNotification(newsComponentQueryParams));
    dispatch(getNewsSeenFromUser(newsComponentSeenQueryParams));
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getAnnouncements();
      getNews();
    }, 120000) // in milliseconds
    return () => clearInterval(intervalId);
  }, [currentUser?.id]);

  useEffect(() => {
    getAnnouncements();
  }, []);

  useEffect(() => {
    getNews();
  }, []);

  const unseenAnnouncementsNumber: number = unseenAnnouncements?.length ? unseenAnnouncements?.length : 0;
  const unseenNewsNumber: number = unseenNews?.length ? unseenNews?.length : 0;

  const tabs: TabsProps['items'] = [
    {
      key: '1',
      label: `${i18n.t('header.notifications.announcements')} (${unseenAnnouncementsNumber.toString()}) `,
      children: <AnnouncementsNotificationComponent announcements={announcements} unseenAnnouncements={unseenAnnouncements} announcementQueryParams={announcementComponentQueryParams} announcementSeenQueryParams={announcementComponentSeenQueryParams} />,
    },
    {
      key: '2',
      label: `${i18n.t('header.notifications.news')} (${unseenNewsNumber.toString()})`,
      children: <NewsNotificationComponent news={news} unseenNews={unseenNews} newsQueryParams={newsComponentQueryParams} newsSeenQueryParams={newsComponentSeenQueryParams} />,
    },
  ];

  const items = <Tabs defaultActiveKey="1" items={tabs} />;

  return (
    <div className="notificationPopover">
      <Popover
        content={items}
        trigger="click"
        placement="bottomRight"
        overlayStyle={{ minWidth: '500px' }}
      >
        <Badge size='small' overflowCount={99} offset={[-8, 8]} count={(unseenAnnouncementsNumber + unseenNewsNumber).toString()}>
          <Button type="text" shape="circle" style={{ fontSize: 16 }}>
            <BellIcon fill='none' />
          </Button>
        </Badge>
      </Popover>
    </div>
  );
}
