import { i18n } from "../services";

export interface Active {
  active: boolean;
  label: string;
  shortLabel: string;
}

export const ActiveOptions = (): Array<Active> => [
  { active: true, label: i18n.t('common.constants.active.active'), shortLabel: i18n.t('common.constants.active.activeShort') },
  { active: false, label: i18n.t('common.constants.active.inactive'), shortLabel: i18n.t('common.constants.active.inactiveShort')},
]

export const getActiveLabel = (active: boolean) =>
  ActiveOptions().find((a: Active) => a.active === active)?.label;

export const getActiveShortLabel = (active: boolean) =>
  ActiveOptions().find((a: Active) => a.active === active)?.shortLabel;
