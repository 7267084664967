import { Button, Row, Typography, theme } from 'antd';
import React from 'react';
import { DownloadService, RootState, i18n, useAppSelector } from '../../common';
import { apiRoutes } from '../../common/api/routes';
import { FileExtensionEnum } from '../../common/enums';

const { Text } = Typography;
const { useToken } = theme;

export default function ExportClients() {
  const { token } = useToken();

  const userAuth = useAppSelector((state: RootState) => state.auth);

  const onClientsExport = () => {
    DownloadService.downloadFile(userAuth, apiRoutes.exportClients, 'clients_export', FileExtensionEnum.EXCEL);
  };

  return (
    <>
      <Row justify={'center'}>
        <Text style={{ color: token.colorTextDescription }}>{i18n.translate('components.other.exportClients.description')}</Text>
      </Row>
      <Row className='mt16' justify={'center'}>
        <Button onClick={onClientsExport} type="primary">
          {i18n.translate('components.other.exportClients.exportBtn')}
        </Button>
      </Row>
    </>
  );
}
