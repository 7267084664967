import { Button, Col, Drawer, Row, Space, Typography } from 'antd';
import React, { useState } from 'react';
import { ClientInterface, RootState, ServiceInterface, createService, i18n, updateService, useAppDispatch, useAppSelector } from '../../common';
import ServiceForm from '../forms/serviceForm/serviceForm.component';
import { AddIcon } from '../icons';
import ServicesTable from '../tables/servicesTable.component';

const { Title } = Typography;

interface Props {
  clientProp: ClientInterface;
}

const Services = ({ clientProp }: Props) => {
  const dispatch = useAppDispatch();

  const [trigger, setTrigger] = useState<number>(0);
  const [serviceDrawerOpen, setServiceDrawerOpen] = useState<boolean>(false);

  const [service, setService] = useState<ServiceInterface | undefined>(undefined);
  
  const servicesTotal = useAppSelector((state: RootState) => state.service.all.total);

  const handleCloseChange = () => {
    setServiceDrawerOpen(false);
    setTrigger(0);
    setService(undefined);
  };

  const handleOpenChange = () => {
    setServiceDrawerOpen(true);
  };

  const onServiceSelect = (selectedService: ServiceInterface) => {
    setService(selectedService);
    handleOpenChange();
  };

  const onServiceFormFinish = (values: any) => {
    if (service) {
      dispatch(updateService(service.id!, values));
    } else {
      const data = {
        ...values,
        client: { id: clientProp.id }
      }
      dispatch(createService(data));
    }
    handleCloseChange();
  };

  return (
    <>
      <Space direction="vertical" size={16} style={{ display: 'flex' }}>
        <Row justify={'space-between'} align={'middle'}>
          <Col>
            <Title level={2} style={{ margin: 0 }}>
              {servicesTotal} usluga
            </Title>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<AddIcon />}
              onClick={handleOpenChange}
            >
              {i18n.t('components.other.services.createNew')}
            </Button>
          </Col>
        </Row>
        <ServicesTable injectedQueryParams={{ filters: [{ name: 'client.id', operator: 'EQ', value: clientProp.id!.toString() }], sorters: [{ columnKey: 'id', order: 'descend' }] }} onServiceSelect={onServiceSelect}></ServicesTable>
      </Space>
      <Drawer
        title={i18n.translate('components.other.services.drawer.create')}
        onClose={handleCloseChange}
        open={serviceDrawerOpen}
        destroyOnClose
        width={425}
        extra={
          <Space>
            <Button onClick={handleCloseChange}>{i18n.translate('buttons.cancel')}</Button>
            <Button onClick={() => setTrigger(trigger + 1)} type="primary">
              {i18n.translate('buttons.submit')}
            </Button>
          </Space>
        }
      >
        <ServiceForm trigger={trigger} service={service} onServiceFormFinish={onServiceFormFinish}></ServiceForm>
      </Drawer>
    </>
  );
}

export default Services;
