import { Breadcrumb, Card, Radio, RadioChangeEvent, Space, Tabs, TabsProps, Typography } from 'antd';
import React, { useEffect } from 'react';
import { Language, Languages, RootState, UserInterface, getUser, i18n, setLang, updateUserLang, useAppDispatch, useAppSelector } from '../../../common';
import { ChangePassword, ProfileDetails } from '../../../components';

const { Title } = Typography;

const ProfilePage = () => {
  const dispatch = useAppDispatch();
  
  const userAuth = useAppSelector((state: RootState) => state.auth);
  const user: UserInterface | undefined = useAppSelector((state: RootState) => state.user.one);

  useEffect(() => {
    if(userAuth.user?.id) dispatch(getUser(userAuth.user!.id, { includes: ['dbRole'] }));
  }, []
  );

  const onLanguageChange = (e: RadioChangeEvent) => {
    dispatch(updateUserLang(userAuth.user!.id!, { language: e.target.value } as UserInterface));
    setLang(e.target.value);
  };

  if (!user || !user.id) return null;

  const renderLanguageTab = (): JSX.Element => 
    <Space direction="vertical" size={16} style={{ display: 'flex' }}>
      <Radio.Group size="large" onChange={onLanguageChange} value={userAuth.lang}>
        <Space direction="vertical">
          {Languages().map((language: Language) => (
            <Radio value={language.id} key={language.id}>{language.name}</Radio>
          ))}
        </Space>
      </Radio.Group>
    </Space>
  
  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: i18n.translate('pages.profile.tabs.userDetails'),
      children: <ProfileDetails user={user!}></ProfileDetails>,
    },
    {
      key: '2',
      label: i18n.translate('pages.profile.tabs.password'),
      children: <ChangePassword user={user!}/>,
    },
    {
      key: '3',
      label: i18n.translate('pages.profile.tabs.language'),
      children: renderLanguageTab(),
    },
  ];

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: 24 }} 
        items={[{ title: i18n.translate('breadcrumb.homepage') }, { title: i18n.translate('breadcrumb.profile') }]} 
      />
      <Card>
        <Space direction="vertical" size={16} style={{ display: 'flex' }}>
          <Title level={2} style={{ margin: 0 }}>{i18n.translate('pages.profile.title')}</Title>
          <Tabs defaultActiveKey="1" items={tabItems} destroyInactiveTabPane />
        </Space>
      </Card>
    </>

  );
}

export default ProfilePage;
