import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { SalesOpportunityInterface, SalesOpportunitiesResponseInterface, SalesOpportunityResponseInterface, UserInterface } from '../../interfaces';
import type { AppDispatch } from '../store';
import { JsonApiService, QueryParams, SalesOpportunityAgentService } from '../../api';
import { i18n, navigate } from '../../services';

// ============ INTERFACE ============

export interface SalesOpportunityStateInterface {
  all: {
    data: Array<SalesOpportunityInterface>;
    total?: number;
  };
  one?: SalesOpportunityInterface;
  error?: Error;
}

// ============ INIT STATE ============

const initialState: SalesOpportunityStateInterface = {
  all: {data: []},
};


// ========= SLICE ==========

export const salesOpportunitySlice = createSlice({
  name: 'salesOpportunity',
  initialState,
  reducers: {
    setSalesOpportunityAllData: (state: SalesOpportunityStateInterface, action: PayloadAction<Array<SalesOpportunityInterface>>) => {
      state.all.data = action.payload;
    },
    setSalesOpportunityAllTotal: (state: SalesOpportunityStateInterface, action: PayloadAction<number>) => {
      state.all.total = action.payload;
    },
    setSalesOpportunityOne: (state: SalesOpportunityStateInterface, action: PayloadAction<SalesOpportunityInterface>) => {
      state.one = action.payload;
    },
    updateSalesOpportunityAllData: (state: SalesOpportunityStateInterface, action: PayloadAction<SalesOpportunityInterface>) => {
      const index = state.all.data.findIndex(x => x.id === action.payload.id);
      state.all.data[index] = action.payload;
    },
    createSalesOpportunityAllData: (state: SalesOpportunityStateInterface, action: PayloadAction<SalesOpportunityInterface>) => {
      state.all.data.unshift(action.payload);
      if (state.all.total || state.all.total === 0) state.all.total += 1;
    },
    setSalesOpportunityError: (state: SalesOpportunityStateInterface, action: PayloadAction<Error>) => {
      state.error = action.payload;
    }
  }
})

export const { setSalesOpportunityAllData, setSalesOpportunityError, setSalesOpportunityAllTotal, setSalesOpportunityOne, updateSalesOpportunityAllData, createSalesOpportunityAllData } = salesOpportunitySlice.actions;

export const getSalesOpportunities = (queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('sales_opportunity', undefined, queryParams)
    .subscribe({
      next: (response: SalesOpportunitiesResponseInterface) => {
        dispatch(setSalesOpportunityAllData(response.data));
        if (response.meta && (response.meta.totalResourceCount || response.meta.totalResourceCount === 0)) dispatch(setSalesOpportunityAllTotal(response.meta.totalResourceCount));
      },
      error: (error: Error) => {
        dispatch(setSalesOpportunityError(error));
        dispatch(setSalesOpportunityAllTotal(0));
      }
    })
}

export const getSalesOpportunity = (id: number, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('sales_opportunity', id, queryParams)
    .subscribe({
      next: (response: SalesOpportunityResponseInterface) => {
        dispatch(setSalesOpportunityOne(response.data));
      },
      error: (error: Error) => {
        dispatch(setSalesOpportunityError(error));
      }
    })
}

export const createSalesOpportunity = (data: SalesOpportunityInterface, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.post('sales_opportunity', data, queryParams)
    .subscribe({
      next: (response: any) => {
        if (response.error) {
          notification.error({ message: i18n.translate('components.banners.salesOpportunity.createError'), duration: 2 });
          return;
        }
        dispatch(setSalesOpportunityOne(response.data));
        dispatch(createSalesOpportunityAllData(response.data));
        notification.success({ message: i18n.translate('components.banners.salesOpportunity.createSuccess'), duration: 2 });
        navigate(-1);
      },
      error: (error: Error) => {
        dispatch(setSalesOpportunityError(error));
        notification.error({ message: i18n.translate('components.banners.salesOpportunity.createError'), duration: 2 });
      }
    })
}

export const updateSalesOpportunity = (id: number, data: SalesOpportunityInterface, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.patch('sales_opportunity', id, data, queryParams)
    .subscribe({
      next: (response: any) => {
        if (response.error) {
          notification.error({ message: i18n.translate('components.banners.salesOpportunity.editError'), duration: 2 });
          return;
        }
        dispatch(setSalesOpportunityOne(response.data));
        dispatch(updateSalesOpportunityAllData(response.data));
        notification.success({ message: i18n.translate('components.banners.salesOpportunity.editSuccess'), duration: 2 });
        navigate(-1);
      },
      error: (error: Error) => {
        dispatch(setSalesOpportunityError(error));
        notification.error({ message: i18n.translate('components.banners.salesOpportunity.editError'), duration: 2 });
      }
    })
}

export const salesOpportunitiesToAgents = (transfer: boolean, selectedAgents: Array<UserInterface>, selectedSalesOpportunities: Array<SalesOpportunityInterface>, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  if (!selectedSalesOpportunities.length || !selectedAgents.length) return;

  const request = {
    requests: selectedSalesOpportunities.map((so) => ({
      salesOpportunityId: so.id,
      agentIds: selectedAgents.map((a => a.id))
    }))
  };

  if (transfer) {
    SalesOpportunityAgentService.transfer(request)
      .subscribe({
        next: (response: any) => {
          if (response.errors) {
            notification.error({ message: i18n.translate('components.banners.salesOpportunity.transferSalesOpportunityError'), duration: 2 });
            return;
          }
          dispatch(getSalesOpportunities(queryParams));
          notification.success({ message: i18n.translate('components.banners.salesOpportunity.transferSalesOpportunitySuccess'), duration: 2 });
        },
        error: (error: Error) => {
          dispatch(setSalesOpportunityError(error));
          notification.error({ message: i18n.translate('components.banners.salesOpportunity.transferSalesOpportunityError'), duration: 2 });
        }
      })
  } else {
    SalesOpportunityAgentService.add(request)
      .subscribe({
        next: (response: any) => {
          if (response.errors) {
            notification.error({ message: i18n.translate('components.banners.salesOpportunity.addSalesOpportunityError'), duration: 2 });
            return;
          }
          dispatch(getSalesOpportunities(queryParams));
          notification.success({ message: i18n.translate('components.banners.salesOpportunity.addSalesOpportunitySuccess'), duration: 2 });
        },
        error: (error: Error) => {
          dispatch(setSalesOpportunityError(error));
          notification.error({ message: i18n.translate('components.banners.salesOpportunity.addSalesOpportunityError'), duration: 2 });
        }
      })
  }
}

export default salesOpportunitySlice.reducer;