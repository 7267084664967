import { Grid, Typography } from 'antd';
import React, { ReactElement } from 'react';
import { Cell, Label, Legend, LegendProps, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { SalesOpportunityStatusCountsInterface, TotalSalesOpportunityStatusStatisticInterface, getSalesOpportunityStatusColor, getSalesOpportunityStatusName, i18n } from '../../../common';
import { colors } from '../../../theme';

const { useBreakpoint } = Grid;
const { Text } = Typography;

interface Props {
  statistics: TotalSalesOpportunityStatusStatisticInterface;
  title?: string;
}

const { Title } = Typography;

export default function DashboardCampaignStats({ statistics, title }: Props) {

  const { md } = useBreakpoint();

  const { salesOpportunityStatusCounts, total } = statistics;

  const data = salesOpportunityStatusCounts?.length ? salesOpportunityStatusCounts.map((x: SalesOpportunityStatusCountsInterface) => ({ name: getSalesOpportunityStatusName(x.id), value: x.count, color: getSalesOpportunityStatusColor(x.id) })) : [{ name: 'None', value: 1, color: colors.yellowBackground }];

  const CustomLegend: React.FC<LegendProps> = ({ payload }: LegendProps): ReactElement => (
    <ul className="customLegend1" style={{ padding: 0, display: 'grid', gridTemplateColumns: (window.innerWidth > 1399 && window.innerWidth < 1600) ? 'auto' : 'auto auto', gap: 16 }}>
      {payload?.map((entry: any, index) => (
        <div key={`legend${index}`} style={{ display: 'flex', flexDirection: (window.innerWidth > 1400 && window.innerWidth < 1600) ? 'row' : 'column', gap: 8, fontSize: '12px' }}>
          <li className="legendItem" key={`item-title-${index}`}>
            <span style={{ color: entry.color, margin: '0 8px 0 0' }}>⬤</span>
            <Text strong type="secondary">{entry.value}</Text>
          </li>
          <li className="legendItemText" key={`item-text-${index}`}>
            {entry.payload.value}
          </li>
        </div>
      ))}
    </ul>
  );

  return (
    <div className="campaignStats">
      <Title level={2}>{title ?? i18n.translate('pages.dashboard.salesOpportunitiesStats')}</Title>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            cx="50px"
            cy="50%"
            dataKey="value" // make sure to map the dataKey to "value"
            innerRadius={50}
            outerRadius={95}
            paddingAngle={1}
          >
            {data.map((entry: any, index: number) => (
              <Cell style={{outline: 'none'}} key={`cell-${index}`} fill={entry.color} />
            ))}
            <Label
              position="center"
              fill="grey"
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              {`${i18n.translate("pages.campaigns.statistics.total")}: ${total}`}
            </Label>
          </Pie>
          {salesOpportunityStatusCounts?.length > 0 && <Tooltip />}
          <Legend content={<CustomLegend />} layout="horizontal" verticalAlign={md ? "middle" : "bottom"} align={md ? "right" : "center"} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}
