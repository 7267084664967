import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/es/table';
import { Avatar, Button, Dropdown, MenuProps, Space, Tooltip } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import {
  useAppDispatch,
  useAppSelector,
  RootState,
  QueryParams,
  DefaultPagination,
  i18n,
  getSalesOpportunities,
  SalesOpportunityInterface,
  DisplayDateFormat,
  getSalesOpportunityStatusName,
  Pagination,
} from '../../common';
import { Search, CampaignFilter, UserFilter, SalesOpportunityStatusFilter } from '../filters';
import { AppRoutes } from '../../pages/app';
import { colors } from '../../theme';
import { SalesOpportunitiesToAgentsDrawer } from '../other';
import { GuardFunction } from '../guard';
import { UserIcon } from '../icons';
import Table, { TableFilter, TableParams, TableSearch } from './table.component';

interface Props {
  injectedQueryParams?: QueryParams;
  columns?: Array<SalesOpportunitiesTableColumnType>;
  filters?: Array<SalesOpportunitiesTableFilterType>;
  pagination?: Pagination;
}

type SalesOpportunitiesTableColumnType =
  | 'id'
  | 'name'
  | 'client'
  | 'users'
  | 'campaign'
  | 'latestActivity'
  | 'upcomingActivity'
  | 'endDate'
  | 'status'
  | 'actions';
type SalesOpportunitiesTableFilterType = 'campaign' | 'status' | 'user' | 'search';

export const allSalesOpportunitiesTableColumns: Array<SalesOpportunitiesTableColumnType> = [
  'id',
  'name',
  'client',
  'users',
  'campaign',
  'latestActivity',
  'upcomingActivity',
  'endDate',
  'status',
  'actions'
];
export const allSalesOpportunitiesTableFilters: Array<SalesOpportunitiesTableFilterType> = [
  'campaign',
  'status',
  'user',
  'search',
];

const SalesOpportunitiesTable = ({ injectedQueryParams, columns, filters, pagination }: Props) => {
  const dispatch = useAppDispatch();

  const componentQueryParams: QueryParams = {
    ...injectedQueryParams,
    includes: [
      ...(injectedQueryParams?.includes || []),
      'latestStatus',
      'client',
      'users',
      'campaign',
    ],
    sorters: [
      ...(injectedQueryParams?.sorters || 
      [{ columnKey: 'id', order: 'descend', field: 'id' }])
    ]
  };

  const [agentsDrawerOpen, setAgentsDrawerOpen] = useState<boolean>(false);
  const [drawerType, setDrawerType] = useState<'transfer' | 'add'>('transfer');

  const [queryParams, setQueryParams] = useState<QueryParams>(componentQueryParams);
  const [selectedSalesOpportunities, setSelectedSalesOpportunities] = useState<Array<SalesOpportunityInterface>>([]);

  useEffect(() => {
    dispatch(getSalesOpportunities(queryParams));
  }, [queryParams]);

  const salesOpportunities = useAppSelector((state: RootState) => state.salesOpportunity.all);
  const currentUser = useAppSelector((state: RootState) => state.auth.user);

  const tableColumns: Array<SalesOpportunitiesTableColumnType> = columns || allSalesOpportunitiesTableColumns;

  const menu = (tableSalesOpportunity: SalesOpportunityInterface) => {
    const items: MenuProps['items'] = [
      GuardFunction({ domain: 'sales_opportunity', action: 'set_any_user' }) ? {
        key: '1',
        label: <a onClick={() => { setSelectedSalesOpportunities([tableSalesOpportunity]); setDrawerType('transfer'); setAgentsDrawerOpen(true) }}>{i18n.translate(`components.table.salesOpportunityTable.actions.transferSalesOpportunityToAgent`)}</a>,
      } : null,
      GuardFunction({ domain: 'sales_opportunity', action: 'set_any_user' }) ? {
        key: '2',
        label: <a onClick={() => { setSelectedSalesOpportunities([tableSalesOpportunity]); setDrawerType('add'); setAgentsDrawerOpen(true) }}>{i18n.translate(`components.table.salesOpportunityTable.actions.addAgentToSalesOpportunity`)}</a>,
      } : null,
      GuardFunction(({ domain: 'sales_opportunity', action: 'update_any' }) || tableSalesOpportunity.users?.find(so => so.id === currentUser!.id)) ? {
        key: '3',
        label: <Link to={`${AppRoutes.SALES_OPPORTUNITIES.fullPath}/${tableSalesOpportunity.id}/edit`}>{i18n.translate(`components.table.salesOpportunityTable.actions.edit`)}</Link>,
      } : null
    ];

    return { items };
  }

  const groupActions: Array<{ key: number, label: string, show?: boolean, action: () => void }> = [
    {
      key: 1,
      label: i18n.translate(`components.table.salesOpportunityTable.actions.transferSalesOpportunityToAgent`),
      show: GuardFunction({ domain: 'sales_opportunity', action: 'set_any_user' }), // TODO korigirati akcije
      action: () => { setDrawerType('transfer'); setAgentsDrawerOpen(true) }
    },
    {
      key: 2,
      label: i18n.translate(`components.table.salesOpportunityTable.actions.addAgentToSalesOpportunity`),
      show: GuardFunction({ domain: 'sales_opportunity', action: 'set_any_user' }), // TODO korigirati akcije
      action: () => { setDrawerType('add'); setAgentsDrawerOpen(true) }
    },
  ].filter(x => x.show);

  const salesOpportunitiesTableColumns: ColumnsType<SalesOpportunityInterface> = [
    {
      title: i18n.t('components.table.salesOpportunityTable.id'),
      dataIndex: 'id',
      key: 'id',
      render: (dataIndex: string, salesOpportunity: SalesOpportunityInterface) => (
        <Link
          to={`${AppRoutes.SALES_OPPORTUNITIES.fullPath}/${salesOpportunity.id}`}
          key={`${AppRoutes.SALES_OPPORTUNITIES.fullPath}/${salesOpportunity.id}`}
        >
          {`#${salesOpportunity.id}`}
        </Link>
      ),
      sorter: true,
      width: 100,
    },
    {
      title: i18n.t('components.table.salesOpportunityTable.name'),
      dataIndex: 'name',
      key: 'name',
      render: (dataIndex: string, salesOpportunity: SalesOpportunityInterface) => (
        <Link
          to={`${AppRoutes.SALES_OPPORTUNITIES.fullPath}/${salesOpportunity.id}`}
          key={`${AppRoutes.SALES_OPPORTUNITIES.fullPath}/${salesOpportunity.id}`}
        >
          {salesOpportunity.name}
        </Link>
      ),
      sorter: true,
      width: 300,
    },
    {
      title: i18n.t('components.table.salesOpportunityTable.client'),
      dataIndex: 'client.id',
      key: 'client',
      render: (dataIndex: string, salesOpportunity: SalesOpportunityInterface) => (
        <Link
          to={`${AppRoutes.CLIENTS.fullPath}/${salesOpportunity.client?.id}`}
          key={`${AppRoutes.CLIENTS.fullPath}/${salesOpportunity.client?.id}`}
        >
          {salesOpportunity.client?.name}
        </Link>
      ),
      width: 300,
    },
    {
      title: i18n.t('components.table.salesOpportunityTable.campaign'),
      dataIndex: 'campaign.id',
      key: 'campaign',
      render: (dataIndex: string, salesOpportunity: SalesOpportunityInterface) =>
        salesOpportunity.campaign ? (
          <Link
            to={`${AppRoutes.CAMPAIGNS.fullPath}/${salesOpportunity.campaign?.id}`}
            key={`${AppRoutes.CAMPAIGNS.fullPath}/${salesOpportunity.campaign?.id}`}
          >
            {`#${salesOpportunity.campaign.id}`}
          </Link>
        ) : '-',
      width: 100,
    },
    {
      title: i18n.t('components.table.salesOpportunityTable.user'),
      dataIndex: 'users.fullName',
      key: 'users',
      render: (dataIndex: string, salesOpportunity: SalesOpportunityInterface) =>
        salesOpportunity.users && (
          <Avatar.Group maxCount={2} maxStyle={{ color: colors.blue, backgroundColor: colors.blueBackground }}>
            {
              salesOpportunity.users.map((user) => (
                <Tooltip key={user.id} title={user.fullName} placement="top">
                  {user.profileImageUrl ? <Avatar src={user.profileImageUrl} /> : <Avatar icon={<UserIcon />} />}
                </Tooltip>
              ))
            }
          </Avatar.Group>
        ),
      width: 150,
    },
    {
      title: i18n.t('components.table.salesOpportunityTable.lastActivity'),
      dataIndex: 'latestActivity',
      key: 'latestActivity',
      render: (dataIndex: string, salesOpportunity: SalesOpportunityInterface) =>
        salesOpportunity.latestActivity && (dayjs(salesOpportunity.latestActivity).isSame('1980-01-01', 'year') ? '-' : `${dayjs(salesOpportunity.latestActivity).format(DisplayDateFormat)}`),
      sorter: true,
      width: 200,
    },
    {
      title: i18n.t('components.table.salesOpportunityTable.upcomingActivity'),
      dataIndex: 'upcomingActivity',
      key: 'upcomingActivity',
      render: (dataIndex: string, salesOpportunity: SalesOpportunityInterface) =>
        salesOpportunity.upcomingActivity && (dayjs(salesOpportunity.upcomingActivity).isSame('2038-01-01', 'year') ? '-' : `${dayjs(salesOpportunity.upcomingActivity).format(DisplayDateFormat)}`),
      sorter: true,
      width: 200,
    },
    {
      title: i18n.t('components.table.salesOpportunityTable.endDate'),
      dataIndex: 'endDate',
      key: 'endDate',
      render: (dataIndex: string, salesOpportunity: SalesOpportunityInterface) =>
        salesOpportunity.endDate ? `${dayjs(salesOpportunity.endDate).format(DisplayDateFormat)}` : '-',
      sorter: true,
      width: 200,
    },
    {
      title: i18n.t('components.table.salesOpportunityTable.status'),
      dataIndex: 'latestStatus.id',
      key: 'status',
      render: (dataIndex: string, salesOpportunity: SalesOpportunityInterface) =>
        salesOpportunity.latestStatus ? getSalesOpportunityStatusName(salesOpportunity.latestStatus?.id) : '-',
      sorter: false,
      width: 200,
    },
    {
      title: i18n.translate(`components.table.salesOpportunityTable.actions`),
      dataIndex: 'actions',
      key: 'actions',
      render: (dataIndex: string, salesOpportunity: SalesOpportunityInterface) => (
        <Dropdown
          menu={menu(salesOpportunity)}
          trigger={['click']}
        >
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              <Button type="link" icon={<EllipsisOutlined />} />
            </Space>
          </a>
        </Dropdown>
      ),
      width: 64,
    },
  ].filter((c) => tableColumns.includes(c.key! as SalesOpportunitiesTableColumnType));

  interface Filter {
    filterKey: SalesOpportunitiesTableFilterType;
    tableFilter: TableFilter;
  }

  const tableFilters: Array<SalesOpportunitiesTableFilterType> = filters || allSalesOpportunitiesTableFilters;

  const salesOpportunitiesTableFilters: Array<Filter> = [
    {
      filterKey: 'campaign' as SalesOpportunitiesTableFilterType,
      tableFilter: { component: CampaignFilter, key: 'campaign.id' },
    },
    {
      filterKey: 'status' as SalesOpportunitiesTableFilterType,
      tableFilter: { component: SalesOpportunityStatusFilter, key: 'latestStatus.id' },
    },
    {
      filterKey: 'user' as SalesOpportunitiesTableFilterType,
      tableFilter: { component: UserFilter, key: 'users.id' }
    },
  ].filter((f) => tableFilters.includes(f.filterKey as SalesOpportunitiesTableFilterType));

  const searchFields: Array<TableSearch> | undefined = tableFilters.includes('search' as SalesOpportunitiesTableFilterType)
    ? [{ component: Search, keys: ['name', 'client.name'], placeholder: i18n.t('components.table.salesOpportunityTable.search') }]
    : undefined;

  // Potrebno prilagoditi funkciju ovisno o tome koje su sve mogućnosti
  const handleTableChange = (tableParams: TableParams) => {
    setQueryParams({
      pagination: tableParams.pagination,
      filters: [...(tableParams.filters || []), ...(componentQueryParams?.filters || [])],
      sorters: tableParams.sorters || componentQueryParams.sorters,
      includes: componentQueryParams.includes,
      search: tableParams.search,
    });
  };

  const handleGroupAction = (selectedRows: Array<SalesOpportunityInterface>, selectedGroupAction?: number) => {
    setSelectedSalesOpportunities(selectedRows);
    groupActions?.find(x => x.key === selectedGroupAction)?.action();
  };

  if (!salesOpportunities) return null;

  return (
    <>
      <Table
        rowKey={"id"}
        groupActions={tableColumns.includes('actions' as SalesOpportunitiesTableColumnType) ? groupActions : undefined}
        handleGroupAction={handleGroupAction}
        columns={salesOpportunitiesTableColumns}
        dataSource={salesOpportunities.data}
        handleTableChange={handleTableChange}
        paginationDefault={pagination || DefaultPagination}
        total={salesOpportunities.total}
        filters={salesOpportunitiesTableFilters.map((filter: Filter) => filter.tableFilter)}
        searchFields={searchFields}
      />
      <SalesOpportunitiesToAgentsDrawer isOpen={agentsDrawerOpen} drawerType={drawerType} selectedSalesOpportunities={selectedSalesOpportunities} onCloseDrawer={() => { setSelectedSalesOpportunities([]); setAgentsDrawerOpen(false) }} />
    </>
  );
};

export default SalesOpportunitiesTable;
