import { InboxOutlined } from '@ant-design/icons';
import { Form, Input, Upload, UploadFile, UploadProps, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { FileInterface, NewsInterface, i18n } from '../../../common';
import { CustomFileRender } from '../../other';

interface Props {
  onFinish?: (values: any) => void;
  onFinishFail?: (values: any) => void;
  trigger: number;
  news?: NewsInterface;
}

const { TextArea } = Input;
const { Dragger } = Upload;

function NewsForm({ onFinish, onFinishFail, news, trigger }: Props) {
  const [form] = Form.useForm();

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  useEffect(() => {
    setFileList(news?.files?.length ? news?.files?.map((x: FileInterface) => ({ id: x.id, uid: x.uuid, name: x.name })) : [])
  }, [news]);

  useEffect(() => {
    if (onFinish && trigger > 0) {
      form.submit();
    }
  }, [trigger]);

  const onRemoveFile = (file: any) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  }

  const props: UploadProps = {
    name: 'files',
    action: process.env.REACT_APP_DMS_URL,
    fileList,
    itemRender: (originNode, file) => (
      <CustomFileRender file={file} onRemoveFile={onRemoveFile} />
    ),
    onChange: (info) => {
      setFileList(info.fileList);
      if (info.file.status === 'done') {
        notification.success({ message: i18n.translate('components.forms.news.upload.success', { fileName: info.file.name })});
      } else if (info.file.status === 'error') {
        notification.error({ message: i18n.translate('components.forms.news.upload.error', { fileName: info.file.name })});
      }
    },
  };

  return (
    <>
      <Form
        name="user"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFail}
        layout="vertical"
        initialValues={news}
      >
        <Form.Item
          name="title"
          label={i18n.t('components.forms.news.title')}
          rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="content" label={i18n.t('components.forms.news.content')}>
          <TextArea autoSize={{ minRows: 3 }} maxLength={5000} />
        </Form.Item>
        <Form.Item name="filesDTO" label={i18n.t('components.forms.news.files')}>
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">{i18n.translate(`components.forms.news.upload.text`)}</p>
            <p className="ant-upload-hint">{i18n.translate(`components.forms.news.upload.hint`)}</p>
          </Dragger>
        </Form.Item>
      </Form>
    </>
  );
}

export default NewsForm;
