import React from 'react';
import { Label, Pie, PieChart, ResponsiveContainer, Cell, Tooltip } from 'recharts';
import { CampaignInterface, getSalesOpportunityStatusColor, getSalesOpportunityStatusName, i18n } from '../../common';
import { colors } from '../../theme';

interface Props {
  campaign: CampaignInterface;
  selectedUserId?: number;
}

const CampaignStatisticsPieChart = ({ campaign, selectedUserId }: Props) => {

  const statistics = selectedUserId ? campaign.salesOpportunityStatisticsFromView?.filter((x: any) => x.userId === selectedUserId) : campaign.salesOpportunityStatisticsFromView?.filter((item: any, index: number, array: any) => array.findIndex((item2: any) => (item2.statusId === item.statusId)) === index);

  const data = statistics?.length ? statistics.map((x: any) => ({ name: getSalesOpportunityStatusName(x.statusId), value: selectedUserId ? x.totalPerStatusForUser : x.totalPerStatus, color: getSalesOpportunityStatusColor(x.statusId) })) : [{ name: 'None', value: 1, color: colors.yellowBackground }];

  return (
    <div style={{ width: 200, height: 225 }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={data}
            innerRadius={60}
            outerRadius={100}
            paddingAngle={1}
            dataKey="value"
          >
            {data.map((entry: any, index: number) => (
              <Cell style={{outline: 'none'}} key={`cell-${index}`} fill={entry.color} />
            ))}
            <Label
              position="center"
              fill="grey"
              style={{
                whiteSpace: 'pre',
                fontSize: '16px',
                fontWeight: 'bold',
              }}
            >
              {`${i18n.translate("pages.campaigns.statistics.total")}: ${statistics?.length > 0 ? statistics.reduce((sum: number, statistic: any) => sum + (selectedUserId ? statistic.totalPerStatusForUser : statistic.totalPerStatus), 0) : 0}`}
            </Label>
          </Pie>
          {statistics?.length > 0 && <Tooltip />}
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
}

export default CampaignStatisticsPieChart;
