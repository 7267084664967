/* eslint-disable @typescript-eslint/no-unused-vars */
import { Breadcrumb, Card } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppRoutes } from '..';
import { RootState, createOffer, i18n, useAppDispatch, useAppSelector } from '../../../common';
import { OfferForm } from '../../../components';

const OfferCreatePage = () => {
  const dispatch = useAppDispatch();

  const currentUserId = useAppSelector((state: RootState) => state.auth.user?.id);

  const [priceTotal, setTotal] = useState<number>(0);
  const [totalVat, setVat] = useState<number>(0);

  const onFormFinish = (values: any) => {
    const newOffer = {
      ...values,
      total: priceTotal,
      vat: totalVat,
      user: { id: currentUserId }
    };
    dispatch(createOffer(newOffer));
  };

  return (
    <>
      <Breadcrumb
        style={{ marginBottom: 24 }}
        items={[
          {
            title: <Link to={AppRoutes.DASHBOARD.fullPath}>{i18n.translate('breadcrumb.homepage')}</Link>,
          },
          {
            title: <Link to={AppRoutes.OFFERS.fullPath}>{i18n.translate('breadcrumb.offer')}</Link>,
          },
          { title: i18n.translate('breadcrumb.offerCreate') },
        ]}
      />
      <Card>
        <OfferForm
          onFinish={(values) => onFormFinish(values)}
          total={priceTotal}
          setTotal={setTotal}
          vat={totalVat}
          setVat={setVat}
        />
      </Card>
    </>
  );
};

export default OfferCreatePage;
