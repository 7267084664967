import { Breadcrumb, Button, Card, Col, Descriptions, Divider, Grid, Row, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { QueryParams, RootState, getOffer, i18n, useAppDispatch, useAppSelector, formatNumber, getApplicationSettings, DownloadService, FileExtensionEnum, apiRoutes } from '../../../common';
import { OfferInterface } from '../../../common/interfaces/offer/offer.interface';
import { EditIcon, GuardFunction, OfferItemTable } from '../../../components';
import { AppRoutes } from '../_router/app.routes';

const { useBreakpoint } = Grid;

const { Title } = Typography;

const OfferPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { md } = useBreakpoint();

  const params = useParams();

  const componentQueryParams: QueryParams = { includes: ['client,offerItems,user'] };

  const userAuth = useAppSelector((state: RootState) => state.auth);

  const [queryParams] = useState<QueryParams>(componentQueryParams);

  useEffect(() => {
    if (params.offer_id) dispatch(getOffer(parseInt(params.offer_id!, 10), queryParams));
  }, [params]);

  useEffect(() => {
    dispatch(getApplicationSettings());
  }, []);

  const offer: OfferInterface | undefined = useAppSelector((state: RootState) => state.offer.one);
  const applicationSettings = useAppSelector((state: RootState) => state.applicationSettings.one);

  if (!offer || !offer.id) return null;

  if (!(GuardFunction({ domain: 'offer', action: 'view_one_any' }) || offer.user?.id === userAuth.user!.id)) navigate(AppRoutes.DASHBOARD.fullPath);

  function totalPrice(price: number, vat: number): number {
    return (price - vat);
  }

  function renderSenderDescription() {
    const senderDescriptionsItems: Array<{ label: string; render: JSX.Element }> = [
      {
        label: i18n.translate('pages.offers.descriptions.senderName'),
        render: <>{applicationSettings?.companyName}</>,
      },
      {
        label: i18n.translate('pages.offers.descriptions.address'),
        render: <>{applicationSettings?.address}</>,
      },
      {
        label: i18n.translate('pages.offers.descriptions.oib'),
        render: <>{applicationSettings?.oib}</>,
      },
    ];

    return (
      <Descriptions
        title={i18n.translate('pages.offers.descriptions.sender')}
        column={1}
        labelStyle={{ width: '200px', justifyContent: md ? 'flex-end' : 'flex-start', marginRight: 16 }}
        layout={md ? 'horizontal' : 'vertical'}
      >
        <Descriptions.Item>
          <Divider style={{ margin: 0 }} />
        </Descriptions.Item>
        {senderDescriptionsItems.map((item: { label: string; render: JSX.Element }, index: number) => (
          <Descriptions.Item key={index} label={item.label}>
            {item.render}
          </Descriptions.Item>
        ))}
      </Descriptions>
    );
  }

  function renderRecipientDescription() {
    const recipientDescriptionsItems: Array<{ label: string; render: JSX.Element }> = [
      {
        label: i18n.translate('pages.offers.descriptions.client'),
        render: <>{offer?.client?.name}</>,
      },
      {
        label: i18n.translate('pages.offers.descriptions.address'),
        render: <>{offer?.client?.address}</>,
      },
      {
        label: i18n.translate('pages.offers.descriptions.oib'),
        render: <>{offer?.client?.oib}</>,
      },
    ];

    return (
      <Descriptions
        title={i18n.translate('pages.offers.descriptions.recipient')}
        column={1}
        labelStyle={{ width: '200px', justifyContent: md ? 'flex-end' : 'flex-start', marginRight: 16 }}
        layout={md ? 'horizontal' : 'vertical'}
      >
        <Descriptions.Item>
          <Divider style={{ margin: 0 }} />
        </Descriptions.Item>
        {recipientDescriptionsItems.map((item: { label: string; render: JSX.Element }, index: number) => (
          <Descriptions.Item key={index} label={item.label}>
            {item.render}
          </Descriptions.Item>
        ))}
      </Descriptions>
    );
  }

  function renderTotalDescription() {
    const totalDescriptionsItems: Array<{ label: string; render: JSX.Element }> = [
      {
        label: i18n.translate('pages.offers.descriptions.total'),
        render: <>{formatNumber(totalPrice(offer?.total as number, offer?.vat as number), '€')}</>,
      },
      {
        label: i18n.translate('pages.offers.descriptions.vat'),
        render: <>{formatNumber(offer ? offer.vat : 0, '€')}</>,
      },
    ];

    return (
      <Descriptions column={md ? 1 : 4} labelStyle={{ width: '200px', justifyContent: md ? 'flex-end' : 'flex-start', marginRight: 16 }} layout={md ? 'horizontal' : 'vertical'} style={{ marginTop: 24 }}>
        {totalDescriptionsItems.map((item: { label: string; render: JSX.Element }, index: number) => (
          <Descriptions.Item style={{ float: md ? 'right' : undefined }} key={index} label={item.label}>
            {item.render}
          </Descriptions.Item>
        ))}
        <Descriptions.Item
          style={{ float: md ? 'right' : undefined }}
          label={i18n.translate('pages.offers.descriptions.finalTotal')}
          labelStyle={{ fontWeight: 'bold', color: 'black' }}
          contentStyle={{ fontWeight: 'bold', color: 'black' }}
        >
          {formatNumber(offer?.total, '€')}
        </Descriptions.Item>
      </Descriptions>
    );
  }

  return (
    <div>
      <Breadcrumb
        style={{ marginBottom: 24 }}
        items={[
          {
            title: (
              <Link to={AppRoutes.DASHBOARD.fullPath}>
                {i18n.translate('breadcrumb.homepage')}
              </Link>
            ),
          },
          {
            title: (
              <Link to={AppRoutes.OFFERS.fullPath}>
                {i18n.translate('breadcrumb.offer')}
              </Link>
            ),
          },
          { title: offer?.name },
        ]}
      />
      <Card>
        <Row justify={'space-between'} align={'middle'}>
          <Col>
            <Title level={2} style={{ margin: 0 }}>
              {offer?.name}
            </Title>
          </Col>
          <Col>
            <Space>
              <Button
                onClick={() => {
                  DownloadService.downloadFile(userAuth, [apiRoutes.exportOffer, offer.id].join('/'), offer.name, FileExtensionEnum.PDF);
                }}
              >
                {i18n.translate('pages.offers.exportPdf')}
              </Button>
              {(GuardFunction({ domain: 'offer', action: 'update_any' }) || offer.user?.id === userAuth.user!.id) &&
                <Button
                  type="primary"
                  icon={<EditIcon />}
                  onClick={() => {
                    navigate(`${AppRoutes.OFFERS.fullPath}/${params.offer_id}/edit`);
                  }}
                >
                  {i18n.translate('components.forms.offers.edit')}
                </Button>
              }
            </Space>
          </Col>
        </Row>
        <Row style={{marginTop: 12, marginBottom: 12}}>
          <Descriptions>
            <Descriptions.Item labelStyle={{ marginLeft: -3 }} label={i18n.translate('pages.offers.descriptions.name')}>
              {offer?.name}
            </Descriptions.Item>
          </Descriptions>
        </Row>
        <Row gutter={24}>
          <Col span={12}>{renderSenderDescription()}</Col>
          <Col span={12}>{renderRecipientDescription()}</Col>
        </Row>
        <Row>
          <Col span={24}>
            <OfferItemTable offerItems={offer.offerItems!} />
          </Col>
        </Row>
        <Row>
          <Col span={22}>
            {renderTotalDescription()}
          </Col>
        </Row>
        {offer?.description && <Row style={{marginTop: 24}}>
          <Descriptions>
            <Descriptions.Item label={i18n.translate('pages.offers.descriptions.description')}>
              {offer.description}
            </Descriptions.Item>
          </Descriptions>
        </Row>}
      </Card>
    </div>
  );
};

export default OfferPage;
