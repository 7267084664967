import { ActivityStatusEnum } from "../enums";
import { i18n } from "../services";

export interface ActivityStatus {
  id: number;
  name: string;
}

export const ActivityStatuses = (): Array<ActivityStatus> => [
  { id: ActivityStatusEnum.ACTIVE, name: i18n.translate("common.constants.activityStatus.active") }, // ovaj je izbačen s BE, ovdje je ostavljen čisto da ne dođe kasnije do zabune, no ne korsti se
  { id: ActivityStatusEnum.INACTIVE, name: i18n.translate("common.constants.activityStatus.inactive") },
  { id: ActivityStatusEnum.DONE, name: i18n.translate("common.constants.activityStatus.done") },
  { id: ActivityStatusEnum.PLANNED, name: i18n.translate("common.constants.activityStatus.planned") },
]
