import { Breadcrumb, Button, Card, Col, Row, Space, Typography } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { i18n } from '../../../common';
import { AddIcon, Guard, UsersTable } from '../../../components';
import { AppRoutes } from '../_router/app.routes';

const { Title } = Typography;

const UsersPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Breadcrumb
        items={[
          { title: <Link to={AppRoutes.DASHBOARD.fullPath} key={AppRoutes.DASHBOARD.fullPath}>{i18n.t('breadcrumb.homepage')}</Link> },
          { title: i18n.t('breadcrumb.users') },
        ]}
        style={{ marginBottom: 24 }}
      />
      <Card>
        <Space direction="vertical" size={16} style={{ display: 'flex' }}>
          <Row justify={'space-between'} align={'middle'}>
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                {i18n.t('pages.users.title')}
              </Title>
            </Col>
            <Col>
              <Guard permission={{ domain: 'user', action: 'create' }}>
                <Button
                  type="primary"
                  icon={<AddIcon />}
                  onClick={() => {
                    navigate(`${AppRoutes.USERS.fullPath}/new`);
                  }}
                >
                  {i18n.t('components.forms.user.create')}
                </Button>
              </Guard>
            </Col>
          </Row>
          <UsersTable></UsersTable>
        </Space>
      </Card>
    </>
  );
};

export default UsersPage;
