import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, Card } from 'antd';
import { useAppDispatch, QueryParams, getUser, UserInterface, useAppSelector, RootState, updateUser, i18n } from '../../../common';
import { UserForm } from '../../../components';
import { AppRoutes } from '../_router/app.routes';

const UserEditPage = () => {
  const dispatch = useAppDispatch();

  const params = useParams();

  const queryParams: QueryParams = { includes: ['dbRole'] };

  // TODO možda dodatno zaštititi da se ne dohvaća s api-ja ako je već u reduxu
  useEffect(() => {
    if (params.user_id) dispatch(getUser(parseInt(params.user_id!, 10), queryParams));
  }, [params]
  );

  const user: UserInterface | undefined = useAppSelector((state: RootState) => state.user.one);

  if (!user || !user.id) return null;

  const onUserEdit = (values: UserInterface) => {
    dispatch(updateUser(user.id!, values, false));
  };

  return (
    <>
      <Breadcrumb style={{ marginBottom: 24 }} items={[
        { title: <Link to={AppRoutes.DASHBOARD.fullPath} key={AppRoutes.DASHBOARD.fullPath}>{i18n.translate('breadcrumb.homepage')}</Link> },
        { title: <Link to={AppRoutes.USERS.fullPath} key={AppRoutes.USERS.fullPath}>{i18n.t('breadcrumb.users')}</Link> },
        { title: i18n.translate('breadcrumb.userEdit') }]} />
      <Card>
        <UserForm
          user={user}
          onUserFormFinish={(values) => onUserEdit(values)}
          title={i18n.t('components.forms.user.editTitle')} submitBtnLabel={i18n.t('buttons.saveChanges')}
        />
      </Card>
    </>
  );
}

export default UserEditPage;
