import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { JsonApiService, QueryParams } from '../../api';
import {
  OfferInterface,
  OfferResponseInterface,
  OffersResponseInterface,
} from '../../interfaces/offer/offer.interface';
import type { AppDispatch } from '../store';
import { navigate, i18n } from '../../services';
import { AppRoutes } from '../../../pages/app';

export interface OfferStateInterface {
  all: {
    data: Array<OfferInterface>;
    total?: number;
  };
  one?: OfferInterface;
  error?: Error;
}

const initialState: OfferStateInterface = {
  all: { data: [] },
};


const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    setOfferAllData: (state: OfferStateInterface, action: PayloadAction<Array<OfferInterface>>) => {
      state.all.data = action.payload;
    },
    setOfferAllTotal: (state: OfferStateInterface, action: PayloadAction<number>) => {
      state.all.total = action.payload;
    },
    setOfferOne: (state: OfferStateInterface, action: PayloadAction<OfferInterface>) => {
      state.one = action.payload;
    },
    setOfferError: (state: OfferStateInterface, action: PayloadAction<Error>) => {
      state.error = action.payload;
    },
    createOfferAllData: (state: OfferStateInterface, action: PayloadAction<OfferInterface>) => {
      state.all.data.unshift(action.payload);
      if (state.all.total || state.all.total === 0) state.all.total += 1;
    },
    updateOfferAllData: (state: OfferStateInterface, action: PayloadAction<OfferInterface>) => {
      const index = state.all.data.findIndex((x) => x.id === action.payload.id);
      state.all.data[index] = action.payload;
    },
  },
});

export const { setOfferAllData, setOfferAllTotal, setOfferOne, setOfferError, createOfferAllData, updateOfferAllData } =
  offerSlice.actions;

export const getOffers = (queryParams: QueryParams) => (dispatch: AppDispatch) =>
  JsonApiService.get('offer', undefined, queryParams).subscribe({
    next: (response: OffersResponseInterface) => {
      dispatch(setOfferAllData(response.data));
      if (response.meta && (response.meta.totalResourceCount || response.meta.totalResourceCount === 0)) dispatch(setOfferAllTotal(response.meta.totalResourceCount));
    },
    error: (error: Error) => {
      dispatch(setOfferError(error));
      dispatch(setOfferAllTotal(0));
    },
  });

export const getOffer = (id: number, queryParams?: QueryParams) => (dispatch: AppDispatch) =>
  JsonApiService.get('offer', id, queryParams).subscribe({
    next: (response: OfferResponseInterface) => {
      dispatch(setOfferOne(response.data));
    },
    error: (error: Error) => {
      dispatch(setOfferError(error));
    },
  });

export const createOffer = (data: OfferInterface) => (dispatch: AppDispatch) => {
  JsonApiService.post('offer', data).subscribe({
    next: (response: any) => {
      dispatch(setOfferOne(response.data));
      dispatch(createOfferAllData(response.data));
      notification.success({ message: i18n.translate('components.banners.offer.createSuccess'), duration: 2 });
      navigate(AppRoutes.OFFERS.fullPath);
    },
    error: (error: Error) => {
      dispatch(setOfferError(error));
      notification.error({ message: i18n.translate('components.banners.offer.createError'), duration: 2 });
    },
  });
};

export const updateOffer = (id: number, data: any) => (dispatch: AppDispatch) => {
  JsonApiService.patch('offer', id, data).subscribe({
    next: (response: any) => {
      dispatch(setOfferOne(response.data));
      dispatch(updateOfferAllData(response.data));
      notification.success({ message: i18n.translate('components.banners.offer.editSuccess'), duration: 2 });
    },
    error: (error: Error) => {
      dispatch(setOfferError(error));
      notification.error({ message: i18n.translate('components.banners.offer.editError'), duration: 2 });
    },
  });
};

export default offerSlice.reducer;
