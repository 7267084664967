import { Layout, Menu, Row, Space, Typography, theme } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PlumLogoWhite from '../../../assets/images/plum logo_white.svg';
import i18n from '../../../common/services/translate.service';
import { AppRoutes } from '../../../pages/app/_router/app.routes';
import { GuardFunction } from '../../guard';
import { ActivitiesIcon, AssignmentsIcon, CampaignsIcon, ClientsIcon, DashboardIcon, OffersIcon, ReservationsIcon, SalesIcon, UsersIcon } from '../../icons';
import { colors } from '../../../theme';

const { useToken } = theme;

const { Sider } = Layout;
const { Title } = Typography;

export default function SiderComponent() {

  const { token } = useToken();

  const navigate = useNavigate();

  const navItems = [
    {
      key: AppRoutes.DASHBOARD.path,
      icon: <DashboardIcon />,
      label: i18n.translate(`sider.navigation.menu.dashboard`),
      onClick: () => navigate(AppRoutes.DASHBOARD.fullPath)
    },
    {
      key: AppRoutes.CLIENTS.path,
      icon: <ClientsIcon />,
      label: i18n.translate(`sider.navigation.menu.clients`),
      onClick: () => navigate(AppRoutes.CLIENTS.path)
    },
    {
      key: AppRoutes.SALES_OPPORTUNITIES.path,
      icon: <SalesIcon />,
      label: i18n.translate(`sider.navigation.menu.sales`),
      onClick: () => navigate(AppRoutes.SALES_OPPORTUNITIES.path)
    },
    {
      key: AppRoutes.CAMPAIGNS.path,
      icon: <CampaignsIcon />,
      label: i18n.translate(`sider.navigation.menu.campaigns`),
      onClick: () => navigate(AppRoutes.CAMPAIGNS.path)
    },
    {
      key: AppRoutes.ACTIVITIES.path,
      icon: <ActivitiesIcon fill='none' />,
      label: i18n.translate(`sider.navigation.menu.activities`),
      onClick: () => navigate(AppRoutes.ACTIVITIES.path)
    },
    {
      key: AppRoutes.ASSIGNMENTS.path,
      icon: <AssignmentsIcon />,
      label: i18n.translate(`sider.navigation.menu.assignments`),
      onClick: () => navigate(AppRoutes.ASSIGNMENTS.path)
    },
    {
      key: AppRoutes.OFFERS.path,
      icon: <OffersIcon />,
      label: i18n.translate(`sider.navigation.menu.offers`),
      onClick: () => navigate(AppRoutes.OFFERS.path)
    },
    {
      key: AppRoutes.RESERVATIONS.path,
      icon: <ReservationsIcon />,
      label: i18n.translate(`sider.navigation.menu.reservations`),
      onClick: () => navigate(AppRoutes.RESERVATIONS.path)
    },
    GuardFunction({ domain: 'user', action: 'view_all_any' }) ?
      {
        key: AppRoutes.USERS.path,
        icon: <UsersIcon />,
        label: i18n.translate(`sider.navigation.menu.users`),
        onClick: () => navigate(AppRoutes.USERS.path)
      } : null,
  ].filter(x => x !== null);

  const getDefaultSelectedKeys = (): string[] | undefined => {
    const itemKey = window.location.pathname.split('/')[1];
    for(const navItem of navItems) {
      if (navItem?.key === itemKey) return [navItem.key];
    }
    return [AppRoutes.DASHBOARD.path]
  }

  const onCollapse = (collapsed: boolean) => {
    const container = document.getElementById('app-container');

    if (container) {
      if (!collapsed && window.innerWidth > 900) {
        container.style.marginLeft = '263px';
      } else {
        container.style.marginLeft = '0px';
      }
    }
  };

  return (
    <Sider
      style={{
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 999,
        backgroundColor: colors.bluePrimary
      }}
      width={263}
      breakpoint="lg"
      collapsedWidth="0"
      onCollapse={(collapsed) => {
        onCollapse(collapsed);
      }}
    >
      <Row justify='start' align='middle' style={{ minHeight: 63, paddingLeft: 24 }}>
        <Space size='middle' align='center'>
          <img width={17} height={20} src={PlumLogoWhite} alt="" style={{ color: token.colorWhite }} />
          <Title level={3} style={{ color: token.colorWhite, margin: 0 }}>{i18n.translate(`login.top.title`)}</Title>
        </Space>
      </Row>
      <Menu
        mode="inline"
        defaultSelectedKeys={getDefaultSelectedKeys()}
        items={navItems}
      />
    </Sider>
  );
}
