import { Locale } from 'antd/es/locale';
import enGB from 'antd/locale/en_GB';
import hrHR from 'antd/locale/hr_HR';
import { LanguageEnum } from '../enums';
import { i18n } from '../services';

export interface Language {
  id: 'en' | 'hr';
  name: string;
  locale: Locale;
  localeCalendar: 'en' | 'hr';
}

export const Languages = (): Array<Language> => [
  { id: LanguageEnum.HR, name: i18n.translate('common.constants.language.hr'), locale: hrHR, localeCalendar: 'hr' },
  { id: LanguageEnum.EN, name: i18n.translate('common.constants.language.en'), locale: enGB, localeCalendar: 'en' },
];

export const getLanguage = (id?: 'en' | 'hr') => (id && Languages().find((p: Language) => p.id === id)) || Languages()[0];

export const getLanguageId = (id?: 'en' | 'hr') => getLanguage(id).id;

export const getLanguageName = (id?: 'en' | 'hr') => getLanguage(id).name;

export const getLanguageLocale = (id?: 'en' | 'hr') => getLanguage(id).locale;

export const getLanguageLocaleCalendar = (id?: 'en' | 'hr') => getLanguage(id).localeCalendar;
