import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Breadcrumb, Card } from 'antd';
import { useAppDispatch, getCampaign, CampaignInterface, useAppSelector, RootState, updateCampaign, i18n, CampaignStatusEnum } from '../../../common';
import { CampaignForm, GuardFunction, allCampaignFormItems } from '../../../components';
import { AppRoutes } from '../_router/app.routes';

const CampaignEditPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const params = useParams();

  // TODO možda dodatno zaštititi da se ne dohvaća s api-ja ako je već u reduxu
  useEffect(() => {
    if(params.campaign_id) dispatch(getCampaign(parseInt(params.campaign_id!, 10), { includes: ['campaignStatus', 'files'] }));
  }, [params]
  );

  const formItems = !GuardFunction({ domain: 'campaign', action: 'deactivate' }) ? allCampaignFormItems.filter((f) => (f !== 'active')) : undefined;

  const campaign: CampaignInterface | undefined = useAppSelector((state: RootState) => state.campaign.one);

  if (!campaign || !campaign.id) return null;

  if (!GuardFunction({ domain: 'campaign', action: 'update' })) navigate(AppRoutes.DASHBOARD.fullPath);

  const disabled = campaign.campaignStatus?.id === CampaignStatusEnum.FINISHED ? allCampaignFormItems.filter((f) => (f !== 'campaignStatus')) : undefined;

  const onCampaignEdit = (values: CampaignInterface) => {
    dispatch(updateCampaign(campaign.id!, values, { includes: ['campaignStatus', 'files'] }));
  };

  return (
    <>
      <Breadcrumb style={{marginBottom: 24}} items={[
        { title: <Link to={AppRoutes.DASHBOARD.fullPath} key={AppRoutes.DASHBOARD.fullPath}>{i18n.translate('breadcrumb.homepage')}</Link>}, 
        { title: <Link to={AppRoutes.CAMPAIGNS.fullPath} key={AppRoutes.CAMPAIGNS.fullPath}>{i18n.t('breadcrumb.campaigns')}</Link> }, 
        { title: i18n.translate('breadcrumb.campaignEdit') }]} />
      <Card>
        <CampaignForm formItems={formItems} disabled={disabled} campaign={campaign} onCampaignFormFinish={(values) => onCampaignEdit(values)} title={i18n.t('components.forms.campaign.edit')} submitBtnLabel={i18n.t('buttons.saveChanges')}></CampaignForm>
      </Card>
    </>
  );
}

export default CampaignEditPage;
