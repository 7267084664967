import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { ServiceInterface, ServiceResponseInterface, ServicesResponseInterface } from '../../interfaces';
import type { AppDispatch } from '../store';
import { JsonApiService, QueryParams } from '../../api';
import { i18n } from '../../services';

// ============ INTERFACE ============

export interface ServiceStateInterface {
  all: {
    data: Array<ServiceInterface>;
    total?: number;
  };
  one?: ServiceInterface;
  error?: Error;
}

// ============ INIT STATE ============

const initialState: ServiceStateInterface = {
  all: {data: []},
};


// ========= SLICE ==========

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setServiceAllData: (state: ServiceStateInterface, action: PayloadAction<Array<ServiceInterface>>) => {
      state.all.data = action.payload;
    },
    setServiceAllTotal: (state: ServiceStateInterface, action: PayloadAction<number>) => {
      state.all.total = action.payload;
    },
    setServiceOne: (state: ServiceStateInterface, action: PayloadAction<ServiceInterface>) => {
      state.one = action.payload;
    },
    updateServiceAllData: (state: ServiceStateInterface, action: PayloadAction<ServiceInterface>) => {
      const index = state.all.data.findIndex(x => x.id === action.payload.id);
      state.all.data[index] = action.payload;
    },
    createServiceAllData: (state: ServiceStateInterface, action: PayloadAction<ServiceInterface>) => {
      state.all.data.unshift(action.payload);
      if (state.all.total || state.all.total === 0) state.all.total += 1;
    },
    setServiceError: (state: ServiceStateInterface, action: PayloadAction<Error>) => {
      state.error = action.payload;
    }
  }
})

export const { setServiceAllData, setServiceError, setServiceAllTotal, setServiceOne, updateServiceAllData, createServiceAllData } = serviceSlice.actions;

export const getServices = (queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('services', undefined, queryParams)
    .subscribe({
      next: (response: ServicesResponseInterface) => {
        dispatch(setServiceAllData(response.data));
        if (response.meta && (response.meta.totalResourceCount || response.meta.totalResourceCount === 0)) dispatch(setServiceAllTotal(response.meta.totalResourceCount));
      },
      error: (error: Error) => {
        dispatch(setServiceError(error));
        dispatch(setServiceAllTotal(0));
      }
    })
}

export const getService = (id: number, queryParams?: QueryParams) => (dispatch: AppDispatch) => {
  JsonApiService.get('services', id, queryParams)
    .subscribe({
      next: (response: ServiceResponseInterface) => {
        dispatch(setServiceOne(response.data));
      },
      error: (error: Error) => {
        dispatch(setServiceError(error));
      }
    })
}

export const createService = (data: ServiceInterface) => (dispatch: AppDispatch) => {
  // example
  JsonApiService.post('services', data)
    .subscribe({
      next: (response: any) => {
        dispatch(setServiceOne(response.data));
        dispatch(createServiceAllData(response.data));
        notification.success({ message: i18n.translate('components.other.services.createSuccess'), duration: 2 });
      },
      error: (error: Error) => {
        dispatch(setServiceError(error));
      }
    })
}

export const updateService = (id: number, data: ServiceInterface) => (dispatch: AppDispatch) => {
  // example
  JsonApiService.patch('services', id, data)
    .subscribe({
      next: (response: any) => {
        dispatch(setServiceOne(response.data));
        dispatch(updateServiceAllData(response.data));
      },
      error: (error: Error) => {
        dispatch(setServiceError(error));
      }
    })
}

export default serviceSlice.reducer;
