import React, { useEffect, useState } from 'react';
import { Button, Dropdown, MenuProps, Space, Tag, Tree, TreeProps, Typography, theme } from "antd";
import { DownOutlined, CloseOutlined } from '@ant-design/icons';

import { FilterProps } from '../../tables/table.component';
import { QueryParams, RootState, AreaInterface, getAreaFilter, i18n, useAppDispatch, useAppSelector } from '../../../common';
import Search from '../search/search.component';

const { Title } = Typography;
const { useToken } = theme;

interface FilterValue {
  id: number;
  checked: boolean;
}

interface TreeValue {
  id: number;
  key: number;
  title: string;
  children?: Array<TreeValue>;
}

const AreaFilter = ({ dataKey, handleFilterChange }: FilterProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const [filterValues, setFilterValues] = useState<Array<FilterValue>>([]);
  const [treeValues, setTreeValues] = useState<Array<TreeValue>>([]);

  const [selectedAreas, setSelectedAreas] = useState<Array<AreaInterface>>([]);

  const [open, setOpen] = useState(false);

  const areaFilter = useAppSelector((state: RootState) => state.area.filter);

  const componentQueryParams: QueryParams = { includes: ['childrenAreas'], pagination: { current: 1, pageSize: 999999 } };

  useEffect(() => {
    dispatch(getAreaFilter(componentQueryParams));
  }, []
  )

  useEffect(() => {
    if (areaFilter && !filterValues.length) {
      setTreeValues(areaFilter.map((area: AreaInterface) => ({ id: area.id!, key: area.id!, title: area.name, children: area.childrenAreas?.map((childArea: AreaInterface) => ({ id: childArea.id!, key: childArea.id!, title: childArea.name})) })));
    }
  }, [areaFilter]
  )

  const findChildAreaParent = (id: number) => {
    let childArea;
    let foundParent;

    areaFilter?.forEach(area => {
      childArea = area.childrenAreas?.find(x => x.id === id);
      if (childArea) foundParent = area;
    });

    return foundParent;
  };

  const onValueChange: TreeProps['onCheck'] = (checkedKeys: any) => {
    setSelectedAreas(checkedKeys.map((x: number) => (findChildAreaParent(x))).filter((x: any, index: number, self: any) => x !== undefined && self.indexOf(x) === index));
    setFilterValues(checkedKeys.map((x: number) => ({ id: x, checked: true })));
  };

  const onFilterConfirm = () => {
    if (filterValues.filter((fv: FilterValue) => fv.checked).length === 0) handleFilterChange();
    else handleFilterChange({name: dataKey, operator: 'EQ', value: filterValues.filter((f: FilterValue) => f.checked).map((f: FilterValue) => f.id.toString())});
    setOpen(false);
  }

  const handleOpenChange = (flag: boolean) => {
    setOpen(flag);
  };

  const onCloseTag = (index: number) => {
    const newSelectedAreas = [...selectedAreas];
    newSelectedAreas.splice(index, 1);
    setSelectedAreas([...newSelectedAreas]);
    setFilterValues([...newSelectedAreas].map((x: AreaInterface) => ({ id: x.id!, checked: true })));
  };

  const onSearchChange = (keys: Array<string>, value: string) => {
    dispatch(getAreaFilter({ includes: ['childrenAreas'], pagination: { current: 1, pageSize: 999999 }, filters: [{ name: keys[0], operator: 'LIKE', value: encodeURIComponent(`${value}%`)}] }));
    // dispatch(getAreaFilter({ includes: ['childrenAreas'], pagination: { current: 1, pageSize: 999999 }, search: { keys, value } }));
  };

  // konfiguracija menu-a unutar dropdowna
  const items: MenuProps['items'] = [{
    label: (
      <Tree
        checkable
        selectable={false}
        onCheck={onValueChange}
        treeData={treeValues}
        checkedKeys={filterValues.map((fv: FilterValue) => fv.id)}
      />
    ),
    key: 1,
    style: { backgroundColor: 'inherit' }
  }]

  // Dropdown i menu style - nažalost ant design nije ponudio kvalitetnije rješenje, a nisam htio iz nule raditi dropdown i menu
  const { token } = useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8
  };

  const menuStyle = {
    boxShadow: 'none',
    padding: 0,
    margin: 0,
    height: 250,
    overflow: 'auto'
  };

  return (
    <Dropdown
      menu={{ items }}
      open={open}
      onOpenChange={handleOpenChange}
      trigger={['click']} dropdownRender={(menu) => (
        <div style={contentStyle}>
          <Title level={3} style={{ paddingLeft: 12, paddingRight: 12, paddingBottom: 8 }}>{i18n.t('components.filters.area')}</Title>
          <Space direction="vertical" size="small" style={{ display: 'flex', paddingLeft: 12, paddingBottom: 8 }}>
            {selectedAreas?.map((area: AreaInterface, index: number) =>
              <Tag color="processing" closable closeIcon={<CloseOutlined />} onClose={() => onCloseTag(index)} style={{ borderRadius: 16 }} key={area.id}>{area.name}</Tag>
            )}
          </Space>
          <Space direction="vertical" size="small" style={{ display: 'flex', paddingLeft: 12, paddingBottom: 8 }}>
            <Search dataKeys={['name']} placeholder='' handleSearchChange={onSearchChange} />
          </Space>
          {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
          <Space style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 16 }}>
            <Button type='link' onClick={onFilterConfirm}>
              Filtriraj
            </Button>
          </Space>
        </div>
      )}
    >
      <a onClick={(e) => e.preventDefault()}>
        <Space>
          {i18n.t('components.filters.area')}{filterValues.find((f: FilterValue) => f.checked) && `(${filterValues.filter((f: FilterValue) => f.checked).length})`}
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  )
}

export default AreaFilter;
