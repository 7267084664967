import { PlusSquareOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Divider, Form, Grid, Input, InputNumber, Row, Select, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { ClientInterface, RootState, getApplicationSettings, i18n, useAppDispatch, useAppSelector, formatNumber, getClientFilter } from '../../../common';
import { OfferInterface } from '../../../common/interfaces/offer/offer.interface';
import { colors } from '../../../theme';
import { DeleteIcon } from '../../icons';

const { useBreakpoint } = Grid;

const { Title, Text } = Typography;
interface Props {
  onFinish: (values: any) => void;
  offer?: OfferInterface;
  total: number;
  setTotal: (i: number) => void;
  vat: number;
  setVat: (i: number) => void;
}

function OfferForm({ onFinish, offer, total, setTotal, vat, setVat }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { md } = useBreakpoint();

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(getClientFilter({pagination: { current: 1, pageSize: 999999 }, sorters: [{ columnKey: 'name', order: 'ascend' }]}));
    dispatch(getApplicationSettings());
  }, []);

  // Mapiranje izlaznih vrijednosti
  const onFormSubmit = (values: any) => {
    const data = {
      ...values,
      offerItemDTOs: values.offerItems,
      offerItems: undefined
    } 
    if (onFinish) onFinish(data);
  }

  const clients = useAppSelector((state: RootState) => state.client.filter);
  const applicationSettings = useAppSelector((state: RootState) => state.applicationSettings.one);

  const onClientChange = (value: number) => {
    const client: ClientInterface | undefined = clients?.find((c: ClientInterface) => c.id === value);
    form.setFieldsValue({ client: { oib: client?.oib, address: client?.address } });
  };

  function renderSenderDescription() {
    const senderDescriptionsItems: Array<{ label: string; render: JSX.Element }> = [
      {
        label: i18n.translate('pages.offers.descriptions.name'),
        render: <>{applicationSettings?.companyName}</>,
      },
      {
        label: i18n.translate('pages.offers.descriptions.address'),
        render: <>{applicationSettings?.address}</>,
      },
      {
        label: i18n.translate('pages.offers.descriptions.oib'),
        render: <>{applicationSettings?.oib}</>,
      },
    ];

    return (
      <Descriptions
        title={i18n.translate('pages.offers.descriptions.sender')}
        column={1}
        labelStyle={{ width: '200px', justifyContent: md ? 'flex-end' : 'flex-start', marginRight: 16 }}
        layout={md ? 'horizontal' : 'vertical'}
      >
        <Descriptions.Item>
          <Divider style={{ margin: 0 }} />
        </Descriptions.Item>
        {senderDescriptionsItems.map((item: { label: string; render: JSX.Element }, index: number) => (
          <Descriptions.Item key={index} label={item.label}>
            {item.render}
          </Descriptions.Item>
        ))}
      </Descriptions>
    );
  }

  function renderTotalDescription() {
    const totalDescriptionsItems: Array<{ label: string; render: JSX.Element }> = [
      {
        label: i18n.translate('pages.offers.descriptions.total'),
        render: <>{formatNumber(total - vat, '€')}</>,
      },
      {
        label: i18n.translate('pages.offers.descriptions.vat'),
        render: <>{formatNumber(vat, '€')}</>,
      },
    ];

    return (
      <Descriptions column={md ? 1 : 4} labelStyle={{ width: '200px', justifyContent: md ? 'flex-end' : 'flex-start', marginRight: 16 }} layout={md ? 'horizontal' : 'vertical'}>
        {totalDescriptionsItems.map((item: { label: string; render: JSX.Element }, index: number) => (
          <Descriptions.Item style={{ float: md ? 'right' : undefined }} key={index} label={item.label}>
            {item.render}
          </Descriptions.Item>
        ))}
        <Descriptions.Item
          style={{ float: md ? 'right' : undefined }}
          label={i18n.translate('pages.offers.descriptions.finalTotal')}
          labelStyle={{ fontWeight: 'bold', color: 'black' }}
          contentStyle={{ fontWeight: 'bold', color: 'black' }}
        >
          {formatNumber(total, '€')}
        </Descriptions.Item>
      </Descriptions>
    );
  }

  const handleTotal = (offerItemsValues: any) => {
    if (offerItemsValues) {
      const items = [...offerItemsValues];
      let tempTotal = 0;
      let tempVat = 0;
      offerItemsValues.forEach((fieldGroup: any, index: any) => {
        if (fieldGroup && fieldGroup.price && fieldGroup.amount) {
          const base = fieldGroup.price * fieldGroup.amount;
          const discount = base * (fieldGroup.discount / 100);
          const vatValue = (base - discount) * (fieldGroup.vat / 100); 
          fieldGroup.total = (base - discount) + vatValue;
          items.splice(index, 1, fieldGroup);
          form.setFieldsValue({ offerItems: items });
          tempTotal += fieldGroup.total;
          tempVat += vatValue;
        }
      });
      setTotal(tempTotal);
      setVat(tempVat);
    }
  };

  const offerItems = Form.useWatch("offerItems", form);
  useEffect(() => {
    handleTotal(offerItems);
  }, [offerItems]);

  const initialValues = {
    ...offer
  };

  return (
    <Form size='middle' className='offerForm' name="offerForm" form={form} onFinish={onFormSubmit} initialValues={initialValues} layout={md ? 'horizontal' : 'vertical'}>
      <Row justify={'space-between'}>
        <Title level={3}>
          {offer
            ? i18n.translate('components.forms.offers.editTitle')
            : i18n.translate('components.forms.offers.createTitle')}
        </Title>
        <Space>
          <Button onClick={() => navigate(-1)}>{i18n.translate('components.forms.common.cancel')}</Button>
          <Button type="primary" htmlType="submit">
            {offer ? i18n.translate('components.forms.offers.edit') : i18n.translate('components.forms.offers.create')}
          </Button>
        </Space>
      </Row>
      <Row>
        <Form.Item
          name="name"
          label={<Text type="secondary">{i18n.t('components.forms.offers.name')}</Text>}
          style={{ width: '100%', marginTop: 24 }}
          wrapperCol={{ md: { span: 8 } }}
          rules={[{ required: true, message: i18n.t(`components.forms.common.required`) }]}
        >
          <Input size='small' />
        </Form.Item>
      </Row>
      <Row gutter={24}>
        <Col span={12}>{renderSenderDescription()}</Col>
        <Col span={12}>
          <Descriptions title={i18n.translate('pages.offers.descriptions.recipient')} column={1}>
            <Descriptions.Item>
              <Divider style={{ margin: 0 }} />
            </Descriptions.Item>
          </Descriptions>
          <Form.Item
            labelCol={{ md: { span: 6 } }}
            wrapperCol={{ md: { span: 12 } }}
            name={['client', 'id']}
            label={<Text type="secondary">{i18n.translate('components.forms.offers.clients')}</Text>}
            rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
          >
            <Select showSearch placeholder={i18n.translate('components.forms.common.select')} onChange={onClientChange}>
              {clients?.map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={['client', 'address']}
            labelCol={{ md: { span: 6 } }}
            wrapperCol={{ md: { span: 16 } }}
            label={<Text type="secondary">{i18n.translate('components.forms.offers.address')}</Text>}
          >
            <Input size='small' disabled />
          </Form.Item>
          <Form.Item
            name={['client', 'oib']}
            labelCol={{ md: { span: 6 } }}
            wrapperCol={{ md: { span: 12 } }}
            label={<Text type="secondary">{i18n.translate('components.forms.offers.oib')}</Text>}
          >
            <Input size='small' disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row
        className='offerItemsHeaderRow'
        style={{ backgroundColor: colors.greyBackground, padding: '12px', marginBottom: '12px' }}
        justify={'space-between'}
      >
        <Col span={4}>{i18n.t('components.forms.offers.code')}</Col>
        <Col span={4}>{i18n.t('components.forms.offers.itemName')}</Col>
        <Col span={3}>{i18n.t('components.forms.offers.price')}</Col>
        <Col span={2}>{i18n.t('components.forms.offers.amount')}</Col>
        <Col span={2}>{i18n.t('components.forms.offers.vat')}</Col>
        <Col span={2}>{i18n.t('components.forms.offers.discount')}</Col>
        <Col span={5}>{i18n.t('components.forms.offers.total')}</Col>
      </Row>
      <Form.List name="offerItems">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <>
                <Title level={2} className='fieldTitle'>{`${i18n.t('components.forms.offers.product')} ${index + 1}`}</Title>
                <Row
                  className='offerItems'
                  key={field.key}
                  wrap={false}
                  style={{ paddingLeft: '12px', paddingRight: '12px' }}
                  justify={'space-between'}
                >
                  <Col span={4}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'itemCode']}
                      label={i18n.t('components.forms.offers.code')}
                    >
                      <Input size='small' placeholder={i18n.t('components.forms.offers.code')} />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'name']}
                      label={i18n.t('components.forms.offers.itemName')}
                      rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
                    >
                      <Input size='small' placeholder={i18n.t('components.forms.offers.itemName')} />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'price']}
                      label={i18n.t('components.forms.offers.price')}
                      rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
                    >
                      <InputNumber
                        min={0}
                        controls={false}
                        decimalSeparator={','}
                        step={'0.01'}
                        placeholder={'0,00'}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'amount']}
                      label={i18n.t('components.forms.offers.amount')}
                      rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
                    >
                      <InputNumber
                        placeholder={'1'} 
                        min={1}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'vat']}
                      label={i18n.t('components.forms.offers.vat')}
                      rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
                    >
                      <InputNumber
                        min={0}
                        max={100}
                        formatter={(value) => `${value}%`}
                        placeholder={i18n.t('components.forms.offers.vat')}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item
                      {...field}
                      name={[field.name, 'discount']}
                      label={i18n.t('components.forms.offers.discount')}
                      rules={[{ required: true, message: i18n.translate(`components.forms.common.required`) }]}
                    >
                      <InputNumber
                        min={0}
                        max={100}
                        formatter={(value) => `${value}%`}
                        placeholder={i18n.t('components.forms.offers.discount')}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Row wrap={false} justify="space-between">
                      <Form.Item style={{ width: '100%', marginRight: 8}} {...field} name={[field.name, 'total']}>
                        <InputNumber
                          min={0}
                          controls={false}
                          formatter={(value) => `${formatNumber(value, '€')}`}
                          step={'0.01'}
                          placeholder={'0,00'}
                          disabled
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                      <Form.Item>
                        <Button style={{ height: 32 }}icon={<DeleteIcon style={{ color: 'red' }} />} onClick={() => remove(field.name)} />
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
              </>
            ))}
            <Row>
              <Form.Item>
                <Button type="link" onClick={() => add({ amount: 1, vat: 25, discount: 0 })} icon={<PlusSquareOutlined />}>
                  {i18n.t('components.forms.offers.addOfferItem')}
                </Button>
              </Form.Item>
            </Row>
          </>
        )}
      </Form.List>
      <Row>
        <Col span={22}>
          {renderTotalDescription()}
        </Col>
      </Row>
      <Row>
        <Form.Item
          name="description"
          label={<Text type="secondary">{i18n.t('components.forms.offers.description')}</Text>}
          style={{ width: '100%', marginTop: 24 }}
          wrapperCol={{ md: { span: 8 } }}
        >
          <TextArea autoSize={{ minRows: 3 }} maxLength={5000} />
        </Form.Item>
      </Row>
    </Form>
  );
}

export default OfferForm;
